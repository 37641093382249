import React, { useContext, useMemo, useReducer } from 'react';

import ServerDataContext from './context'

import reducer, { initialState } from '../reducer/LoginReducer'

import { keepOpen } from '../helpers/keepDropdownOpen'



export const ServerDataProvider = (props) => {

  const [ state, dispatch ] = useReducer(reducer, initialState)

  const value = useMemo(() => {
    return {
      data: props.value
    };
  }, [props.value]);

  const setRole = (data) => {
    dispatch({ type: 'SET_ROLE',payload:data })
  }
  const setUserName = data =>{
    dispatch({ type: 'SET_NAME',payload:data })
  }

  const setUserId = id => {
    dispatch({ type: 'SET_USER_ID',payload: id })
  }

  const setCdn = data => {
    dispatch({ type: 'SET_CDN', payload: data })
  }
  
  const handleTransactionDropdown = id => {
    keepOpen(id)
  }
 
  const handleSettingDropdown = id => {
    keepOpen(id)
  }
  
  const drpDwnKeepOpen = _ => {
    $(document).on('click', '#content-wrapper', function (e) {
      e.stopPropagation();
    });
  }

    //
    const toggleSidebar = _ => {
      const bg = document.querySelector('#content-wrapper');
      const btn = document.querySelector('.sidebar')
  
      btn.classList.toggle('toggled')
      bg.classList.toggle('Sidebar_background_scroll')
    }
  
    const closeSidebar = _ => {
      $(document).on('click', '.nav-link', function (e) {
          const element = document.querySelector('.sidebar')
          element.classList.remove('toggled')
      });
    }
  
    // const closeSidebar = _ => {
    //   const element = document.querySelector('.sidebar')
    //   const bg = document.querySelector('#content-wrapper')
    //   // console.log('tested')
    //   if(element.classList.contains('toggled')){
    //     element.classList.toggle('toggled')
    //   }
    //   if(bg.classList.contains('Sidebar_background_scroll')){
    //     bg.classList.toggle('Sidebar_background_scroll')
    //   }
    // }
  
    //
  

  return (
    <ServerDataContext.Provider value={{ state, dispatch, setRole, setUserName, setCdn, setUserId, handleTransactionDropdown, handleSettingDropdown, drpDwnKeepOpen, toggleSidebar, closeSidebar }}>
      {props.children}
    </ServerDataContext.Provider>
  );
};

export const useServerData = (fn) => {
  const context = useContext(ServerDataContext);

  if (!context) {
    throw new Error(
      'useServerData() must be a child of <ServerDataProvider />'
    );
  }

  if (fn) {
    return fn(context.data);
  }
  return context.data;
};
