import { data } from "jquery"

export default function dashboardApi(http) {
  return {
    getDashboardData: (data) => {
      return http.post('/api/dashboardData', data)
    },
    getDashboardGraph: () => {
      return http.post("/api/dashboardGraph")
    }

  }
}
