export default function orderApi(http) {

  return {
    create: (order) => {
      return http.post('/api/add-order', order)
    },
    getOrders: () => {
      return http.get("/api/orders")
    },
    getOrdersData: (data) => {
      return http.post("/api/get-orders", data)
    },
    viewOrders: (id) => {
      return http.post('/api/get-order', id)
    },
    addTOCart: (order) => {
      return http.post('/api/add-to-cart', order)

    },
    getCarts: () => {
      return http.post('/api/carts')
    },
    deleteCart: (id) => {
      return http.post('/api/delete-cart', id)
    },
    reprocessOrder: (id) => {
      return http.post('/api/reprocess-order', id)
    },
    getDashboardOrders: (data) => {
      return http.post('/api/orders', data)
    },

    exportOrdersDetails: (id) => {
      return http.post('/api/export-orders', id)
    },
    exportAllOrders: (data) => {
      return http.post("/api/export-all-orders", data)
    },
  }
}
