import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Prototype from 'prop-types';
import AppContext from '../../state/context';

const AddButton = ({ link, buttonName, appRole }) => {
    const { state: { role } } = useContext(AppContext);
    if (appRole.indexOf(role) >= 0) {
        return (
            <Link to={link} className="btn new-btn-top float-right">
                {buttonName}
            </Link>
        );
    }
    return null;
}
AddButton.protoType = {
    link: Prototype.string.isRequired,
    buttonName: Prototype.string.isRequired,
    appRole: Prototype.string.isRequired,
}
export default AddButton;