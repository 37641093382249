export default function customerInfoApi(http) {
    return {
        getCustomerInfoList: () => {
            return http.get('/api/getCustomerList')
        },
        getCustomerInfoById: (id) => {
            return http.post('/api/getCustomerInfoById', id)
        },
        addCustomerInfo: (data) => {
            return http.post('/api/addCustomerInfo', data)
        },
        updateCustomerInfo: (data) => {
            return http.post('/api/updateCustomerInfo', data)
        },
        deleteCustomerInfo: (id) => {
            return http.post('/api/deleteCustomerInfo', id)
        }
    }
}
