export default function merchantApi(http) {

  return {
    create: (merchant) => {
      return http.post('/api/add-merchants', merchant)
    },
    getMerchants: () => {
      return http.get('/api/merchants')
    },
    getMerchantList: (data) => {
      return http.post('/api/merchantsList',data)
    },
    getMerchantsById: (id) => {
      return http.post('/api/merchantById',id)
    },
    delete: (id) => {
      return http.post('/api/delete-merchant', id)
    },
    update: (merchant) => {
      return http.post('/api/update-merchant', merchant)
    },
    getTopUpHistory: (data) => {
      return http.post('/api/getTopUpHistory', data)
    },
    getMerchantsRequestById: (id) => {
      return http.post('/api/merchantRequestById',id)
    },
    updateMerchantsRequest: (merchant) => {
      return http.post('/api/update-merchant-request', merchant)
    },
    acceptMerchantsRequest: (merchant) => {
      return http.post('/api/accept-merchant-request', merchant)
    },
    getMerchantAgreement: (id) => {
      return http.post('/api/merchantAgreement',id)
    },
    updateMerchantAgreement: (merchant) => {
      return http.post('/api/update-merchant-agreement', merchant)
    },
    getMerchantAgreementView: (id) => {
      return http.post('/api/merchantAgreementView',id)
    },
  }
}
