export const states =[
    {
      "label": "Andaman and Nicobar Islands",
      "value": "Andaman and Nicobar Islands",
      "country_id": "India"
    },
    {
      "label": "Andhra Pradesh",
      "value": "Andhra Pradesh",
      "country_id": "India"
    },
    {
      "label": "Arunachal Pradesh",
      "value": "Arunachal Pradesh",
      "country_id": "India"
    },
    {
      "label": "Assam",
      "value": "Assam",
      "country_id": "India"
    },
    {
      "label": "Bihar",
      "value": "Bihar",
      "country_id": "India"
    },
    {
      "label": "Chandigarh",
      "value": "Chandigarh",
      "country_id": "India"
    },
    {
      "label": "Chhattisgarh",
      "value": "Chhattisgarh",
      "country_id": "India"
    },
    {
      "label": "Dadra and Nagar Haveli",
      "value": "Dadra and Nagar Haveli",
      "country_id": "India"
    },
    {
      "label": "Daman and Diu",
      "value": "Daman and Diu",
      "country_id": "India"
    },
    {
      "label": "Delhi",
      "value": "Delhi",
      "country_id": "India"
    },
    {
      "label": "Goa",
      "value": "Goa",
      "country_id": "India"
    },
    {
      "label": "Gujarat",
      "value": "Gujarat",
      "country_id": "India"
    },
    {
      "label": "Haryana",
      "value": "Haryana",
      "country_id": "India"
    },
    {
      "label": "Himachal Pradesh",
      "value": "Himachal Pradesh",
      "country_id": "India"
    },
    {
      "label": "Jammu and Kashmir",
      "value": "Jammu and Kashmir",
      "country_id": "India"
    },
    {
      "label": "Jharkhand",
      "value": "Jharkhand",
      "country_id": "India"
    },
    {
      "label": "Karnataka",
      "value": "Karnataka",
      "country_id": "India"
    },
    {
      "label": "Kerala",
      "value": "Kerala",
      "country_id": "India"
    },
    {
      "label": "Lakshadweep",
      "value": "Lakshadweep",
      "country_id": "India"
    },
    {
      "label": "Madhya Pradesh",
      "value": "Madhya Pradesh",
      "country_id": "India"
    },
    {
      "label": "Maharashtra",
      "value": "Maharashtra",
      "country_id": "India"
    },
    {
      "label": "Manipur",
      "value": "Manipur",
      "country_id": "India"
    },
    {
      "label": "Meghalaya",
      "value": "Meghalaya",
      "country_id": "India"
    },
    {
      "label": "Mizoram",
      "value": "Mizoram",
      "country_id": "India"
    },
    {
      "label": "Nagaland",
      "value": "Nagaland",
      "country_id": "India"
    },
    {
      "label": "Odisha",
      "value": "Odisha",
      "country_id": "India"
    },
    {
      "label": "Pondicherry",
      "value": "Pondicherry",
      "country_id": "India"
    },
    {
      "label": "Punjab",
      "value": "Punjab",
      "country_id": "India"
    },
    {
      "label": "Rajasthan",
      "value": "Rajasthan",
      "country_id": "India"
    },
    {
      "label": "Sikkim",
      "value": "Sikkim",
      "country_id": "India"
    },
    {
      "label": "Tamil Nadu",
      "value": "Tamil Nadu",
      "country_id": "India"
    },
    {
      "label": "Telangana",
      "value": "Telangana",
      "country_id": "India"
    },
    {
      "label": "Tripura",
      "value": "Tripura",
      "country_id": "India"
    },
    {
      "label": "Uttar Pradesh",
      "value": "Uttar Pradesh",
      "country_id": "India"
    },
    {
      "label": "Uttarakhand",
      "value": "Uttarakhand",
      "country_id": "India"
    },
    {
      "label": "West Bengal",
      "value": "West Bengal",
      "country_id": "India"
    },
    {
      "label": "Badakhshan",
      "value": "Badakhshan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Badgis",
      "value": "Badgis",
      "country_id": "Afghanistan"
    },
    {
      "label": "Baglan",
      "value": "Baglan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Balkh",
      "value": "Balkh",
      "country_id": "Afghanistan"
    },
    {
      "label": "Bamiyan",
      "value": "Bamiyan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Farah",
      "value": "Farah",
      "country_id": "Afghanistan"
    },
    {
      "label": "Faryab",
      "value": "Faryab",
      "country_id": "Afghanistan"
    },
    {
      "label": "Gawr",
      "value": "Gawr",
      "country_id": "Afghanistan"
    },
    {
      "label": "Gazni",
      "value": "Gazni",
      "country_id": "Afghanistan"
    },
    {
      "label": "Herat",
      "value": "Herat",
      "country_id": "Afghanistan"
    },
    {
      "label": "Hilmand",
      "value": "Hilmand",
      "country_id": "Afghanistan"
    },
    {
      "label": "Jawzjan",
      "value": "Jawzjan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Kabul",
      "value": "Kabul",
      "country_id": "Afghanistan"
    },
    {
      "label": "Kapisa",
      "value": "Kapisa",
      "country_id": "Afghanistan"
    },
    {
      "label": "Khawst",
      "value": "Khawst",
      "country_id": "Afghanistan"
    },
    {
      "label": "Kunar",
      "value": "Kunar",
      "country_id": "Afghanistan"
    },
    {
      "label": "Lagman",
      "value": "Lagman",
      "country_id": "Afghanistan"
    },
    {
      "label": "Lawghar",
      "value": "Lawghar",
      "country_id": "Afghanistan"
    },
    {
      "label": "Nangarhar",
      "value": "Nangarhar",
      "country_id": "Afghanistan"
    },
    {
      "label": "Nimruz",
      "value": "Nimruz",
      "country_id": "Afghanistan"
    },
    {
      "label": "Nuristan",
      "value": "Nuristan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Paktika",
      "value": "Paktika",
      "country_id": "Afghanistan"
    },
    {
      "label": "Paktiya",
      "value": "Paktiya",
      "country_id": "Afghanistan"
    },
    {
      "label": "Parwan",
      "value": "Parwan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Qandahar",
      "value": "Qandahar",
      "country_id": "Afghanistan"
    },
    {
      "label": "Qunduz",
      "value": "Qunduz",
      "country_id": "Afghanistan"
    },
    {
      "label": "Samangan",
      "value": "Samangan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Sar-e Pul",
      "value": "Sar-e Pul",
      "country_id": "Afghanistan"
    },
    {
      "label": "Takhar",
      "value": "Takhar",
      "country_id": "Afghanistan"
    },
    {
      "label": "Uruzgan",
      "value": "Uruzgan",
      "country_id": "Afghanistan"
    },
    {
      "label": "Wardag",
      "value": "Wardag",
      "country_id": "Afghanistan"
    },
    {
      "label": "Zabul",
      "value": "Zabul",
      "country_id": "Afghanistan"
    },
    {
      "label": "Berat",
      "value": "Berat",
      "country_id": "Albania"
    },
    {
      "label": "Bulqize",
      "value": "Bulqize",
      "country_id": "Albania"
    },
    {
      "label": "Delvine",
      "value": "Delvine",
      "country_id": "Albania"
    },
    {
      "label": "Devoll",
      "value": "Devoll",
      "country_id": "Albania"
    },
    {
      "label": "Dibre",
      "value": "Dibre",
      "country_id": "Albania"
    },
    {
      "label": "Durres",
      "value": "Durres",
      "country_id": "Albania"
    },
    {
      "label": "Elbasan",
      "value": "Elbasan",
      "country_id": "Albania"
    },
    {
      "label": "Fier",
      "value": "Fier",
      "country_id": "Albania"
    },
    {
      "label": "Gjirokaster",
      "value": "Gjirokaster",
      "country_id": "Albania"
    },
    {
      "label": "Gramsh",
      "value": "Gramsh",
      "country_id": "Albania"
    },
    {
      "label": "Has",
      "value": "Has",
      "country_id": "Albania"
    },
    {
      "label": "Kavaje",
      "value": "Kavaje",
      "country_id": "Albania"
    },
    {
      "label": "Kolonje",
      "value": "Kolonje",
      "country_id": "Albania"
    },
    {
      "label": "Korce",
      "value": "Korce",
      "country_id": "Albania"
    },
    {
      "label": "Kruje",
      "value": "Kruje",
      "country_id": "Albania"
    },
    {
      "label": "Kucove",
      "value": "Kucove",
      "country_id": "Albania"
    },
    {
      "label": "Kukes",
      "value": "Kukes",
      "country_id": "Albania"
    },
    {
      "label": "Kurbin",
      "value": "Kurbin",
      "country_id": "Albania"
    },
    {
      "label": "Lezhe",
      "value": "Lezhe",
      "country_id": "Albania"
    },
    {
      "label": "Librazhd",
      "value": "Librazhd",
      "country_id": "Albania"
    },
    {
      "label": "Lushnje",
      "value": "Lushnje",
      "country_id": "Albania"
    },
    {
      "label": "Mallakaster",
      "value": "Mallakaster",
      "country_id": "Albania"
    },
    {
      "label": "Malsi e Madhe",
      "value": "Malsi e Madhe",
      "country_id": "Albania"
    },
    {
      "label": "Mat",
      "value": "Mat",
      "country_id": "Albania"
    },
    {
      "label": "Mirdite",
      "value": "Mirdite",
      "country_id": "Albania"
    },
    {
      "label": "Peqin",
      "value": "Peqin",
      "country_id": "Albania"
    },
    {
      "label": "Permet",
      "value": "Permet",
      "country_id": "Albania"
    },
    {
      "label": "Pogradec",
      "value": "Pogradec",
      "country_id": "Albania"
    },
    {
      "label": "Puke",
      "value": "Puke",
      "country_id": "Albania"
    },
    {
      "label": "Sarande",
      "value": "Sarande",
      "country_id": "Albania"
    },
    {
      "label": "Shkoder",
      "value": "Shkoder",
      "country_id": "Albania"
    },
    {
      "label": "Skrapar",
      "value": "Skrapar",
      "country_id": "Albania"
    },
    {
      "label": "Tepelene",
      "value": "Tepelene",
      "country_id": "Albania"
    },
    {
      "label": "Tirane",
      "value": "Tirane",
      "country_id": "Albania"
    },
    {
      "label": "Tropoje",
      "value": "Tropoje",
      "country_id": "Albania"
    },
    {
      "label": "Vlore",
      "value": "Vlore",
      "country_id": "Albania"
    },
    {
      "label": "Ayn Daflah",
      "value": "Ayn Daflah",
      "country_id": "Algeria"
    },
    {
      "label": "Ayn Tamushanat",
      "value": "Ayn Tamushanat",
      "country_id": "Algeria"
    },
    {
      "label": "Adrar",
      "value": "Adrar",
      "country_id": "Algeria"
    },
    {
      "label": "Algiers",
      "value": "Algiers",
      "country_id": "Algeria"
    },
    {
      "label": "Annabah",
      "value": "Annabah",
      "country_id": "Algeria"
    },
    {
      "label": "Bashshar",
      "value": "Bashshar",
      "country_id": "Algeria"
    },
    {
      "label": "Batnah",
      "value": "Batnah",
      "country_id": "Algeria"
    },
    {
      "label": "Bijayah",
      "value": "Bijayah",
      "country_id": "Algeria"
    },
    {
      "label": "Biskrah",
      "value": "Biskrah",
      "country_id": "Algeria"
    },
    {
      "label": "Blidah",
      "value": "Blidah",
      "country_id": "Algeria"
    },
    {
      "label": "Buirah",
      "value": "Buirah",
      "country_id": "Algeria"
    },
    {
      "label": "Bumardas",
      "value": "Bumardas",
      "country_id": "Algeria"
    },
    {
      "label": "Burj Bu Arririj",
      "value": "Burj Bu Arririj",
      "country_id": "Algeria"
    },
    {
      "label": "Ghalizan",
      "value": "Ghalizan",
      "country_id": "Algeria"
    },
    {
      "label": "Ghardayah",
      "value": "Ghardayah",
      "country_id": "Algeria"
    },
    {
      "label": "Ilizi",
      "value": "Ilizi",
      "country_id": "Algeria"
    },
    {
      "label": "Jijili",
      "value": "Jijili",
      "country_id": "Algeria"
    },
    {
      "label": "Jilfah",
      "value": "Jilfah",
      "country_id": "Algeria"
    },
    {
      "label": "Khanshalah",
      "value": "Khanshalah",
      "country_id": "Algeria"
    },
    {
      "label": "Masilah",
      "value": "Masilah",
      "country_id": "Algeria"
    },
    {
      "label": "Midyah",
      "value": "Midyah",
      "country_id": "Algeria"
    },
    {
      "label": "Milah",
      "value": "Milah",
      "country_id": "Algeria"
    },
    {
      "label": "Muaskar",
      "value": "Muaskar",
      "country_id": "Algeria"
    },
    {
      "label": "Mustaghanam",
      "value": "Mustaghanam",
      "country_id": "Algeria"
    },
    {
      "label": "Naama",
      "value": "Naama",
      "country_id": "Algeria"
    },
    {
      "label": "Oran",
      "value": "Oran",
      "country_id": "Algeria"
    },
    {
      "label": "Ouargla",
      "value": "Ouargla",
      "country_id": "Algeria"
    },
    {
      "label": "Qalmah",
      "value": "Qalmah",
      "country_id": "Algeria"
    },
    {
      "label": "Qustantinah",
      "value": "Qustantinah",
      "country_id": "Algeria"
    },
    {
      "label": "Sakikdah",
      "value": "Sakikdah",
      "country_id": "Algeria"
    },
    {
      "label": "Satif",
      "value": "Satif",
      "country_id": "Algeria"
    },
    {
      "label": "Sayda",
      "value": "Sayda",
      "country_id": "Algeria"
    },
    {
      "label": "Sidi ban-al-''Abbas",
      "value": "Sidi ban-al-''Abbas",
      "country_id": "Algeria"
    },
    {
      "label": "Suq Ahras",
      "value": "Suq Ahras",
      "country_id": "Algeria"
    },
    {
      "label": "Tamanghasat",
      "value": "Tamanghasat",
      "country_id": "Algeria"
    },
    {
      "label": "Tibazah",
      "value": "Tibazah",
      "country_id": "Algeria"
    },
    {
      "label": "Tibissah",
      "value": "Tibissah",
      "country_id": "Algeria"
    },
    {
      "label": "Tilimsan",
      "value": "Tilimsan",
      "country_id": "Algeria"
    },
    {
      "label": "Tinduf",
      "value": "Tinduf",
      "country_id": "Algeria"
    },
    {
      "label": "Tisamsilt",
      "value": "Tisamsilt",
      "country_id": "Algeria"
    },
    {
      "label": "Tiyarat",
      "value": "Tiyarat",
      "country_id": "Algeria"
    },
    {
      "label": "Tizi Wazu",
      "value": "Tizi Wazu",
      "country_id": "Algeria"
    },
    {
      "label": "Umm-al-Bawaghi",
      "value": "Umm-al-Bawaghi",
      "country_id": "Algeria"
    },
    {
      "label": "Wahran",
      "value": "Wahran",
      "country_id": "Algeria"
    },
    {
      "label": "Warqla",
      "value": "Warqla",
      "country_id": "Algeria"
    },
    {
      "label": "Wilaya d Alger",
      "value": "Wilaya d Alger",
      "country_id": "Algeria"
    },
    {
      "label": "Wilaya de Bejaia",
      "value": "Wilaya de Bejaia",
      "country_id": "Algeria"
    },
    {
      "label": "Wilaya de Constantine",
      "value": "Wilaya de Constantine",
      "country_id": "Algeria"
    },
    {
      "label": "al-Aghwat",
      "value": "al-Aghwat",
      "country_id": "Algeria"
    },
    {
      "label": "al-Bayadh",
      "value": "al-Bayadh",
      "country_id": "Algeria"
    },
    {
      "label": "al-Jaza''ir",
      "value": "al-Jaza''ir",
      "country_id": "Algeria"
    },
    {
      "label": "al-Wad",
      "value": "al-Wad",
      "country_id": "Algeria"
    },
    {
      "label": "ash-Shalif",
      "value": "ash-Shalif",
      "country_id": "Algeria"
    },
    {
      "label": "at-Tarif",
      "value": "at-Tarif",
      "country_id": "Algeria"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "American Samoa"
    },
    {
      "label": "Manu''a",
      "value": "Manu''a",
      "country_id": "American Samoa"
    },
    {
      "label": "Swains Island",
      "value": "Swains Island",
      "country_id": "American Samoa"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "American Samoa"
    },
    {
      "label": "Andorra la Vella",
      "value": "Andorra la Vella",
      "country_id": "Andorra"
    },
    {
      "label": "Canillo",
      "value": "Canillo",
      "country_id": "Andorra"
    },
    {
      "label": "Encamp",
      "value": "Encamp",
      "country_id": "Andorra"
    },
    {
      "label": "La Massana",
      "value": "La Massana",
      "country_id": "Andorra"
    },
    {
      "label": "Les Escaldes",
      "value": "Les Escaldes",
      "country_id": "Andorra"
    },
    {
      "label": "Ordino",
      "value": "Ordino",
      "country_id": "Andorra"
    },
    {
      "label": "Sant Julia de Loria",
      "value": "Sant Julia de Loria",
      "country_id": "Andorra"
    },
    {
      "label": "Bengo",
      "value": "Bengo",
      "country_id": "Angola"
    },
    {
      "label": "Benguela",
      "value": "Benguela",
      "country_id": "Angola"
    },
    {
      "label": "Bie",
      "value": "Bie",
      "country_id": "Angola"
    },
    {
      "label": "Cabinda",
      "value": "Cabinda",
      "country_id": "Angola"
    },
    {
      "label": "Cunene",
      "value": "Cunene",
      "country_id": "Angola"
    },
    {
      "label": "Huambo",
      "value": "Huambo",
      "country_id": "Angola"
    },
    {
      "label": "Huila",
      "value": "Huila",
      "country_id": "Angola"
    },
    {
      "label": "Kuando-Kubango",
      "value": "Kuando-Kubango",
      "country_id": "Angola"
    },
    {
      "label": "Kwanza Norte",
      "value": "Kwanza Norte",
      "country_id": "Angola"
    },
    {
      "label": "Kwanza Sul",
      "value": "Kwanza Sul",
      "country_id": "Angola"
    },
    {
      "label": "Luanda",
      "value": "Luanda",
      "country_id": "Angola"
    },
    {
      "label": "Lunda Norte",
      "value": "Lunda Norte",
      "country_id": "Angola"
    },
    {
      "label": "Lunda Sul",
      "value": "Lunda Sul",
      "country_id": "Angola"
    },
    {
      "label": "Malanje",
      "value": "Malanje",
      "country_id": "Angola"
    },
    {
      "label": "Moxico",
      "value": "Moxico",
      "country_id": "Angola"
    },
    {
      "label": "Namibe",
      "value": "Namibe",
      "country_id": "Angola"
    },
    {
      "label": "Uige",
      "value": "Uige",
      "country_id": "Angola"
    },
    {
      "label": "Zaire",
      "value": "Zaire",
      "country_id": "Angola"
    },
    {
      "label": "Other Provinces",
      "value": "Other Provinces",
      "country_id": "Anguilla"
    },
    {
      "label": "Sector claimed by Argentina/Ch",
      "value": "Sector claimed by Argentina/Ch",
      "country_id": "Antarctica"
    },
    {
      "label": "Sector claimed by Argentina/UK",
      "value": "Sector claimed by Argentina/UK",
      "country_id": "Antarctica"
    },
    {
      "label": "Sector claimed by Australia",
      "value": "Sector claimed by Australia",
      "country_id": "Antarctica"
    },
    {
      "label": "Sector claimed by France",
      "value": "Sector claimed by France",
      "country_id": "Antarctica"
    },
    {
      "label": "Sector claimed by New Zealand",
      "value": "Sector claimed by New Zealand",
      "country_id": "Antarctica"
    },
    {
      "label": "Sector claimed by Norway",
      "value": "Sector claimed by Norway",
      "country_id": "Antarctica"
    },
    {
      "label": "Unclaimed Sector",
      "value": "Unclaimed Sector",
      "country_id": "Antarctica"
    },
    {
      "label": "Barbuda",
      "value": "Barbuda",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Saint George",
      "value": "Saint George",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Saint John",
      "value": "Saint John",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Saint Mary",
      "value": "Saint Mary",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Saint Paul",
      "value": "Saint Paul",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Saint Peter",
      "value": "Saint Peter",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Saint Philip",
      "value": "Saint Philip",
      "country_id": "Antigua And Barbuda"
    },
    {
      "label": "Buenos Aires",
      "value": "Buenos Aires",
      "country_id": "Argentina"
    },
    {
      "label": "Catamarca",
      "value": "Catamarca",
      "country_id": "Argentina"
    },
    {
      "label": "Chaco",
      "value": "Chaco",
      "country_id": "Argentina"
    },
    {
      "label": "Chubut",
      "value": "Chubut",
      "country_id": "Argentina"
    },
    {
      "label": "Cordoba",
      "value": "Cordoba",
      "country_id": "Argentina"
    },
    {
      "label": "Corrientes",
      "value": "Corrientes",
      "country_id": "Argentina"
    },
    {
      "label": "Distrito Federal",
      "value": "Distrito Federal",
      "country_id": "Argentina"
    },
    {
      "label": "Entre Rios",
      "value": "Entre Rios",
      "country_id": "Argentina"
    },
    {
      "label": "Formosa",
      "value": "Formosa",
      "country_id": "Argentina"
    },
    {
      "label": "Jujuy",
      "value": "Jujuy",
      "country_id": "Argentina"
    },
    {
      "label": "La Pampa",
      "value": "La Pampa",
      "country_id": "Argentina"
    },
    {
      "label": "La Rioja",
      "value": "La Rioja",
      "country_id": "Argentina"
    },
    {
      "label": "Mendoza",
      "value": "Mendoza",
      "country_id": "Argentina"
    },
    {
      "label": "Misiones",
      "value": "Misiones",
      "country_id": "Argentina"
    },
    {
      "label": "Neuquen",
      "value": "Neuquen",
      "country_id": "Argentina"
    },
    {
      "label": "Rio Negro",
      "value": "Rio Negro",
      "country_id": "Argentina"
    },
    {
      "label": "Salta",
      "value": "Salta",
      "country_id": "Argentina"
    },
    {
      "label": "San Juan",
      "value": "San Juan",
      "country_id": "Argentina"
    },
    {
      "label": "San Luis",
      "value": "San Luis",
      "country_id": "Argentina"
    },
    {
      "label": "Santa Cruz",
      "value": "Santa Cruz",
      "country_id": "Argentina"
    },
    {
      "label": "Santa Fe",
      "value": "Santa Fe",
      "country_id": "Argentina"
    },
    {
      "label": "Santiago del Estero",
      "value": "Santiago del Estero",
      "country_id": "Argentina"
    },
    {
      "label": "Tierra del Fuego",
      "value": "Tierra del Fuego",
      "country_id": "Argentina"
    },
    {
      "label": "Tucuman",
      "value": "Tucuman",
      "country_id": "Argentina"
    },
    {
      "label": "Aragatsotn",
      "value": "Aragatsotn",
      "country_id": "Armenia"
    },
    {
      "label": "Ararat",
      "value": "Ararat",
      "country_id": "Armenia"
    },
    {
      "label": "Armavir",
      "value": "Armavir",
      "country_id": "Armenia"
    },
    {
      "label": "Gegharkunik",
      "value": "Gegharkunik",
      "country_id": "Armenia"
    },
    {
      "label": "Kotaik",
      "value": "Kotaik",
      "country_id": "Armenia"
    },
    {
      "label": "Lori",
      "value": "Lori",
      "country_id": "Armenia"
    },
    {
      "label": "Shirak",
      "value": "Shirak",
      "country_id": "Armenia"
    },
    {
      "label": "Stepanakert",
      "value": "Stepanakert",
      "country_id": "Armenia"
    },
    {
      "label": "Syunik",
      "value": "Syunik",
      "country_id": "Armenia"
    },
    {
      "label": "Tavush",
      "value": "Tavush",
      "country_id": "Armenia"
    },
    {
      "label": "Vayots Dzor",
      "value": "Vayots Dzor",
      "country_id": "Armenia"
    },
    {
      "label": "Yerevan",
      "value": "Yerevan",
      "country_id": "Armenia"
    },
    {
      "label": "Aruba",
      "value": "Aruba",
      "country_id": "Aruba"
    },
    {
      "label": "Auckland",
      "value": "Auckland",
      "country_id": "Australia"
    },
    {
      "label": "Australian Capital Territory",
      "value": "Australian Capital Territory",
      "country_id": "Australia"
    },
    {
      "label": "Balgowlah",
      "value": "Balgowlah",
      "country_id": "Australia"
    },
    {
      "label": "Balmain",
      "value": "Balmain",
      "country_id": "Australia"
    },
    {
      "label": "Bankstown",
      "value": "Bankstown",
      "country_id": "Australia"
    },
    {
      "label": "Baulkham Hills",
      "value": "Baulkham Hills",
      "country_id": "Australia"
    },
    {
      "label": "Bonnet Bay",
      "value": "Bonnet Bay",
      "country_id": "Australia"
    },
    {
      "label": "Camberwell",
      "value": "Camberwell",
      "country_id": "Australia"
    },
    {
      "label": "Carole Park",
      "value": "Carole Park",
      "country_id": "Australia"
    },
    {
      "label": "Castle Hill",
      "value": "Castle Hill",
      "country_id": "Australia"
    },
    {
      "label": "Caulfield",
      "value": "Caulfield",
      "country_id": "Australia"
    },
    {
      "label": "Chatswood",
      "value": "Chatswood",
      "country_id": "Australia"
    },
    {
      "label": "Cheltenham",
      "value": "Cheltenham",
      "country_id": "Australia"
    },
    {
      "label": "Cherrybrook",
      "value": "Cherrybrook",
      "country_id": "Australia"
    },
    {
      "label": "Clayton",
      "value": "Clayton",
      "country_id": "Australia"
    },
    {
      "label": "Collingwood",
      "value": "Collingwood",
      "country_id": "Australia"
    },
    {
      "label": "Frenchs Forest",
      "value": "Frenchs Forest",
      "country_id": "Australia"
    },
    {
      "label": "Hawthorn",
      "value": "Hawthorn",
      "country_id": "Australia"
    },
    {
      "label": "Jannnali",
      "value": "Jannnali",
      "country_id": "Australia"
    },
    {
      "label": "Knoxfield",
      "value": "Knoxfield",
      "country_id": "Australia"
    },
    {
      "label": "Melbourne",
      "value": "Melbourne",
      "country_id": "Australia"
    },
    {
      "label": "New South Wales",
      "value": "New South Wales",
      "country_id": "Australia"
    },
    {
      "label": "Northern Territory",
      "value": "Northern Territory",
      "country_id": "Australia"
    },
    {
      "label": "Perth",
      "value": "Perth",
      "country_id": "Australia"
    },
    {
      "label": "Queensland",
      "value": "Queensland",
      "country_id": "Australia"
    },
    {
      "label": "South Australia",
      "value": "South Australia",
      "country_id": "Australia"
    },
    {
      "label": "Tasmania",
      "value": "Tasmania",
      "country_id": "Australia"
    },
    {
      "label": "Templestowe",
      "value": "Templestowe",
      "country_id": "Australia"
    },
    {
      "label": "Victoria",
      "value": "Victoria",
      "country_id": "Australia"
    },
    {
      "label": "Werribee south",
      "value": "Werribee south",
      "country_id": "Australia"
    },
    {
      "label": "Western Australia",
      "value": "Western Australia",
      "country_id": "Australia"
    },
    {
      "label": "Wheeler",
      "value": "Wheeler",
      "country_id": "Australia"
    },
    {
      "label": "Bundesland Salzburg",
      "value": "Bundesland Salzburg",
      "country_id": "Austria"
    },
    {
      "label": "Bundesland Steiermark",
      "value": "Bundesland Steiermark",
      "country_id": "Austria"
    },
    {
      "label": "Bundesland Tirol",
      "value": "Bundesland Tirol",
      "country_id": "Austria"
    },
    {
      "label": "Burgenland",
      "value": "Burgenland",
      "country_id": "Austria"
    },
    {
      "label": "Carinthia",
      "value": "Carinthia",
      "country_id": "Austria"
    },
    {
      "label": "Karnten",
      "value": "Karnten",
      "country_id": "Austria"
    },
    {
      "label": "Liezen",
      "value": "Liezen",
      "country_id": "Austria"
    },
    {
      "label": "Lower Austria",
      "value": "Lower Austria",
      "country_id": "Austria"
    },
    {
      "label": "Niederosterreich",
      "value": "Niederosterreich",
      "country_id": "Austria"
    },
    {
      "label": "Oberosterreich",
      "value": "Oberosterreich",
      "country_id": "Austria"
    },
    {
      "label": "Salzburg",
      "value": "Salzburg",
      "country_id": "Austria"
    },
    {
      "label": "Schleswig-Holstein",
      "value": "Schleswig-Holstein",
      "country_id": "Austria"
    },
    {
      "label": "Steiermark",
      "value": "Steiermark",
      "country_id": "Austria"
    },
    {
      "label": "Styria",
      "value": "Styria",
      "country_id": "Austria"
    },
    {
      "label": "Tirol",
      "value": "Tirol",
      "country_id": "Austria"
    },
    {
      "label": "Upper Austria",
      "value": "Upper Austria",
      "country_id": "Austria"
    },
    {
      "label": "Vorarlberg",
      "value": "Vorarlberg",
      "country_id": "Austria"
    },
    {
      "label": "Wien",
      "value": "Wien",
      "country_id": "Austria"
    },
    {
      "label": "Abseron",
      "value": "Abseron",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Baki Sahari",
      "value": "Baki Sahari",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Ganca",
      "value": "Ganca",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Ganja",
      "value": "Ganja",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Kalbacar",
      "value": "Kalbacar",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Lankaran",
      "value": "Lankaran",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Mil-Qarabax",
      "value": "Mil-Qarabax",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Mugan-Salyan",
      "value": "Mugan-Salyan",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Nagorni-Qarabax",
      "value": "Nagorni-Qarabax",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Naxcivan",
      "value": "Naxcivan",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Priaraks",
      "value": "Priaraks",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Qazax",
      "value": "Qazax",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Saki",
      "value": "Saki",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Sirvan",
      "value": "Sirvan",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Xacmaz",
      "value": "Xacmaz",
      "country_id": "Azerbaijan"
    },
    {
      "label": "Abaco",
      "value": "Abaco",
      "country_id": "Bahamas The"
    },
    {
      "label": "Acklins Island",
      "value": "Acklins Island",
      "country_id": "Bahamas The"
    },
    {
      "label": "Andros",
      "value": "Andros",
      "country_id": "Bahamas The"
    },
    {
      "label": "Berry Islands",
      "value": "Berry Islands",
      "country_id": "Bahamas The"
    },
    {
      "label": "Biminis",
      "value": "Biminis",
      "country_id": "Bahamas The"
    },
    {
      "label": "Cat Island",
      "value": "Cat Island",
      "country_id": "Bahamas The"
    },
    {
      "label": "Crooked Island",
      "value": "Crooked Island",
      "country_id": "Bahamas The"
    },
    {
      "label": "Eleuthera",
      "value": "Eleuthera",
      "country_id": "Bahamas The"
    },
    {
      "label": "Exuma and Cays",
      "value": "Exuma and Cays",
      "country_id": "Bahamas The"
    },
    {
      "label": "Grand Bahama",
      "value": "Grand Bahama",
      "country_id": "Bahamas The"
    },
    {
      "label": "Inagua Islands",
      "value": "Inagua Islands",
      "country_id": "Bahamas The"
    },
    {
      "label": "Long Island",
      "value": "Long Island",
      "country_id": "Bahamas The"
    },
    {
      "label": "Mayaguana",
      "value": "Mayaguana",
      "country_id": "Bahamas The"
    },
    {
      "label": "New Providence",
      "value": "New Providence",
      "country_id": "Bahamas The"
    },
    {
      "label": "Ragged Island",
      "value": "Ragged Island",
      "country_id": "Bahamas The"
    },
    {
      "label": "Rum Cay",
      "value": "Rum Cay",
      "country_id": "Bahamas The"
    },
    {
      "label": "San Salvador",
      "value": "San Salvador",
      "country_id": "Bahamas The"
    },
    {
      "label": "Isa",
      "value": "Isa",
      "country_id": "Bahrain"
    },
    {
      "label": "Badiyah",
      "value": "Badiyah",
      "country_id": "Bahrain"
    },
    {
      "label": "Hidd",
      "value": "Hidd",
      "country_id": "Bahrain"
    },
    {
      "label": "Jidd Hafs",
      "value": "Jidd Hafs",
      "country_id": "Bahrain"
    },
    {
      "label": "Mahama",
      "value": "Mahama",
      "country_id": "Bahrain"
    },
    {
      "label": "Manama",
      "value": "Manama",
      "country_id": "Bahrain"
    },
    {
      "label": "Sitrah",
      "value": "Sitrah",
      "country_id": "Bahrain"
    },
    {
      "label": "al-Manamah",
      "value": "al-Manamah",
      "country_id": "Bahrain"
    },
    {
      "label": "al-Muharraq",
      "value": "al-Muharraq",
      "country_id": "Bahrain"
    },
    {
      "label": "ar-Rifa''a",
      "value": "ar-Rifa''a",
      "country_id": "Bahrain"
    },
    {
      "label": "Bagar Hat",
      "value": "Bagar Hat",
      "country_id": "Bangladesh"
    },
    {
      "label": "Bandarban",
      "value": "Bandarban",
      "country_id": "Bangladesh"
    },
    {
      "label": "Barguna",
      "value": "Barguna",
      "country_id": "Bangladesh"
    },
    {
      "label": "Barisal",
      "value": "Barisal",
      "country_id": "Bangladesh"
    },
    {
      "label": "Bhola",
      "value": "Bhola",
      "country_id": "Bangladesh"
    },
    {
      "label": "Bogora",
      "value": "Bogora",
      "country_id": "Bangladesh"
    },
    {
      "label": "Brahman Bariya",
      "value": "Brahman Bariya",
      "country_id": "Bangladesh"
    },
    {
      "label": "Chandpur",
      "value": "Chandpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Chattagam",
      "value": "Chattagam",
      "country_id": "Bangladesh"
    },
    {
      "label": "Chittagong Division",
      "value": "Chittagong Division",
      "country_id": "Bangladesh"
    },
    {
      "label": "Chuadanga",
      "value": "Chuadanga",
      "country_id": "Bangladesh"
    },
    {
      "label": "Dhaka",
      "value": "Dhaka",
      "country_id": "Bangladesh"
    },
    {
      "label": "Dinajpur",
      "value": "Dinajpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Faridpur",
      "value": "Faridpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Feni",
      "value": "Feni",
      "country_id": "Bangladesh"
    },
    {
      "label": "Gaybanda",
      "value": "Gaybanda",
      "country_id": "Bangladesh"
    },
    {
      "label": "Gazipur",
      "value": "Gazipur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Gopalganj",
      "value": "Gopalganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Habiganj",
      "value": "Habiganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Jaipur Hat",
      "value": "Jaipur Hat",
      "country_id": "Bangladesh"
    },
    {
      "label": "Jamalpur",
      "value": "Jamalpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Jessor",
      "value": "Jessor",
      "country_id": "Bangladesh"
    },
    {
      "label": "Jhalakati",
      "value": "Jhalakati",
      "country_id": "Bangladesh"
    },
    {
      "label": "Jhanaydah",
      "value": "Jhanaydah",
      "country_id": "Bangladesh"
    },
    {
      "label": "Khagrachhari",
      "value": "Khagrachhari",
      "country_id": "Bangladesh"
    },
    {
      "label": "Khulna",
      "value": "Khulna",
      "country_id": "Bangladesh"
    },
    {
      "label": "Kishorganj",
      "value": "Kishorganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Koks Bazar",
      "value": "Koks Bazar",
      "country_id": "Bangladesh"
    },
    {
      "label": "Komilla",
      "value": "Komilla",
      "country_id": "Bangladesh"
    },
    {
      "label": "Kurigram",
      "value": "Kurigram",
      "country_id": "Bangladesh"
    },
    {
      "label": "Kushtiya",
      "value": "Kushtiya",
      "country_id": "Bangladesh"
    },
    {
      "label": "Lakshmipur",
      "value": "Lakshmipur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Lalmanir Hat",
      "value": "Lalmanir Hat",
      "country_id": "Bangladesh"
    },
    {
      "label": "Madaripur",
      "value": "Madaripur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Magura",
      "value": "Magura",
      "country_id": "Bangladesh"
    },
    {
      "label": "Maimansingh",
      "value": "Maimansingh",
      "country_id": "Bangladesh"
    },
    {
      "label": "Manikganj",
      "value": "Manikganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Maulvi Bazar",
      "value": "Maulvi Bazar",
      "country_id": "Bangladesh"
    },
    {
      "label": "Meherpur",
      "value": "Meherpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Munshiganj",
      "value": "Munshiganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Naral",
      "value": "Naral",
      "country_id": "Bangladesh"
    },
    {
      "label": "Narayanganj",
      "value": "Narayanganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Narsingdi",
      "value": "Narsingdi",
      "country_id": "Bangladesh"
    },
    {
      "label": "Nator",
      "value": "Nator",
      "country_id": "Bangladesh"
    },
    {
      "label": "Naugaon",
      "value": "Naugaon",
      "country_id": "Bangladesh"
    },
    {
      "label": "Nawabganj",
      "value": "Nawabganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Netrakona",
      "value": "Netrakona",
      "country_id": "Bangladesh"
    },
    {
      "label": "Nilphamari",
      "value": "Nilphamari",
      "country_id": "Bangladesh"
    },
    {
      "label": "Noakhali",
      "value": "Noakhali",
      "country_id": "Bangladesh"
    },
    {
      "label": "Pabna",
      "value": "Pabna",
      "country_id": "Bangladesh"
    },
    {
      "label": "Panchagarh",
      "value": "Panchagarh",
      "country_id": "Bangladesh"
    },
    {
      "label": "Patuakhali",
      "value": "Patuakhali",
      "country_id": "Bangladesh"
    },
    {
      "label": "Pirojpur",
      "value": "Pirojpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Rajbari",
      "value": "Rajbari",
      "country_id": "Bangladesh"
    },
    {
      "label": "Rajshahi",
      "value": "Rajshahi",
      "country_id": "Bangladesh"
    },
    {
      "label": "Rangamati",
      "value": "Rangamati",
      "country_id": "Bangladesh"
    },
    {
      "label": "Rangpur",
      "value": "Rangpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Satkhira",
      "value": "Satkhira",
      "country_id": "Bangladesh"
    },
    {
      "label": "Shariatpur",
      "value": "Shariatpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Sherpur",
      "value": "Sherpur",
      "country_id": "Bangladesh"
    },
    {
      "label": "Silhat",
      "value": "Silhat",
      "country_id": "Bangladesh"
    },
    {
      "label": "Sirajganj",
      "value": "Sirajganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Sunamganj",
      "value": "Sunamganj",
      "country_id": "Bangladesh"
    },
    {
      "label": "Tangayal",
      "value": "Tangayal",
      "country_id": "Bangladesh"
    },
    {
      "label": "Thakurgaon",
      "value": "Thakurgaon",
      "country_id": "Bangladesh"
    },
    {
      "label": "Christ Church",
      "value": "Christ Church",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Andrew",
      "value": "Saint Andrew",
      "country_id": "Barbados"
    },
    {
      "label": "Saint George",
      "value": "Saint George",
      "country_id": "Barbados"
    },
    {
      "label": "Saint James",
      "value": "Saint James",
      "country_id": "Barbados"
    },
    {
      "label": "Saint John",
      "value": "Saint John",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Joseph",
      "value": "Saint Joseph",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Lucy",
      "value": "Saint Lucy",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Michael",
      "value": "Saint Michael",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Peter",
      "value": "Saint Peter",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Philip",
      "value": "Saint Philip",
      "country_id": "Barbados"
    },
    {
      "label": "Saint Thomas",
      "value": "Saint Thomas",
      "country_id": "Barbados"
    },
    {
      "label": "Brest",
      "value": "Brest",
      "country_id": "Belarus"
    },
    {
      "label": "Homjel",
      "value": "Homjel",
      "country_id": "Belarus"
    },
    {
      "label": "Hrodna",
      "value": "Hrodna",
      "country_id": "Belarus"
    },
    {
      "label": "Mahiljow",
      "value": "Mahiljow",
      "country_id": "Belarus"
    },
    {
      "label": "Mahilyowskaya Voblasts",
      "value": "Mahilyowskaya Voblasts",
      "country_id": "Belarus"
    },
    {
      "label": "Minsk",
      "value": "Minsk",
      "country_id": "Belarus"
    },
    {
      "label": "Minskaja Voblasts",
      "value": "Minskaja Voblasts",
      "country_id": "Belarus"
    },
    {
      "label": "Petrik",
      "value": "Petrik",
      "country_id": "Belarus"
    },
    {
      "label": "Vicebsk",
      "value": "Vicebsk",
      "country_id": "Belarus"
    },
    {
      "label": "Antwerpen",
      "value": "Antwerpen",
      "country_id": "Belgium"
    },
    {
      "label": "Berchem",
      "value": "Berchem",
      "country_id": "Belgium"
    },
    {
      "label": "Brabant",
      "value": "Brabant",
      "country_id": "Belgium"
    },
    {
      "label": "Brabant Wallon",
      "value": "Brabant Wallon",
      "country_id": "Belgium"
    },
    {
      "label": "Brussel",
      "value": "Brussel",
      "country_id": "Belgium"
    },
    {
      "label": "East Flanders",
      "value": "East Flanders",
      "country_id": "Belgium"
    },
    {
      "label": "Hainaut",
      "value": "Hainaut",
      "country_id": "Belgium"
    },
    {
      "label": "Liege",
      "value": "Liege",
      "country_id": "Belgium"
    },
    {
      "label": "Limburg",
      "value": "Limburg",
      "country_id": "Belgium"
    },
    {
      "label": "Luxembourg",
      "value": "Luxembourg",
      "country_id": "Belgium"
    },
    {
      "label": "Namur",
      "value": "Namur",
      "country_id": "Belgium"
    },
    {
      "label": "Ontario",
      "value": "Ontario",
      "country_id": "Belgium"
    },
    {
      "label": "Oost-Vlaanderen",
      "value": "Oost-Vlaanderen",
      "country_id": "Belgium"
    },
    {
      "label": "Provincie Brabant",
      "value": "Provincie Brabant",
      "country_id": "Belgium"
    },
    {
      "label": "Vlaams-Brabant",
      "value": "Vlaams-Brabant",
      "country_id": "Belgium"
    },
    {
      "label": "Wallonne",
      "value": "Wallonne",
      "country_id": "Belgium"
    },
    {
      "label": "West-Vlaanderen",
      "value": "West-Vlaanderen",
      "country_id": "Belgium"
    },
    {
      "label": "Belize",
      "value": "Belize",
      "country_id": "Belize"
    },
    {
      "label": "Cayo",
      "value": "Cayo",
      "country_id": "Belize"
    },
    {
      "label": "Corozal",
      "value": "Corozal",
      "country_id": "Belize"
    },
    {
      "label": "Orange Walk",
      "value": "Orange Walk",
      "country_id": "Belize"
    },
    {
      "label": "Stann Creek",
      "value": "Stann Creek",
      "country_id": "Belize"
    },
    {
      "label": "Toledo",
      "value": "Toledo",
      "country_id": "Belize"
    },
    {
      "label": "Alibori",
      "value": "Alibori",
      "country_id": "Benin"
    },
    {
      "label": "Atacora",
      "value": "Atacora",
      "country_id": "Benin"
    },
    {
      "label": "Atlantique",
      "value": "Atlantique",
      "country_id": "Benin"
    },
    {
      "label": "Borgou",
      "value": "Borgou",
      "country_id": "Benin"
    },
    {
      "label": "Collines",
      "value": "Collines",
      "country_id": "Benin"
    },
    {
      "label": "Couffo",
      "value": "Couffo",
      "country_id": "Benin"
    },
    {
      "label": "Donga",
      "value": "Donga",
      "country_id": "Benin"
    },
    {
      "label": "Littoral",
      "value": "Littoral",
      "country_id": "Benin"
    },
    {
      "label": "Mono",
      "value": "Mono",
      "country_id": "Benin"
    },
    {
      "label": "Oueme",
      "value": "Oueme",
      "country_id": "Benin"
    },
    {
      "label": "Plateau",
      "value": "Plateau",
      "country_id": "Benin"
    },
    {
      "label": "Zou",
      "value": "Zou",
      "country_id": "Benin"
    },
    {
      "label": "Hamilton",
      "value": "Hamilton",
      "country_id": "Bermuda"
    },
    {
      "label": "Saint George",
      "value": "Saint George",
      "country_id": "Bermuda"
    },
    {
      "label": "Bumthang",
      "value": "Bumthang",
      "country_id": "Bhutan"
    },
    {
      "label": "Chhukha",
      "value": "Chhukha",
      "country_id": "Bhutan"
    },
    {
      "label": "Chirang",
      "value": "Chirang",
      "country_id": "Bhutan"
    },
    {
      "label": "Daga",
      "value": "Daga",
      "country_id": "Bhutan"
    },
    {
      "label": "Geylegphug",
      "value": "Geylegphug",
      "country_id": "Bhutan"
    },
    {
      "label": "Ha",
      "value": "Ha",
      "country_id": "Bhutan"
    },
    {
      "label": "Lhuntshi",
      "value": "Lhuntshi",
      "country_id": "Bhutan"
    },
    {
      "label": "Mongar",
      "value": "Mongar",
      "country_id": "Bhutan"
    },
    {
      "label": "Pemagatsel",
      "value": "Pemagatsel",
      "country_id": "Bhutan"
    },
    {
      "label": "Punakha",
      "value": "Punakha",
      "country_id": "Bhutan"
    },
    {
      "label": "Rinpung",
      "value": "Rinpung",
      "country_id": "Bhutan"
    },
    {
      "label": "Samchi",
      "value": "Samchi",
      "country_id": "Bhutan"
    },
    {
      "label": "Samdrup Jongkhar",
      "value": "Samdrup Jongkhar",
      "country_id": "Bhutan"
    },
    {
      "label": "Shemgang",
      "value": "Shemgang",
      "country_id": "Bhutan"
    },
    {
      "label": "Tashigang",
      "value": "Tashigang",
      "country_id": "Bhutan"
    },
    {
      "label": "Timphu",
      "value": "Timphu",
      "country_id": "Bhutan"
    },
    {
      "label": "Tongsa",
      "value": "Tongsa",
      "country_id": "Bhutan"
    },
    {
      "label": "Wangdiphodrang",
      "value": "Wangdiphodrang",
      "country_id": "Bhutan"
    },
    {
      "label": "Beni",
      "value": "Beni",
      "country_id": "Bolivia"
    },
    {
      "label": "Chuquisaca",
      "value": "Chuquisaca",
      "country_id": "Bolivia"
    },
    {
      "label": "Cochabamba",
      "value": "Cochabamba",
      "country_id": "Bolivia"
    },
    {
      "label": "La Paz",
      "value": "La Paz",
      "country_id": "Bolivia"
    },
    {
      "label": "Oruro",
      "value": "Oruro",
      "country_id": "Bolivia"
    },
    {
      "label": "Pando",
      "value": "Pando",
      "country_id": "Bolivia"
    },
    {
      "label": "Potosi",
      "value": "Potosi",
      "country_id": "Bolivia"
    },
    {
      "label": "Santa Cruz",
      "value": "Santa Cruz",
      "country_id": "Bolivia"
    },
    {
      "label": "Tarija",
      "value": "Tarija",
      "country_id": "Bolivia"
    },
    {
      "label": "Federacija Bosna i Hercegovina",
      "value": "Federacija Bosna i Hercegovina",
      "country_id": "Bosnia and Herzegovina"
    },
    {
      "label": "Republika Srpska",
      "value": "Republika Srpska",
      "country_id": "Bosnia and Herzegovina"
    },
    {
      "label": "Central Bobonong",
      "value": "Central Bobonong",
      "country_id": "Botswana"
    },
    {
      "label": "Central Boteti",
      "value": "Central Boteti",
      "country_id": "Botswana"
    },
    {
      "label": "Central Mahalapye",
      "value": "Central Mahalapye",
      "country_id": "Botswana"
    },
    {
      "label": "Central Serowe-Palapye",
      "value": "Central Serowe-Palapye",
      "country_id": "Botswana"
    },
    {
      "label": "Central Tutume",
      "value": "Central Tutume",
      "country_id": "Botswana"
    },
    {
      "label": "Chobe",
      "value": "Chobe",
      "country_id": "Botswana"
    },
    {
      "label": "Francistown",
      "value": "Francistown",
      "country_id": "Botswana"
    },
    {
      "label": "Gaborone",
      "value": "Gaborone",
      "country_id": "Botswana"
    },
    {
      "label": "Ghanzi",
      "value": "Ghanzi",
      "country_id": "Botswana"
    },
    {
      "label": "Jwaneng",
      "value": "Jwaneng",
      "country_id": "Botswana"
    },
    {
      "label": "Kgalagadi North",
      "value": "Kgalagadi North",
      "country_id": "Botswana"
    },
    {
      "label": "Kgalagadi South",
      "value": "Kgalagadi South",
      "country_id": "Botswana"
    },
    {
      "label": "Kgatleng",
      "value": "Kgatleng",
      "country_id": "Botswana"
    },
    {
      "label": "Kweneng",
      "value": "Kweneng",
      "country_id": "Botswana"
    },
    {
      "label": "Lobatse",
      "value": "Lobatse",
      "country_id": "Botswana"
    },
    {
      "label": "Ngamiland",
      "value": "Ngamiland",
      "country_id": "Botswana"
    },
    {
      "label": "Ngwaketse",
      "value": "Ngwaketse",
      "country_id": "Botswana"
    },
    {
      "label": "North East",
      "value": "North East",
      "country_id": "Botswana"
    },
    {
      "label": "Okavango",
      "value": "Okavango",
      "country_id": "Botswana"
    },
    {
      "label": "Orapa",
      "value": "Orapa",
      "country_id": "Botswana"
    },
    {
      "label": "Selibe Phikwe",
      "value": "Selibe Phikwe",
      "country_id": "Botswana"
    },
    {
      "label": "South East",
      "value": "South East",
      "country_id": "Botswana"
    },
    {
      "label": "Sowa",
      "value": "Sowa",
      "country_id": "Botswana"
    },
    {
      "label": "Bouvet Island",
      "value": "Bouvet Island",
      "country_id": "Bouvet Island"
    },
    {
      "label": "Acre",
      "value": "Acre",
      "country_id": "Brazil"
    },
    {
      "label": "Alagoas",
      "value": "Alagoas",
      "country_id": "Brazil"
    },
    {
      "label": "Amapa",
      "value": "Amapa",
      "country_id": "Brazil"
    },
    {
      "label": "Amazonas",
      "value": "Amazonas",
      "country_id": "Brazil"
    },
    {
      "label": "Bahia",
      "value": "Bahia",
      "country_id": "Brazil"
    },
    {
      "label": "Ceara",
      "value": "Ceara",
      "country_id": "Brazil"
    },
    {
      "label": "Distrito Federal",
      "value": "Distrito Federal",
      "country_id": "Brazil"
    },
    {
      "label": "Espirito Santo",
      "value": "Espirito Santo",
      "country_id": "Brazil"
    },
    {
      "label": "Estado de Sao Paulo",
      "value": "Estado de Sao Paulo",
      "country_id": "Brazil"
    },
    {
      "label": "Goias",
      "value": "Goias",
      "country_id": "Brazil"
    },
    {
      "label": "Maranhao",
      "value": "Maranhao",
      "country_id": "Brazil"
    },
    {
      "label": "Mato Grosso",
      "value": "Mato Grosso",
      "country_id": "Brazil"
    },
    {
      "label": "Mato Grosso do Sul",
      "value": "Mato Grosso do Sul",
      "country_id": "Brazil"
    },
    {
      "label": "Minas Gerais",
      "value": "Minas Gerais",
      "country_id": "Brazil"
    },
    {
      "label": "Para",
      "value": "Para",
      "country_id": "Brazil"
    },
    {
      "label": "Paraiba",
      "value": "Paraiba",
      "country_id": "Brazil"
    },
    {
      "label": "Parana",
      "value": "Parana",
      "country_id": "Brazil"
    },
    {
      "label": "Pernambuco",
      "value": "Pernambuco",
      "country_id": "Brazil"
    },
    {
      "label": "Piaui",
      "value": "Piaui",
      "country_id": "Brazil"
    },
    {
      "label": "Rio Grande do Norte",
      "value": "Rio Grande do Norte",
      "country_id": "Brazil"
    },
    {
      "label": "Rio Grande do Sul",
      "value": "Rio Grande do Sul",
      "country_id": "Brazil"
    },
    {
      "label": "Rio de Janeiro",
      "value": "Rio de Janeiro",
      "country_id": "Brazil"
    },
    {
      "label": "Rondonia",
      "value": "Rondonia",
      "country_id": "Brazil"
    },
    {
      "label": "Roraima",
      "value": "Roraima",
      "country_id": "Brazil"
    },
    {
      "label": "Santa Catarina",
      "value": "Santa Catarina",
      "country_id": "Brazil"
    },
    {
      "label": "Sao Paulo",
      "value": "Sao Paulo",
      "country_id": "Brazil"
    },
    {
      "label": "Sergipe",
      "value": "Sergipe",
      "country_id": "Brazil"
    },
    {
      "label": "Tocantins",
      "value": "Tocantins",
      "country_id": "Brazil"
    },
    {
      "label": "British Indian Ocean Territory",
      "value": "British Indian Ocean Territory",
      "country_id": "British Indian Ocean Territory"
    },
    {
      "label": "Belait",
      "value": "Belait",
      "country_id": "Brunei"
    },
    {
      "label": "Brunei-Muara",
      "value": "Brunei-Muara",
      "country_id": "Brunei"
    },
    {
      "label": "Temburong",
      "value": "Temburong",
      "country_id": "Brunei"
    },
    {
      "label": "Tutong",
      "value": "Tutong",
      "country_id": "Brunei"
    },
    {
      "label": "Blagoevgrad",
      "value": "Blagoevgrad",
      "country_id": "Bulgaria"
    },
    {
      "label": "Burgas",
      "value": "Burgas",
      "country_id": "Bulgaria"
    },
    {
      "label": "Dobrich",
      "value": "Dobrich",
      "country_id": "Bulgaria"
    },
    {
      "label": "Gabrovo",
      "value": "Gabrovo",
      "country_id": "Bulgaria"
    },
    {
      "label": "Haskovo",
      "value": "Haskovo",
      "country_id": "Bulgaria"
    },
    {
      "label": "Jambol",
      "value": "Jambol",
      "country_id": "Bulgaria"
    },
    {
      "label": "Kardzhali",
      "value": "Kardzhali",
      "country_id": "Bulgaria"
    },
    {
      "label": "Kjustendil",
      "value": "Kjustendil",
      "country_id": "Bulgaria"
    },
    {
      "label": "Lovech",
      "value": "Lovech",
      "country_id": "Bulgaria"
    },
    {
      "label": "Montana",
      "value": "Montana",
      "country_id": "Bulgaria"
    },
    {
      "label": "Oblast Sofiya-Grad",
      "value": "Oblast Sofiya-Grad",
      "country_id": "Bulgaria"
    },
    {
      "label": "Pazardzhik",
      "value": "Pazardzhik",
      "country_id": "Bulgaria"
    },
    {
      "label": "Pernik",
      "value": "Pernik",
      "country_id": "Bulgaria"
    },
    {
      "label": "Pleven",
      "value": "Pleven",
      "country_id": "Bulgaria"
    },
    {
      "label": "Plovdiv",
      "value": "Plovdiv",
      "country_id": "Bulgaria"
    },
    {
      "label": "Razgrad",
      "value": "Razgrad",
      "country_id": "Bulgaria"
    },
    {
      "label": "Ruse",
      "value": "Ruse",
      "country_id": "Bulgaria"
    },
    {
      "label": "Shumen",
      "value": "Shumen",
      "country_id": "Bulgaria"
    },
    {
      "label": "Silistra",
      "value": "Silistra",
      "country_id": "Bulgaria"
    },
    {
      "label": "Sliven",
      "value": "Sliven",
      "country_id": "Bulgaria"
    },
    {
      "label": "Smoljan",
      "value": "Smoljan",
      "country_id": "Bulgaria"
    },
    {
      "label": "Sofija grad",
      "value": "Sofija grad",
      "country_id": "Bulgaria"
    },
    {
      "label": "Sofijska oblast",
      "value": "Sofijska oblast",
      "country_id": "Bulgaria"
    },
    {
      "label": "Stara Zagora",
      "value": "Stara Zagora",
      "country_id": "Bulgaria"
    },
    {
      "label": "Targovishte",
      "value": "Targovishte",
      "country_id": "Bulgaria"
    },
    {
      "label": "Varna",
      "value": "Varna",
      "country_id": "Bulgaria"
    },
    {
      "label": "Veliko Tarnovo",
      "value": "Veliko Tarnovo",
      "country_id": "Bulgaria"
    },
    {
      "label": "Vidin",
      "value": "Vidin",
      "country_id": "Bulgaria"
    },
    {
      "label": "Vraca",
      "value": "Vraca",
      "country_id": "Bulgaria"
    },
    {
      "label": "Yablaniza",
      "value": "Yablaniza",
      "country_id": "Bulgaria"
    },
    {
      "label": "Bale",
      "value": "Bale",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Bam",
      "value": "Bam",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Bazega",
      "value": "Bazega",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Bougouriba",
      "value": "Bougouriba",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Boulgou",
      "value": "Boulgou",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Boulkiemde",
      "value": "Boulkiemde",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Comoe",
      "value": "Comoe",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Ganzourgou",
      "value": "Ganzourgou",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Gnagna",
      "value": "Gnagna",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Gourma",
      "value": "Gourma",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Houet",
      "value": "Houet",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Ioba",
      "value": "Ioba",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Kadiogo",
      "value": "Kadiogo",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Kenedougou",
      "value": "Kenedougou",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Komandjari",
      "value": "Komandjari",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Kompienga",
      "value": "Kompienga",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Kossi",
      "value": "Kossi",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Kouritenga",
      "value": "Kouritenga",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Kourweogo",
      "value": "Kourweogo",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Leraba",
      "value": "Leraba",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Mouhoun",
      "value": "Mouhoun",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Nahouri",
      "value": "Nahouri",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Namentenga",
      "value": "Namentenga",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Noumbiel",
      "value": "Noumbiel",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Oubritenga",
      "value": "Oubritenga",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Oudalan",
      "value": "Oudalan",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Passore",
      "value": "Passore",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Poni",
      "value": "Poni",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Sanguie",
      "value": "Sanguie",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Sanmatenga",
      "value": "Sanmatenga",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Seno",
      "value": "Seno",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Sissili",
      "value": "Sissili",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Soum",
      "value": "Soum",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Sourou",
      "value": "Sourou",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Tapoa",
      "value": "Tapoa",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Tuy",
      "value": "Tuy",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Yatenga",
      "value": "Yatenga",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Zondoma",
      "value": "Zondoma",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Zoundweogo",
      "value": "Zoundweogo",
      "country_id": "Burkina Faso"
    },
    {
      "label": "Bubanza",
      "value": "Bubanza",
      "country_id": "Burundi"
    },
    {
      "label": "Bujumbura",
      "value": "Bujumbura",
      "country_id": "Burundi"
    },
    {
      "label": "Bururi",
      "value": "Bururi",
      "country_id": "Burundi"
    },
    {
      "label": "Cankuzo",
      "value": "Cankuzo",
      "country_id": "Burundi"
    },
    {
      "label": "Cibitoke",
      "value": "Cibitoke",
      "country_id": "Burundi"
    },
    {
      "label": "Gitega",
      "value": "Gitega",
      "country_id": "Burundi"
    },
    {
      "label": "Karuzi",
      "value": "Karuzi",
      "country_id": "Burundi"
    },
    {
      "label": "Kayanza",
      "value": "Kayanza",
      "country_id": "Burundi"
    },
    {
      "label": "Kirundo",
      "value": "Kirundo",
      "country_id": "Burundi"
    },
    {
      "label": "Makamba",
      "value": "Makamba",
      "country_id": "Burundi"
    },
    {
      "label": "Muramvya",
      "value": "Muramvya",
      "country_id": "Burundi"
    },
    {
      "label": "Muyinga",
      "value": "Muyinga",
      "country_id": "Burundi"
    },
    {
      "label": "Ngozi",
      "value": "Ngozi",
      "country_id": "Burundi"
    },
    {
      "label": "Rutana",
      "value": "Rutana",
      "country_id": "Burundi"
    },
    {
      "label": "Ruyigi",
      "value": "Ruyigi",
      "country_id": "Burundi"
    },
    {
      "label": "Banteay Mean Chey",
      "value": "Banteay Mean Chey",
      "country_id": "Cambodia"
    },
    {
      "label": "Bat Dambang",
      "value": "Bat Dambang",
      "country_id": "Cambodia"
    },
    {
      "label": "Kampong Cham",
      "value": "Kampong Cham",
      "country_id": "Cambodia"
    },
    {
      "label": "Kampong Chhnang",
      "value": "Kampong Chhnang",
      "country_id": "Cambodia"
    },
    {
      "label": "Kampong Spoeu",
      "value": "Kampong Spoeu",
      "country_id": "Cambodia"
    },
    {
      "label": "Kampong Thum",
      "value": "Kampong Thum",
      "country_id": "Cambodia"
    },
    {
      "label": "Kampot",
      "value": "Kampot",
      "country_id": "Cambodia"
    },
    {
      "label": "Kandal",
      "value": "Kandal",
      "country_id": "Cambodia"
    },
    {
      "label": "Kaoh Kong",
      "value": "Kaoh Kong",
      "country_id": "Cambodia"
    },
    {
      "label": "Kracheh",
      "value": "Kracheh",
      "country_id": "Cambodia"
    },
    {
      "label": "Krong Kaeb",
      "value": "Krong Kaeb",
      "country_id": "Cambodia"
    },
    {
      "label": "Krong Pailin",
      "value": "Krong Pailin",
      "country_id": "Cambodia"
    },
    {
      "label": "Krong Preah Sihanouk",
      "value": "Krong Preah Sihanouk",
      "country_id": "Cambodia"
    },
    {
      "label": "Mondol Kiri",
      "value": "Mondol Kiri",
      "country_id": "Cambodia"
    },
    {
      "label": "Otdar Mean Chey",
      "value": "Otdar Mean Chey",
      "country_id": "Cambodia"
    },
    {
      "label": "Phnum Penh",
      "value": "Phnum Penh",
      "country_id": "Cambodia"
    },
    {
      "label": "Pousat",
      "value": "Pousat",
      "country_id": "Cambodia"
    },
    {
      "label": "Preah Vihear",
      "value": "Preah Vihear",
      "country_id": "Cambodia"
    },
    {
      "label": "Prey Veaeng",
      "value": "Prey Veaeng",
      "country_id": "Cambodia"
    },
    {
      "label": "Rotanak Kiri",
      "value": "Rotanak Kiri",
      "country_id": "Cambodia"
    },
    {
      "label": "Siem Reab",
      "value": "Siem Reab",
      "country_id": "Cambodia"
    },
    {
      "label": "Stueng Traeng",
      "value": "Stueng Traeng",
      "country_id": "Cambodia"
    },
    {
      "label": "Svay Rieng",
      "value": "Svay Rieng",
      "country_id": "Cambodia"
    },
    {
      "label": "Takaev",
      "value": "Takaev",
      "country_id": "Cambodia"
    },
    {
      "label": "Adamaoua",
      "value": "Adamaoua",
      "country_id": "Cameroon"
    },
    {
      "label": "Centre",
      "value": "Centre",
      "country_id": "Cameroon"
    },
    {
      "label": "Est",
      "value": "Est",
      "country_id": "Cameroon"
    },
    {
      "label": "Littoral",
      "value": "Littoral",
      "country_id": "Cameroon"
    },
    {
      "label": "Nord",
      "value": "Nord",
      "country_id": "Cameroon"
    },
    {
      "label": "Nord Extreme",
      "value": "Nord Extreme",
      "country_id": "Cameroon"
    },
    {
      "label": "Nordouest",
      "value": "Nordouest",
      "country_id": "Cameroon"
    },
    {
      "label": "Ouest",
      "value": "Ouest",
      "country_id": "Cameroon"
    },
    {
      "label": "Sud",
      "value": "Sud",
      "country_id": "Cameroon"
    },
    {
      "label": "Sudouest",
      "value": "Sudouest",
      "country_id": "Cameroon"
    },
    {
      "label": "Alberta",
      "value": "Alberta",
      "country_id": "Canada"
    },
    {
      "label": "British Columbia",
      "value": "British Columbia",
      "country_id": "Canada"
    },
    {
      "label": "Manitoba",
      "value": "Manitoba",
      "country_id": "Canada"
    },
    {
      "label": "New Brunswick",
      "value": "New Brunswick",
      "country_id": "Canada"
    },
    {
      "label": "Newfoundland and Labrador",
      "value": "Newfoundland and Labrador",
      "country_id": "Canada"
    },
    {
      "label": "Northwest Territories",
      "value": "Northwest Territories",
      "country_id": "Canada"
    },
    {
      "label": "Nova Scotia",
      "value": "Nova Scotia",
      "country_id": "Canada"
    },
    {
      "label": "Nunavut",
      "value": "Nunavut",
      "country_id": "Canada"
    },
    {
      "label": "Ontario",
      "value": "Ontario",
      "country_id": "Canada"
    },
    {
      "label": "Prince Edward Island",
      "value": "Prince Edward Island",
      "country_id": "Canada"
    },
    {
      "label": "Quebec",
      "value": "Quebec",
      "country_id": "Canada"
    },
    {
      "label": "Saskatchewan",
      "value": "Saskatchewan",
      "country_id": "Canada"
    },
    {
      "label": "Yukon",
      "value": "Yukon",
      "country_id": "Canada"
    },
    {
      "label": "Boavista",
      "value": "Boavista",
      "country_id": "Cape Verde"
    },
    {
      "label": "Brava",
      "value": "Brava",
      "country_id": "Cape Verde"
    },
    {
      "label": "Fogo",
      "value": "Fogo",
      "country_id": "Cape Verde"
    },
    {
      "label": "Maio",
      "value": "Maio",
      "country_id": "Cape Verde"
    },
    {
      "label": "Sal",
      "value": "Sal",
      "country_id": "Cape Verde"
    },
    {
      "label": "Santo Antao",
      "value": "Santo Antao",
      "country_id": "Cape Verde"
    },
    {
      "label": "Sao Nicolau",
      "value": "Sao Nicolau",
      "country_id": "Cape Verde"
    },
    {
      "label": "Sao Tiago",
      "value": "Sao Tiago",
      "country_id": "Cape Verde"
    },
    {
      "label": "Sao Vicente",
      "value": "Sao Vicente",
      "country_id": "Cape Verde"
    },
    {
      "label": "Grand Cayman",
      "value": "Grand Cayman",
      "country_id": "Cayman Islands"
    },
    {
      "label": "Bamingui-Bangoran",
      "value": "Bamingui-Bangoran",
      "country_id": "Central African Republic"
    },
    {
      "label": "Bangui",
      "value": "Bangui",
      "country_id": "Central African Republic"
    },
    {
      "label": "Basse-Kotto",
      "value": "Basse-Kotto",
      "country_id": "Central African Republic"
    },
    {
      "label": "Haut-Mbomou",
      "value": "Haut-Mbomou",
      "country_id": "Central African Republic"
    },
    {
      "label": "Haute-Kotto",
      "value": "Haute-Kotto",
      "country_id": "Central African Republic"
    },
    {
      "label": "Kemo",
      "value": "Kemo",
      "country_id": "Central African Republic"
    },
    {
      "label": "Lobaye",
      "value": "Lobaye",
      "country_id": "Central African Republic"
    },
    {
      "label": "Mambere-Kadei",
      "value": "Mambere-Kadei",
      "country_id": "Central African Republic"
    },
    {
      "label": "Mbomou",
      "value": "Mbomou",
      "country_id": "Central African Republic"
    },
    {
      "label": "Nana-Gribizi",
      "value": "Nana-Gribizi",
      "country_id": "Central African Republic"
    },
    {
      "label": "Nana-Mambere",
      "value": "Nana-Mambere",
      "country_id": "Central African Republic"
    },
    {
      "label": "Ombella Mpoko",
      "value": "Ombella Mpoko",
      "country_id": "Central African Republic"
    },
    {
      "label": "Ouaka",
      "value": "Ouaka",
      "country_id": "Central African Republic"
    },
    {
      "label": "Ouham",
      "value": "Ouham",
      "country_id": "Central African Republic"
    },
    {
      "label": "Ouham-Pende",
      "value": "Ouham-Pende",
      "country_id": "Central African Republic"
    },
    {
      "label": "Sangha-Mbaere",
      "value": "Sangha-Mbaere",
      "country_id": "Central African Republic"
    },
    {
      "label": "Vakaga",
      "value": "Vakaga",
      "country_id": "Central African Republic"
    },
    {
      "label": "Batha",
      "value": "Batha",
      "country_id": "Chad"
    },
    {
      "label": "Biltine",
      "value": "Biltine",
      "country_id": "Chad"
    },
    {
      "label": "Bourkou-Ennedi-Tibesti",
      "value": "Bourkou-Ennedi-Tibesti",
      "country_id": "Chad"
    },
    {
      "label": "Chari-Baguirmi",
      "value": "Chari-Baguirmi",
      "country_id": "Chad"
    },
    {
      "label": "Guera",
      "value": "Guera",
      "country_id": "Chad"
    },
    {
      "label": "Kanem",
      "value": "Kanem",
      "country_id": "Chad"
    },
    {
      "label": "Lac",
      "value": "Lac",
      "country_id": "Chad"
    },
    {
      "label": "Logone Occidental",
      "value": "Logone Occidental",
      "country_id": "Chad"
    },
    {
      "label": "Logone Oriental",
      "value": "Logone Oriental",
      "country_id": "Chad"
    },
    {
      "label": "Mayo-Kebbi",
      "value": "Mayo-Kebbi",
      "country_id": "Chad"
    },
    {
      "label": "Moyen-Chari",
      "value": "Moyen-Chari",
      "country_id": "Chad"
    },
    {
      "label": "Ouaddai",
      "value": "Ouaddai",
      "country_id": "Chad"
    },
    {
      "label": "Salamat",
      "value": "Salamat",
      "country_id": "Chad"
    },
    {
      "label": "Tandjile",
      "value": "Tandjile",
      "country_id": "Chad"
    },
    {
      "label": "Aisen",
      "value": "Aisen",
      "country_id": "Chile"
    },
    {
      "label": "Antofagasta",
      "value": "Antofagasta",
      "country_id": "Chile"
    },
    {
      "label": "Araucania",
      "value": "Araucania",
      "country_id": "Chile"
    },
    {
      "label": "Atacama",
      "value": "Atacama",
      "country_id": "Chile"
    },
    {
      "label": "Bio Bio",
      "value": "Bio Bio",
      "country_id": "Chile"
    },
    {
      "label": "Coquimbo",
      "value": "Coquimbo",
      "country_id": "Chile"
    },
    {
      "label": "Libertador General Bernardo O",
      "value": "Libertador General Bernardo O",
      "country_id": "Chile"
    },
    {
      "label": "Los Lagos",
      "value": "Los Lagos",
      "country_id": "Chile"
    },
    {
      "label": "Magellanes",
      "value": "Magellanes",
      "country_id": "Chile"
    },
    {
      "label": "Maule",
      "value": "Maule",
      "country_id": "Chile"
    },
    {
      "label": "Metropolitana",
      "value": "Metropolitana",
      "country_id": "Chile"
    },
    {
      "label": "Metropolitana de Santiago",
      "value": "Metropolitana de Santiago",
      "country_id": "Chile"
    },
    {
      "label": "Tarapaca",
      "value": "Tarapaca",
      "country_id": "Chile"
    },
    {
      "label": "Valparaiso",
      "value": "Valparaiso",
      "country_id": "Chile"
    },
    {
      "label": "Anhui",
      "value": "Anhui",
      "country_id": "China"
    },
    {
      "label": "Anhui Province",
      "value": "Anhui Province",
      "country_id": "China"
    },
    {
      "label": "Anhui Sheng",
      "value": "Anhui Sheng",
      "country_id": "China"
    },
    {
      "label": "Aomen",
      "value": "Aomen",
      "country_id": "China"
    },
    {
      "label": "Beijing",
      "value": "Beijing",
      "country_id": "China"
    },
    {
      "label": "Beijing Shi",
      "value": "Beijing Shi",
      "country_id": "China"
    },
    {
      "label": "Chongqing",
      "value": "Chongqing",
      "country_id": "China"
    },
    {
      "label": "Fujian",
      "value": "Fujian",
      "country_id": "China"
    },
    {
      "label": "Fujian Sheng",
      "value": "Fujian Sheng",
      "country_id": "China"
    },
    {
      "label": "Gansu",
      "value": "Gansu",
      "country_id": "China"
    },
    {
      "label": "Guangdong",
      "value": "Guangdong",
      "country_id": "China"
    },
    {
      "label": "Guangdong Sheng",
      "value": "Guangdong Sheng",
      "country_id": "China"
    },
    {
      "label": "Guangxi",
      "value": "Guangxi",
      "country_id": "China"
    },
    {
      "label": "Guizhou",
      "value": "Guizhou",
      "country_id": "China"
    },
    {
      "label": "Hainan",
      "value": "Hainan",
      "country_id": "China"
    },
    {
      "label": "Hebei",
      "value": "Hebei",
      "country_id": "China"
    },
    {
      "label": "Heilongjiang",
      "value": "Heilongjiang",
      "country_id": "China"
    },
    {
      "label": "Henan",
      "value": "Henan",
      "country_id": "China"
    },
    {
      "label": "Hubei",
      "value": "Hubei",
      "country_id": "China"
    },
    {
      "label": "Hunan",
      "value": "Hunan",
      "country_id": "China"
    },
    {
      "label": "Jiangsu",
      "value": "Jiangsu",
      "country_id": "China"
    },
    {
      "label": "Jiangsu Sheng",
      "value": "Jiangsu Sheng",
      "country_id": "China"
    },
    {
      "label": "Jiangxi",
      "value": "Jiangxi",
      "country_id": "China"
    },
    {
      "label": "Jilin",
      "value": "Jilin",
      "country_id": "China"
    },
    {
      "label": "Liaoning",
      "value": "Liaoning",
      "country_id": "China"
    },
    {
      "label": "Liaoning Sheng",
      "value": "Liaoning Sheng",
      "country_id": "China"
    },
    {
      "label": "Nei Monggol",
      "value": "Nei Monggol",
      "country_id": "China"
    },
    {
      "label": "Ningxia Hui",
      "value": "Ningxia Hui",
      "country_id": "China"
    },
    {
      "label": "Qinghai",
      "value": "Qinghai",
      "country_id": "China"
    },
    {
      "label": "Shaanxi",
      "value": "Shaanxi",
      "country_id": "China"
    },
    {
      "label": "Shandong",
      "value": "Shandong",
      "country_id": "China"
    },
    {
      "label": "Shandong Sheng",
      "value": "Shandong Sheng",
      "country_id": "China"
    },
    {
      "label": "Shanghai",
      "value": "Shanghai",
      "country_id": "China"
    },
    {
      "label": "Shanxi",
      "value": "Shanxi",
      "country_id": "China"
    },
    {
      "label": "Sichuan",
      "value": "Sichuan",
      "country_id": "China"
    },
    {
      "label": "Tianjin",
      "value": "Tianjin",
      "country_id": "China"
    },
    {
      "label": "Xianggang",
      "value": "Xianggang",
      "country_id": "China"
    },
    {
      "label": "Xinjiang",
      "value": "Xinjiang",
      "country_id": "China"
    },
    {
      "label": "Xizang",
      "value": "Xizang",
      "country_id": "China"
    },
    {
      "label": "Yunnan",
      "value": "Yunnan",
      "country_id": "China"
    },
    {
      "label": "Zhejiang",
      "value": "Zhejiang",
      "country_id": "China"
    },
    {
      "label": "Zhejiang Sheng",
      "value": "Zhejiang Sheng",
      "country_id": "China"
    },
    {
      "label": "Christmas Island",
      "value": "Christmas Island",
      "country_id": "Christmas Island"
    },
    {
      "label": "Cocos (Keeling) Islands",
      "value": "Cocos (Keeling) Islands",
      "country_id": "Cocos (Keeling) Islands"
    },
    {
      "label": "Amazonas",
      "value": "Amazonas",
      "country_id": "Colombia"
    },
    {
      "label": "Antioquia",
      "value": "Antioquia",
      "country_id": "Colombia"
    },
    {
      "label": "Arauca",
      "value": "Arauca",
      "country_id": "Colombia"
    },
    {
      "label": "Atlantico",
      "value": "Atlantico",
      "country_id": "Colombia"
    },
    {
      "label": "Bogota",
      "value": "Bogota",
      "country_id": "Colombia"
    },
    {
      "label": "Bolivar",
      "value": "Bolivar",
      "country_id": "Colombia"
    },
    {
      "label": "Boyaca",
      "value": "Boyaca",
      "country_id": "Colombia"
    },
    {
      "label": "Caldas",
      "value": "Caldas",
      "country_id": "Colombia"
    },
    {
      "label": "Caqueta",
      "value": "Caqueta",
      "country_id": "Colombia"
    },
    {
      "label": "Casanare",
      "value": "Casanare",
      "country_id": "Colombia"
    },
    {
      "label": "Cauca",
      "value": "Cauca",
      "country_id": "Colombia"
    },
    {
      "label": "Cesar",
      "value": "Cesar",
      "country_id": "Colombia"
    },
    {
      "label": "Choco",
      "value": "Choco",
      "country_id": "Colombia"
    },
    {
      "label": "Cordoba",
      "value": "Cordoba",
      "country_id": "Colombia"
    },
    {
      "label": "Cundinamarca",
      "value": "Cundinamarca",
      "country_id": "Colombia"
    },
    {
      "label": "Guainia",
      "value": "Guainia",
      "country_id": "Colombia"
    },
    {
      "label": "Guaviare",
      "value": "Guaviare",
      "country_id": "Colombia"
    },
    {
      "label": "Huila",
      "value": "Huila",
      "country_id": "Colombia"
    },
    {
      "label": "La Guajira",
      "value": "La Guajira",
      "country_id": "Colombia"
    },
    {
      "label": "Magdalena",
      "value": "Magdalena",
      "country_id": "Colombia"
    },
    {
      "label": "Meta",
      "value": "Meta",
      "country_id": "Colombia"
    },
    {
      "label": "Narino",
      "value": "Narino",
      "country_id": "Colombia"
    },
    {
      "label": "Norte de Santander",
      "value": "Norte de Santander",
      "country_id": "Colombia"
    },
    {
      "label": "Putumayo",
      "value": "Putumayo",
      "country_id": "Colombia"
    },
    {
      "label": "Quindio",
      "value": "Quindio",
      "country_id": "Colombia"
    },
    {
      "label": "Risaralda",
      "value": "Risaralda",
      "country_id": "Colombia"
    },
    {
      "label": "San Andres y Providencia",
      "value": "San Andres y Providencia",
      "country_id": "Colombia"
    },
    {
      "label": "Santander",
      "value": "Santander",
      "country_id": "Colombia"
    },
    {
      "label": "Sucre",
      "value": "Sucre",
      "country_id": "Colombia"
    },
    {
      "label": "Tolima",
      "value": "Tolima",
      "country_id": "Colombia"
    },
    {
      "label": "Valle del Cauca",
      "value": "Valle del Cauca",
      "country_id": "Colombia"
    },
    {
      "label": "Vaupes",
      "value": "Vaupes",
      "country_id": "Colombia"
    },
    {
      "label": "Vichada",
      "value": "Vichada",
      "country_id": "Colombia"
    },
    {
      "label": "Mwali",
      "value": "Mwali",
      "country_id": "Comoros"
    },
    {
      "label": "Njazidja",
      "value": "Njazidja",
      "country_id": "Comoros"
    },
    {
      "label": "Nzwani",
      "value": "Nzwani",
      "country_id": "Comoros"
    },
    {
      "label": "Bouenza",
      "value": "Bouenza",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Brazzaville",
      "value": "Brazzaville",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Cuvette",
      "value": "Cuvette",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Kouilou",
      "value": "Kouilou",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Lekoumou",
      "value": "Lekoumou",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Likouala",
      "value": "Likouala",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Niari",
      "value": "Niari",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Plateaux",
      "value": "Plateaux",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Pool",
      "value": "Pool",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Sangha",
      "value": "Sangha",
      "country_id": "Republic Of The Congo"
    },
    {
      "label": "Bandundu",
      "value": "Bandundu",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Bas-Congo",
      "value": "Bas-Congo",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Equateur",
      "value": "Equateur",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Haut-Congo",
      "value": "Haut-Congo",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Kasai-Occidental",
      "value": "Kasai-Occidental",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Kasai-Oriental",
      "value": "Kasai-Oriental",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Katanga",
      "value": "Katanga",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Kinshasa",
      "value": "Kinshasa",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Maniema",
      "value": "Maniema",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Nord-Kivu",
      "value": "Nord-Kivu",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Sud-Kivu",
      "value": "Sud-Kivu",
      "country_id": "Democratic Republic Of The Congo"
    },
    {
      "label": "Aitutaki",
      "value": "Aitutaki",
      "country_id": "Cook Islands"
    },
    {
      "label": "Atiu",
      "value": "Atiu",
      "country_id": "Cook Islands"
    },
    {
      "label": "Mangaia",
      "value": "Mangaia",
      "country_id": "Cook Islands"
    },
    {
      "label": "Manihiki",
      "value": "Manihiki",
      "country_id": "Cook Islands"
    },
    {
      "label": "Mauke",
      "value": "Mauke",
      "country_id": "Cook Islands"
    },
    {
      "label": "Mitiaro",
      "value": "Mitiaro",
      "country_id": "Cook Islands"
    },
    {
      "label": "Nassau",
      "value": "Nassau",
      "country_id": "Cook Islands"
    },
    {
      "label": "Pukapuka",
      "value": "Pukapuka",
      "country_id": "Cook Islands"
    },
    {
      "label": "Rakahanga",
      "value": "Rakahanga",
      "country_id": "Cook Islands"
    },
    {
      "label": "Rarotonga",
      "value": "Rarotonga",
      "country_id": "Cook Islands"
    },
    {
      "label": "Tongareva",
      "value": "Tongareva",
      "country_id": "Cook Islands"
    },
    {
      "label": "Alajuela",
      "value": "Alajuela",
      "country_id": "Costa Rica"
    },
    {
      "label": "Cartago",
      "value": "Cartago",
      "country_id": "Costa Rica"
    },
    {
      "label": "Guanacaste",
      "value": "Guanacaste",
      "country_id": "Costa Rica"
    },
    {
      "label": "Heredia",
      "value": "Heredia",
      "country_id": "Costa Rica"
    },
    {
      "label": "Limon",
      "value": "Limon",
      "country_id": "Costa Rica"
    },
    {
      "label": "Puntarenas",
      "value": "Puntarenas",
      "country_id": "Costa Rica"
    },
    {
      "label": "San Jose",
      "value": "San Jose",
      "country_id": "Costa Rica"
    },
    {
      "label": "Abidjan",
      "value": "Abidjan",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Agneby",
      "value": "Agneby",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Bafing",
      "value": "Bafing",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Denguele",
      "value": "Denguele",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Dix-huit Montagnes",
      "value": "Dix-huit Montagnes",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Fromager",
      "value": "Fromager",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Haut-Sassandra",
      "value": "Haut-Sassandra",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Lacs",
      "value": "Lacs",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Lagunes",
      "value": "Lagunes",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Marahoue",
      "value": "Marahoue",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Moyen-Cavally",
      "value": "Moyen-Cavally",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Moyen-Comoe",
      "value": "Moyen-Comoe",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "N''zi-Comoe",
      "value": "N''zi-Comoe",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Sassandra",
      "value": "Sassandra",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Savanes",
      "value": "Savanes",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Sud-Bandama",
      "value": "Sud-Bandama",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Sud-Comoe",
      "value": "Sud-Comoe",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Vallee du Bandama",
      "value": "Vallee du Bandama",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Worodougou",
      "value": "Worodougou",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Zanzan",
      "value": "Zanzan",
      "country_id": "Cote D''Ivoire (Ivory Coast)"
    },
    {
      "label": "Bjelovar-Bilogora",
      "value": "Bjelovar-Bilogora",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Dubrovnik-Neretva",
      "value": "Dubrovnik-Neretva",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Grad Zagreb",
      "value": "Grad Zagreb",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Istra",
      "value": "Istra",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Karlovac",
      "value": "Karlovac",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Koprivnica-Krizhevci",
      "value": "Koprivnica-Krizhevci",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Krapina-Zagorje",
      "value": "Krapina-Zagorje",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Lika-Senj",
      "value": "Lika-Senj",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Medhimurje",
      "value": "Medhimurje",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Medimurska Zupanija",
      "value": "Medimurska Zupanija",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Osijek-Baranja",
      "value": "Osijek-Baranja",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Osjecko-Baranjska Zupanija",
      "value": "Osjecko-Baranjska Zupanija",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Pozhega-Slavonija",
      "value": "Pozhega-Slavonija",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Primorje-Gorski Kotar",
      "value": "Primorje-Gorski Kotar",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Shibenik-Knin",
      "value": "Shibenik-Knin",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Sisak-Moslavina",
      "value": "Sisak-Moslavina",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Slavonski Brod-Posavina",
      "value": "Slavonski Brod-Posavina",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Split-Dalmacija",
      "value": "Split-Dalmacija",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Varazhdin",
      "value": "Varazhdin",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Virovitica-Podravina",
      "value": "Virovitica-Podravina",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Vukovar-Srijem",
      "value": "Vukovar-Srijem",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Zadar",
      "value": "Zadar",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Zagreb",
      "value": "Zagreb",
      "country_id": "Croatia (Hrvatska)"
    },
    {
      "label": "Camaguey",
      "value": "Camaguey",
      "country_id": "Cuba"
    },
    {
      "label": "Ciego de Avila",
      "value": "Ciego de Avila",
      "country_id": "Cuba"
    },
    {
      "label": "Cienfuegos",
      "value": "Cienfuegos",
      "country_id": "Cuba"
    },
    {
      "label": "Ciudad de la Habana",
      "value": "Ciudad de la Habana",
      "country_id": "Cuba"
    },
    {
      "label": "Granma",
      "value": "Granma",
      "country_id": "Cuba"
    },
    {
      "label": "Guantanamo",
      "value": "Guantanamo",
      "country_id": "Cuba"
    },
    {
      "label": "Habana",
      "value": "Habana",
      "country_id": "Cuba"
    },
    {
      "label": "Holguin",
      "value": "Holguin",
      "country_id": "Cuba"
    },
    {
      "label": "Isla de la Juventud",
      "value": "Isla de la Juventud",
      "country_id": "Cuba"
    },
    {
      "label": "La Habana",
      "value": "La Habana",
      "country_id": "Cuba"
    },
    {
      "label": "Las Tunas",
      "value": "Las Tunas",
      "country_id": "Cuba"
    },
    {
      "label": "Matanzas",
      "value": "Matanzas",
      "country_id": "Cuba"
    },
    {
      "label": "Pinar del Rio",
      "value": "Pinar del Rio",
      "country_id": "Cuba"
    },
    {
      "label": "Sancti Spiritus",
      "value": "Sancti Spiritus",
      "country_id": "Cuba"
    },
    {
      "label": "Santiago de Cuba",
      "value": "Santiago de Cuba",
      "country_id": "Cuba"
    },
    {
      "label": "Villa Clara",
      "value": "Villa Clara",
      "country_id": "Cuba"
    },
    {
      "label": "Government controlled area",
      "value": "Government controlled area",
      "country_id": "Cyprus"
    },
    {
      "label": "Limassol",
      "value": "Limassol",
      "country_id": "Cyprus"
    },
    {
      "label": "Nicosia District",
      "value": "Nicosia District",
      "country_id": "Cyprus"
    },
    {
      "label": "Paphos",
      "value": "Paphos",
      "country_id": "Cyprus"
    },
    {
      "label": "Turkish controlled area",
      "value": "Turkish controlled area",
      "country_id": "Cyprus"
    },
    {
      "label": "Central Bohemian",
      "value": "Central Bohemian",
      "country_id": "Czech Republic"
    },
    {
      "label": "Frycovice",
      "value": "Frycovice",
      "country_id": "Czech Republic"
    },
    {
      "label": "Jihocesky Kraj",
      "value": "Jihocesky Kraj",
      "country_id": "Czech Republic"
    },
    {
      "label": "Jihochesky",
      "value": "Jihochesky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Jihomoravsky",
      "value": "Jihomoravsky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Karlovarsky",
      "value": "Karlovarsky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Klecany",
      "value": "Klecany",
      "country_id": "Czech Republic"
    },
    {
      "label": "Kralovehradecky",
      "value": "Kralovehradecky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Liberecky",
      "value": "Liberecky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Lipov",
      "value": "Lipov",
      "country_id": "Czech Republic"
    },
    {
      "label": "Moravskoslezsky",
      "value": "Moravskoslezsky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Olomoucky",
      "value": "Olomoucky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Olomoucky Kraj",
      "value": "Olomoucky Kraj",
      "country_id": "Czech Republic"
    },
    {
      "label": "Pardubicky",
      "value": "Pardubicky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Plzensky",
      "value": "Plzensky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Praha",
      "value": "Praha",
      "country_id": "Czech Republic"
    },
    {
      "label": "Rajhrad",
      "value": "Rajhrad",
      "country_id": "Czech Republic"
    },
    {
      "label": "Smirice",
      "value": "Smirice",
      "country_id": "Czech Republic"
    },
    {
      "label": "South Moravian",
      "value": "South Moravian",
      "country_id": "Czech Republic"
    },
    {
      "label": "Straz nad Nisou",
      "value": "Straz nad Nisou",
      "country_id": "Czech Republic"
    },
    {
      "label": "Stredochesky",
      "value": "Stredochesky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Unicov",
      "value": "Unicov",
      "country_id": "Czech Republic"
    },
    {
      "label": "Ustecky",
      "value": "Ustecky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Valletta",
      "value": "Valletta",
      "country_id": "Czech Republic"
    },
    {
      "label": "Velesin",
      "value": "Velesin",
      "country_id": "Czech Republic"
    },
    {
      "label": "Vysochina",
      "value": "Vysochina",
      "country_id": "Czech Republic"
    },
    {
      "label": "Zlinsky",
      "value": "Zlinsky",
      "country_id": "Czech Republic"
    },
    {
      "label": "Arhus",
      "value": "Arhus",
      "country_id": "Denmark"
    },
    {
      "label": "Bornholm",
      "value": "Bornholm",
      "country_id": "Denmark"
    },
    {
      "label": "Frederiksborg",
      "value": "Frederiksborg",
      "country_id": "Denmark"
    },
    {
      "label": "Fyn",
      "value": "Fyn",
      "country_id": "Denmark"
    },
    {
      "label": "Hovedstaden",
      "value": "Hovedstaden",
      "country_id": "Denmark"
    },
    {
      "label": "Kobenhavn",
      "value": "Kobenhavn",
      "country_id": "Denmark"
    },
    {
      "label": "Kobenhavns Amt",
      "value": "Kobenhavns Amt",
      "country_id": "Denmark"
    },
    {
      "label": "Kobenhavns Kommune",
      "value": "Kobenhavns Kommune",
      "country_id": "Denmark"
    },
    {
      "label": "Nordjylland",
      "value": "Nordjylland",
      "country_id": "Denmark"
    },
    {
      "label": "Ribe",
      "value": "Ribe",
      "country_id": "Denmark"
    },
    {
      "label": "Ringkobing",
      "value": "Ringkobing",
      "country_id": "Denmark"
    },
    {
      "label": "Roervig",
      "value": "Roervig",
      "country_id": "Denmark"
    },
    {
      "label": "Roskilde",
      "value": "Roskilde",
      "country_id": "Denmark"
    },
    {
      "label": "Roslev",
      "value": "Roslev",
      "country_id": "Denmark"
    },
    {
      "label": "Sjaelland",
      "value": "Sjaelland",
      "country_id": "Denmark"
    },
    {
      "label": "Soeborg",
      "value": "Soeborg",
      "country_id": "Denmark"
    },
    {
      "label": "Sonderjylland",
      "value": "Sonderjylland",
      "country_id": "Denmark"
    },
    {
      "label": "Storstrom",
      "value": "Storstrom",
      "country_id": "Denmark"
    },
    {
      "label": "Syddanmark",
      "value": "Syddanmark",
      "country_id": "Denmark"
    },
    {
      "label": "Toelloese",
      "value": "Toelloese",
      "country_id": "Denmark"
    },
    {
      "label": "Vejle",
      "value": "Vejle",
      "country_id": "Denmark"
    },
    {
      "label": "Vestsjalland",
      "value": "Vestsjalland",
      "country_id": "Denmark"
    },
    {
      "label": "Viborg",
      "value": "Viborg",
      "country_id": "Denmark"
    },
    {
      "label": "Ali Sabih",
      "value": "Ali Sabih",
      "country_id": "Djibouti"
    },
    {
      "label": "Dikhil",
      "value": "Dikhil",
      "country_id": "Djibouti"
    },
    {
      "label": "Jibuti",
      "value": "Jibuti",
      "country_id": "Djibouti"
    },
    {
      "label": "Tajurah",
      "value": "Tajurah",
      "country_id": "Djibouti"
    },
    {
      "label": "Ubuk",
      "value": "Ubuk",
      "country_id": "Djibouti"
    },
    {
      "label": "Saint Andrew",
      "value": "Saint Andrew",
      "country_id": "Dominica"
    },
    {
      "label": "Saint David",
      "value": "Saint David",
      "country_id": "Dominica"
    },
    {
      "label": "Saint George",
      "value": "Saint George",
      "country_id": "Dominica"
    },
    {
      "label": "Saint John",
      "value": "Saint John",
      "country_id": "Dominica"
    },
    {
      "label": "Saint Joseph",
      "value": "Saint Joseph",
      "country_id": "Dominica"
    },
    {
      "label": "Saint Luke",
      "value": "Saint Luke",
      "country_id": "Dominica"
    },
    {
      "label": "Saint Mark",
      "value": "Saint Mark",
      "country_id": "Dominica"
    },
    {
      "label": "Saint Patrick",
      "value": "Saint Patrick",
      "country_id": "Dominica"
    },
    {
      "label": "Saint Paul",
      "value": "Saint Paul",
      "country_id": "Dominica"
    },
    {
      "label": "Saint Peter",
      "value": "Saint Peter",
      "country_id": "Dominica"
    },
    {
      "label": "Azua",
      "value": "Azua",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Bahoruco",
      "value": "Bahoruco",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Barahona",
      "value": "Barahona",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Dajabon",
      "value": "Dajabon",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Distrito Nacional",
      "value": "Distrito Nacional",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Duarte",
      "value": "Duarte",
      "country_id": "Dominican Republic"
    },
    {
      "label": "El Seybo",
      "value": "El Seybo",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Elias Pina",
      "value": "Elias Pina",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Espaillat",
      "value": "Espaillat",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Hato Mayor",
      "value": "Hato Mayor",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Independencia",
      "value": "Independencia",
      "country_id": "Dominican Republic"
    },
    {
      "label": "La Altagracia",
      "value": "La Altagracia",
      "country_id": "Dominican Republic"
    },
    {
      "label": "La Romana",
      "value": "La Romana",
      "country_id": "Dominican Republic"
    },
    {
      "label": "La Vega",
      "value": "La Vega",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Maria Trinidad Sanchez",
      "value": "Maria Trinidad Sanchez",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Monsenor Nouel",
      "value": "Monsenor Nouel",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Monte Cristi",
      "value": "Monte Cristi",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Monte Plata",
      "value": "Monte Plata",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Pedernales",
      "value": "Pedernales",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Peravia",
      "value": "Peravia",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Puerto Plata",
      "value": "Puerto Plata",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Salcedo",
      "value": "Salcedo",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Samana",
      "value": "Samana",
      "country_id": "Dominican Republic"
    },
    {
      "label": "San Cristobal",
      "value": "San Cristobal",
      "country_id": "Dominican Republic"
    },
    {
      "label": "San Juan",
      "value": "San Juan",
      "country_id": "Dominican Republic"
    },
    {
      "label": "San Pedro de Macoris",
      "value": "San Pedro de Macoris",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Sanchez Ramirez",
      "value": "Sanchez Ramirez",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Santiago",
      "value": "Santiago",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Santiago Rodriguez",
      "value": "Santiago Rodriguez",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Valverde",
      "value": "Valverde",
      "country_id": "Dominican Republic"
    },
    {
      "label": "Aileu",
      "value": "Aileu",
      "country_id": "East Timor"
    },
    {
      "label": "Ainaro",
      "value": "Ainaro",
      "country_id": "East Timor"
    },
    {
      "label": "Ambeno",
      "value": "Ambeno",
      "country_id": "East Timor"
    },
    {
      "label": "Baucau",
      "value": "Baucau",
      "country_id": "East Timor"
    },
    {
      "label": "Bobonaro",
      "value": "Bobonaro",
      "country_id": "East Timor"
    },
    {
      "label": "Cova Lima",
      "value": "Cova Lima",
      "country_id": "East Timor"
    },
    {
      "label": "Dili",
      "value": "Dili",
      "country_id": "East Timor"
    },
    {
      "label": "Ermera",
      "value": "Ermera",
      "country_id": "East Timor"
    },
    {
      "label": "Lautem",
      "value": "Lautem",
      "country_id": "East Timor"
    },
    {
      "label": "Liquica",
      "value": "Liquica",
      "country_id": "East Timor"
    },
    {
      "label": "Manatuto",
      "value": "Manatuto",
      "country_id": "East Timor"
    },
    {
      "label": "Manufahi",
      "value": "Manufahi",
      "country_id": "East Timor"
    },
    {
      "label": "Viqueque",
      "value": "Viqueque",
      "country_id": "East Timor"
    },
    {
      "label": "Azuay",
      "value": "Azuay",
      "country_id": "Ecuador"
    },
    {
      "label": "Bolivar",
      "value": "Bolivar",
      "country_id": "Ecuador"
    },
    {
      "label": "Canar",
      "value": "Canar",
      "country_id": "Ecuador"
    },
    {
      "label": "Carchi",
      "value": "Carchi",
      "country_id": "Ecuador"
    },
    {
      "label": "Chimborazo",
      "value": "Chimborazo",
      "country_id": "Ecuador"
    },
    {
      "label": "Cotopaxi",
      "value": "Cotopaxi",
      "country_id": "Ecuador"
    },
    {
      "label": "El Oro",
      "value": "El Oro",
      "country_id": "Ecuador"
    },
    {
      "label": "Esmeraldas",
      "value": "Esmeraldas",
      "country_id": "Ecuador"
    },
    {
      "label": "Galapagos",
      "value": "Galapagos",
      "country_id": "Ecuador"
    },
    {
      "label": "Guayas",
      "value": "Guayas",
      "country_id": "Ecuador"
    },
    {
      "label": "Imbabura",
      "value": "Imbabura",
      "country_id": "Ecuador"
    },
    {
      "label": "Loja",
      "value": "Loja",
      "country_id": "Ecuador"
    },
    {
      "label": "Los Rios",
      "value": "Los Rios",
      "country_id": "Ecuador"
    },
    {
      "label": "Manabi",
      "value": "Manabi",
      "country_id": "Ecuador"
    },
    {
      "label": "Morona Santiago",
      "value": "Morona Santiago",
      "country_id": "Ecuador"
    },
    {
      "label": "Napo",
      "value": "Napo",
      "country_id": "Ecuador"
    },
    {
      "label": "Orellana",
      "value": "Orellana",
      "country_id": "Ecuador"
    },
    {
      "label": "Pastaza",
      "value": "Pastaza",
      "country_id": "Ecuador"
    },
    {
      "label": "Pichincha",
      "value": "Pichincha",
      "country_id": "Ecuador"
    },
    {
      "label": "Sucumbios",
      "value": "Sucumbios",
      "country_id": "Ecuador"
    },
    {
      "label": "Tungurahua",
      "value": "Tungurahua",
      "country_id": "Ecuador"
    },
    {
      "label": "Zamora Chinchipe",
      "value": "Zamora Chinchipe",
      "country_id": "Ecuador"
    },
    {
      "label": "Aswan",
      "value": "Aswan",
      "country_id": "Egypt"
    },
    {
      "label": "Asyut",
      "value": "Asyut",
      "country_id": "Egypt"
    },
    {
      "label": "Bani Suwayf",
      "value": "Bani Suwayf",
      "country_id": "Egypt"
    },
    {
      "label": "Bur Sa''id",
      "value": "Bur Sa''id",
      "country_id": "Egypt"
    },
    {
      "label": "Cairo",
      "value": "Cairo",
      "country_id": "Egypt"
    },
    {
      "label": "Dumyat",
      "value": "Dumyat",
      "country_id": "Egypt"
    },
    {
      "label": "Kafr-ash-Shaykh",
      "value": "Kafr-ash-Shaykh",
      "country_id": "Egypt"
    },
    {
      "label": "Matruh",
      "value": "Matruh",
      "country_id": "Egypt"
    },
    {
      "label": "Muhafazat ad Daqahliyah",
      "value": "Muhafazat ad Daqahliyah",
      "country_id": "Egypt"
    },
    {
      "label": "Muhafazat al Fayyum",
      "value": "Muhafazat al Fayyum",
      "country_id": "Egypt"
    },
    {
      "label": "Muhafazat al Gharbiyah",
      "value": "Muhafazat al Gharbiyah",
      "country_id": "Egypt"
    },
    {
      "label": "Muhafazat al Iskandariyah",
      "value": "Muhafazat al Iskandariyah",
      "country_id": "Egypt"
    },
    {
      "label": "Muhafazat al Qahirah",
      "value": "Muhafazat al Qahirah",
      "country_id": "Egypt"
    },
    {
      "label": "Qina",
      "value": "Qina",
      "country_id": "Egypt"
    },
    {
      "label": "Sawhaj",
      "value": "Sawhaj",
      "country_id": "Egypt"
    },
    {
      "label": "Sina al-Janubiyah",
      "value": "Sina al-Janubiyah",
      "country_id": "Egypt"
    },
    {
      "label": "Sina ash-Shamaliyah",
      "value": "Sina ash-Shamaliyah",
      "country_id": "Egypt"
    },
    {
      "label": "ad-Daqahliyah",
      "value": "ad-Daqahliyah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Bahr-al-Ahmar",
      "value": "al-Bahr-al-Ahmar",
      "country_id": "Egypt"
    },
    {
      "label": "al-Buhayrah",
      "value": "al-Buhayrah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Fayyum",
      "value": "al-Fayyum",
      "country_id": "Egypt"
    },
    {
      "label": "al-Gharbiyah",
      "value": "al-Gharbiyah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Iskandariyah",
      "value": "al-Iskandariyah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Ismailiyah",
      "value": "al-Ismailiyah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Jizah",
      "value": "al-Jizah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Minufiyah",
      "value": "al-Minufiyah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Minya",
      "value": "al-Minya",
      "country_id": "Egypt"
    },
    {
      "label": "al-Qahira",
      "value": "al-Qahira",
      "country_id": "Egypt"
    },
    {
      "label": "al-Qalyubiyah",
      "value": "al-Qalyubiyah",
      "country_id": "Egypt"
    },
    {
      "label": "al-Uqsur",
      "value": "al-Uqsur",
      "country_id": "Egypt"
    },
    {
      "label": "al-Wadi al-Jadid",
      "value": "al-Wadi al-Jadid",
      "country_id": "Egypt"
    },
    {
      "label": "as-Suways",
      "value": "as-Suways",
      "country_id": "Egypt"
    },
    {
      "label": "ash-Sharqiyah",
      "value": "ash-Sharqiyah",
      "country_id": "Egypt"
    },
    {
      "label": "Ahuachapan",
      "value": "Ahuachapan",
      "country_id": "El Salvador"
    },
    {
      "label": "Cabanas",
      "value": "Cabanas",
      "country_id": "El Salvador"
    },
    {
      "label": "Chalatenango",
      "value": "Chalatenango",
      "country_id": "El Salvador"
    },
    {
      "label": "Cuscatlan",
      "value": "Cuscatlan",
      "country_id": "El Salvador"
    },
    {
      "label": "La Libertad",
      "value": "La Libertad",
      "country_id": "El Salvador"
    },
    {
      "label": "La Paz",
      "value": "La Paz",
      "country_id": "El Salvador"
    },
    {
      "label": "La Union",
      "value": "La Union",
      "country_id": "El Salvador"
    },
    {
      "label": "Morazan",
      "value": "Morazan",
      "country_id": "El Salvador"
    },
    {
      "label": "San Miguel",
      "value": "San Miguel",
      "country_id": "El Salvador"
    },
    {
      "label": "San Salvador",
      "value": "San Salvador",
      "country_id": "El Salvador"
    },
    {
      "label": "San Vicente",
      "value": "San Vicente",
      "country_id": "El Salvador"
    },
    {
      "label": "Santa Ana",
      "value": "Santa Ana",
      "country_id": "El Salvador"
    },
    {
      "label": "Sonsonate",
      "value": "Sonsonate",
      "country_id": "El Salvador"
    },
    {
      "label": "Usulutan",
      "value": "Usulutan",
      "country_id": "El Salvador"
    },
    {
      "label": "Annobon",
      "value": "Annobon",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Bioko Norte",
      "value": "Bioko Norte",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Bioko Sur",
      "value": "Bioko Sur",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Centro Sur",
      "value": "Centro Sur",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Kie-Ntem",
      "value": "Kie-Ntem",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Litoral",
      "value": "Litoral",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Wele-Nzas",
      "value": "Wele-Nzas",
      "country_id": "Equatorial Guinea"
    },
    {
      "label": "Anseba",
      "value": "Anseba",
      "country_id": "Eritrea"
    },
    {
      "label": "Debub",
      "value": "Debub",
      "country_id": "Eritrea"
    },
    {
      "label": "Debub-Keih-Bahri",
      "value": "Debub-Keih-Bahri",
      "country_id": "Eritrea"
    },
    {
      "label": "Gash-Barka",
      "value": "Gash-Barka",
      "country_id": "Eritrea"
    },
    {
      "label": "Maekel",
      "value": "Maekel",
      "country_id": "Eritrea"
    },
    {
      "label": "Semien-Keih-Bahri",
      "value": "Semien-Keih-Bahri",
      "country_id": "Eritrea"
    },
    {
      "label": "Harju",
      "value": "Harju",
      "country_id": "Estonia"
    },
    {
      "label": "Hiiu",
      "value": "Hiiu",
      "country_id": "Estonia"
    },
    {
      "label": "Ida-Viru",
      "value": "Ida-Viru",
      "country_id": "Estonia"
    },
    {
      "label": "Jarva",
      "value": "Jarva",
      "country_id": "Estonia"
    },
    {
      "label": "Jogeva",
      "value": "Jogeva",
      "country_id": "Estonia"
    },
    {
      "label": "Laane",
      "value": "Laane",
      "country_id": "Estonia"
    },
    {
      "label": "Laane-Viru",
      "value": "Laane-Viru",
      "country_id": "Estonia"
    },
    {
      "label": "Parnu",
      "value": "Parnu",
      "country_id": "Estonia"
    },
    {
      "label": "Polva",
      "value": "Polva",
      "country_id": "Estonia"
    },
    {
      "label": "Rapla",
      "value": "Rapla",
      "country_id": "Estonia"
    },
    {
      "label": "Saare",
      "value": "Saare",
      "country_id": "Estonia"
    },
    {
      "label": "Tartu",
      "value": "Tartu",
      "country_id": "Estonia"
    },
    {
      "label": "Valga",
      "value": "Valga",
      "country_id": "Estonia"
    },
    {
      "label": "Viljandi",
      "value": "Viljandi",
      "country_id": "Estonia"
    },
    {
      "label": "Voru",
      "value": "Voru",
      "country_id": "Estonia"
    },
    {
      "label": "Addis Abeba",
      "value": "Addis Abeba",
      "country_id": "Ethiopia"
    },
    {
      "label": "Afar",
      "value": "Afar",
      "country_id": "Ethiopia"
    },
    {
      "label": "Amhara",
      "value": "Amhara",
      "country_id": "Ethiopia"
    },
    {
      "label": "Benishangul",
      "value": "Benishangul",
      "country_id": "Ethiopia"
    },
    {
      "label": "Diredawa",
      "value": "Diredawa",
      "country_id": "Ethiopia"
    },
    {
      "label": "Gambella",
      "value": "Gambella",
      "country_id": "Ethiopia"
    },
    {
      "label": "Harar",
      "value": "Harar",
      "country_id": "Ethiopia"
    },
    {
      "label": "Jigjiga",
      "value": "Jigjiga",
      "country_id": "Ethiopia"
    },
    {
      "label": "Mekele",
      "value": "Mekele",
      "country_id": "Ethiopia"
    },
    {
      "label": "Oromia",
      "value": "Oromia",
      "country_id": "Ethiopia"
    },
    {
      "label": "Somali",
      "value": "Somali",
      "country_id": "Ethiopia"
    },
    {
      "label": "Southern",
      "value": "Southern",
      "country_id": "Ethiopia"
    },
    {
      "label": "Tigray",
      "value": "Tigray",
      "country_id": "Ethiopia"
    },
    {
      "label": "Christmas Island",
      "value": "Christmas Island",
      "country_id": "External Territories of Australia"
    },
    {
      "label": "Cocos Islands",
      "value": "Cocos Islands",
      "country_id": "External Territories of Australia"
    },
    {
      "label": "Coral Sea Islands",
      "value": "Coral Sea Islands",
      "country_id": "External Territories of Australia"
    },
    {
      "label": "Falkland Islands",
      "value": "Falkland Islands",
      "country_id": "Falkland Islands"
    },
    {
      "label": "South Georgia",
      "value": "South Georgia",
      "country_id": "Falkland Islands"
    },
    {
      "label": "Klaksvik",
      "value": "Klaksvik",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Nor ara Eysturoy",
      "value": "Nor ara Eysturoy",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Nor oy",
      "value": "Nor oy",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Sandoy",
      "value": "Sandoy",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Streymoy",
      "value": "Streymoy",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Su uroy",
      "value": "Su uroy",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Sy ra Eysturoy",
      "value": "Sy ra Eysturoy",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Torshavn",
      "value": "Torshavn",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Vaga",
      "value": "Vaga",
      "country_id": "Faroe Islands"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Fiji Islands"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "Fiji Islands"
    },
    {
      "label": "Northern",
      "value": "Northern",
      "country_id": "Fiji Islands"
    },
    {
      "label": "South Pacific",
      "value": "South Pacific",
      "country_id": "Fiji Islands"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Fiji Islands"
    },
    {
      "label": "Ahvenanmaa",
      "value": "Ahvenanmaa",
      "country_id": "Finland"
    },
    {
      "label": "Etela-Karjala",
      "value": "Etela-Karjala",
      "country_id": "Finland"
    },
    {
      "label": "Etela-Pohjanmaa",
      "value": "Etela-Pohjanmaa",
      "country_id": "Finland"
    },
    {
      "label": "Etela-Savo",
      "value": "Etela-Savo",
      "country_id": "Finland"
    },
    {
      "label": "Etela-Suomen Laani",
      "value": "Etela-Suomen Laani",
      "country_id": "Finland"
    },
    {
      "label": "Ita-Suomen Laani",
      "value": "Ita-Suomen Laani",
      "country_id": "Finland"
    },
    {
      "label": "Ita-Uusimaa",
      "value": "Ita-Uusimaa",
      "country_id": "Finland"
    },
    {
      "label": "Kainuu",
      "value": "Kainuu",
      "country_id": "Finland"
    },
    {
      "label": "Kanta-Hame",
      "value": "Kanta-Hame",
      "country_id": "Finland"
    },
    {
      "label": "Keski-Pohjanmaa",
      "value": "Keski-Pohjanmaa",
      "country_id": "Finland"
    },
    {
      "label": "Keski-Suomi",
      "value": "Keski-Suomi",
      "country_id": "Finland"
    },
    {
      "label": "Kymenlaakso",
      "value": "Kymenlaakso",
      "country_id": "Finland"
    },
    {
      "label": "Lansi-Suomen Laani",
      "value": "Lansi-Suomen Laani",
      "country_id": "Finland"
    },
    {
      "label": "Lappi",
      "value": "Lappi",
      "country_id": "Finland"
    },
    {
      "label": "Northern Savonia",
      "value": "Northern Savonia",
      "country_id": "Finland"
    },
    {
      "label": "Ostrobothnia",
      "value": "Ostrobothnia",
      "country_id": "Finland"
    },
    {
      "label": "Oulun Laani",
      "value": "Oulun Laani",
      "country_id": "Finland"
    },
    {
      "label": "Paijat-Hame",
      "value": "Paijat-Hame",
      "country_id": "Finland"
    },
    {
      "label": "Pirkanmaa",
      "value": "Pirkanmaa",
      "country_id": "Finland"
    },
    {
      "label": "Pohjanmaa",
      "value": "Pohjanmaa",
      "country_id": "Finland"
    },
    {
      "label": "Pohjois-Karjala",
      "value": "Pohjois-Karjala",
      "country_id": "Finland"
    },
    {
      "label": "Pohjois-Pohjanmaa",
      "value": "Pohjois-Pohjanmaa",
      "country_id": "Finland"
    },
    {
      "label": "Pohjois-Savo",
      "value": "Pohjois-Savo",
      "country_id": "Finland"
    },
    {
      "label": "Saarijarvi",
      "value": "Saarijarvi",
      "country_id": "Finland"
    },
    {
      "label": "Satakunta",
      "value": "Satakunta",
      "country_id": "Finland"
    },
    {
      "label": "Southern Savonia",
      "value": "Southern Savonia",
      "country_id": "Finland"
    },
    {
      "label": "Tavastia Proper",
      "value": "Tavastia Proper",
      "country_id": "Finland"
    },
    {
      "label": "Uleaborgs Lan",
      "value": "Uleaborgs Lan",
      "country_id": "Finland"
    },
    {
      "label": "Uusimaa",
      "value": "Uusimaa",
      "country_id": "Finland"
    },
    {
      "label": "Varsinais-Suomi",
      "value": "Varsinais-Suomi",
      "country_id": "Finland"
    },
    {
      "label": "Ain",
      "value": "Ain",
      "country_id": "France"
    },
    {
      "label": "Aisne",
      "value": "Aisne",
      "country_id": "France"
    },
    {
      "label": "Albi Le Sequestre",
      "value": "Albi Le Sequestre",
      "country_id": "France"
    },
    {
      "label": "Allier",
      "value": "Allier",
      "country_id": "France"
    },
    {
      "label": "Alpes-Cote dAzur",
      "value": "Alpes-Cote dAzur",
      "country_id": "France"
    },
    {
      "label": "Alpes-Maritimes",
      "value": "Alpes-Maritimes",
      "country_id": "France"
    },
    {
      "label": "Alpes-de-Haute-Provence",
      "value": "Alpes-de-Haute-Provence",
      "country_id": "France"
    },
    {
      "label": "Alsace",
      "value": "Alsace",
      "country_id": "France"
    },
    {
      "label": "Aquitaine",
      "value": "Aquitaine",
      "country_id": "France"
    },
    {
      "label": "Ardeche",
      "value": "Ardeche",
      "country_id": "France"
    },
    {
      "label": "Ardennes",
      "value": "Ardennes",
      "country_id": "France"
    },
    {
      "label": "Ariege",
      "value": "Ariege",
      "country_id": "France"
    },
    {
      "label": "Aube",
      "value": "Aube",
      "country_id": "France"
    },
    {
      "label": "Aude",
      "value": "Aude",
      "country_id": "France"
    },
    {
      "label": "Auvergne",
      "value": "Auvergne",
      "country_id": "France"
    },
    {
      "label": "Aveyron",
      "value": "Aveyron",
      "country_id": "France"
    },
    {
      "label": "Bas-Rhin",
      "value": "Bas-Rhin",
      "country_id": "France"
    },
    {
      "label": "Basse-Normandie",
      "value": "Basse-Normandie",
      "country_id": "France"
    },
    {
      "label": "Bouches-du-Rhone",
      "value": "Bouches-du-Rhone",
      "country_id": "France"
    },
    {
      "label": "Bourgogne",
      "value": "Bourgogne",
      "country_id": "France"
    },
    {
      "label": "Bretagne",
      "value": "Bretagne",
      "country_id": "France"
    },
    {
      "label": "Brittany",
      "value": "Brittany",
      "country_id": "France"
    },
    {
      "label": "Burgundy",
      "value": "Burgundy",
      "country_id": "France"
    },
    {
      "label": "Calvados",
      "value": "Calvados",
      "country_id": "France"
    },
    {
      "label": "Cantal",
      "value": "Cantal",
      "country_id": "France"
    },
    {
      "label": "Cedex",
      "value": "Cedex",
      "country_id": "France"
    },
    {
      "label": "Centre",
      "value": "Centre",
      "country_id": "France"
    },
    {
      "label": "Charente",
      "value": "Charente",
      "country_id": "France"
    },
    {
      "label": "Charente-Maritime",
      "value": "Charente-Maritime",
      "country_id": "France"
    },
    {
      "label": "Cher",
      "value": "Cher",
      "country_id": "France"
    },
    {
      "label": "Correze",
      "value": "Correze",
      "country_id": "France"
    },
    {
      "label": "Corse-du-Sud",
      "value": "Corse-du-Sud",
      "country_id": "France"
    },
    {
      "label": "Cote-d''Or",
      "value": "Cote-d''Or",
      "country_id": "France"
    },
    {
      "label": "Cotes-d''Armor",
      "value": "Cotes-d''Armor",
      "country_id": "France"
    },
    {
      "label": "Creuse",
      "value": "Creuse",
      "country_id": "France"
    },
    {
      "label": "Crolles",
      "value": "Crolles",
      "country_id": "France"
    },
    {
      "label": "Deux-Sevres",
      "value": "Deux-Sevres",
      "country_id": "France"
    },
    {
      "label": "Dordogne",
      "value": "Dordogne",
      "country_id": "France"
    },
    {
      "label": "Doubs",
      "value": "Doubs",
      "country_id": "France"
    },
    {
      "label": "Drome",
      "value": "Drome",
      "country_id": "France"
    },
    {
      "label": "Essonne",
      "value": "Essonne",
      "country_id": "France"
    },
    {
      "label": "Eure",
      "value": "Eure",
      "country_id": "France"
    },
    {
      "label": "Eure-et-Loir",
      "value": "Eure-et-Loir",
      "country_id": "France"
    },
    {
      "label": "Feucherolles",
      "value": "Feucherolles",
      "country_id": "France"
    },
    {
      "label": "Finistere",
      "value": "Finistere",
      "country_id": "France"
    },
    {
      "label": "Franche-Comte",
      "value": "Franche-Comte",
      "country_id": "France"
    },
    {
      "label": "Gard",
      "value": "Gard",
      "country_id": "France"
    },
    {
      "label": "Gers",
      "value": "Gers",
      "country_id": "France"
    },
    {
      "label": "Gironde",
      "value": "Gironde",
      "country_id": "France"
    },
    {
      "label": "Haut-Rhin",
      "value": "Haut-Rhin",
      "country_id": "France"
    },
    {
      "label": "Haute-Corse",
      "value": "Haute-Corse",
      "country_id": "France"
    },
    {
      "label": "Haute-Garonne",
      "value": "Haute-Garonne",
      "country_id": "France"
    },
    {
      "label": "Haute-Loire",
      "value": "Haute-Loire",
      "country_id": "France"
    },
    {
      "label": "Haute-Marne",
      "value": "Haute-Marne",
      "country_id": "France"
    },
    {
      "label": "Haute-Saone",
      "value": "Haute-Saone",
      "country_id": "France"
    },
    {
      "label": "Haute-Savoie",
      "value": "Haute-Savoie",
      "country_id": "France"
    },
    {
      "label": "Haute-Vienne",
      "value": "Haute-Vienne",
      "country_id": "France"
    },
    {
      "label": "Hautes-Alpes",
      "value": "Hautes-Alpes",
      "country_id": "France"
    },
    {
      "label": "Hautes-Pyrenees",
      "value": "Hautes-Pyrenees",
      "country_id": "France"
    },
    {
      "label": "Hauts-de-Seine",
      "value": "Hauts-de-Seine",
      "country_id": "France"
    },
    {
      "label": "Herault",
      "value": "Herault",
      "country_id": "France"
    },
    {
      "label": "Ile-de-France",
      "value": "Ile-de-France",
      "country_id": "France"
    },
    {
      "label": "Ille-et-Vilaine",
      "value": "Ille-et-Vilaine",
      "country_id": "France"
    },
    {
      "label": "Indre",
      "value": "Indre",
      "country_id": "France"
    },
    {
      "label": "Indre-et-Loire",
      "value": "Indre-et-Loire",
      "country_id": "France"
    },
    {
      "label": "Isere",
      "value": "Isere",
      "country_id": "France"
    },
    {
      "label": "Jura",
      "value": "Jura",
      "country_id": "France"
    },
    {
      "label": "Klagenfurt",
      "value": "Klagenfurt",
      "country_id": "France"
    },
    {
      "label": "Landes",
      "value": "Landes",
      "country_id": "France"
    },
    {
      "label": "Languedoc-Roussillon",
      "value": "Languedoc-Roussillon",
      "country_id": "France"
    },
    {
      "label": "Larcay",
      "value": "Larcay",
      "country_id": "France"
    },
    {
      "label": "Le Castellet",
      "value": "Le Castellet",
      "country_id": "France"
    },
    {
      "label": "Le Creusot",
      "value": "Le Creusot",
      "country_id": "France"
    },
    {
      "label": "Limousin",
      "value": "Limousin",
      "country_id": "France"
    },
    {
      "label": "Loir-et-Cher",
      "value": "Loir-et-Cher",
      "country_id": "France"
    },
    {
      "label": "Loire",
      "value": "Loire",
      "country_id": "France"
    },
    {
      "label": "Loire-Atlantique",
      "value": "Loire-Atlantique",
      "country_id": "France"
    },
    {
      "label": "Loiret",
      "value": "Loiret",
      "country_id": "France"
    },
    {
      "label": "Lorraine",
      "value": "Lorraine",
      "country_id": "France"
    },
    {
      "label": "Lot",
      "value": "Lot",
      "country_id": "France"
    },
    {
      "label": "Lot-et-Garonne",
      "value": "Lot-et-Garonne",
      "country_id": "France"
    },
    {
      "label": "Lower Normandy",
      "value": "Lower Normandy",
      "country_id": "France"
    },
    {
      "label": "Lozere",
      "value": "Lozere",
      "country_id": "France"
    },
    {
      "label": "Maine-et-Loire",
      "value": "Maine-et-Loire",
      "country_id": "France"
    },
    {
      "label": "Manche",
      "value": "Manche",
      "country_id": "France"
    },
    {
      "label": "Marne",
      "value": "Marne",
      "country_id": "France"
    },
    {
      "label": "Mayenne",
      "value": "Mayenne",
      "country_id": "France"
    },
    {
      "label": "Meurthe-et-Moselle",
      "value": "Meurthe-et-Moselle",
      "country_id": "France"
    },
    {
      "label": "Meuse",
      "value": "Meuse",
      "country_id": "France"
    },
    {
      "label": "Midi-Pyrenees",
      "value": "Midi-Pyrenees",
      "country_id": "France"
    },
    {
      "label": "Morbihan",
      "value": "Morbihan",
      "country_id": "France"
    },
    {
      "label": "Moselle",
      "value": "Moselle",
      "country_id": "France"
    },
    {
      "label": "Nievre",
      "value": "Nievre",
      "country_id": "France"
    },
    {
      "label": "Nord",
      "value": "Nord",
      "country_id": "France"
    },
    {
      "label": "Nord-Pas-de-Calais",
      "value": "Nord-Pas-de-Calais",
      "country_id": "France"
    },
    {
      "label": "Oise",
      "value": "Oise",
      "country_id": "France"
    },
    {
      "label": "Orne",
      "value": "Orne",
      "country_id": "France"
    },
    {
      "label": "Paris",
      "value": "Paris",
      "country_id": "France"
    },
    {
      "label": "Pas-de-Calais",
      "value": "Pas-de-Calais",
      "country_id": "France"
    },
    {
      "label": "Pays de la Loire",
      "value": "Pays de la Loire",
      "country_id": "France"
    },
    {
      "label": "Pays-de-la-Loire",
      "value": "Pays-de-la-Loire",
      "country_id": "France"
    },
    {
      "label": "Picardy",
      "value": "Picardy",
      "country_id": "France"
    },
    {
      "label": "Puy-de-Dome",
      "value": "Puy-de-Dome",
      "country_id": "France"
    },
    {
      "label": "Pyrenees-Atlantiques",
      "value": "Pyrenees-Atlantiques",
      "country_id": "France"
    },
    {
      "label": "Pyrenees-Orientales",
      "value": "Pyrenees-Orientales",
      "country_id": "France"
    },
    {
      "label": "Quelmes",
      "value": "Quelmes",
      "country_id": "France"
    },
    {
      "label": "Rhone",
      "value": "Rhone",
      "country_id": "France"
    },
    {
      "label": "Rhone-Alpes",
      "value": "Rhone-Alpes",
      "country_id": "France"
    },
    {
      "label": "Saint Ouen",
      "value": "Saint Ouen",
      "country_id": "France"
    },
    {
      "label": "Saint Viatre",
      "value": "Saint Viatre",
      "country_id": "France"
    },
    {
      "label": "Saone-et-Loire",
      "value": "Saone-et-Loire",
      "country_id": "France"
    },
    {
      "label": "Sarthe",
      "value": "Sarthe",
      "country_id": "France"
    },
    {
      "label": "Savoie",
      "value": "Savoie",
      "country_id": "France"
    },
    {
      "label": "Seine-Maritime",
      "value": "Seine-Maritime",
      "country_id": "France"
    },
    {
      "label": "Seine-Saint-Denis",
      "value": "Seine-Saint-Denis",
      "country_id": "France"
    },
    {
      "label": "Seine-et-Marne",
      "value": "Seine-et-Marne",
      "country_id": "France"
    },
    {
      "label": "Somme",
      "value": "Somme",
      "country_id": "France"
    },
    {
      "label": "Sophia Antipolis",
      "value": "Sophia Antipolis",
      "country_id": "France"
    },
    {
      "label": "Souvans",
      "value": "Souvans",
      "country_id": "France"
    },
    {
      "label": "Tarn",
      "value": "Tarn",
      "country_id": "France"
    },
    {
      "label": "Tarn-et-Garonne",
      "value": "Tarn-et-Garonne",
      "country_id": "France"
    },
    {
      "label": "Territoire de Belfort",
      "value": "Territoire de Belfort",
      "country_id": "France"
    },
    {
      "label": "Treignac",
      "value": "Treignac",
      "country_id": "France"
    },
    {
      "label": "Upper Normandy",
      "value": "Upper Normandy",
      "country_id": "France"
    },
    {
      "label": "Val-d''Oise",
      "value": "Val-d''Oise",
      "country_id": "France"
    },
    {
      "label": "Val-de-Marne",
      "value": "Val-de-Marne",
      "country_id": "France"
    },
    {
      "label": "Var",
      "value": "Var",
      "country_id": "France"
    },
    {
      "label": "Vaucluse",
      "value": "Vaucluse",
      "country_id": "France"
    },
    {
      "label": "Vellise",
      "value": "Vellise",
      "country_id": "France"
    },
    {
      "label": "Vendee",
      "value": "Vendee",
      "country_id": "France"
    },
    {
      "label": "Vienne",
      "value": "Vienne",
      "country_id": "France"
    },
    {
      "label": "Vosges",
      "value": "Vosges",
      "country_id": "France"
    },
    {
      "label": "Yonne",
      "value": "Yonne",
      "country_id": "France"
    },
    {
      "label": "Yvelines",
      "value": "Yvelines",
      "country_id": "France"
    },
    {
      "label": "Cayenne",
      "value": "Cayenne",
      "country_id": "French Guiana"
    },
    {
      "label": "Saint-Laurent-du-Maroni",
      "value": "Saint-Laurent-du-Maroni",
      "country_id": "French Guiana"
    },
    {
      "label": "Iles du Vent",
      "value": "Iles du Vent",
      "country_id": "French Polynesia"
    },
    {
      "label": "Iles sous le Vent",
      "value": "Iles sous le Vent",
      "country_id": "French Polynesia"
    },
    {
      "label": "Marquesas",
      "value": "Marquesas",
      "country_id": "French Polynesia"
    },
    {
      "label": "Tuamotu",
      "value": "Tuamotu",
      "country_id": "French Polynesia"
    },
    {
      "label": "Tubuai",
      "value": "Tubuai",
      "country_id": "French Polynesia"
    },
    {
      "label": "Amsterdam",
      "value": "Amsterdam",
      "country_id": "French Southern Territories"
    },
    {
      "label": "Crozet Islands",
      "value": "Crozet Islands",
      "country_id": "French Southern Territories"
    },
    {
      "label": "Kerguelen",
      "value": "Kerguelen",
      "country_id": "French Southern Territories"
    },
    {
      "label": "Estuaire",
      "value": "Estuaire",
      "country_id": "Gabon"
    },
    {
      "label": "Haut-Ogooue",
      "value": "Haut-Ogooue",
      "country_id": "Gabon"
    },
    {
      "label": "Moyen-Ogooue",
      "value": "Moyen-Ogooue",
      "country_id": "Gabon"
    },
    {
      "label": "Ngounie",
      "value": "Ngounie",
      "country_id": "Gabon"
    },
    {
      "label": "Nyanga",
      "value": "Nyanga",
      "country_id": "Gabon"
    },
    {
      "label": "Ogooue-Ivindo",
      "value": "Ogooue-Ivindo",
      "country_id": "Gabon"
    },
    {
      "label": "Ogooue-Lolo",
      "value": "Ogooue-Lolo",
      "country_id": "Gabon"
    },
    {
      "label": "Ogooue-Maritime",
      "value": "Ogooue-Maritime",
      "country_id": "Gabon"
    },
    {
      "label": "Woleu-Ntem",
      "value": "Woleu-Ntem",
      "country_id": "Gabon"
    },
    {
      "label": "Banjul",
      "value": "Banjul",
      "country_id": "Gambia The"
    },
    {
      "label": "Basse",
      "value": "Basse",
      "country_id": "Gambia The"
    },
    {
      "label": "Brikama",
      "value": "Brikama",
      "country_id": "Gambia The"
    },
    {
      "label": "Janjanbureh",
      "value": "Janjanbureh",
      "country_id": "Gambia The"
    },
    {
      "label": "Kanifing",
      "value": "Kanifing",
      "country_id": "Gambia The"
    },
    {
      "label": "Kerewan",
      "value": "Kerewan",
      "country_id": "Gambia The"
    },
    {
      "label": "Kuntaur",
      "value": "Kuntaur",
      "country_id": "Gambia The"
    },
    {
      "label": "Mansakonko",
      "value": "Mansakonko",
      "country_id": "Gambia The"
    },
    {
      "label": "Abhasia",
      "value": "Abhasia",
      "country_id": "Georgia"
    },
    {
      "label": "Ajaria",
      "value": "Ajaria",
      "country_id": "Georgia"
    },
    {
      "label": "Guria",
      "value": "Guria",
      "country_id": "Georgia"
    },
    {
      "label": "Imereti",
      "value": "Imereti",
      "country_id": "Georgia"
    },
    {
      "label": "Kaheti",
      "value": "Kaheti",
      "country_id": "Georgia"
    },
    {
      "label": "Kvemo Kartli",
      "value": "Kvemo Kartli",
      "country_id": "Georgia"
    },
    {
      "label": "Mcheta-Mtianeti",
      "value": "Mcheta-Mtianeti",
      "country_id": "Georgia"
    },
    {
      "label": "Racha",
      "value": "Racha",
      "country_id": "Georgia"
    },
    {
      "label": "Samagrelo-Zemo Svaneti",
      "value": "Samagrelo-Zemo Svaneti",
      "country_id": "Georgia"
    },
    {
      "label": "Samche-Zhavaheti",
      "value": "Samche-Zhavaheti",
      "country_id": "Georgia"
    },
    {
      "label": "Shida Kartli",
      "value": "Shida Kartli",
      "country_id": "Georgia"
    },
    {
      "label": "Tbilisi",
      "value": "Tbilisi",
      "country_id": "Georgia"
    },
    {
      "label": "Auvergne",
      "value": "Auvergne",
      "country_id": "Germany"
    },
    {
      "label": "Baden-Wurttemberg",
      "value": "Baden-Wurttemberg",
      "country_id": "Germany"
    },
    {
      "label": "Bavaria",
      "value": "Bavaria",
      "country_id": "Germany"
    },
    {
      "label": "Bayern",
      "value": "Bayern",
      "country_id": "Germany"
    },
    {
      "label": "Beilstein Wurtt",
      "value": "Beilstein Wurtt",
      "country_id": "Germany"
    },
    {
      "label": "Berlin",
      "value": "Berlin",
      "country_id": "Germany"
    },
    {
      "label": "Brandenburg",
      "value": "Brandenburg",
      "country_id": "Germany"
    },
    {
      "label": "Bremen",
      "value": "Bremen",
      "country_id": "Germany"
    },
    {
      "label": "Dreisbach",
      "value": "Dreisbach",
      "country_id": "Germany"
    },
    {
      "label": "Freistaat Bayern",
      "value": "Freistaat Bayern",
      "country_id": "Germany"
    },
    {
      "label": "Hamburg",
      "value": "Hamburg",
      "country_id": "Germany"
    },
    {
      "label": "Hannover",
      "value": "Hannover",
      "country_id": "Germany"
    },
    {
      "label": "Heroldstatt",
      "value": "Heroldstatt",
      "country_id": "Germany"
    },
    {
      "label": "Hessen",
      "value": "Hessen",
      "country_id": "Germany"
    },
    {
      "label": "Kortenberg",
      "value": "Kortenberg",
      "country_id": "Germany"
    },
    {
      "label": "Laasdorf",
      "value": "Laasdorf",
      "country_id": "Germany"
    },
    {
      "label": "Land Baden-Wurttemberg",
      "value": "Land Baden-Wurttemberg",
      "country_id": "Germany"
    },
    {
      "label": "Land Bayern",
      "value": "Land Bayern",
      "country_id": "Germany"
    },
    {
      "label": "Land Brandenburg",
      "value": "Land Brandenburg",
      "country_id": "Germany"
    },
    {
      "label": "Land Hessen",
      "value": "Land Hessen",
      "country_id": "Germany"
    },
    {
      "label": "Land Mecklenburg-Vorpommern",
      "value": "Land Mecklenburg-Vorpommern",
      "country_id": "Germany"
    },
    {
      "label": "Land Nordrhein-Westfalen",
      "value": "Land Nordrhein-Westfalen",
      "country_id": "Germany"
    },
    {
      "label": "Land Rheinland-Pfalz",
      "value": "Land Rheinland-Pfalz",
      "country_id": "Germany"
    },
    {
      "label": "Land Sachsen",
      "value": "Land Sachsen",
      "country_id": "Germany"
    },
    {
      "label": "Land Sachsen-Anhalt",
      "value": "Land Sachsen-Anhalt",
      "country_id": "Germany"
    },
    {
      "label": "Land Thuringen",
      "value": "Land Thuringen",
      "country_id": "Germany"
    },
    {
      "label": "Lower Saxony",
      "value": "Lower Saxony",
      "country_id": "Germany"
    },
    {
      "label": "Mecklenburg-Vorpommern",
      "value": "Mecklenburg-Vorpommern",
      "country_id": "Germany"
    },
    {
      "label": "Mulfingen",
      "value": "Mulfingen",
      "country_id": "Germany"
    },
    {
      "label": "Munich",
      "value": "Munich",
      "country_id": "Germany"
    },
    {
      "label": "Neubeuern",
      "value": "Neubeuern",
      "country_id": "Germany"
    },
    {
      "label": "Niedersachsen",
      "value": "Niedersachsen",
      "country_id": "Germany"
    },
    {
      "label": "Noord-Holland",
      "value": "Noord-Holland",
      "country_id": "Germany"
    },
    {
      "label": "Nordrhein-Westfalen",
      "value": "Nordrhein-Westfalen",
      "country_id": "Germany"
    },
    {
      "label": "North Rhine-Westphalia",
      "value": "North Rhine-Westphalia",
      "country_id": "Germany"
    },
    {
      "label": "Osterode",
      "value": "Osterode",
      "country_id": "Germany"
    },
    {
      "label": "Rheinland-Pfalz",
      "value": "Rheinland-Pfalz",
      "country_id": "Germany"
    },
    {
      "label": "Rhineland-Palatinate",
      "value": "Rhineland-Palatinate",
      "country_id": "Germany"
    },
    {
      "label": "Saarland",
      "value": "Saarland",
      "country_id": "Germany"
    },
    {
      "label": "Sachsen",
      "value": "Sachsen",
      "country_id": "Germany"
    },
    {
      "label": "Sachsen-Anhalt",
      "value": "Sachsen-Anhalt",
      "country_id": "Germany"
    },
    {
      "label": "Saxony",
      "value": "Saxony",
      "country_id": "Germany"
    },
    {
      "label": "Schleswig-Holstein",
      "value": "Schleswig-Holstein",
      "country_id": "Germany"
    },
    {
      "label": "Thuringia",
      "value": "Thuringia",
      "country_id": "Germany"
    },
    {
      "label": "Webling",
      "value": "Webling",
      "country_id": "Germany"
    },
    {
      "label": "Weinstrabe",
      "value": "Weinstrabe",
      "country_id": "Germany"
    },
    {
      "label": "schlobborn",
      "value": "schlobborn",
      "country_id": "Germany"
    },
    {
      "label": "Ashanti",
      "value": "Ashanti",
      "country_id": "Ghana"
    },
    {
      "label": "Brong-Ahafo",
      "value": "Brong-Ahafo",
      "country_id": "Ghana"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Ghana"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "Ghana"
    },
    {
      "label": "Greater Accra",
      "value": "Greater Accra",
      "country_id": "Ghana"
    },
    {
      "label": "Northern",
      "value": "Northern",
      "country_id": "Ghana"
    },
    {
      "label": "Upper East",
      "value": "Upper East",
      "country_id": "Ghana"
    },
    {
      "label": "Upper West",
      "value": "Upper West",
      "country_id": "Ghana"
    },
    {
      "label": "Volta",
      "value": "Volta",
      "country_id": "Ghana"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Ghana"
    },
    {
      "label": "Gibraltar",
      "value": "Gibraltar",
      "country_id": "Gibraltar"
    },
    {
      "label": "Acharnes",
      "value": "Acharnes",
      "country_id": "Greece"
    },
    {
      "label": "Ahaia",
      "value": "Ahaia",
      "country_id": "Greece"
    },
    {
      "label": "Aitolia kai Akarnania",
      "value": "Aitolia kai Akarnania",
      "country_id": "Greece"
    },
    {
      "label": "Argolis",
      "value": "Argolis",
      "country_id": "Greece"
    },
    {
      "label": "Arkadia",
      "value": "Arkadia",
      "country_id": "Greece"
    },
    {
      "label": "Arta",
      "value": "Arta",
      "country_id": "Greece"
    },
    {
      "label": "Attica",
      "value": "Attica",
      "country_id": "Greece"
    },
    {
      "label": "Attiki",
      "value": "Attiki",
      "country_id": "Greece"
    },
    {
      "label": "Ayion Oros",
      "value": "Ayion Oros",
      "country_id": "Greece"
    },
    {
      "label": "Crete",
      "value": "Crete",
      "country_id": "Greece"
    },
    {
      "label": "Dodekanisos",
      "value": "Dodekanisos",
      "country_id": "Greece"
    },
    {
      "label": "Drama",
      "value": "Drama",
      "country_id": "Greece"
    },
    {
      "label": "Evia",
      "value": "Evia",
      "country_id": "Greece"
    },
    {
      "label": "Evritania",
      "value": "Evritania",
      "country_id": "Greece"
    },
    {
      "label": "Evros",
      "value": "Evros",
      "country_id": "Greece"
    },
    {
      "label": "Evvoia",
      "value": "Evvoia",
      "country_id": "Greece"
    },
    {
      "label": "Florina",
      "value": "Florina",
      "country_id": "Greece"
    },
    {
      "label": "Fokis",
      "value": "Fokis",
      "country_id": "Greece"
    },
    {
      "label": "Fthiotis",
      "value": "Fthiotis",
      "country_id": "Greece"
    },
    {
      "label": "Grevena",
      "value": "Grevena",
      "country_id": "Greece"
    },
    {
      "label": "Halandri",
      "value": "Halandri",
      "country_id": "Greece"
    },
    {
      "label": "Halkidiki",
      "value": "Halkidiki",
      "country_id": "Greece"
    },
    {
      "label": "Hania",
      "value": "Hania",
      "country_id": "Greece"
    },
    {
      "label": "Heraklion",
      "value": "Heraklion",
      "country_id": "Greece"
    },
    {
      "label": "Hios",
      "value": "Hios",
      "country_id": "Greece"
    },
    {
      "label": "Ilia",
      "value": "Ilia",
      "country_id": "Greece"
    },
    {
      "label": "Imathia",
      "value": "Imathia",
      "country_id": "Greece"
    },
    {
      "label": "Ioannina",
      "value": "Ioannina",
      "country_id": "Greece"
    },
    {
      "label": "Iraklion",
      "value": "Iraklion",
      "country_id": "Greece"
    },
    {
      "label": "Karditsa",
      "value": "Karditsa",
      "country_id": "Greece"
    },
    {
      "label": "Kastoria",
      "value": "Kastoria",
      "country_id": "Greece"
    },
    {
      "label": "Kavala",
      "value": "Kavala",
      "country_id": "Greece"
    },
    {
      "label": "Kefallinia",
      "value": "Kefallinia",
      "country_id": "Greece"
    },
    {
      "label": "Kerkira",
      "value": "Kerkira",
      "country_id": "Greece"
    },
    {
      "label": "Kiklades",
      "value": "Kiklades",
      "country_id": "Greece"
    },
    {
      "label": "Kilkis",
      "value": "Kilkis",
      "country_id": "Greece"
    },
    {
      "label": "Korinthia",
      "value": "Korinthia",
      "country_id": "Greece"
    },
    {
      "label": "Kozani",
      "value": "Kozani",
      "country_id": "Greece"
    },
    {
      "label": "Lakonia",
      "value": "Lakonia",
      "country_id": "Greece"
    },
    {
      "label": "Larisa",
      "value": "Larisa",
      "country_id": "Greece"
    },
    {
      "label": "Lasithi",
      "value": "Lasithi",
      "country_id": "Greece"
    },
    {
      "label": "Lesvos",
      "value": "Lesvos",
      "country_id": "Greece"
    },
    {
      "label": "Levkas",
      "value": "Levkas",
      "country_id": "Greece"
    },
    {
      "label": "Magnisia",
      "value": "Magnisia",
      "country_id": "Greece"
    },
    {
      "label": "Messinia",
      "value": "Messinia",
      "country_id": "Greece"
    },
    {
      "label": "Nomos Attikis",
      "value": "Nomos Attikis",
      "country_id": "Greece"
    },
    {
      "label": "Nomos Zakynthou",
      "value": "Nomos Zakynthou",
      "country_id": "Greece"
    },
    {
      "label": "Pella",
      "value": "Pella",
      "country_id": "Greece"
    },
    {
      "label": "Pieria",
      "value": "Pieria",
      "country_id": "Greece"
    },
    {
      "label": "Piraios",
      "value": "Piraios",
      "country_id": "Greece"
    },
    {
      "label": "Preveza",
      "value": "Preveza",
      "country_id": "Greece"
    },
    {
      "label": "Rethimni",
      "value": "Rethimni",
      "country_id": "Greece"
    },
    {
      "label": "Rodopi",
      "value": "Rodopi",
      "country_id": "Greece"
    },
    {
      "label": "Samos",
      "value": "Samos",
      "country_id": "Greece"
    },
    {
      "label": "Serrai",
      "value": "Serrai",
      "country_id": "Greece"
    },
    {
      "label": "Thesprotia",
      "value": "Thesprotia",
      "country_id": "Greece"
    },
    {
      "label": "Thessaloniki",
      "value": "Thessaloniki",
      "country_id": "Greece"
    },
    {
      "label": "Trikala",
      "value": "Trikala",
      "country_id": "Greece"
    },
    {
      "label": "Voiotia",
      "value": "Voiotia",
      "country_id": "Greece"
    },
    {
      "label": "West Greece",
      "value": "West Greece",
      "country_id": "Greece"
    },
    {
      "label": "Xanthi",
      "value": "Xanthi",
      "country_id": "Greece"
    },
    {
      "label": "Zakinthos",
      "value": "Zakinthos",
      "country_id": "Greece"
    },
    {
      "label": "Aasiaat",
      "value": "Aasiaat",
      "country_id": "Greenland"
    },
    {
      "label": "Ammassalik",
      "value": "Ammassalik",
      "country_id": "Greenland"
    },
    {
      "label": "Illoqqortoormiut",
      "value": "Illoqqortoormiut",
      "country_id": "Greenland"
    },
    {
      "label": "Ilulissat",
      "value": "Ilulissat",
      "country_id": "Greenland"
    },
    {
      "label": "Ivittuut",
      "value": "Ivittuut",
      "country_id": "Greenland"
    },
    {
      "label": "Kangaatsiaq",
      "value": "Kangaatsiaq",
      "country_id": "Greenland"
    },
    {
      "label": "Maniitsoq",
      "value": "Maniitsoq",
      "country_id": "Greenland"
    },
    {
      "label": "Nanortalik",
      "value": "Nanortalik",
      "country_id": "Greenland"
    },
    {
      "label": "Narsaq",
      "value": "Narsaq",
      "country_id": "Greenland"
    },
    {
      "label": "Nuuk",
      "value": "Nuuk",
      "country_id": "Greenland"
    },
    {
      "label": "Paamiut",
      "value": "Paamiut",
      "country_id": "Greenland"
    },
    {
      "label": "Qaanaaq",
      "value": "Qaanaaq",
      "country_id": "Greenland"
    },
    {
      "label": "Qaqortoq",
      "value": "Qaqortoq",
      "country_id": "Greenland"
    },
    {
      "label": "Qasigiannguit",
      "value": "Qasigiannguit",
      "country_id": "Greenland"
    },
    {
      "label": "Qeqertarsuaq",
      "value": "Qeqertarsuaq",
      "country_id": "Greenland"
    },
    {
      "label": "Sisimiut",
      "value": "Sisimiut",
      "country_id": "Greenland"
    },
    {
      "label": "Udenfor kommunal inddeling",
      "value": "Udenfor kommunal inddeling",
      "country_id": "Greenland"
    },
    {
      "label": "Upernavik",
      "value": "Upernavik",
      "country_id": "Greenland"
    },
    {
      "label": "Uummannaq",
      "value": "Uummannaq",
      "country_id": "Greenland"
    },
    {
      "label": "Carriacou-Petite Martinique",
      "value": "Carriacou-Petite Martinique",
      "country_id": "Grenada"
    },
    {
      "label": "Saint Andrew",
      "value": "Saint Andrew",
      "country_id": "Grenada"
    },
    {
      "label": "Saint Davids",
      "value": "Saint Davids",
      "country_id": "Grenada"
    },
    {
      "label": "Saint George''s",
      "value": "Saint George''s",
      "country_id": "Grenada"
    },
    {
      "label": "Saint John",
      "value": "Saint John",
      "country_id": "Grenada"
    },
    {
      "label": "Saint Mark",
      "value": "Saint Mark",
      "country_id": "Grenada"
    },
    {
      "label": "Saint Patrick",
      "value": "Saint Patrick",
      "country_id": "Grenada"
    },
    {
      "label": "Basse-Terre",
      "value": "Basse-Terre",
      "country_id": "Guadeloupe"
    },
    {
      "label": "Grande-Terre",
      "value": "Grande-Terre",
      "country_id": "Guadeloupe"
    },
    {
      "label": "Iles des Saintes",
      "value": "Iles des Saintes",
      "country_id": "Guadeloupe"
    },
    {
      "label": "La Desirade",
      "value": "La Desirade",
      "country_id": "Guadeloupe"
    },
    {
      "label": "Marie-Galante",
      "value": "Marie-Galante",
      "country_id": "Guadeloupe"
    },
    {
      "label": "Saint Barthelemy",
      "value": "Saint Barthelemy",
      "country_id": "Guadeloupe"
    },
    {
      "label": "Saint Martin",
      "value": "Saint Martin",
      "country_id": "Guadeloupe"
    },
    {
      "label": "Agana Heights",
      "value": "Agana Heights",
      "country_id": "Guam"
    },
    {
      "label": "Agat",
      "value": "Agat",
      "country_id": "Guam"
    },
    {
      "label": "Barrigada",
      "value": "Barrigada",
      "country_id": "Guam"
    },
    {
      "label": "Chalan-Pago-Ordot",
      "value": "Chalan-Pago-Ordot",
      "country_id": "Guam"
    },
    {
      "label": "Dededo",
      "value": "Dededo",
      "country_id": "Guam"
    },
    {
      "label": "Hagatna",
      "value": "Hagatna",
      "country_id": "Guam"
    },
    {
      "label": "Inarajan",
      "value": "Inarajan",
      "country_id": "Guam"
    },
    {
      "label": "Mangilao",
      "value": "Mangilao",
      "country_id": "Guam"
    },
    {
      "label": "Merizo",
      "value": "Merizo",
      "country_id": "Guam"
    },
    {
      "label": "Mongmong-Toto-Maite",
      "value": "Mongmong-Toto-Maite",
      "country_id": "Guam"
    },
    {
      "label": "Santa Rita",
      "value": "Santa Rita",
      "country_id": "Guam"
    },
    {
      "label": "Sinajana",
      "value": "Sinajana",
      "country_id": "Guam"
    },
    {
      "label": "Talofofo",
      "value": "Talofofo",
      "country_id": "Guam"
    },
    {
      "label": "Tamuning",
      "value": "Tamuning",
      "country_id": "Guam"
    },
    {
      "label": "Yigo",
      "value": "Yigo",
      "country_id": "Guam"
    },
    {
      "label": "Yona",
      "value": "Yona",
      "country_id": "Guam"
    },
    {
      "label": "Alta Verapaz",
      "value": "Alta Verapaz",
      "country_id": "Guatemala"
    },
    {
      "label": "Baja Verapaz",
      "value": "Baja Verapaz",
      "country_id": "Guatemala"
    },
    {
      "label": "Chimaltenango",
      "value": "Chimaltenango",
      "country_id": "Guatemala"
    },
    {
      "label": "Chiquimula",
      "value": "Chiquimula",
      "country_id": "Guatemala"
    },
    {
      "label": "El Progreso",
      "value": "El Progreso",
      "country_id": "Guatemala"
    },
    {
      "label": "Escuintla",
      "value": "Escuintla",
      "country_id": "Guatemala"
    },
    {
      "label": "Guatemala",
      "value": "Guatemala",
      "country_id": "Guatemala"
    },
    {
      "label": "Huehuetenango",
      "value": "Huehuetenango",
      "country_id": "Guatemala"
    },
    {
      "label": "Izabal",
      "value": "Izabal",
      "country_id": "Guatemala"
    },
    {
      "label": "Jalapa",
      "value": "Jalapa",
      "country_id": "Guatemala"
    },
    {
      "label": "Jutiapa",
      "value": "Jutiapa",
      "country_id": "Guatemala"
    },
    {
      "label": "Peten",
      "value": "Peten",
      "country_id": "Guatemala"
    },
    {
      "label": "Quezaltenango",
      "value": "Quezaltenango",
      "country_id": "Guatemala"
    },
    {
      "label": "Quiche",
      "value": "Quiche",
      "country_id": "Guatemala"
    },
    {
      "label": "Retalhuleu",
      "value": "Retalhuleu",
      "country_id": "Guatemala"
    },
    {
      "label": "Sacatepequez",
      "value": "Sacatepequez",
      "country_id": "Guatemala"
    },
    {
      "label": "San Marcos",
      "value": "San Marcos",
      "country_id": "Guatemala"
    },
    {
      "label": "Santa Rosa",
      "value": "Santa Rosa",
      "country_id": "Guatemala"
    },
    {
      "label": "Solola",
      "value": "Solola",
      "country_id": "Guatemala"
    },
    {
      "label": "Suchitepequez",
      "value": "Suchitepequez",
      "country_id": "Guatemala"
    },
    {
      "label": "Totonicapan",
      "value": "Totonicapan",
      "country_id": "Guatemala"
    },
    {
      "label": "Zacapa",
      "value": "Zacapa",
      "country_id": "Guatemala"
    },
    {
      "label": "Alderney",
      "value": "Alderney",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Castel",
      "value": "Castel",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Forest",
      "value": "Forest",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Saint Andrew",
      "value": "Saint Andrew",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Saint Martin",
      "value": "Saint Martin",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Saint Peter Port",
      "value": "Saint Peter Port",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Saint Pierre du Bois",
      "value": "Saint Pierre du Bois",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Saint Sampson",
      "value": "Saint Sampson",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Saint Saviour",
      "value": "Saint Saviour",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Sark",
      "value": "Sark",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Torteval",
      "value": "Torteval",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Vale",
      "value": "Vale",
      "country_id": "Guernsey and Alderney"
    },
    {
      "label": "Beyla",
      "value": "Beyla",
      "country_id": "Guinea"
    },
    {
      "label": "Boffa",
      "value": "Boffa",
      "country_id": "Guinea"
    },
    {
      "label": "Boke",
      "value": "Boke",
      "country_id": "Guinea"
    },
    {
      "label": "Conakry",
      "value": "Conakry",
      "country_id": "Guinea"
    },
    {
      "label": "Coyah",
      "value": "Coyah",
      "country_id": "Guinea"
    },
    {
      "label": "Dabola",
      "value": "Dabola",
      "country_id": "Guinea"
    },
    {
      "label": "Dalaba",
      "value": "Dalaba",
      "country_id": "Guinea"
    },
    {
      "label": "Dinguiraye",
      "value": "Dinguiraye",
      "country_id": "Guinea"
    },
    {
      "label": "Faranah",
      "value": "Faranah",
      "country_id": "Guinea"
    },
    {
      "label": "Forecariah",
      "value": "Forecariah",
      "country_id": "Guinea"
    },
    {
      "label": "Fria",
      "value": "Fria",
      "country_id": "Guinea"
    },
    {
      "label": "Gaoual",
      "value": "Gaoual",
      "country_id": "Guinea"
    },
    {
      "label": "Gueckedou",
      "value": "Gueckedou",
      "country_id": "Guinea"
    },
    {
      "label": "Kankan",
      "value": "Kankan",
      "country_id": "Guinea"
    },
    {
      "label": "Kerouane",
      "value": "Kerouane",
      "country_id": "Guinea"
    },
    {
      "label": "Kindia",
      "value": "Kindia",
      "country_id": "Guinea"
    },
    {
      "label": "Kissidougou",
      "value": "Kissidougou",
      "country_id": "Guinea"
    },
    {
      "label": "Koubia",
      "value": "Koubia",
      "country_id": "Guinea"
    },
    {
      "label": "Koundara",
      "value": "Koundara",
      "country_id": "Guinea"
    },
    {
      "label": "Kouroussa",
      "value": "Kouroussa",
      "country_id": "Guinea"
    },
    {
      "label": "Labe",
      "value": "Labe",
      "country_id": "Guinea"
    },
    {
      "label": "Lola",
      "value": "Lola",
      "country_id": "Guinea"
    },
    {
      "label": "Macenta",
      "value": "Macenta",
      "country_id": "Guinea"
    },
    {
      "label": "Mali",
      "value": "Mali",
      "country_id": "Guinea"
    },
    {
      "label": "Mamou",
      "value": "Mamou",
      "country_id": "Guinea"
    },
    {
      "label": "Mandiana",
      "value": "Mandiana",
      "country_id": "Guinea"
    },
    {
      "label": "Nzerekore",
      "value": "Nzerekore",
      "country_id": "Guinea"
    },
    {
      "label": "Pita",
      "value": "Pita",
      "country_id": "Guinea"
    },
    {
      "label": "Siguiri",
      "value": "Siguiri",
      "country_id": "Guinea"
    },
    {
      "label": "Telimele",
      "value": "Telimele",
      "country_id": "Guinea"
    },
    {
      "label": "Tougue",
      "value": "Tougue",
      "country_id": "Guinea"
    },
    {
      "label": "Yomou",
      "value": "Yomou",
      "country_id": "Guinea"
    },
    {
      "label": "Bafata",
      "value": "Bafata",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Bissau",
      "value": "Bissau",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Bolama",
      "value": "Bolama",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Cacheu",
      "value": "Cacheu",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Gabu",
      "value": "Gabu",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Oio",
      "value": "Oio",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Quinara",
      "value": "Quinara",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Tombali",
      "value": "Tombali",
      "country_id": "Guinea-Bissau"
    },
    {
      "label": "Barima-Waini",
      "value": "Barima-Waini",
      "country_id": "Guyana"
    },
    {
      "label": "Cuyuni-Mazaruni",
      "value": "Cuyuni-Mazaruni",
      "country_id": "Guyana"
    },
    {
      "label": "Demerara-Mahaica",
      "value": "Demerara-Mahaica",
      "country_id": "Guyana"
    },
    {
      "label": "East Berbice-Corentyne",
      "value": "East Berbice-Corentyne",
      "country_id": "Guyana"
    },
    {
      "label": "Essequibo Islands-West Demerar",
      "value": "Essequibo Islands-West Demerar",
      "country_id": "Guyana"
    },
    {
      "label": "Mahaica-Berbice",
      "value": "Mahaica-Berbice",
      "country_id": "Guyana"
    },
    {
      "label": "Pomeroon-Supenaam",
      "value": "Pomeroon-Supenaam",
      "country_id": "Guyana"
    },
    {
      "label": "Potaro-Siparuni",
      "value": "Potaro-Siparuni",
      "country_id": "Guyana"
    },
    {
      "label": "Upper Demerara-Berbice",
      "value": "Upper Demerara-Berbice",
      "country_id": "Guyana"
    },
    {
      "label": "Upper Takutu-Upper Essequibo",
      "value": "Upper Takutu-Upper Essequibo",
      "country_id": "Guyana"
    },
    {
      "label": "Artibonite",
      "value": "Artibonite",
      "country_id": "Haiti"
    },
    {
      "label": "Centre",
      "value": "Centre",
      "country_id": "Haiti"
    },
    {
      "label": "Grand''Anse",
      "value": "Grand''Anse",
      "country_id": "Haiti"
    },
    {
      "label": "Nord",
      "value": "Nord",
      "country_id": "Haiti"
    },
    {
      "label": "Nord-Est",
      "value": "Nord-Est",
      "country_id": "Haiti"
    },
    {
      "label": "Nord-Ouest",
      "value": "Nord-Ouest",
      "country_id": "Haiti"
    },
    {
      "label": "Ouest",
      "value": "Ouest",
      "country_id": "Haiti"
    },
    {
      "label": "Sud",
      "value": "Sud",
      "country_id": "Haiti"
    },
    {
      "label": "Sud-Est",
      "value": "Sud-Est",
      "country_id": "Haiti"
    },
    {
      "label": "Heard and McDonald Islands",
      "value": "Heard and McDonald Islands",
      "country_id": "Heard and McDonald Islands"
    },
    {
      "label": "Atlantida",
      "value": "Atlantida",
      "country_id": "Honduras"
    },
    {
      "label": "Choluteca",
      "value": "Choluteca",
      "country_id": "Honduras"
    },
    {
      "label": "Colon",
      "value": "Colon",
      "country_id": "Honduras"
    },
    {
      "label": "Comayagua",
      "value": "Comayagua",
      "country_id": "Honduras"
    },
    {
      "label": "Copan",
      "value": "Copan",
      "country_id": "Honduras"
    },
    {
      "label": "Cortes",
      "value": "Cortes",
      "country_id": "Honduras"
    },
    {
      "label": "Distrito Central",
      "value": "Distrito Central",
      "country_id": "Honduras"
    },
    {
      "label": "El Paraiso",
      "value": "El Paraiso",
      "country_id": "Honduras"
    },
    {
      "label": "Francisco Morazan",
      "value": "Francisco Morazan",
      "country_id": "Honduras"
    },
    {
      "label": "Gracias a Dios",
      "value": "Gracias a Dios",
      "country_id": "Honduras"
    },
    {
      "label": "Intibuca",
      "value": "Intibuca",
      "country_id": "Honduras"
    },
    {
      "label": "Islas de la Bahia",
      "value": "Islas de la Bahia",
      "country_id": "Honduras"
    },
    {
      "label": "La Paz",
      "value": "La Paz",
      "country_id": "Honduras"
    },
    {
      "label": "Lempira",
      "value": "Lempira",
      "country_id": "Honduras"
    },
    {
      "label": "Ocotepeque",
      "value": "Ocotepeque",
      "country_id": "Honduras"
    },
    {
      "label": "Olancho",
      "value": "Olancho",
      "country_id": "Honduras"
    },
    {
      "label": "Santa Barbara",
      "value": "Santa Barbara",
      "country_id": "Honduras"
    },
    {
      "label": "Valle",
      "value": "Valle",
      "country_id": "Honduras"
    },
    {
      "label": "Yoro",
      "value": "Yoro",
      "country_id": "Honduras"
    },
    {
      "label": "Hong Kong",
      "value": "Hong Kong",
      "country_id": "Hong Kong S.A.R."
    },
    {
      "label": "Bacs-Kiskun",
      "value": "Bacs-Kiskun",
      "country_id": "Hungary"
    },
    {
      "label": "Baranya",
      "value": "Baranya",
      "country_id": "Hungary"
    },
    {
      "label": "Bekes",
      "value": "Bekes",
      "country_id": "Hungary"
    },
    {
      "label": "Borsod-Abauj-Zemplen",
      "value": "Borsod-Abauj-Zemplen",
      "country_id": "Hungary"
    },
    {
      "label": "Budapest",
      "value": "Budapest",
      "country_id": "Hungary"
    },
    {
      "label": "Csongrad",
      "value": "Csongrad",
      "country_id": "Hungary"
    },
    {
      "label": "Fejer",
      "value": "Fejer",
      "country_id": "Hungary"
    },
    {
      "label": "Gyor-Moson-Sopron",
      "value": "Gyor-Moson-Sopron",
      "country_id": "Hungary"
    },
    {
      "label": "Hajdu-Bihar",
      "value": "Hajdu-Bihar",
      "country_id": "Hungary"
    },
    {
      "label": "Heves",
      "value": "Heves",
      "country_id": "Hungary"
    },
    {
      "label": "Jasz-Nagykun-Szolnok",
      "value": "Jasz-Nagykun-Szolnok",
      "country_id": "Hungary"
    },
    {
      "label": "Komarom-Esztergom",
      "value": "Komarom-Esztergom",
      "country_id": "Hungary"
    },
    {
      "label": "Nograd",
      "value": "Nograd",
      "country_id": "Hungary"
    },
    {
      "label": "Pest",
      "value": "Pest",
      "country_id": "Hungary"
    },
    {
      "label": "Somogy",
      "value": "Somogy",
      "country_id": "Hungary"
    },
    {
      "label": "Szabolcs-Szatmar-Bereg",
      "value": "Szabolcs-Szatmar-Bereg",
      "country_id": "Hungary"
    },
    {
      "label": "Tolna",
      "value": "Tolna",
      "country_id": "Hungary"
    },
    {
      "label": "Vas",
      "value": "Vas",
      "country_id": "Hungary"
    },
    {
      "label": "Veszprem",
      "value": "Veszprem",
      "country_id": "Hungary"
    },
    {
      "label": "Zala",
      "value": "Zala",
      "country_id": "Hungary"
    },
    {
      "label": "Austurland",
      "value": "Austurland",
      "country_id": "Iceland"
    },
    {
      "label": "Gullbringusysla",
      "value": "Gullbringusysla",
      "country_id": "Iceland"
    },
    {
      "label": "Hofu borgarsva i",
      "value": "Hofu borgarsva i",
      "country_id": "Iceland"
    },
    {
      "label": "Nor urland eystra",
      "value": "Nor urland eystra",
      "country_id": "Iceland"
    },
    {
      "label": "Nor urland vestra",
      "value": "Nor urland vestra",
      "country_id": "Iceland"
    },
    {
      "label": "Su urland",
      "value": "Su urland",
      "country_id": "Iceland"
    },
    {
      "label": "Su urnes",
      "value": "Su urnes",
      "country_id": "Iceland"
    },
    {
      "label": "Vestfir ir",
      "value": "Vestfir ir",
      "country_id": "Iceland"
    },
    {
      "label": "Vesturland",
      "value": "Vesturland",
      "country_id": "Iceland"
    },
    {
      "label": "Aceh",
      "value": "Aceh",
      "country_id": "Indonesia"
    },
    {
      "label": "Bali",
      "value": "Bali",
      "country_id": "Indonesia"
    },
    {
      "label": "Bangka-Belitung",
      "value": "Bangka-Belitung",
      "country_id": "Indonesia"
    },
    {
      "label": "Banten",
      "value": "Banten",
      "country_id": "Indonesia"
    },
    {
      "label": "Bengkulu",
      "value": "Bengkulu",
      "country_id": "Indonesia"
    },
    {
      "label": "Gandaria",
      "value": "Gandaria",
      "country_id": "Indonesia"
    },
    {
      "label": "Gorontalo",
      "value": "Gorontalo",
      "country_id": "Indonesia"
    },
    {
      "label": "Jakarta",
      "value": "Jakarta",
      "country_id": "Indonesia"
    },
    {
      "label": "Jambi",
      "value": "Jambi",
      "country_id": "Indonesia"
    },
    {
      "label": "Jawa Barat",
      "value": "Jawa Barat",
      "country_id": "Indonesia"
    },
    {
      "label": "Jawa Tengah",
      "value": "Jawa Tengah",
      "country_id": "Indonesia"
    },
    {
      "label": "Jawa Timur",
      "value": "Jawa Timur",
      "country_id": "Indonesia"
    },
    {
      "label": "Kalimantan Barat",
      "value": "Kalimantan Barat",
      "country_id": "Indonesia"
    },
    {
      "label": "Kalimantan Selatan",
      "value": "Kalimantan Selatan",
      "country_id": "Indonesia"
    },
    {
      "label": "Kalimantan Tengah",
      "value": "Kalimantan Tengah",
      "country_id": "Indonesia"
    },
    {
      "label": "Kalimantan Timur",
      "value": "Kalimantan Timur",
      "country_id": "Indonesia"
    },
    {
      "label": "Kendal",
      "value": "Kendal",
      "country_id": "Indonesia"
    },
    {
      "label": "Lampung",
      "value": "Lampung",
      "country_id": "Indonesia"
    },
    {
      "label": "Maluku",
      "value": "Maluku",
      "country_id": "Indonesia"
    },
    {
      "label": "Maluku Utara",
      "value": "Maluku Utara",
      "country_id": "Indonesia"
    },
    {
      "label": "Nusa Tenggara Barat",
      "value": "Nusa Tenggara Barat",
      "country_id": "Indonesia"
    },
    {
      "label": "Nusa Tenggara Timur",
      "value": "Nusa Tenggara Timur",
      "country_id": "Indonesia"
    },
    {
      "label": "Papua",
      "value": "Papua",
      "country_id": "Indonesia"
    },
    {
      "label": "Riau",
      "value": "Riau",
      "country_id": "Indonesia"
    },
    {
      "label": "Riau Kepulauan",
      "value": "Riau Kepulauan",
      "country_id": "Indonesia"
    },
    {
      "label": "Solo",
      "value": "Solo",
      "country_id": "Indonesia"
    },
    {
      "label": "Sulawesi Selatan",
      "value": "Sulawesi Selatan",
      "country_id": "Indonesia"
    },
    {
      "label": "Sulawesi Tengah",
      "value": "Sulawesi Tengah",
      "country_id": "Indonesia"
    },
    {
      "label": "Sulawesi Tenggara",
      "value": "Sulawesi Tenggara",
      "country_id": "Indonesia"
    },
    {
      "label": "Sulawesi Utara",
      "value": "Sulawesi Utara",
      "country_id": "Indonesia"
    },
    {
      "label": "Sumatera Barat",
      "value": "Sumatera Barat",
      "country_id": "Indonesia"
    },
    {
      "label": "Sumatera Selatan",
      "value": "Sumatera Selatan",
      "country_id": "Indonesia"
    },
    {
      "label": "Sumatera Utara",
      "value": "Sumatera Utara",
      "country_id": "Indonesia"
    },
    {
      "label": "Yogyakarta",
      "value": "Yogyakarta",
      "country_id": "Indonesia"
    },
    {
      "label": "Ardabil",
      "value": "Ardabil",
      "country_id": "Iran"
    },
    {
      "label": "Azarbayjan-e Bakhtari",
      "value": "Azarbayjan-e Bakhtari",
      "country_id": "Iran"
    },
    {
      "label": "Azarbayjan-e Khavari",
      "value": "Azarbayjan-e Khavari",
      "country_id": "Iran"
    },
    {
      "label": "Bushehr",
      "value": "Bushehr",
      "country_id": "Iran"
    },
    {
      "label": "Chahar Mahal-e Bakhtiari",
      "value": "Chahar Mahal-e Bakhtiari",
      "country_id": "Iran"
    },
    {
      "label": "Esfahan",
      "value": "Esfahan",
      "country_id": "Iran"
    },
    {
      "label": "Fars",
      "value": "Fars",
      "country_id": "Iran"
    },
    {
      "label": "Gilan",
      "value": "Gilan",
      "country_id": "Iran"
    },
    {
      "label": "Golestan",
      "value": "Golestan",
      "country_id": "Iran"
    },
    {
      "label": "Hamadan",
      "value": "Hamadan",
      "country_id": "Iran"
    },
    {
      "label": "Hormozgan",
      "value": "Hormozgan",
      "country_id": "Iran"
    },
    {
      "label": "Ilam",
      "value": "Ilam",
      "country_id": "Iran"
    },
    {
      "label": "Kerman",
      "value": "Kerman",
      "country_id": "Iran"
    },
    {
      "label": "Kermanshah",
      "value": "Kermanshah",
      "country_id": "Iran"
    },
    {
      "label": "Khorasan",
      "value": "Khorasan",
      "country_id": "Iran"
    },
    {
      "label": "Khuzestan",
      "value": "Khuzestan",
      "country_id": "Iran"
    },
    {
      "label": "Kohgiluyeh-e Boyerahmad",
      "value": "Kohgiluyeh-e Boyerahmad",
      "country_id": "Iran"
    },
    {
      "label": "Kordestan",
      "value": "Kordestan",
      "country_id": "Iran"
    },
    {
      "label": "Lorestan",
      "value": "Lorestan",
      "country_id": "Iran"
    },
    {
      "label": "Markazi",
      "value": "Markazi",
      "country_id": "Iran"
    },
    {
      "label": "Mazandaran",
      "value": "Mazandaran",
      "country_id": "Iran"
    },
    {
      "label": "Ostan-e Esfahan",
      "value": "Ostan-e Esfahan",
      "country_id": "Iran"
    },
    {
      "label": "Qazvin",
      "value": "Qazvin",
      "country_id": "Iran"
    },
    {
      "label": "Qom",
      "value": "Qom",
      "country_id": "Iran"
    },
    {
      "label": "Semnan",
      "value": "Semnan",
      "country_id": "Iran"
    },
    {
      "label": "Sistan-e Baluchestan",
      "value": "Sistan-e Baluchestan",
      "country_id": "Iran"
    },
    {
      "label": "Tehran",
      "value": "Tehran",
      "country_id": "Iran"
    },
    {
      "label": "Yazd",
      "value": "Yazd",
      "country_id": "Iran"
    },
    {
      "label": "Zanjan",
      "value": "Zanjan",
      "country_id": "Iran"
    },
    {
      "label": "Babil",
      "value": "Babil",
      "country_id": "Iraq"
    },
    {
      "label": "Baghdad",
      "value": "Baghdad",
      "country_id": "Iraq"
    },
    {
      "label": "Dahuk",
      "value": "Dahuk",
      "country_id": "Iraq"
    },
    {
      "label": "Dhi Qar",
      "value": "Dhi Qar",
      "country_id": "Iraq"
    },
    {
      "label": "Diyala",
      "value": "Diyala",
      "country_id": "Iraq"
    },
    {
      "label": "Erbil",
      "value": "Erbil",
      "country_id": "Iraq"
    },
    {
      "label": "Irbil",
      "value": "Irbil",
      "country_id": "Iraq"
    },
    {
      "label": "Karbala",
      "value": "Karbala",
      "country_id": "Iraq"
    },
    {
      "label": "Kurdistan",
      "value": "Kurdistan",
      "country_id": "Iraq"
    },
    {
      "label": "Maysan",
      "value": "Maysan",
      "country_id": "Iraq"
    },
    {
      "label": "Ninawa",
      "value": "Ninawa",
      "country_id": "Iraq"
    },
    {
      "label": "Salah-ad-Din",
      "value": "Salah-ad-Din",
      "country_id": "Iraq"
    },
    {
      "label": "Wasit",
      "value": "Wasit",
      "country_id": "Iraq"
    },
    {
      "label": "al-Anbar",
      "value": "al-Anbar",
      "country_id": "Iraq"
    },
    {
      "label": "al-Basrah",
      "value": "al-Basrah",
      "country_id": "Iraq"
    },
    {
      "label": "al-Muthanna",
      "value": "al-Muthanna",
      "country_id": "Iraq"
    },
    {
      "label": "al-Qadisiyah",
      "value": "al-Qadisiyah",
      "country_id": "Iraq"
    },
    {
      "label": "an-Najaf",
      "value": "an-Najaf",
      "country_id": "Iraq"
    },
    {
      "label": "as-Sulaymaniyah",
      "value": "as-Sulaymaniyah",
      "country_id": "Iraq"
    },
    {
      "label": "at-Ta''mim",
      "value": "at-Ta''mim",
      "country_id": "Iraq"
    },
    {
      "label": "Armagh",
      "value": "Armagh",
      "country_id": "Ireland"
    },
    {
      "label": "Carlow",
      "value": "Carlow",
      "country_id": "Ireland"
    },
    {
      "label": "Cavan",
      "value": "Cavan",
      "country_id": "Ireland"
    },
    {
      "label": "Clare",
      "value": "Clare",
      "country_id": "Ireland"
    },
    {
      "label": "Cork",
      "value": "Cork",
      "country_id": "Ireland"
    },
    {
      "label": "Donegal",
      "value": "Donegal",
      "country_id": "Ireland"
    },
    {
      "label": "Dublin",
      "value": "Dublin",
      "country_id": "Ireland"
    },
    {
      "label": "Galway",
      "value": "Galway",
      "country_id": "Ireland"
    },
    {
      "label": "Kerry",
      "value": "Kerry",
      "country_id": "Ireland"
    },
    {
      "label": "Kildare",
      "value": "Kildare",
      "country_id": "Ireland"
    },
    {
      "label": "Kilkenny",
      "value": "Kilkenny",
      "country_id": "Ireland"
    },
    {
      "label": "Laois",
      "value": "Laois",
      "country_id": "Ireland"
    },
    {
      "label": "Leinster",
      "value": "Leinster",
      "country_id": "Ireland"
    },
    {
      "label": "Leitrim",
      "value": "Leitrim",
      "country_id": "Ireland"
    },
    {
      "label": "Limerick",
      "value": "Limerick",
      "country_id": "Ireland"
    },
    {
      "label": "Loch Garman",
      "value": "Loch Garman",
      "country_id": "Ireland"
    },
    {
      "label": "Longford",
      "value": "Longford",
      "country_id": "Ireland"
    },
    {
      "label": "Louth",
      "value": "Louth",
      "country_id": "Ireland"
    },
    {
      "label": "Mayo",
      "value": "Mayo",
      "country_id": "Ireland"
    },
    {
      "label": "Meath",
      "value": "Meath",
      "country_id": "Ireland"
    },
    {
      "label": "Monaghan",
      "value": "Monaghan",
      "country_id": "Ireland"
    },
    {
      "label": "Offaly",
      "value": "Offaly",
      "country_id": "Ireland"
    },
    {
      "label": "Roscommon",
      "value": "Roscommon",
      "country_id": "Ireland"
    },
    {
      "label": "Sligo",
      "value": "Sligo",
      "country_id": "Ireland"
    },
    {
      "label": "Tipperary North Riding",
      "value": "Tipperary North Riding",
      "country_id": "Ireland"
    },
    {
      "label": "Tipperary South Riding",
      "value": "Tipperary South Riding",
      "country_id": "Ireland"
    },
    {
      "label": "Ulster",
      "value": "Ulster",
      "country_id": "Ireland"
    },
    {
      "label": "Waterford",
      "value": "Waterford",
      "country_id": "Ireland"
    },
    {
      "label": "Westmeath",
      "value": "Westmeath",
      "country_id": "Ireland"
    },
    {
      "label": "Wexford",
      "value": "Wexford",
      "country_id": "Ireland"
    },
    {
      "label": "Wicklow",
      "value": "Wicklow",
      "country_id": "Ireland"
    },
    {
      "label": "Beit Hanania",
      "value": "Beit Hanania",
      "country_id": "Israel"
    },
    {
      "label": "Ben Gurion Airport",
      "value": "Ben Gurion Airport",
      "country_id": "Israel"
    },
    {
      "label": "Bethlehem",
      "value": "Bethlehem",
      "country_id": "Israel"
    },
    {
      "label": "Caesarea",
      "value": "Caesarea",
      "country_id": "Israel"
    },
    {
      "label": "Centre",
      "value": "Centre",
      "country_id": "Israel"
    },
    {
      "label": "Gaza",
      "value": "Gaza",
      "country_id": "Israel"
    },
    {
      "label": "Hadaron",
      "value": "Hadaron",
      "country_id": "Israel"
    },
    {
      "label": "Haifa District",
      "value": "Haifa District",
      "country_id": "Israel"
    },
    {
      "label": "Hamerkaz",
      "value": "Hamerkaz",
      "country_id": "Israel"
    },
    {
      "label": "Hazafon",
      "value": "Hazafon",
      "country_id": "Israel"
    },
    {
      "label": "Hebron",
      "value": "Hebron",
      "country_id": "Israel"
    },
    {
      "label": "Jaffa",
      "value": "Jaffa",
      "country_id": "Israel"
    },
    {
      "label": "Jerusalem",
      "value": "Jerusalem",
      "country_id": "Israel"
    },
    {
      "label": "Khefa",
      "value": "Khefa",
      "country_id": "Israel"
    },
    {
      "label": "Kiryat Yam",
      "value": "Kiryat Yam",
      "country_id": "Israel"
    },
    {
      "label": "Lower Galilee",
      "value": "Lower Galilee",
      "country_id": "Israel"
    },
    {
      "label": "Qalqilya",
      "value": "Qalqilya",
      "country_id": "Israel"
    },
    {
      "label": "Talme Elazar",
      "value": "Talme Elazar",
      "country_id": "Israel"
    },
    {
      "label": "Tel Aviv",
      "value": "Tel Aviv",
      "country_id": "Israel"
    },
    {
      "label": "Tsafon",
      "value": "Tsafon",
      "country_id": "Israel"
    },
    {
      "label": "Umm El Fahem",
      "value": "Umm El Fahem",
      "country_id": "Israel"
    },
    {
      "label": "Yerushalayim",
      "value": "Yerushalayim",
      "country_id": "Israel"
    },
    {
      "label": "Abruzzi",
      "value": "Abruzzi",
      "country_id": "Italy"
    },
    {
      "label": "Abruzzo",
      "value": "Abruzzo",
      "country_id": "Italy"
    },
    {
      "label": "Agrigento",
      "value": "Agrigento",
      "country_id": "Italy"
    },
    {
      "label": "Alessandria",
      "value": "Alessandria",
      "country_id": "Italy"
    },
    {
      "label": "Ancona",
      "value": "Ancona",
      "country_id": "Italy"
    },
    {
      "label": "Arezzo",
      "value": "Arezzo",
      "country_id": "Italy"
    },
    {
      "label": "Ascoli Piceno",
      "value": "Ascoli Piceno",
      "country_id": "Italy"
    },
    {
      "label": "Asti",
      "value": "Asti",
      "country_id": "Italy"
    },
    {
      "label": "Avellino",
      "value": "Avellino",
      "country_id": "Italy"
    },
    {
      "label": "Bari",
      "value": "Bari",
      "country_id": "Italy"
    },
    {
      "label": "Basilicata",
      "value": "Basilicata",
      "country_id": "Italy"
    },
    {
      "label": "Belluno",
      "value": "Belluno",
      "country_id": "Italy"
    },
    {
      "label": "Benevento",
      "value": "Benevento",
      "country_id": "Italy"
    },
    {
      "label": "Bergamo",
      "value": "Bergamo",
      "country_id": "Italy"
    },
    {
      "label": "Biella",
      "value": "Biella",
      "country_id": "Italy"
    },
    {
      "label": "Bologna",
      "value": "Bologna",
      "country_id": "Italy"
    },
    {
      "label": "Bolzano",
      "value": "Bolzano",
      "country_id": "Italy"
    },
    {
      "label": "Brescia",
      "value": "Brescia",
      "country_id": "Italy"
    },
    {
      "label": "Brindisi",
      "value": "Brindisi",
      "country_id": "Italy"
    },
    {
      "label": "Calabria",
      "value": "Calabria",
      "country_id": "Italy"
    },
    {
      "label": "Campania",
      "value": "Campania",
      "country_id": "Italy"
    },
    {
      "label": "Cartoceto",
      "value": "Cartoceto",
      "country_id": "Italy"
    },
    {
      "label": "Caserta",
      "value": "Caserta",
      "country_id": "Italy"
    },
    {
      "label": "Catania",
      "value": "Catania",
      "country_id": "Italy"
    },
    {
      "label": "Chieti",
      "value": "Chieti",
      "country_id": "Italy"
    },
    {
      "label": "Como",
      "value": "Como",
      "country_id": "Italy"
    },
    {
      "label": "Cosenza",
      "value": "Cosenza",
      "country_id": "Italy"
    },
    {
      "label": "Cremona",
      "value": "Cremona",
      "country_id": "Italy"
    },
    {
      "label": "Cuneo",
      "value": "Cuneo",
      "country_id": "Italy"
    },
    {
      "label": "Emilia-Romagna",
      "value": "Emilia-Romagna",
      "country_id": "Italy"
    },
    {
      "label": "Ferrara",
      "value": "Ferrara",
      "country_id": "Italy"
    },
    {
      "label": "Firenze",
      "value": "Firenze",
      "country_id": "Italy"
    },
    {
      "label": "Florence",
      "value": "Florence",
      "country_id": "Italy"
    },
    {
      "label": "Forli-Cesena",
      "value": "Forli-Cesena",
      "country_id": "Italy"
    },
    {
      "label": "Friuli-Venezia Giulia",
      "value": "Friuli-Venezia Giulia",
      "country_id": "Italy"
    },
    {
      "label": "Frosinone",
      "value": "Frosinone",
      "country_id": "Italy"
    },
    {
      "label": "Genoa",
      "value": "Genoa",
      "country_id": "Italy"
    },
    {
      "label": "Gorizia",
      "value": "Gorizia",
      "country_id": "Italy"
    },
    {
      "label": "L''Aquila",
      "value": "L''Aquila",
      "country_id": "Italy"
    },
    {
      "label": "Lazio",
      "value": "Lazio",
      "country_id": "Italy"
    },
    {
      "label": "Lecce",
      "value": "Lecce",
      "country_id": "Italy"
    },
    {
      "label": "Lecco",
      "value": "Lecco",
      "country_id": "Italy"
    },
    {
      "label": "Lecco Province",
      "value": "Lecco Province",
      "country_id": "Italy"
    },
    {
      "label": "Liguria",
      "value": "Liguria",
      "country_id": "Italy"
    },
    {
      "label": "Lodi",
      "value": "Lodi",
      "country_id": "Italy"
    },
    {
      "label": "Lombardia",
      "value": "Lombardia",
      "country_id": "Italy"
    },
    {
      "label": "Lombardy",
      "value": "Lombardy",
      "country_id": "Italy"
    },
    {
      "label": "Macerata",
      "value": "Macerata",
      "country_id": "Italy"
    },
    {
      "label": "Mantova",
      "value": "Mantova",
      "country_id": "Italy"
    },
    {
      "label": "Marche",
      "value": "Marche",
      "country_id": "Italy"
    },
    {
      "label": "Messina",
      "value": "Messina",
      "country_id": "Italy"
    },
    {
      "label": "Milan",
      "value": "Milan",
      "country_id": "Italy"
    },
    {
      "label": "Modena",
      "value": "Modena",
      "country_id": "Italy"
    },
    {
      "label": "Molise",
      "value": "Molise",
      "country_id": "Italy"
    },
    {
      "label": "Molteno",
      "value": "Molteno",
      "country_id": "Italy"
    },
    {
      "label": "Montenegro",
      "value": "Montenegro",
      "country_id": "Italy"
    },
    {
      "label": "Monza and Brianza",
      "value": "Monza and Brianza",
      "country_id": "Italy"
    },
    {
      "label": "Naples",
      "value": "Naples",
      "country_id": "Italy"
    },
    {
      "label": "Novara",
      "value": "Novara",
      "country_id": "Italy"
    },
    {
      "label": "Padova",
      "value": "Padova",
      "country_id": "Italy"
    },
    {
      "label": "Parma",
      "value": "Parma",
      "country_id": "Italy"
    },
    {
      "label": "Pavia",
      "value": "Pavia",
      "country_id": "Italy"
    },
    {
      "label": "Perugia",
      "value": "Perugia",
      "country_id": "Italy"
    },
    {
      "label": "Pesaro-Urbino",
      "value": "Pesaro-Urbino",
      "country_id": "Italy"
    },
    {
      "label": "Piacenza",
      "value": "Piacenza",
      "country_id": "Italy"
    },
    {
      "label": "Piedmont",
      "value": "Piedmont",
      "country_id": "Italy"
    },
    {
      "label": "Piemonte",
      "value": "Piemonte",
      "country_id": "Italy"
    },
    {
      "label": "Pisa",
      "value": "Pisa",
      "country_id": "Italy"
    },
    {
      "label": "Pordenone",
      "value": "Pordenone",
      "country_id": "Italy"
    },
    {
      "label": "Potenza",
      "value": "Potenza",
      "country_id": "Italy"
    },
    {
      "label": "Puglia",
      "value": "Puglia",
      "country_id": "Italy"
    },
    {
      "label": "Reggio Emilia",
      "value": "Reggio Emilia",
      "country_id": "Italy"
    },
    {
      "label": "Rimini",
      "value": "Rimini",
      "country_id": "Italy"
    },
    {
      "label": "Roma",
      "value": "Roma",
      "country_id": "Italy"
    },
    {
      "label": "Salerno",
      "value": "Salerno",
      "country_id": "Italy"
    },
    {
      "label": "Sardegna",
      "value": "Sardegna",
      "country_id": "Italy"
    },
    {
      "label": "Sassari",
      "value": "Sassari",
      "country_id": "Italy"
    },
    {
      "label": "Savona",
      "value": "Savona",
      "country_id": "Italy"
    },
    {
      "label": "Sicilia",
      "value": "Sicilia",
      "country_id": "Italy"
    },
    {
      "label": "Siena",
      "value": "Siena",
      "country_id": "Italy"
    },
    {
      "label": "Sondrio",
      "value": "Sondrio",
      "country_id": "Italy"
    },
    {
      "label": "South Tyrol",
      "value": "South Tyrol",
      "country_id": "Italy"
    },
    {
      "label": "Taranto",
      "value": "Taranto",
      "country_id": "Italy"
    },
    {
      "label": "Teramo",
      "value": "Teramo",
      "country_id": "Italy"
    },
    {
      "label": "Torino",
      "value": "Torino",
      "country_id": "Italy"
    },
    {
      "label": "Toscana",
      "value": "Toscana",
      "country_id": "Italy"
    },
    {
      "label": "Trapani",
      "value": "Trapani",
      "country_id": "Italy"
    },
    {
      "label": "Trentino-Alto Adige",
      "value": "Trentino-Alto Adige",
      "country_id": "Italy"
    },
    {
      "label": "Trento",
      "value": "Trento",
      "country_id": "Italy"
    },
    {
      "label": "Treviso",
      "value": "Treviso",
      "country_id": "Italy"
    },
    {
      "label": "Udine",
      "value": "Udine",
      "country_id": "Italy"
    },
    {
      "label": "Umbria",
      "value": "Umbria",
      "country_id": "Italy"
    },
    {
      "label": "Valle d''Aosta",
      "value": "Valle d''Aosta",
      "country_id": "Italy"
    },
    {
      "label": "Varese",
      "value": "Varese",
      "country_id": "Italy"
    },
    {
      "label": "Veneto",
      "value": "Veneto",
      "country_id": "Italy"
    },
    {
      "label": "Venezia",
      "value": "Venezia",
      "country_id": "Italy"
    },
    {
      "label": "Verbano-Cusio-Ossola",
      "value": "Verbano-Cusio-Ossola",
      "country_id": "Italy"
    },
    {
      "label": "Vercelli",
      "value": "Vercelli",
      "country_id": "Italy"
    },
    {
      "label": "Verona",
      "value": "Verona",
      "country_id": "Italy"
    },
    {
      "label": "Vicenza",
      "value": "Vicenza",
      "country_id": "Italy"
    },
    {
      "label": "Viterbo",
      "value": "Viterbo",
      "country_id": "Italy"
    },
    {
      "label": "Buxoro Viloyati",
      "value": "Buxoro Viloyati",
      "country_id": "Jamaica"
    },
    {
      "label": "Clarendon",
      "value": "Clarendon",
      "country_id": "Jamaica"
    },
    {
      "label": "Hanover",
      "value": "Hanover",
      "country_id": "Jamaica"
    },
    {
      "label": "Kingston",
      "value": "Kingston",
      "country_id": "Jamaica"
    },
    {
      "label": "Manchester",
      "value": "Manchester",
      "country_id": "Jamaica"
    },
    {
      "label": "Portland",
      "value": "Portland",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint Andrews",
      "value": "Saint Andrews",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint Ann",
      "value": "Saint Ann",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint Catherine",
      "value": "Saint Catherine",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint Elizabeth",
      "value": "Saint Elizabeth",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint James",
      "value": "Saint James",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint Mary",
      "value": "Saint Mary",
      "country_id": "Jamaica"
    },
    {
      "label": "Saint Thomas",
      "value": "Saint Thomas",
      "country_id": "Jamaica"
    },
    {
      "label": "Trelawney",
      "value": "Trelawney",
      "country_id": "Jamaica"
    },
    {
      "label": "Westmoreland",
      "value": "Westmoreland",
      "country_id": "Jamaica"
    },
    {
      "label": "Aichi",
      "value": "Aichi",
      "country_id": "Japan"
    },
    {
      "label": "Akita",
      "value": "Akita",
      "country_id": "Japan"
    },
    {
      "label": "Aomori",
      "value": "Aomori",
      "country_id": "Japan"
    },
    {
      "label": "Chiba",
      "value": "Chiba",
      "country_id": "Japan"
    },
    {
      "label": "Ehime",
      "value": "Ehime",
      "country_id": "Japan"
    },
    {
      "label": "Fukui",
      "value": "Fukui",
      "country_id": "Japan"
    },
    {
      "label": "Fukuoka",
      "value": "Fukuoka",
      "country_id": "Japan"
    },
    {
      "label": "Fukushima",
      "value": "Fukushima",
      "country_id": "Japan"
    },
    {
      "label": "Gifu",
      "value": "Gifu",
      "country_id": "Japan"
    },
    {
      "label": "Gumma",
      "value": "Gumma",
      "country_id": "Japan"
    },
    {
      "label": "Hiroshima",
      "value": "Hiroshima",
      "country_id": "Japan"
    },
    {
      "label": "Hokkaido",
      "value": "Hokkaido",
      "country_id": "Japan"
    },
    {
      "label": "Hyogo",
      "value": "Hyogo",
      "country_id": "Japan"
    },
    {
      "label": "Ibaraki",
      "value": "Ibaraki",
      "country_id": "Japan"
    },
    {
      "label": "Ishikawa",
      "value": "Ishikawa",
      "country_id": "Japan"
    },
    {
      "label": "Iwate",
      "value": "Iwate",
      "country_id": "Japan"
    },
    {
      "label": "Kagawa",
      "value": "Kagawa",
      "country_id": "Japan"
    },
    {
      "label": "Kagoshima",
      "value": "Kagoshima",
      "country_id": "Japan"
    },
    {
      "label": "Kanagawa",
      "value": "Kanagawa",
      "country_id": "Japan"
    },
    {
      "label": "Kanto",
      "value": "Kanto",
      "country_id": "Japan"
    },
    {
      "label": "Kochi",
      "value": "Kochi",
      "country_id": "Japan"
    },
    {
      "label": "Kumamoto",
      "value": "Kumamoto",
      "country_id": "Japan"
    },
    {
      "label": "Kyoto",
      "value": "Kyoto",
      "country_id": "Japan"
    },
    {
      "label": "Mie",
      "value": "Mie",
      "country_id": "Japan"
    },
    {
      "label": "Miyagi",
      "value": "Miyagi",
      "country_id": "Japan"
    },
    {
      "label": "Miyazaki",
      "value": "Miyazaki",
      "country_id": "Japan"
    },
    {
      "label": "Nagano",
      "value": "Nagano",
      "country_id": "Japan"
    },
    {
      "label": "Nagasaki",
      "value": "Nagasaki",
      "country_id": "Japan"
    },
    {
      "label": "Nara",
      "value": "Nara",
      "country_id": "Japan"
    },
    {
      "label": "Niigata",
      "value": "Niigata",
      "country_id": "Japan"
    },
    {
      "label": "Oita",
      "value": "Oita",
      "country_id": "Japan"
    },
    {
      "label": "Okayama",
      "value": "Okayama",
      "country_id": "Japan"
    },
    {
      "label": "Okinawa",
      "value": "Okinawa",
      "country_id": "Japan"
    },
    {
      "label": "Osaka",
      "value": "Osaka",
      "country_id": "Japan"
    },
    {
      "label": "Saga",
      "value": "Saga",
      "country_id": "Japan"
    },
    {
      "label": "Saitama",
      "value": "Saitama",
      "country_id": "Japan"
    },
    {
      "label": "Shiga",
      "value": "Shiga",
      "country_id": "Japan"
    },
    {
      "label": "Shimane",
      "value": "Shimane",
      "country_id": "Japan"
    },
    {
      "label": "Shizuoka",
      "value": "Shizuoka",
      "country_id": "Japan"
    },
    {
      "label": "Tochigi",
      "value": "Tochigi",
      "country_id": "Japan"
    },
    {
      "label": "Tokushima",
      "value": "Tokushima",
      "country_id": "Japan"
    },
    {
      "label": "Tokyo",
      "value": "Tokyo",
      "country_id": "Japan"
    },
    {
      "label": "Tottori",
      "value": "Tottori",
      "country_id": "Japan"
    },
    {
      "label": "Toyama",
      "value": "Toyama",
      "country_id": "Japan"
    },
    {
      "label": "Wakayama",
      "value": "Wakayama",
      "country_id": "Japan"
    },
    {
      "label": "Yamagata",
      "value": "Yamagata",
      "country_id": "Japan"
    },
    {
      "label": "Yamaguchi",
      "value": "Yamaguchi",
      "country_id": "Japan"
    },
    {
      "label": "Yamanashi",
      "value": "Yamanashi",
      "country_id": "Japan"
    },
    {
      "label": "Grouville",
      "value": "Grouville",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Brelade",
      "value": "Saint Brelade",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Clement",
      "value": "Saint Clement",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Helier",
      "value": "Saint Helier",
      "country_id": "Jersey"
    },
    {
      "label": "Saint John",
      "value": "Saint John",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Lawrence",
      "value": "Saint Lawrence",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Martin",
      "value": "Saint Martin",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Mary",
      "value": "Saint Mary",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Peter",
      "value": "Saint Peter",
      "country_id": "Jersey"
    },
    {
      "label": "Saint Saviour",
      "value": "Saint Saviour",
      "country_id": "Jersey"
    },
    {
      "label": "Trinity",
      "value": "Trinity",
      "country_id": "Jersey"
    },
    {
      "label": "Ajlun",
      "value": "Ajlun",
      "country_id": "Jordan"
    },
    {
      "label": "Amman",
      "value": "Amman",
      "country_id": "Jordan"
    },
    {
      "label": "Irbid",
      "value": "Irbid",
      "country_id": "Jordan"
    },
    {
      "label": "Jarash",
      "value": "Jarash",
      "country_id": "Jordan"
    },
    {
      "label": "Ma''an",
      "value": "Ma''an",
      "country_id": "Jordan"
    },
    {
      "label": "Madaba",
      "value": "Madaba",
      "country_id": "Jordan"
    },
    {
      "label": "al-''Aqabah",
      "value": "al-''Aqabah",
      "country_id": "Jordan"
    },
    {
      "label": "al-Balqa",
      "value": "al-Balqa",
      "country_id": "Jordan"
    },
    {
      "label": "al-Karak",
      "value": "al-Karak",
      "country_id": "Jordan"
    },
    {
      "label": "al-Mafraq",
      "value": "al-Mafraq",
      "country_id": "Jordan"
    },
    {
      "label": "at-Tafilah",
      "value": "at-Tafilah",
      "country_id": "Jordan"
    },
    {
      "label": "az-Zarqa",
      "value": "az-Zarqa",
      "country_id": "Jordan"
    },
    {
      "label": "Akmecet",
      "value": "Akmecet",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Akmola",
      "value": "Akmola",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Aktobe",
      "value": "Aktobe",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Almati",
      "value": "Almati",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Atirau",
      "value": "Atirau",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Batis Kazakstan",
      "value": "Batis Kazakstan",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Burlinsky Region",
      "value": "Burlinsky Region",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Karagandi",
      "value": "Karagandi",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Kostanay",
      "value": "Kostanay",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Mankistau",
      "value": "Mankistau",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Ontustik Kazakstan",
      "value": "Ontustik Kazakstan",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Pavlodar",
      "value": "Pavlodar",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Sigis Kazakstan",
      "value": "Sigis Kazakstan",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Soltustik Kazakstan",
      "value": "Soltustik Kazakstan",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Taraz",
      "value": "Taraz",
      "country_id": "Kazakhstan"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Kenya"
    },
    {
      "label": "Coast",
      "value": "Coast",
      "country_id": "Kenya"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "Kenya"
    },
    {
      "label": "Nairobi",
      "value": "Nairobi",
      "country_id": "Kenya"
    },
    {
      "label": "North Eastern",
      "value": "North Eastern",
      "country_id": "Kenya"
    },
    {
      "label": "Nyanza",
      "value": "Nyanza",
      "country_id": "Kenya"
    },
    {
      "label": "Rift Valley",
      "value": "Rift Valley",
      "country_id": "Kenya"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Kenya"
    },
    {
      "label": "Abaiang",
      "value": "Abaiang",
      "country_id": "Kiribati"
    },
    {
      "label": "Abemana",
      "value": "Abemana",
      "country_id": "Kiribati"
    },
    {
      "label": "Aranuka",
      "value": "Aranuka",
      "country_id": "Kiribati"
    },
    {
      "label": "Arorae",
      "value": "Arorae",
      "country_id": "Kiribati"
    },
    {
      "label": "Banaba",
      "value": "Banaba",
      "country_id": "Kiribati"
    },
    {
      "label": "Beru",
      "value": "Beru",
      "country_id": "Kiribati"
    },
    {
      "label": "Butaritari",
      "value": "Butaritari",
      "country_id": "Kiribati"
    },
    {
      "label": "Kiritimati",
      "value": "Kiritimati",
      "country_id": "Kiribati"
    },
    {
      "label": "Kuria",
      "value": "Kuria",
      "country_id": "Kiribati"
    },
    {
      "label": "Maiana",
      "value": "Maiana",
      "country_id": "Kiribati"
    },
    {
      "label": "Makin",
      "value": "Makin",
      "country_id": "Kiribati"
    },
    {
      "label": "Marakei",
      "value": "Marakei",
      "country_id": "Kiribati"
    },
    {
      "label": "Nikunau",
      "value": "Nikunau",
      "country_id": "Kiribati"
    },
    {
      "label": "Nonouti",
      "value": "Nonouti",
      "country_id": "Kiribati"
    },
    {
      "label": "Onotoa",
      "value": "Onotoa",
      "country_id": "Kiribati"
    },
    {
      "label": "Phoenix Islands",
      "value": "Phoenix Islands",
      "country_id": "Kiribati"
    },
    {
      "label": "Tabiteuea North",
      "value": "Tabiteuea North",
      "country_id": "Kiribati"
    },
    {
      "label": "Tabiteuea South",
      "value": "Tabiteuea South",
      "country_id": "Kiribati"
    },
    {
      "label": "Tabuaeran",
      "value": "Tabuaeran",
      "country_id": "Kiribati"
    },
    {
      "label": "Tamana",
      "value": "Tamana",
      "country_id": "Kiribati"
    },
    {
      "label": "Tarawa North",
      "value": "Tarawa North",
      "country_id": "Kiribati"
    },
    {
      "label": "Tarawa South",
      "value": "Tarawa South",
      "country_id": "Kiribati"
    },
    {
      "label": "Teraina",
      "value": "Teraina",
      "country_id": "Kiribati"
    },
    {
      "label": "Chagangdo",
      "value": "Chagangdo",
      "country_id": "Korea North"
    },
    {
      "label": "Hamgyeongbukto",
      "value": "Hamgyeongbukto",
      "country_id": "Korea North"
    },
    {
      "label": "Hamgyeongnamdo",
      "value": "Hamgyeongnamdo",
      "country_id": "Korea North"
    },
    {
      "label": "Hwanghaebukto",
      "value": "Hwanghaebukto",
      "country_id": "Korea North"
    },
    {
      "label": "Hwanghaenamdo",
      "value": "Hwanghaenamdo",
      "country_id": "Korea North"
    },
    {
      "label": "Kaeseong",
      "value": "Kaeseong",
      "country_id": "Korea North"
    },
    {
      "label": "Kangweon",
      "value": "Kangweon",
      "country_id": "Korea North"
    },
    {
      "label": "Nampo",
      "value": "Nampo",
      "country_id": "Korea North"
    },
    {
      "label": "Pyeonganbukto",
      "value": "Pyeonganbukto",
      "country_id": "Korea North"
    },
    {
      "label": "Pyeongannamdo",
      "value": "Pyeongannamdo",
      "country_id": "Korea North"
    },
    {
      "label": "Pyeongyang",
      "value": "Pyeongyang",
      "country_id": "Korea North"
    },
    {
      "label": "Yanggang",
      "value": "Yanggang",
      "country_id": "Korea North"
    },
    {
      "label": "Busan",
      "value": "Busan",
      "country_id": "Korea South"
    },
    {
      "label": "Cheju",
      "value": "Cheju",
      "country_id": "Korea South"
    },
    {
      "label": "Chollabuk",
      "value": "Chollabuk",
      "country_id": "Korea South"
    },
    {
      "label": "Chollanam",
      "value": "Chollanam",
      "country_id": "Korea South"
    },
    {
      "label": "Chungbuk",
      "value": "Chungbuk",
      "country_id": "Korea South"
    },
    {
      "label": "Chungcheongbuk",
      "value": "Chungcheongbuk",
      "country_id": "Korea South"
    },
    {
      "label": "Chungcheongnam",
      "value": "Chungcheongnam",
      "country_id": "Korea South"
    },
    {
      "label": "Chungnam",
      "value": "Chungnam",
      "country_id": "Korea South"
    },
    {
      "label": "Daegu",
      "value": "Daegu",
      "country_id": "Korea South"
    },
    {
      "label": "Gangwon-do",
      "value": "Gangwon-do",
      "country_id": "Korea South"
    },
    {
      "label": "Goyang-si",
      "value": "Goyang-si",
      "country_id": "Korea South"
    },
    {
      "label": "Gyeonggi-do",
      "value": "Gyeonggi-do",
      "country_id": "Korea South"
    },
    {
      "label": "Gyeongsang",
      "value": "Gyeongsang",
      "country_id": "Korea South"
    },
    {
      "label": "Gyeongsangnam-do",
      "value": "Gyeongsangnam-do",
      "country_id": "Korea South"
    },
    {
      "label": "Incheon",
      "value": "Incheon",
      "country_id": "Korea South"
    },
    {
      "label": "Jeju-Si",
      "value": "Jeju-Si",
      "country_id": "Korea South"
    },
    {
      "label": "Jeonbuk",
      "value": "Jeonbuk",
      "country_id": "Korea South"
    },
    {
      "label": "Kangweon",
      "value": "Kangweon",
      "country_id": "Korea South"
    },
    {
      "label": "Kwangju",
      "value": "Kwangju",
      "country_id": "Korea South"
    },
    {
      "label": "Kyeonggi",
      "value": "Kyeonggi",
      "country_id": "Korea South"
    },
    {
      "label": "Kyeongsangbuk",
      "value": "Kyeongsangbuk",
      "country_id": "Korea South"
    },
    {
      "label": "Kyeongsangnam",
      "value": "Kyeongsangnam",
      "country_id": "Korea South"
    },
    {
      "label": "Kyonggi-do",
      "value": "Kyonggi-do",
      "country_id": "Korea South"
    },
    {
      "label": "Kyungbuk-Do",
      "value": "Kyungbuk-Do",
      "country_id": "Korea South"
    },
    {
      "label": "Kyunggi-Do",
      "value": "Kyunggi-Do",
      "country_id": "Korea South"
    },
    {
      "label": "Kyunggi-do",
      "value": "Kyunggi-do",
      "country_id": "Korea South"
    },
    {
      "label": "Pusan",
      "value": "Pusan",
      "country_id": "Korea South"
    },
    {
      "label": "Seoul",
      "value": "Seoul",
      "country_id": "Korea South"
    },
    {
      "label": "Sudogwon",
      "value": "Sudogwon",
      "country_id": "Korea South"
    },
    {
      "label": "Taegu",
      "value": "Taegu",
      "country_id": "Korea South"
    },
    {
      "label": "Taejeon",
      "value": "Taejeon",
      "country_id": "Korea South"
    },
    {
      "label": "Taejon-gwangyoksi",
      "value": "Taejon-gwangyoksi",
      "country_id": "Korea South"
    },
    {
      "label": "Ulsan",
      "value": "Ulsan",
      "country_id": "Korea South"
    },
    {
      "label": "Wonju",
      "value": "Wonju",
      "country_id": "Korea South"
    },
    {
      "label": "gwangyoksi",
      "value": "gwangyoksi",
      "country_id": "Korea South"
    },
    {
      "label": "Al Asimah",
      "value": "Al Asimah",
      "country_id": "Kuwait"
    },
    {
      "label": "Hawalli",
      "value": "Hawalli",
      "country_id": "Kuwait"
    },
    {
      "label": "Mishref",
      "value": "Mishref",
      "country_id": "Kuwait"
    },
    {
      "label": "Qadesiya",
      "value": "Qadesiya",
      "country_id": "Kuwait"
    },
    {
      "label": "Safat",
      "value": "Safat",
      "country_id": "Kuwait"
    },
    {
      "label": "Salmiya",
      "value": "Salmiya",
      "country_id": "Kuwait"
    },
    {
      "label": "al-Ahmadi",
      "value": "al-Ahmadi",
      "country_id": "Kuwait"
    },
    {
      "label": "al-Farwaniyah",
      "value": "al-Farwaniyah",
      "country_id": "Kuwait"
    },
    {
      "label": "al-Jahra",
      "value": "al-Jahra",
      "country_id": "Kuwait"
    },
    {
      "label": "al-Kuwayt",
      "value": "al-Kuwayt",
      "country_id": "Kuwait"
    },
    {
      "label": "Batken",
      "value": "Batken",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Bishkek",
      "value": "Bishkek",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Chui",
      "value": "Chui",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Issyk-Kul",
      "value": "Issyk-Kul",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Jalal-Abad",
      "value": "Jalal-Abad",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Naryn",
      "value": "Naryn",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Osh",
      "value": "Osh",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Talas",
      "value": "Talas",
      "country_id": "Kyrgyzstan"
    },
    {
      "label": "Attopu",
      "value": "Attopu",
      "country_id": "Laos"
    },
    {
      "label": "Bokeo",
      "value": "Bokeo",
      "country_id": "Laos"
    },
    {
      "label": "Bolikhamsay",
      "value": "Bolikhamsay",
      "country_id": "Laos"
    },
    {
      "label": "Champasak",
      "value": "Champasak",
      "country_id": "Laos"
    },
    {
      "label": "Houaphanh",
      "value": "Houaphanh",
      "country_id": "Laos"
    },
    {
      "label": "Khammouane",
      "value": "Khammouane",
      "country_id": "Laos"
    },
    {
      "label": "Luang Nam Tha",
      "value": "Luang Nam Tha",
      "country_id": "Laos"
    },
    {
      "label": "Luang Prabang",
      "value": "Luang Prabang",
      "country_id": "Laos"
    },
    {
      "label": "Oudomxay",
      "value": "Oudomxay",
      "country_id": "Laos"
    },
    {
      "label": "Phongsaly",
      "value": "Phongsaly",
      "country_id": "Laos"
    },
    {
      "label": "Saravan",
      "value": "Saravan",
      "country_id": "Laos"
    },
    {
      "label": "Savannakhet",
      "value": "Savannakhet",
      "country_id": "Laos"
    },
    {
      "label": "Sekong",
      "value": "Sekong",
      "country_id": "Laos"
    },
    {
      "label": "Viangchan Prefecture",
      "value": "Viangchan Prefecture",
      "country_id": "Laos"
    },
    {
      "label": "Viangchan Province",
      "value": "Viangchan Province",
      "country_id": "Laos"
    },
    {
      "label": "Xaignabury",
      "value": "Xaignabury",
      "country_id": "Laos"
    },
    {
      "label": "Xiang Khuang",
      "value": "Xiang Khuang",
      "country_id": "Laos"
    },
    {
      "label": "Aizkraukles",
      "value": "Aizkraukles",
      "country_id": "Latvia"
    },
    {
      "label": "Aluksnes",
      "value": "Aluksnes",
      "country_id": "Latvia"
    },
    {
      "label": "Balvu",
      "value": "Balvu",
      "country_id": "Latvia"
    },
    {
      "label": "Bauskas",
      "value": "Bauskas",
      "country_id": "Latvia"
    },
    {
      "label": "Cesu",
      "value": "Cesu",
      "country_id": "Latvia"
    },
    {
      "label": "Daugavpils",
      "value": "Daugavpils",
      "country_id": "Latvia"
    },
    {
      "label": "Daugavpils City",
      "value": "Daugavpils City",
      "country_id": "Latvia"
    },
    {
      "label": "Dobeles",
      "value": "Dobeles",
      "country_id": "Latvia"
    },
    {
      "label": "Gulbenes",
      "value": "Gulbenes",
      "country_id": "Latvia"
    },
    {
      "label": "Jekabspils",
      "value": "Jekabspils",
      "country_id": "Latvia"
    },
    {
      "label": "Jelgava",
      "value": "Jelgava",
      "country_id": "Latvia"
    },
    {
      "label": "Jelgavas",
      "value": "Jelgavas",
      "country_id": "Latvia"
    },
    {
      "label": "Jurmala City",
      "value": "Jurmala City",
      "country_id": "Latvia"
    },
    {
      "label": "Kraslavas",
      "value": "Kraslavas",
      "country_id": "Latvia"
    },
    {
      "label": "Kuldigas",
      "value": "Kuldigas",
      "country_id": "Latvia"
    },
    {
      "label": "Liepaja",
      "value": "Liepaja",
      "country_id": "Latvia"
    },
    {
      "label": "Liepajas",
      "value": "Liepajas",
      "country_id": "Latvia"
    },
    {
      "label": "Limbazhu",
      "value": "Limbazhu",
      "country_id": "Latvia"
    },
    {
      "label": "Ludzas",
      "value": "Ludzas",
      "country_id": "Latvia"
    },
    {
      "label": "Madonas",
      "value": "Madonas",
      "country_id": "Latvia"
    },
    {
      "label": "Ogres",
      "value": "Ogres",
      "country_id": "Latvia"
    },
    {
      "label": "Preilu",
      "value": "Preilu",
      "country_id": "Latvia"
    },
    {
      "label": "Rezekne",
      "value": "Rezekne",
      "country_id": "Latvia"
    },
    {
      "label": "Rezeknes",
      "value": "Rezeknes",
      "country_id": "Latvia"
    },
    {
      "label": "Riga",
      "value": "Riga",
      "country_id": "Latvia"
    },
    {
      "label": "Rigas",
      "value": "Rigas",
      "country_id": "Latvia"
    },
    {
      "label": "Saldus",
      "value": "Saldus",
      "country_id": "Latvia"
    },
    {
      "label": "Talsu",
      "value": "Talsu",
      "country_id": "Latvia"
    },
    {
      "label": "Tukuma",
      "value": "Tukuma",
      "country_id": "Latvia"
    },
    {
      "label": "Valkas",
      "value": "Valkas",
      "country_id": "Latvia"
    },
    {
      "label": "Valmieras",
      "value": "Valmieras",
      "country_id": "Latvia"
    },
    {
      "label": "Ventspils",
      "value": "Ventspils",
      "country_id": "Latvia"
    },
    {
      "label": "Ventspils City",
      "value": "Ventspils City",
      "country_id": "Latvia"
    },
    {
      "label": "Beirut",
      "value": "Beirut",
      "country_id": "Lebanon"
    },
    {
      "label": "Jabal Lubnan",
      "value": "Jabal Lubnan",
      "country_id": "Lebanon"
    },
    {
      "label": "Mohafazat Liban-Nord",
      "value": "Mohafazat Liban-Nord",
      "country_id": "Lebanon"
    },
    {
      "label": "Mohafazat Mont-Liban",
      "value": "Mohafazat Mont-Liban",
      "country_id": "Lebanon"
    },
    {
      "label": "Sidon",
      "value": "Sidon",
      "country_id": "Lebanon"
    },
    {
      "label": "al-Biqa",
      "value": "al-Biqa",
      "country_id": "Lebanon"
    },
    {
      "label": "al-Janub",
      "value": "al-Janub",
      "country_id": "Lebanon"
    },
    {
      "label": "an-Nabatiyah",
      "value": "an-Nabatiyah",
      "country_id": "Lebanon"
    },
    {
      "label": "ash-Shamal",
      "value": "ash-Shamal",
      "country_id": "Lebanon"
    },
    {
      "label": "Berea",
      "value": "Berea",
      "country_id": "Lesotho"
    },
    {
      "label": "Butha-Buthe",
      "value": "Butha-Buthe",
      "country_id": "Lesotho"
    },
    {
      "label": "Leribe",
      "value": "Leribe",
      "country_id": "Lesotho"
    },
    {
      "label": "Mafeteng",
      "value": "Mafeteng",
      "country_id": "Lesotho"
    },
    {
      "label": "Maseru",
      "value": "Maseru",
      "country_id": "Lesotho"
    },
    {
      "label": "Mohale''s Hoek",
      "value": "Mohale''s Hoek",
      "country_id": "Lesotho"
    },
    {
      "label": "Mokhotlong",
      "value": "Mokhotlong",
      "country_id": "Lesotho"
    },
    {
      "label": "Qacha''s Nek",
      "value": "Qacha''s Nek",
      "country_id": "Lesotho"
    },
    {
      "label": "Quthing",
      "value": "Quthing",
      "country_id": "Lesotho"
    },
    {
      "label": "Thaba-Tseka",
      "value": "Thaba-Tseka",
      "country_id": "Lesotho"
    },
    {
      "label": "Bomi",
      "value": "Bomi",
      "country_id": "Liberia"
    },
    {
      "label": "Bong",
      "value": "Bong",
      "country_id": "Liberia"
    },
    {
      "label": "Grand Bassa",
      "value": "Grand Bassa",
      "country_id": "Liberia"
    },
    {
      "label": "Grand Cape Mount",
      "value": "Grand Cape Mount",
      "country_id": "Liberia"
    },
    {
      "label": "Grand Gedeh",
      "value": "Grand Gedeh",
      "country_id": "Liberia"
    },
    {
      "label": "Loffa",
      "value": "Loffa",
      "country_id": "Liberia"
    },
    {
      "label": "Margibi",
      "value": "Margibi",
      "country_id": "Liberia"
    },
    {
      "label": "Maryland and Grand Kru",
      "value": "Maryland and Grand Kru",
      "country_id": "Liberia"
    },
    {
      "label": "Montserrado",
      "value": "Montserrado",
      "country_id": "Liberia"
    },
    {
      "label": "Nimba",
      "value": "Nimba",
      "country_id": "Liberia"
    },
    {
      "label": "Rivercess",
      "value": "Rivercess",
      "country_id": "Liberia"
    },
    {
      "label": "Sinoe",
      "value": "Sinoe",
      "country_id": "Liberia"
    },
    {
      "label": "Fezzan",
      "value": "Fezzan",
      "country_id": "Libya"
    },
    {
      "label": "Banghazi",
      "value": "Banghazi",
      "country_id": "Libya"
    },
    {
      "label": "Darnah",
      "value": "Darnah",
      "country_id": "Libya"
    },
    {
      "label": "Ghadamis",
      "value": "Ghadamis",
      "country_id": "Libya"
    },
    {
      "label": "Gharyan",
      "value": "Gharyan",
      "country_id": "Libya"
    },
    {
      "label": "Misratah",
      "value": "Misratah",
      "country_id": "Libya"
    },
    {
      "label": "Murzuq",
      "value": "Murzuq",
      "country_id": "Libya"
    },
    {
      "label": "Sabha",
      "value": "Sabha",
      "country_id": "Libya"
    },
    {
      "label": "Sawfajjin",
      "value": "Sawfajjin",
      "country_id": "Libya"
    },
    {
      "label": "Surt",
      "value": "Surt",
      "country_id": "Libya"
    },
    {
      "label": "Tarabulus",
      "value": "Tarabulus",
      "country_id": "Libya"
    },
    {
      "label": "Tarhunah",
      "value": "Tarhunah",
      "country_id": "Libya"
    },
    {
      "label": "Tripolitania",
      "value": "Tripolitania",
      "country_id": "Libya"
    },
    {
      "label": "Tubruq",
      "value": "Tubruq",
      "country_id": "Libya"
    },
    {
      "label": "Yafran",
      "value": "Yafran",
      "country_id": "Libya"
    },
    {
      "label": "Zlitan",
      "value": "Zlitan",
      "country_id": "Libya"
    },
    {
      "label": "al-''Aziziyah",
      "value": "al-''Aziziyah",
      "country_id": "Libya"
    },
    {
      "label": "al-Fatih",
      "value": "al-Fatih",
      "country_id": "Libya"
    },
    {
      "label": "al-Jabal al Akhdar",
      "value": "al-Jabal al Akhdar",
      "country_id": "Libya"
    },
    {
      "label": "al-Jufrah",
      "value": "al-Jufrah",
      "country_id": "Libya"
    },
    {
      "label": "al-Khums",
      "value": "al-Khums",
      "country_id": "Libya"
    },
    {
      "label": "al-Kufrah",
      "value": "al-Kufrah",
      "country_id": "Libya"
    },
    {
      "label": "an-Nuqat al-Khams",
      "value": "an-Nuqat al-Khams",
      "country_id": "Libya"
    },
    {
      "label": "ash-Shati",
      "value": "ash-Shati",
      "country_id": "Libya"
    },
    {
      "label": "az-Zawiyah",
      "value": "az-Zawiyah",
      "country_id": "Libya"
    },
    {
      "label": "Balzers",
      "value": "Balzers",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Eschen",
      "value": "Eschen",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Gamprin",
      "value": "Gamprin",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Mauren",
      "value": "Mauren",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Planken",
      "value": "Planken",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Ruggell",
      "value": "Ruggell",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Schaan",
      "value": "Schaan",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Schellenberg",
      "value": "Schellenberg",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Triesen",
      "value": "Triesen",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Triesenberg",
      "value": "Triesenberg",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Vaduz",
      "value": "Vaduz",
      "country_id": "Liechtenstein"
    },
    {
      "label": "Alytaus",
      "value": "Alytaus",
      "country_id": "Lithuania"
    },
    {
      "label": "Anyksciai",
      "value": "Anyksciai",
      "country_id": "Lithuania"
    },
    {
      "label": "Kauno",
      "value": "Kauno",
      "country_id": "Lithuania"
    },
    {
      "label": "Klaipedos",
      "value": "Klaipedos",
      "country_id": "Lithuania"
    },
    {
      "label": "Marijampoles",
      "value": "Marijampoles",
      "country_id": "Lithuania"
    },
    {
      "label": "Panevezhio",
      "value": "Panevezhio",
      "country_id": "Lithuania"
    },
    {
      "label": "Panevezys",
      "value": "Panevezys",
      "country_id": "Lithuania"
    },
    {
      "label": "Shiauliu",
      "value": "Shiauliu",
      "country_id": "Lithuania"
    },
    {
      "label": "Taurages",
      "value": "Taurages",
      "country_id": "Lithuania"
    },
    {
      "label": "Telshiu",
      "value": "Telshiu",
      "country_id": "Lithuania"
    },
    {
      "label": "Telsiai",
      "value": "Telsiai",
      "country_id": "Lithuania"
    },
    {
      "label": "Utenos",
      "value": "Utenos",
      "country_id": "Lithuania"
    },
    {
      "label": "Vilniaus",
      "value": "Vilniaus",
      "country_id": "Lithuania"
    },
    {
      "label": "Capellen",
      "value": "Capellen",
      "country_id": "Luxembourg"
    },
    {
      "label": "Clervaux",
      "value": "Clervaux",
      "country_id": "Luxembourg"
    },
    {
      "label": "Diekirch",
      "value": "Diekirch",
      "country_id": "Luxembourg"
    },
    {
      "label": "Echternach",
      "value": "Echternach",
      "country_id": "Luxembourg"
    },
    {
      "label": "Esch-sur-Alzette",
      "value": "Esch-sur-Alzette",
      "country_id": "Luxembourg"
    },
    {
      "label": "Grevenmacher",
      "value": "Grevenmacher",
      "country_id": "Luxembourg"
    },
    {
      "label": "Luxembourg",
      "value": "Luxembourg",
      "country_id": "Luxembourg"
    },
    {
      "label": "Mersch",
      "value": "Mersch",
      "country_id": "Luxembourg"
    },
    {
      "label": "Redange",
      "value": "Redange",
      "country_id": "Luxembourg"
    },
    {
      "label": "Remich",
      "value": "Remich",
      "country_id": "Luxembourg"
    },
    {
      "label": "Vianden",
      "value": "Vianden",
      "country_id": "Luxembourg"
    },
    {
      "label": "Wiltz",
      "value": "Wiltz",
      "country_id": "Luxembourg"
    },
    {
      "label": "Macau",
      "value": "Macau",
      "country_id": "Macau S.A.R."
    },
    {
      "label": "Berovo",
      "value": "Berovo",
      "country_id": "Macedonia"
    },
    {
      "label": "Bitola",
      "value": "Bitola",
      "country_id": "Macedonia"
    },
    {
      "label": "Brod",
      "value": "Brod",
      "country_id": "Macedonia"
    },
    {
      "label": "Debar",
      "value": "Debar",
      "country_id": "Macedonia"
    },
    {
      "label": "Delchevo",
      "value": "Delchevo",
      "country_id": "Macedonia"
    },
    {
      "label": "Demir Hisar",
      "value": "Demir Hisar",
      "country_id": "Macedonia"
    },
    {
      "label": "Gevgelija",
      "value": "Gevgelija",
      "country_id": "Macedonia"
    },
    {
      "label": "Gostivar",
      "value": "Gostivar",
      "country_id": "Macedonia"
    },
    {
      "label": "Kavadarci",
      "value": "Kavadarci",
      "country_id": "Macedonia"
    },
    {
      "label": "Kichevo",
      "value": "Kichevo",
      "country_id": "Macedonia"
    },
    {
      "label": "Kochani",
      "value": "Kochani",
      "country_id": "Macedonia"
    },
    {
      "label": "Kratovo",
      "value": "Kratovo",
      "country_id": "Macedonia"
    },
    {
      "label": "Kriva Palanka",
      "value": "Kriva Palanka",
      "country_id": "Macedonia"
    },
    {
      "label": "Krushevo",
      "value": "Krushevo",
      "country_id": "Macedonia"
    },
    {
      "label": "Kumanovo",
      "value": "Kumanovo",
      "country_id": "Macedonia"
    },
    {
      "label": "Negotino",
      "value": "Negotino",
      "country_id": "Macedonia"
    },
    {
      "label": "Ohrid",
      "value": "Ohrid",
      "country_id": "Macedonia"
    },
    {
      "label": "Prilep",
      "value": "Prilep",
      "country_id": "Macedonia"
    },
    {
      "label": "Probishtip",
      "value": "Probishtip",
      "country_id": "Macedonia"
    },
    {
      "label": "Radovish",
      "value": "Radovish",
      "country_id": "Macedonia"
    },
    {
      "label": "Resen",
      "value": "Resen",
      "country_id": "Macedonia"
    },
    {
      "label": "Shtip",
      "value": "Shtip",
      "country_id": "Macedonia"
    },
    {
      "label": "Skopje",
      "value": "Skopje",
      "country_id": "Macedonia"
    },
    {
      "label": "Struga",
      "value": "Struga",
      "country_id": "Macedonia"
    },
    {
      "label": "Strumica",
      "value": "Strumica",
      "country_id": "Macedonia"
    },
    {
      "label": "Sveti Nikole",
      "value": "Sveti Nikole",
      "country_id": "Macedonia"
    },
    {
      "label": "Tetovo",
      "value": "Tetovo",
      "country_id": "Macedonia"
    },
    {
      "label": "Valandovo",
      "value": "Valandovo",
      "country_id": "Macedonia"
    },
    {
      "label": "Veles",
      "value": "Veles",
      "country_id": "Macedonia"
    },
    {
      "label": "Vinica",
      "value": "Vinica",
      "country_id": "Macedonia"
    },
    {
      "label": "Antananarivo",
      "value": "Antananarivo",
      "country_id": "Madagascar"
    },
    {
      "label": "Antsiranana",
      "value": "Antsiranana",
      "country_id": "Madagascar"
    },
    {
      "label": "Fianarantsoa",
      "value": "Fianarantsoa",
      "country_id": "Madagascar"
    },
    {
      "label": "Mahajanga",
      "value": "Mahajanga",
      "country_id": "Madagascar"
    },
    {
      "label": "Toamasina",
      "value": "Toamasina",
      "country_id": "Madagascar"
    },
    {
      "label": "Toliary",
      "value": "Toliary",
      "country_id": "Madagascar"
    },
    {
      "label": "Balaka",
      "value": "Balaka",
      "country_id": "Malawi"
    },
    {
      "label": "Blantyre City",
      "value": "Blantyre City",
      "country_id": "Malawi"
    },
    {
      "label": "Chikwawa",
      "value": "Chikwawa",
      "country_id": "Malawi"
    },
    {
      "label": "Chiradzulu",
      "value": "Chiradzulu",
      "country_id": "Malawi"
    },
    {
      "label": "Chitipa",
      "value": "Chitipa",
      "country_id": "Malawi"
    },
    {
      "label": "Dedza",
      "value": "Dedza",
      "country_id": "Malawi"
    },
    {
      "label": "Dowa",
      "value": "Dowa",
      "country_id": "Malawi"
    },
    {
      "label": "Karonga",
      "value": "Karonga",
      "country_id": "Malawi"
    },
    {
      "label": "Kasungu",
      "value": "Kasungu",
      "country_id": "Malawi"
    },
    {
      "label": "Lilongwe City",
      "value": "Lilongwe City",
      "country_id": "Malawi"
    },
    {
      "label": "Machinga",
      "value": "Machinga",
      "country_id": "Malawi"
    },
    {
      "label": "Mangochi",
      "value": "Mangochi",
      "country_id": "Malawi"
    },
    {
      "label": "Mchinji",
      "value": "Mchinji",
      "country_id": "Malawi"
    },
    {
      "label": "Mulanje",
      "value": "Mulanje",
      "country_id": "Malawi"
    },
    {
      "label": "Mwanza",
      "value": "Mwanza",
      "country_id": "Malawi"
    },
    {
      "label": "Mzimba",
      "value": "Mzimba",
      "country_id": "Malawi"
    },
    {
      "label": "Mzuzu City",
      "value": "Mzuzu City",
      "country_id": "Malawi"
    },
    {
      "label": "Nkhata Bay",
      "value": "Nkhata Bay",
      "country_id": "Malawi"
    },
    {
      "label": "Nkhotakota",
      "value": "Nkhotakota",
      "country_id": "Malawi"
    },
    {
      "label": "Nsanje",
      "value": "Nsanje",
      "country_id": "Malawi"
    },
    {
      "label": "Ntcheu",
      "value": "Ntcheu",
      "country_id": "Malawi"
    },
    {
      "label": "Ntchisi",
      "value": "Ntchisi",
      "country_id": "Malawi"
    },
    {
      "label": "Phalombe",
      "value": "Phalombe",
      "country_id": "Malawi"
    },
    {
      "label": "Rumphi",
      "value": "Rumphi",
      "country_id": "Malawi"
    },
    {
      "label": "Salima",
      "value": "Salima",
      "country_id": "Malawi"
    },
    {
      "label": "Thyolo",
      "value": "Thyolo",
      "country_id": "Malawi"
    },
    {
      "label": "Zomba Municipality",
      "value": "Zomba Municipality",
      "country_id": "Malawi"
    },
    {
      "label": "Johor",
      "value": "Johor",
      "country_id": "Malaysia"
    },
    {
      "label": "Kedah",
      "value": "Kedah",
      "country_id": "Malaysia"
    },
    {
      "label": "Kelantan",
      "value": "Kelantan",
      "country_id": "Malaysia"
    },
    {
      "label": "Kuala Lumpur",
      "value": "Kuala Lumpur",
      "country_id": "Malaysia"
    },
    {
      "label": "Labuan",
      "value": "Labuan",
      "country_id": "Malaysia"
    },
    {
      "label": "Melaka",
      "value": "Melaka",
      "country_id": "Malaysia"
    },
    {
      "label": "Negeri Johor",
      "value": "Negeri Johor",
      "country_id": "Malaysia"
    },
    {
      "label": "Negeri Sembilan",
      "value": "Negeri Sembilan",
      "country_id": "Malaysia"
    },
    {
      "label": "Pahang",
      "value": "Pahang",
      "country_id": "Malaysia"
    },
    {
      "label": "Penang",
      "value": "Penang",
      "country_id": "Malaysia"
    },
    {
      "label": "Perak",
      "value": "Perak",
      "country_id": "Malaysia"
    },
    {
      "label": "Perlis",
      "value": "Perlis",
      "country_id": "Malaysia"
    },
    {
      "label": "Pulau Pinang",
      "value": "Pulau Pinang",
      "country_id": "Malaysia"
    },
    {
      "label": "Sabah",
      "value": "Sabah",
      "country_id": "Malaysia"
    },
    {
      "label": "Sarawak",
      "value": "Sarawak",
      "country_id": "Malaysia"
    },
    {
      "label": "Selangor",
      "value": "Selangor",
      "country_id": "Malaysia"
    },
    {
      "label": "Sembilan",
      "value": "Sembilan",
      "country_id": "Malaysia"
    },
    {
      "label": "Terengganu",
      "value": "Terengganu",
      "country_id": "Malaysia"
    },
    {
      "label": "Alif Alif",
      "value": "Alif Alif",
      "country_id": "Maldives"
    },
    {
      "label": "Alif Dhaal",
      "value": "Alif Dhaal",
      "country_id": "Maldives"
    },
    {
      "label": "Baa",
      "value": "Baa",
      "country_id": "Maldives"
    },
    {
      "label": "Dhaal",
      "value": "Dhaal",
      "country_id": "Maldives"
    },
    {
      "label": "Faaf",
      "value": "Faaf",
      "country_id": "Maldives"
    },
    {
      "label": "Gaaf Alif",
      "value": "Gaaf Alif",
      "country_id": "Maldives"
    },
    {
      "label": "Gaaf Dhaal",
      "value": "Gaaf Dhaal",
      "country_id": "Maldives"
    },
    {
      "label": "Ghaviyani",
      "value": "Ghaviyani",
      "country_id": "Maldives"
    },
    {
      "label": "Haa Alif",
      "value": "Haa Alif",
      "country_id": "Maldives"
    },
    {
      "label": "Haa Dhaal",
      "value": "Haa Dhaal",
      "country_id": "Maldives"
    },
    {
      "label": "Kaaf",
      "value": "Kaaf",
      "country_id": "Maldives"
    },
    {
      "label": "Laam",
      "value": "Laam",
      "country_id": "Maldives"
    },
    {
      "label": "Lhaviyani",
      "value": "Lhaviyani",
      "country_id": "Maldives"
    },
    {
      "label": "Male",
      "value": "Male",
      "country_id": "Maldives"
    },
    {
      "label": "Miim",
      "value": "Miim",
      "country_id": "Maldives"
    },
    {
      "label": "Nuun",
      "value": "Nuun",
      "country_id": "Maldives"
    },
    {
      "label": "Raa",
      "value": "Raa",
      "country_id": "Maldives"
    },
    {
      "label": "Shaviyani",
      "value": "Shaviyani",
      "country_id": "Maldives"
    },
    {
      "label": "Siin",
      "value": "Siin",
      "country_id": "Maldives"
    },
    {
      "label": "Thaa",
      "value": "Thaa",
      "country_id": "Maldives"
    },
    {
      "label": "Vaav",
      "value": "Vaav",
      "country_id": "Maldives"
    },
    {
      "label": "Bamako",
      "value": "Bamako",
      "country_id": "Mali"
    },
    {
      "label": "Gao",
      "value": "Gao",
      "country_id": "Mali"
    },
    {
      "label": "Kayes",
      "value": "Kayes",
      "country_id": "Mali"
    },
    {
      "label": "Kidal",
      "value": "Kidal",
      "country_id": "Mali"
    },
    {
      "label": "Koulikoro",
      "value": "Koulikoro",
      "country_id": "Mali"
    },
    {
      "label": "Mopti",
      "value": "Mopti",
      "country_id": "Mali"
    },
    {
      "label": "Segou",
      "value": "Segou",
      "country_id": "Mali"
    },
    {
      "label": "Sikasso",
      "value": "Sikasso",
      "country_id": "Mali"
    },
    {
      "label": "Tombouctou",
      "value": "Tombouctou",
      "country_id": "Mali"
    },
    {
      "label": "Gozo and Comino",
      "value": "Gozo and Comino",
      "country_id": "Malta"
    },
    {
      "label": "Inner Harbour",
      "value": "Inner Harbour",
      "country_id": "Malta"
    },
    {
      "label": "Northern",
      "value": "Northern",
      "country_id": "Malta"
    },
    {
      "label": "Outer Harbour",
      "value": "Outer Harbour",
      "country_id": "Malta"
    },
    {
      "label": "South Eastern",
      "value": "South Eastern",
      "country_id": "Malta"
    },
    {
      "label": "Valletta",
      "value": "Valletta",
      "country_id": "Malta"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Malta"
    },
    {
      "label": "Castletown",
      "value": "Castletown",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Douglas",
      "value": "Douglas",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Laxey",
      "value": "Laxey",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Onchan",
      "value": "Onchan",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Peel",
      "value": "Peel",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Port Erin",
      "value": "Port Erin",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Port Saint Mary",
      "value": "Port Saint Mary",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Ramsey",
      "value": "Ramsey",
      "country_id": "Man (Isle of)"
    },
    {
      "label": "Ailinlaplap",
      "value": "Ailinlaplap",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Ailuk",
      "value": "Ailuk",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Arno",
      "value": "Arno",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Aur",
      "value": "Aur",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Bikini",
      "value": "Bikini",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Ebon",
      "value": "Ebon",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Enewetak",
      "value": "Enewetak",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Jabat",
      "value": "Jabat",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Jaluit",
      "value": "Jaluit",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Kili",
      "value": "Kili",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Kwajalein",
      "value": "Kwajalein",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Lae",
      "value": "Lae",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Lib",
      "value": "Lib",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Likiep",
      "value": "Likiep",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Majuro",
      "value": "Majuro",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Maloelap",
      "value": "Maloelap",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Mejit",
      "value": "Mejit",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Mili",
      "value": "Mili",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Namorik",
      "value": "Namorik",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Namu",
      "value": "Namu",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Rongelap",
      "value": "Rongelap",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Ujae",
      "value": "Ujae",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Utrik",
      "value": "Utrik",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Wotho",
      "value": "Wotho",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Wotje",
      "value": "Wotje",
      "country_id": "Marshall Islands"
    },
    {
      "label": "Fort-de-France",
      "value": "Fort-de-France",
      "country_id": "Martinique"
    },
    {
      "label": "La Trinite",
      "value": "La Trinite",
      "country_id": "Martinique"
    },
    {
      "label": "Le Marin",
      "value": "Le Marin",
      "country_id": "Martinique"
    },
    {
      "label": "Saint-Pierre",
      "value": "Saint-Pierre",
      "country_id": "Martinique"
    },
    {
      "label": "Adrar",
      "value": "Adrar",
      "country_id": "Mauritania"
    },
    {
      "label": "Assaba",
      "value": "Assaba",
      "country_id": "Mauritania"
    },
    {
      "label": "Brakna",
      "value": "Brakna",
      "country_id": "Mauritania"
    },
    {
      "label": "Dhakhlat Nawadibu",
      "value": "Dhakhlat Nawadibu",
      "country_id": "Mauritania"
    },
    {
      "label": "Hudh-al-Gharbi",
      "value": "Hudh-al-Gharbi",
      "country_id": "Mauritania"
    },
    {
      "label": "Hudh-ash-Sharqi",
      "value": "Hudh-ash-Sharqi",
      "country_id": "Mauritania"
    },
    {
      "label": "Inshiri",
      "value": "Inshiri",
      "country_id": "Mauritania"
    },
    {
      "label": "Nawakshut",
      "value": "Nawakshut",
      "country_id": "Mauritania"
    },
    {
      "label": "Qidimagha",
      "value": "Qidimagha",
      "country_id": "Mauritania"
    },
    {
      "label": "Qurqul",
      "value": "Qurqul",
      "country_id": "Mauritania"
    },
    {
      "label": "Taqant",
      "value": "Taqant",
      "country_id": "Mauritania"
    },
    {
      "label": "Tiris Zammur",
      "value": "Tiris Zammur",
      "country_id": "Mauritania"
    },
    {
      "label": "Trarza",
      "value": "Trarza",
      "country_id": "Mauritania"
    },
    {
      "label": "Black River",
      "value": "Black River",
      "country_id": "Mauritius"
    },
    {
      "label": "Eau Coulee",
      "value": "Eau Coulee",
      "country_id": "Mauritius"
    },
    {
      "label": "Flacq",
      "value": "Flacq",
      "country_id": "Mauritius"
    },
    {
      "label": "Floreal",
      "value": "Floreal",
      "country_id": "Mauritius"
    },
    {
      "label": "Grand Port",
      "value": "Grand Port",
      "country_id": "Mauritius"
    },
    {
      "label": "Moka",
      "value": "Moka",
      "country_id": "Mauritius"
    },
    {
      "label": "Pamplempousses",
      "value": "Pamplempousses",
      "country_id": "Mauritius"
    },
    {
      "label": "Plaines Wilhelm",
      "value": "Plaines Wilhelm",
      "country_id": "Mauritius"
    },
    {
      "label": "Port Louis",
      "value": "Port Louis",
      "country_id": "Mauritius"
    },
    {
      "label": "Riviere du Rempart",
      "value": "Riviere du Rempart",
      "country_id": "Mauritius"
    },
    {
      "label": "Rodrigues",
      "value": "Rodrigues",
      "country_id": "Mauritius"
    },
    {
      "label": "Rose Hill",
      "value": "Rose Hill",
      "country_id": "Mauritius"
    },
    {
      "label": "Savanne",
      "value": "Savanne",
      "country_id": "Mauritius"
    },
    {
      "label": "Mayotte",
      "value": "Mayotte",
      "country_id": "Mayotte"
    },
    {
      "label": "Pamanzi",
      "value": "Pamanzi",
      "country_id": "Mayotte"
    },
    {
      "label": "Aguascalientes",
      "value": "Aguascalientes",
      "country_id": "Mexico"
    },
    {
      "label": "Baja California",
      "value": "Baja California",
      "country_id": "Mexico"
    },
    {
      "label": "Baja California Sur",
      "value": "Baja California Sur",
      "country_id": "Mexico"
    },
    {
      "label": "Campeche",
      "value": "Campeche",
      "country_id": "Mexico"
    },
    {
      "label": "Chiapas",
      "value": "Chiapas",
      "country_id": "Mexico"
    },
    {
      "label": "Chihuahua",
      "value": "Chihuahua",
      "country_id": "Mexico"
    },
    {
      "label": "Coahuila",
      "value": "Coahuila",
      "country_id": "Mexico"
    },
    {
      "label": "Colima",
      "value": "Colima",
      "country_id": "Mexico"
    },
    {
      "label": "Distrito Federal",
      "value": "Distrito Federal",
      "country_id": "Mexico"
    },
    {
      "label": "Durango",
      "value": "Durango",
      "country_id": "Mexico"
    },
    {
      "label": "Estado de Mexico",
      "value": "Estado de Mexico",
      "country_id": "Mexico"
    },
    {
      "label": "Guanajuato",
      "value": "Guanajuato",
      "country_id": "Mexico"
    },
    {
      "label": "Guerrero",
      "value": "Guerrero",
      "country_id": "Mexico"
    },
    {
      "label": "Hidalgo",
      "value": "Hidalgo",
      "country_id": "Mexico"
    },
    {
      "label": "Jalisco",
      "value": "Jalisco",
      "country_id": "Mexico"
    },
    {
      "label": "Mexico",
      "value": "Mexico",
      "country_id": "Mexico"
    },
    {
      "label": "Michoacan",
      "value": "Michoacan",
      "country_id": "Mexico"
    },
    {
      "label": "Morelos",
      "value": "Morelos",
      "country_id": "Mexico"
    },
    {
      "label": "Nayarit",
      "value": "Nayarit",
      "country_id": "Mexico"
    },
    {
      "label": "Nuevo Leon",
      "value": "Nuevo Leon",
      "country_id": "Mexico"
    },
    {
      "label": "Oaxaca",
      "value": "Oaxaca",
      "country_id": "Mexico"
    },
    {
      "label": "Puebla",
      "value": "Puebla",
      "country_id": "Mexico"
    },
    {
      "label": "Queretaro",
      "value": "Queretaro",
      "country_id": "Mexico"
    },
    {
      "label": "Quintana Roo",
      "value": "Quintana Roo",
      "country_id": "Mexico"
    },
    {
      "label": "San Luis Potosi",
      "value": "San Luis Potosi",
      "country_id": "Mexico"
    },
    {
      "label": "Sinaloa",
      "value": "Sinaloa",
      "country_id": "Mexico"
    },
    {
      "label": "Sonora",
      "value": "Sonora",
      "country_id": "Mexico"
    },
    {
      "label": "Tabasco",
      "value": "Tabasco",
      "country_id": "Mexico"
    },
    {
      "label": "Tamaulipas",
      "value": "Tamaulipas",
      "country_id": "Mexico"
    },
    {
      "label": "Tlaxcala",
      "value": "Tlaxcala",
      "country_id": "Mexico"
    },
    {
      "label": "Veracruz",
      "value": "Veracruz",
      "country_id": "Mexico"
    },
    {
      "label": "Yucatan",
      "value": "Yucatan",
      "country_id": "Mexico"
    },
    {
      "label": "Zacatecas",
      "value": "Zacatecas",
      "country_id": "Mexico"
    },
    {
      "label": "Chuuk",
      "value": "Chuuk",
      "country_id": "Micronesia"
    },
    {
      "label": "Kusaie",
      "value": "Kusaie",
      "country_id": "Micronesia"
    },
    {
      "label": "Pohnpei",
      "value": "Pohnpei",
      "country_id": "Micronesia"
    },
    {
      "label": "Yap",
      "value": "Yap",
      "country_id": "Micronesia"
    },
    {
      "label": "Balti",
      "value": "Balti",
      "country_id": "Moldova"
    },
    {
      "label": "Cahul",
      "value": "Cahul",
      "country_id": "Moldova"
    },
    {
      "label": "Chisinau",
      "value": "Chisinau",
      "country_id": "Moldova"
    },
    {
      "label": "Chisinau Oras",
      "value": "Chisinau Oras",
      "country_id": "Moldova"
    },
    {
      "label": "Edinet",
      "value": "Edinet",
      "country_id": "Moldova"
    },
    {
      "label": "Gagauzia",
      "value": "Gagauzia",
      "country_id": "Moldova"
    },
    {
      "label": "Lapusna",
      "value": "Lapusna",
      "country_id": "Moldova"
    },
    {
      "label": "Orhei",
      "value": "Orhei",
      "country_id": "Moldova"
    },
    {
      "label": "Soroca",
      "value": "Soroca",
      "country_id": "Moldova"
    },
    {
      "label": "Taraclia",
      "value": "Taraclia",
      "country_id": "Moldova"
    },
    {
      "label": "Tighina",
      "value": "Tighina",
      "country_id": "Moldova"
    },
    {
      "label": "Transnistria",
      "value": "Transnistria",
      "country_id": "Moldova"
    },
    {
      "label": "Ungheni",
      "value": "Ungheni",
      "country_id": "Moldova"
    },
    {
      "label": "Fontvieille",
      "value": "Fontvieille",
      "country_id": "Monaco"
    },
    {
      "label": "La Condamine",
      "value": "La Condamine",
      "country_id": "Monaco"
    },
    {
      "label": "Monaco-Ville",
      "value": "Monaco-Ville",
      "country_id": "Monaco"
    },
    {
      "label": "Monte Carlo",
      "value": "Monte Carlo",
      "country_id": "Monaco"
    },
    {
      "label": "Arhangaj",
      "value": "Arhangaj",
      "country_id": "Mongolia"
    },
    {
      "label": "Bajan-Olgij",
      "value": "Bajan-Olgij",
      "country_id": "Mongolia"
    },
    {
      "label": "Bajanhongor",
      "value": "Bajanhongor",
      "country_id": "Mongolia"
    },
    {
      "label": "Bulgan",
      "value": "Bulgan",
      "country_id": "Mongolia"
    },
    {
      "label": "Darhan-Uul",
      "value": "Darhan-Uul",
      "country_id": "Mongolia"
    },
    {
      "label": "Dornod",
      "value": "Dornod",
      "country_id": "Mongolia"
    },
    {
      "label": "Dornogovi",
      "value": "Dornogovi",
      "country_id": "Mongolia"
    },
    {
      "label": "Dundgovi",
      "value": "Dundgovi",
      "country_id": "Mongolia"
    },
    {
      "label": "Govi-Altaj",
      "value": "Govi-Altaj",
      "country_id": "Mongolia"
    },
    {
      "label": "Govisumber",
      "value": "Govisumber",
      "country_id": "Mongolia"
    },
    {
      "label": "Hentij",
      "value": "Hentij",
      "country_id": "Mongolia"
    },
    {
      "label": "Hovd",
      "value": "Hovd",
      "country_id": "Mongolia"
    },
    {
      "label": "Hovsgol",
      "value": "Hovsgol",
      "country_id": "Mongolia"
    },
    {
      "label": "Omnogovi",
      "value": "Omnogovi",
      "country_id": "Mongolia"
    },
    {
      "label": "Orhon",
      "value": "Orhon",
      "country_id": "Mongolia"
    },
    {
      "label": "Ovorhangaj",
      "value": "Ovorhangaj",
      "country_id": "Mongolia"
    },
    {
      "label": "Selenge",
      "value": "Selenge",
      "country_id": "Mongolia"
    },
    {
      "label": "Suhbaatar",
      "value": "Suhbaatar",
      "country_id": "Mongolia"
    },
    {
      "label": "Tov",
      "value": "Tov",
      "country_id": "Mongolia"
    },
    {
      "label": "Ulaanbaatar",
      "value": "Ulaanbaatar",
      "country_id": "Mongolia"
    },
    {
      "label": "Uvs",
      "value": "Uvs",
      "country_id": "Mongolia"
    },
    {
      "label": "Zavhan",
      "value": "Zavhan",
      "country_id": "Mongolia"
    },
    {
      "label": "Montserrat",
      "value": "Montserrat",
      "country_id": "Montserrat"
    },
    {
      "label": "Agadir",
      "value": "Agadir",
      "country_id": "Morocco"
    },
    {
      "label": "Casablanca",
      "value": "Casablanca",
      "country_id": "Morocco"
    },
    {
      "label": "Chaouia-Ouardigha",
      "value": "Chaouia-Ouardigha",
      "country_id": "Morocco"
    },
    {
      "label": "Doukkala-Abda",
      "value": "Doukkala-Abda",
      "country_id": "Morocco"
    },
    {
      "label": "Fes-Boulemane",
      "value": "Fes-Boulemane",
      "country_id": "Morocco"
    },
    {
      "label": "Gharb-Chrarda-Beni Hssen",
      "value": "Gharb-Chrarda-Beni Hssen",
      "country_id": "Morocco"
    },
    {
      "label": "Guelmim",
      "value": "Guelmim",
      "country_id": "Morocco"
    },
    {
      "label": "Kenitra",
      "value": "Kenitra",
      "country_id": "Morocco"
    },
    {
      "label": "Marrakech-Tensift-Al Haouz",
      "value": "Marrakech-Tensift-Al Haouz",
      "country_id": "Morocco"
    },
    {
      "label": "Meknes-Tafilalet",
      "value": "Meknes-Tafilalet",
      "country_id": "Morocco"
    },
    {
      "label": "Oriental",
      "value": "Oriental",
      "country_id": "Morocco"
    },
    {
      "label": "Oujda",
      "value": "Oujda",
      "country_id": "Morocco"
    },
    {
      "label": "Province de Tanger",
      "value": "Province de Tanger",
      "country_id": "Morocco"
    },
    {
      "label": "Rabat-Sale-Zammour-Zaer",
      "value": "Rabat-Sale-Zammour-Zaer",
      "country_id": "Morocco"
    },
    {
      "label": "Sala Al Jadida",
      "value": "Sala Al Jadida",
      "country_id": "Morocco"
    },
    {
      "label": "Settat",
      "value": "Settat",
      "country_id": "Morocco"
    },
    {
      "label": "Souss Massa-Draa",
      "value": "Souss Massa-Draa",
      "country_id": "Morocco"
    },
    {
      "label": "Tadla-Azilal",
      "value": "Tadla-Azilal",
      "country_id": "Morocco"
    },
    {
      "label": "Tangier-Tetouan",
      "value": "Tangier-Tetouan",
      "country_id": "Morocco"
    },
    {
      "label": "Taza-Al Hoceima-Taounate",
      "value": "Taza-Al Hoceima-Taounate",
      "country_id": "Morocco"
    },
    {
      "label": "Wilaya de Casablanca",
      "value": "Wilaya de Casablanca",
      "country_id": "Morocco"
    },
    {
      "label": "Wilaya de Rabat-Sale",
      "value": "Wilaya de Rabat-Sale",
      "country_id": "Morocco"
    },
    {
      "label": "Cabo Delgado",
      "value": "Cabo Delgado",
      "country_id": "Mozambique"
    },
    {
      "label": "Gaza",
      "value": "Gaza",
      "country_id": "Mozambique"
    },
    {
      "label": "Inhambane",
      "value": "Inhambane",
      "country_id": "Mozambique"
    },
    {
      "label": "Manica",
      "value": "Manica",
      "country_id": "Mozambique"
    },
    {
      "label": "Maputo",
      "value": "Maputo",
      "country_id": "Mozambique"
    },
    {
      "label": "Maputo Provincia",
      "value": "Maputo Provincia",
      "country_id": "Mozambique"
    },
    {
      "label": "Nampula",
      "value": "Nampula",
      "country_id": "Mozambique"
    },
    {
      "label": "Niassa",
      "value": "Niassa",
      "country_id": "Mozambique"
    },
    {
      "label": "Sofala",
      "value": "Sofala",
      "country_id": "Mozambique"
    },
    {
      "label": "Tete",
      "value": "Tete",
      "country_id": "Mozambique"
    },
    {
      "label": "Zambezia",
      "value": "Zambezia",
      "country_id": "Mozambique"
    },
    {
      "label": "Ayeyarwady",
      "value": "Ayeyarwady",
      "country_id": "Myanmar"
    },
    {
      "label": "Bago",
      "value": "Bago",
      "country_id": "Myanmar"
    },
    {
      "label": "Chin",
      "value": "Chin",
      "country_id": "Myanmar"
    },
    {
      "label": "Kachin",
      "value": "Kachin",
      "country_id": "Myanmar"
    },
    {
      "label": "Kayah",
      "value": "Kayah",
      "country_id": "Myanmar"
    },
    {
      "label": "Kayin",
      "value": "Kayin",
      "country_id": "Myanmar"
    },
    {
      "label": "Magway",
      "value": "Magway",
      "country_id": "Myanmar"
    },
    {
      "label": "Mandalay",
      "value": "Mandalay",
      "country_id": "Myanmar"
    },
    {
      "label": "Mon",
      "value": "Mon",
      "country_id": "Myanmar"
    },
    {
      "label": "Nay Pyi Taw",
      "value": "Nay Pyi Taw",
      "country_id": "Myanmar"
    },
    {
      "label": "Rakhine",
      "value": "Rakhine",
      "country_id": "Myanmar"
    },
    {
      "label": "Sagaing",
      "value": "Sagaing",
      "country_id": "Myanmar"
    },
    {
      "label": "Shan",
      "value": "Shan",
      "country_id": "Myanmar"
    },
    {
      "label": "Tanintharyi",
      "value": "Tanintharyi",
      "country_id": "Myanmar"
    },
    {
      "label": "Yangon",
      "value": "Yangon",
      "country_id": "Myanmar"
    },
    {
      "label": "Caprivi",
      "value": "Caprivi",
      "country_id": "Namibia"
    },
    {
      "label": "Erongo",
      "value": "Erongo",
      "country_id": "Namibia"
    },
    {
      "label": "Hardap",
      "value": "Hardap",
      "country_id": "Namibia"
    },
    {
      "label": "Karas",
      "value": "Karas",
      "country_id": "Namibia"
    },
    {
      "label": "Kavango",
      "value": "Kavango",
      "country_id": "Namibia"
    },
    {
      "label": "Khomas",
      "value": "Khomas",
      "country_id": "Namibia"
    },
    {
      "label": "Kunene",
      "value": "Kunene",
      "country_id": "Namibia"
    },
    {
      "label": "Ohangwena",
      "value": "Ohangwena",
      "country_id": "Namibia"
    },
    {
      "label": "Omaheke",
      "value": "Omaheke",
      "country_id": "Namibia"
    },
    {
      "label": "Omusati",
      "value": "Omusati",
      "country_id": "Namibia"
    },
    {
      "label": "Oshana",
      "value": "Oshana",
      "country_id": "Namibia"
    },
    {
      "label": "Oshikoto",
      "value": "Oshikoto",
      "country_id": "Namibia"
    },
    {
      "label": "Otjozondjupa",
      "value": "Otjozondjupa",
      "country_id": "Namibia"
    },
    {
      "label": "Yaren",
      "value": "Yaren",
      "country_id": "Nauru"
    },
    {
      "label": "Bagmati",
      "value": "Bagmati",
      "country_id": "Nepal"
    },
    {
      "label": "Bheri",
      "value": "Bheri",
      "country_id": "Nepal"
    },
    {
      "label": "Dhawalagiri",
      "value": "Dhawalagiri",
      "country_id": "Nepal"
    },
    {
      "label": "Gandaki",
      "value": "Gandaki",
      "country_id": "Nepal"
    },
    {
      "label": "Janakpur",
      "value": "Janakpur",
      "country_id": "Nepal"
    },
    {
      "label": "Karnali",
      "value": "Karnali",
      "country_id": "Nepal"
    },
    {
      "label": "Koshi",
      "value": "Koshi",
      "country_id": "Nepal"
    },
    {
      "label": "Lumbini",
      "value": "Lumbini",
      "country_id": "Nepal"
    },
    {
      "label": "Mahakali",
      "value": "Mahakali",
      "country_id": "Nepal"
    },
    {
      "label": "Mechi",
      "value": "Mechi",
      "country_id": "Nepal"
    },
    {
      "label": "Narayani",
      "value": "Narayani",
      "country_id": "Nepal"
    },
    {
      "label": "Rapti",
      "value": "Rapti",
      "country_id": "Nepal"
    },
    {
      "label": "Sagarmatha",
      "value": "Sagarmatha",
      "country_id": "Nepal"
    },
    {
      "label": "Seti",
      "value": "Seti",
      "country_id": "Nepal"
    },
    {
      "label": "Bonaire",
      "value": "Bonaire",
      "country_id": "Netherlands Antilles"
    },
    {
      "label": "Curacao",
      "value": "Curacao",
      "country_id": "Netherlands Antilles"
    },
    {
      "label": "Saba",
      "value": "Saba",
      "country_id": "Netherlands Antilles"
    },
    {
      "label": "Sint Eustatius",
      "value": "Sint Eustatius",
      "country_id": "Netherlands Antilles"
    },
    {
      "label": "Sint Maarten",
      "value": "Sint Maarten",
      "country_id": "Netherlands Antilles"
    },
    {
      "label": "Amsterdam",
      "value": "Amsterdam",
      "country_id": "Netherlands The"
    },
    {
      "label": "Benelux",
      "value": "Benelux",
      "country_id": "Netherlands The"
    },
    {
      "label": "Drenthe",
      "value": "Drenthe",
      "country_id": "Netherlands The"
    },
    {
      "label": "Flevoland",
      "value": "Flevoland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Friesland",
      "value": "Friesland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Gelderland",
      "value": "Gelderland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Groningen",
      "value": "Groningen",
      "country_id": "Netherlands The"
    },
    {
      "label": "Limburg",
      "value": "Limburg",
      "country_id": "Netherlands The"
    },
    {
      "label": "Noord-Brabant",
      "value": "Noord-Brabant",
      "country_id": "Netherlands The"
    },
    {
      "label": "Noord-Holland",
      "value": "Noord-Holland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Overijssel",
      "value": "Overijssel",
      "country_id": "Netherlands The"
    },
    {
      "label": "South Holland",
      "value": "South Holland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Utrecht",
      "value": "Utrecht",
      "country_id": "Netherlands The"
    },
    {
      "label": "Zeeland",
      "value": "Zeeland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Zuid-Holland",
      "value": "Zuid-Holland",
      "country_id": "Netherlands The"
    },
    {
      "label": "Iles",
      "value": "Iles",
      "country_id": "New Caledonia"
    },
    {
      "label": "Nord",
      "value": "Nord",
      "country_id": "New Caledonia"
    },
    {
      "label": "Sud",
      "value": "Sud",
      "country_id": "New Caledonia"
    },
    {
      "label": "Area Outside Region",
      "value": "Area Outside Region",
      "country_id": "New Zealand"
    },
    {
      "label": "Auckland",
      "value": "Auckland",
      "country_id": "New Zealand"
    },
    {
      "label": "Bay of Plenty",
      "value": "Bay of Plenty",
      "country_id": "New Zealand"
    },
    {
      "label": "Canterbury",
      "value": "Canterbury",
      "country_id": "New Zealand"
    },
    {
      "label": "Christchurch",
      "value": "Christchurch",
      "country_id": "New Zealand"
    },
    {
      "label": "Gisborne",
      "value": "Gisborne",
      "country_id": "New Zealand"
    },
    {
      "label": "Hawke''s Bay",
      "value": "Hawke''s Bay",
      "country_id": "New Zealand"
    },
    {
      "label": "Manawatu-Wanganui",
      "value": "Manawatu-Wanganui",
      "country_id": "New Zealand"
    },
    {
      "label": "Marlborough",
      "value": "Marlborough",
      "country_id": "New Zealand"
    },
    {
      "label": "Nelson",
      "value": "Nelson",
      "country_id": "New Zealand"
    },
    {
      "label": "Northland",
      "value": "Northland",
      "country_id": "New Zealand"
    },
    {
      "label": "Otago",
      "value": "Otago",
      "country_id": "New Zealand"
    },
    {
      "label": "Rodney",
      "value": "Rodney",
      "country_id": "New Zealand"
    },
    {
      "label": "Southland",
      "value": "Southland",
      "country_id": "New Zealand"
    },
    {
      "label": "Taranaki",
      "value": "Taranaki",
      "country_id": "New Zealand"
    },
    {
      "label": "Tasman",
      "value": "Tasman",
      "country_id": "New Zealand"
    },
    {
      "label": "Waikato",
      "value": "Waikato",
      "country_id": "New Zealand"
    },
    {
      "label": "Wellington",
      "value": "Wellington",
      "country_id": "New Zealand"
    },
    {
      "label": "West Coast",
      "value": "West Coast",
      "country_id": "New Zealand"
    },
    {
      "label": "Atlantico Norte",
      "value": "Atlantico Norte",
      "country_id": "Nicaragua"
    },
    {
      "label": "Atlantico Sur",
      "value": "Atlantico Sur",
      "country_id": "Nicaragua"
    },
    {
      "label": "Boaco",
      "value": "Boaco",
      "country_id": "Nicaragua"
    },
    {
      "label": "Carazo",
      "value": "Carazo",
      "country_id": "Nicaragua"
    },
    {
      "label": "Chinandega",
      "value": "Chinandega",
      "country_id": "Nicaragua"
    },
    {
      "label": "Chontales",
      "value": "Chontales",
      "country_id": "Nicaragua"
    },
    {
      "label": "Esteli",
      "value": "Esteli",
      "country_id": "Nicaragua"
    },
    {
      "label": "Granada",
      "value": "Granada",
      "country_id": "Nicaragua"
    },
    {
      "label": "Jinotega",
      "value": "Jinotega",
      "country_id": "Nicaragua"
    },
    {
      "label": "Leon",
      "value": "Leon",
      "country_id": "Nicaragua"
    },
    {
      "label": "Madriz",
      "value": "Madriz",
      "country_id": "Nicaragua"
    },
    {
      "label": "Managua",
      "value": "Managua",
      "country_id": "Nicaragua"
    },
    {
      "label": "Masaya",
      "value": "Masaya",
      "country_id": "Nicaragua"
    },
    {
      "label": "Matagalpa",
      "value": "Matagalpa",
      "country_id": "Nicaragua"
    },
    {
      "label": "Nueva Segovia",
      "value": "Nueva Segovia",
      "country_id": "Nicaragua"
    },
    {
      "label": "Rio San Juan",
      "value": "Rio San Juan",
      "country_id": "Nicaragua"
    },
    {
      "label": "Rivas",
      "value": "Rivas",
      "country_id": "Nicaragua"
    },
    {
      "label": "Agadez",
      "value": "Agadez",
      "country_id": "Niger"
    },
    {
      "label": "Diffa",
      "value": "Diffa",
      "country_id": "Niger"
    },
    {
      "label": "Dosso",
      "value": "Dosso",
      "country_id": "Niger"
    },
    {
      "label": "Maradi",
      "value": "Maradi",
      "country_id": "Niger"
    },
    {
      "label": "Niamey",
      "value": "Niamey",
      "country_id": "Niger"
    },
    {
      "label": "Tahoua",
      "value": "Tahoua",
      "country_id": "Niger"
    },
    {
      "label": "Tillabery",
      "value": "Tillabery",
      "country_id": "Niger"
    },
    {
      "label": "Zinder",
      "value": "Zinder",
      "country_id": "Niger"
    },
    {
      "label": "Abia",
      "value": "Abia",
      "country_id": "Nigeria"
    },
    {
      "label": "Abuja Federal Capital Territor",
      "value": "Abuja Federal Capital Territor",
      "country_id": "Nigeria"
    },
    {
      "label": "Adamawa",
      "value": "Adamawa",
      "country_id": "Nigeria"
    },
    {
      "label": "Akwa Ibom",
      "value": "Akwa Ibom",
      "country_id": "Nigeria"
    },
    {
      "label": "Anambra",
      "value": "Anambra",
      "country_id": "Nigeria"
    },
    {
      "label": "Bauchi",
      "value": "Bauchi",
      "country_id": "Nigeria"
    },
    {
      "label": "Bayelsa",
      "value": "Bayelsa",
      "country_id": "Nigeria"
    },
    {
      "label": "Benue",
      "value": "Benue",
      "country_id": "Nigeria"
    },
    {
      "label": "Borno",
      "value": "Borno",
      "country_id": "Nigeria"
    },
    {
      "label": "Cross River",
      "value": "Cross River",
      "country_id": "Nigeria"
    },
    {
      "label": "Delta",
      "value": "Delta",
      "country_id": "Nigeria"
    },
    {
      "label": "Ebonyi",
      "value": "Ebonyi",
      "country_id": "Nigeria"
    },
    {
      "label": "Edo",
      "value": "Edo",
      "country_id": "Nigeria"
    },
    {
      "label": "Ekiti",
      "value": "Ekiti",
      "country_id": "Nigeria"
    },
    {
      "label": "Enugu",
      "value": "Enugu",
      "country_id": "Nigeria"
    },
    {
      "label": "Gombe",
      "value": "Gombe",
      "country_id": "Nigeria"
    },
    {
      "label": "Imo",
      "value": "Imo",
      "country_id": "Nigeria"
    },
    {
      "label": "Jigawa",
      "value": "Jigawa",
      "country_id": "Nigeria"
    },
    {
      "label": "Kaduna",
      "value": "Kaduna",
      "country_id": "Nigeria"
    },
    {
      "label": "Kano",
      "value": "Kano",
      "country_id": "Nigeria"
    },
    {
      "label": "Katsina",
      "value": "Katsina",
      "country_id": "Nigeria"
    },
    {
      "label": "Kebbi",
      "value": "Kebbi",
      "country_id": "Nigeria"
    },
    {
      "label": "Kogi",
      "value": "Kogi",
      "country_id": "Nigeria"
    },
    {
      "label": "Kwara",
      "value": "Kwara",
      "country_id": "Nigeria"
    },
    {
      "label": "Lagos",
      "value": "Lagos",
      "country_id": "Nigeria"
    },
    {
      "label": "Nassarawa",
      "value": "Nassarawa",
      "country_id": "Nigeria"
    },
    {
      "label": "Niger",
      "value": "Niger",
      "country_id": "Nigeria"
    },
    {
      "label": "Ogun",
      "value": "Ogun",
      "country_id": "Nigeria"
    },
    {
      "label": "Ondo",
      "value": "Ondo",
      "country_id": "Nigeria"
    },
    {
      "label": "Osun",
      "value": "Osun",
      "country_id": "Nigeria"
    },
    {
      "label": "Oyo",
      "value": "Oyo",
      "country_id": "Nigeria"
    },
    {
      "label": "Plateau",
      "value": "Plateau",
      "country_id": "Nigeria"
    },
    {
      "label": "Rivers",
      "value": "Rivers",
      "country_id": "Nigeria"
    },
    {
      "label": "Sokoto",
      "value": "Sokoto",
      "country_id": "Nigeria"
    },
    {
      "label": "Taraba",
      "value": "Taraba",
      "country_id": "Nigeria"
    },
    {
      "label": "Yobe",
      "value": "Yobe",
      "country_id": "Nigeria"
    },
    {
      "label": "Zamfara",
      "value": "Zamfara",
      "country_id": "Nigeria"
    },
    {
      "label": "Niue",
      "value": "Niue",
      "country_id": "Niue"
    },
    {
      "label": "Norfolk Island",
      "value": "Norfolk Island",
      "country_id": "Norfolk Island"
    },
    {
      "label": "Northern Islands",
      "value": "Northern Islands",
      "country_id": "Northern Mariana Islands"
    },
    {
      "label": "Rota",
      "value": "Rota",
      "country_id": "Northern Mariana Islands"
    },
    {
      "label": "Saipan",
      "value": "Saipan",
      "country_id": "Northern Mariana Islands"
    },
    {
      "label": "Tinian",
      "value": "Tinian",
      "country_id": "Northern Mariana Islands"
    },
    {
      "label": "Akershus",
      "value": "Akershus",
      "country_id": "Norway"
    },
    {
      "label": "Aust Agder",
      "value": "Aust Agder",
      "country_id": "Norway"
    },
    {
      "label": "Bergen",
      "value": "Bergen",
      "country_id": "Norway"
    },
    {
      "label": "Buskerud",
      "value": "Buskerud",
      "country_id": "Norway"
    },
    {
      "label": "Finnmark",
      "value": "Finnmark",
      "country_id": "Norway"
    },
    {
      "label": "Hedmark",
      "value": "Hedmark",
      "country_id": "Norway"
    },
    {
      "label": "Hordaland",
      "value": "Hordaland",
      "country_id": "Norway"
    },
    {
      "label": "Moere og Romsdal",
      "value": "Moere og Romsdal",
      "country_id": "Norway"
    },
    {
      "label": "Nord Trondelag",
      "value": "Nord Trondelag",
      "country_id": "Norway"
    },
    {
      "label": "Nordland",
      "value": "Nordland",
      "country_id": "Norway"
    },
    {
      "label": "Oestfold",
      "value": "Oestfold",
      "country_id": "Norway"
    },
    {
      "label": "Oppland",
      "value": "Oppland",
      "country_id": "Norway"
    },
    {
      "label": "Oslo",
      "value": "Oslo",
      "country_id": "Norway"
    },
    {
      "label": "Rogaland",
      "value": "Rogaland",
      "country_id": "Norway"
    },
    {
      "label": "Soer Troendelag",
      "value": "Soer Troendelag",
      "country_id": "Norway"
    },
    {
      "label": "Sogn og Fjordane",
      "value": "Sogn og Fjordane",
      "country_id": "Norway"
    },
    {
      "label": "Stavern",
      "value": "Stavern",
      "country_id": "Norway"
    },
    {
      "label": "Sykkylven",
      "value": "Sykkylven",
      "country_id": "Norway"
    },
    {
      "label": "Telemark",
      "value": "Telemark",
      "country_id": "Norway"
    },
    {
      "label": "Troms",
      "value": "Troms",
      "country_id": "Norway"
    },
    {
      "label": "Vest Agder",
      "value": "Vest Agder",
      "country_id": "Norway"
    },
    {
      "label": "Vestfold",
      "value": "Vestfold",
      "country_id": "Norway"
    },
    {
      "label": "ÃƒÂ˜stfold",
      "value": "ÃƒÂ˜stfold",
      "country_id": "Norway"
    },
    {
      "label": "Al Buraimi",
      "value": "Al Buraimi",
      "country_id": "Oman"
    },
    {
      "label": "Dhufar",
      "value": "Dhufar",
      "country_id": "Oman"
    },
    {
      "label": "Masqat",
      "value": "Masqat",
      "country_id": "Oman"
    },
    {
      "label": "Musandam",
      "value": "Musandam",
      "country_id": "Oman"
    },
    {
      "label": "Rusayl",
      "value": "Rusayl",
      "country_id": "Oman"
    },
    {
      "label": "Wadi Kabir",
      "value": "Wadi Kabir",
      "country_id": "Oman"
    },
    {
      "label": "ad-Dakhiliyah",
      "value": "ad-Dakhiliyah",
      "country_id": "Oman"
    },
    {
      "label": "adh-Dhahirah",
      "value": "adh-Dhahirah",
      "country_id": "Oman"
    },
    {
      "label": "al-Batinah",
      "value": "al-Batinah",
      "country_id": "Oman"
    },
    {
      "label": "ash-Sharqiyah",
      "value": "ash-Sharqiyah",
      "country_id": "Oman"
    },
    {
      "label": "Baluchistan",
      "value": "Baluchistan",
      "country_id": "Pakistan"
    },
    {
      "label": "Federal Capital Area",
      "value": "Federal Capital Area",
      "country_id": "Pakistan"
    },
    {
      "label": "Federally administered Tribal",
      "value": "Federally administered Tribal",
      "country_id": "Pakistan"
    },
    {
      "label": "North-West Frontier",
      "value": "North-West Frontier",
      "country_id": "Pakistan"
    },
    {
      "label": "Northern Areas",
      "value": "Northern Areas",
      "country_id": "Pakistan"
    },
    {
      "label": "Punjab",
      "value": "Punjab",
      "country_id": "Pakistan"
    },
    {
      "label": "Sind",
      "value": "Sind",
      "country_id": "Pakistan"
    },
    {
      "label": "Aimeliik",
      "value": "Aimeliik",
      "country_id": "Palau"
    },
    {
      "label": "Airai",
      "value": "Airai",
      "country_id": "Palau"
    },
    {
      "label": "Angaur",
      "value": "Angaur",
      "country_id": "Palau"
    },
    {
      "label": "Hatobohei",
      "value": "Hatobohei",
      "country_id": "Palau"
    },
    {
      "label": "Kayangel",
      "value": "Kayangel",
      "country_id": "Palau"
    },
    {
      "label": "Koror",
      "value": "Koror",
      "country_id": "Palau"
    },
    {
      "label": "Melekeok",
      "value": "Melekeok",
      "country_id": "Palau"
    },
    {
      "label": "Ngaraard",
      "value": "Ngaraard",
      "country_id": "Palau"
    },
    {
      "label": "Ngardmau",
      "value": "Ngardmau",
      "country_id": "Palau"
    },
    {
      "label": "Ngaremlengui",
      "value": "Ngaremlengui",
      "country_id": "Palau"
    },
    {
      "label": "Ngatpang",
      "value": "Ngatpang",
      "country_id": "Palau"
    },
    {
      "label": "Ngchesar",
      "value": "Ngchesar",
      "country_id": "Palau"
    },
    {
      "label": "Ngerchelong",
      "value": "Ngerchelong",
      "country_id": "Palau"
    },
    {
      "label": "Ngiwal",
      "value": "Ngiwal",
      "country_id": "Palau"
    },
    {
      "label": "Peleliu",
      "value": "Peleliu",
      "country_id": "Palau"
    },
    {
      "label": "Sonsorol",
      "value": "Sonsorol",
      "country_id": "Palau"
    },
    {
      "label": "Ariha",
      "value": "Ariha",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Bayt Lahm",
      "value": "Bayt Lahm",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Bethlehem",
      "value": "Bethlehem",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Dayr-al-Balah",
      "value": "Dayr-al-Balah",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Ghazzah",
      "value": "Ghazzah",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Ghazzah ash-Shamaliyah",
      "value": "Ghazzah ash-Shamaliyah",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Janin",
      "value": "Janin",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Khan Yunis",
      "value": "Khan Yunis",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Nabulus",
      "value": "Nabulus",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Qalqilyah",
      "value": "Qalqilyah",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Rafah",
      "value": "Rafah",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Ram Allah wal-Birah",
      "value": "Ram Allah wal-Birah",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Salfit",
      "value": "Salfit",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Tubas",
      "value": "Tubas",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Tulkarm",
      "value": "Tulkarm",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "al-Khalil",
      "value": "al-Khalil",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "al-Quds",
      "value": "al-Quds",
      "country_id": "Palestinian Territory Occupied"
    },
    {
      "label": "Bocas del Toro",
      "value": "Bocas del Toro",
      "country_id": "Panama"
    },
    {
      "label": "Chiriqui",
      "value": "Chiriqui",
      "country_id": "Panama"
    },
    {
      "label": "Cocle",
      "value": "Cocle",
      "country_id": "Panama"
    },
    {
      "label": "Colon",
      "value": "Colon",
      "country_id": "Panama"
    },
    {
      "label": "Darien",
      "value": "Darien",
      "country_id": "Panama"
    },
    {
      "label": "Embera",
      "value": "Embera",
      "country_id": "Panama"
    },
    {
      "label": "Herrera",
      "value": "Herrera",
      "country_id": "Panama"
    },
    {
      "label": "Kuna Yala",
      "value": "Kuna Yala",
      "country_id": "Panama"
    },
    {
      "label": "Los Santos",
      "value": "Los Santos",
      "country_id": "Panama"
    },
    {
      "label": "Ngobe Bugle",
      "value": "Ngobe Bugle",
      "country_id": "Panama"
    },
    {
      "label": "Panama",
      "value": "Panama",
      "country_id": "Panama"
    },
    {
      "label": "Veraguas",
      "value": "Veraguas",
      "country_id": "Panama"
    },
    {
      "label": "East New Britain",
      "value": "East New Britain",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "East Sepik",
      "value": "East Sepik",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Eastern Highlands",
      "value": "Eastern Highlands",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Enga",
      "value": "Enga",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Fly River",
      "value": "Fly River",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Gulf",
      "value": "Gulf",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Madang",
      "value": "Madang",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Manus",
      "value": "Manus",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Milne Bay",
      "value": "Milne Bay",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Morobe",
      "value": "Morobe",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "National Capital District",
      "value": "National Capital District",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "New Ireland",
      "value": "New Ireland",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "North Solomons",
      "value": "North Solomons",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Oro",
      "value": "Oro",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Sandaun",
      "value": "Sandaun",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Simbu",
      "value": "Simbu",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Southern Highlands",
      "value": "Southern Highlands",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "West New Britain",
      "value": "West New Britain",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Western Highlands",
      "value": "Western Highlands",
      "country_id": "Papua new Guinea"
    },
    {
      "label": "Alto Paraguay",
      "value": "Alto Paraguay",
      "country_id": "Paraguay"
    },
    {
      "label": "Alto Parana",
      "value": "Alto Parana",
      "country_id": "Paraguay"
    },
    {
      "label": "Amambay",
      "value": "Amambay",
      "country_id": "Paraguay"
    },
    {
      "label": "Asuncion",
      "value": "Asuncion",
      "country_id": "Paraguay"
    },
    {
      "label": "Boqueron",
      "value": "Boqueron",
      "country_id": "Paraguay"
    },
    {
      "label": "Caaguazu",
      "value": "Caaguazu",
      "country_id": "Paraguay"
    },
    {
      "label": "Caazapa",
      "value": "Caazapa",
      "country_id": "Paraguay"
    },
    {
      "label": "Canendiyu",
      "value": "Canendiyu",
      "country_id": "Paraguay"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Paraguay"
    },
    {
      "label": "Concepcion",
      "value": "Concepcion",
      "country_id": "Paraguay"
    },
    {
      "label": "Cordillera",
      "value": "Cordillera",
      "country_id": "Paraguay"
    },
    {
      "label": "Guaira",
      "value": "Guaira",
      "country_id": "Paraguay"
    },
    {
      "label": "Itapua",
      "value": "Itapua",
      "country_id": "Paraguay"
    },
    {
      "label": "Misiones",
      "value": "Misiones",
      "country_id": "Paraguay"
    },
    {
      "label": "Neembucu",
      "value": "Neembucu",
      "country_id": "Paraguay"
    },
    {
      "label": "Paraguari",
      "value": "Paraguari",
      "country_id": "Paraguay"
    },
    {
      "label": "Presidente Hayes",
      "value": "Presidente Hayes",
      "country_id": "Paraguay"
    },
    {
      "label": "San Pedro",
      "value": "San Pedro",
      "country_id": "Paraguay"
    },
    {
      "label": "Amazonas",
      "value": "Amazonas",
      "country_id": "Peru"
    },
    {
      "label": "Ancash",
      "value": "Ancash",
      "country_id": "Peru"
    },
    {
      "label": "Apurimac",
      "value": "Apurimac",
      "country_id": "Peru"
    },
    {
      "label": "Arequipa",
      "value": "Arequipa",
      "country_id": "Peru"
    },
    {
      "label": "Ayacucho",
      "value": "Ayacucho",
      "country_id": "Peru"
    },
    {
      "label": "Cajamarca",
      "value": "Cajamarca",
      "country_id": "Peru"
    },
    {
      "label": "Cusco",
      "value": "Cusco",
      "country_id": "Peru"
    },
    {
      "label": "Huancavelica",
      "value": "Huancavelica",
      "country_id": "Peru"
    },
    {
      "label": "Huanuco",
      "value": "Huanuco",
      "country_id": "Peru"
    },
    {
      "label": "Ica",
      "value": "Ica",
      "country_id": "Peru"
    },
    {
      "label": "Junin",
      "value": "Junin",
      "country_id": "Peru"
    },
    {
      "label": "La Libertad",
      "value": "La Libertad",
      "country_id": "Peru"
    },
    {
      "label": "Lambayeque",
      "value": "Lambayeque",
      "country_id": "Peru"
    },
    {
      "label": "Lima y Callao",
      "value": "Lima y Callao",
      "country_id": "Peru"
    },
    {
      "label": "Loreto",
      "value": "Loreto",
      "country_id": "Peru"
    },
    {
      "label": "Madre de Dios",
      "value": "Madre de Dios",
      "country_id": "Peru"
    },
    {
      "label": "Moquegua",
      "value": "Moquegua",
      "country_id": "Peru"
    },
    {
      "label": "Pasco",
      "value": "Pasco",
      "country_id": "Peru"
    },
    {
      "label": "Piura",
      "value": "Piura",
      "country_id": "Peru"
    },
    {
      "label": "Puno",
      "value": "Puno",
      "country_id": "Peru"
    },
    {
      "label": "San Martin",
      "value": "San Martin",
      "country_id": "Peru"
    },
    {
      "label": "Tacna",
      "value": "Tacna",
      "country_id": "Peru"
    },
    {
      "label": "Tumbes",
      "value": "Tumbes",
      "country_id": "Peru"
    },
    {
      "label": "Ucayali",
      "value": "Ucayali",
      "country_id": "Peru"
    },
    {
      "label": "Batangas",
      "value": "Batangas",
      "country_id": "Philippines"
    },
    {
      "label": "Bicol",
      "value": "Bicol",
      "country_id": "Philippines"
    },
    {
      "label": "Bulacan",
      "value": "Bulacan",
      "country_id": "Philippines"
    },
    {
      "label": "Cagayan",
      "value": "Cagayan",
      "country_id": "Philippines"
    },
    {
      "label": "Caraga",
      "value": "Caraga",
      "country_id": "Philippines"
    },
    {
      "label": "Central Luzon",
      "value": "Central Luzon",
      "country_id": "Philippines"
    },
    {
      "label": "Central Mindanao",
      "value": "Central Mindanao",
      "country_id": "Philippines"
    },
    {
      "label": "Central Visayas",
      "value": "Central Visayas",
      "country_id": "Philippines"
    },
    {
      "label": "Cordillera",
      "value": "Cordillera",
      "country_id": "Philippines"
    },
    {
      "label": "Davao",
      "value": "Davao",
      "country_id": "Philippines"
    },
    {
      "label": "Eastern Visayas",
      "value": "Eastern Visayas",
      "country_id": "Philippines"
    },
    {
      "label": "Greater Metropolitan Area",
      "value": "Greater Metropolitan Area",
      "country_id": "Philippines"
    },
    {
      "label": "Ilocos",
      "value": "Ilocos",
      "country_id": "Philippines"
    },
    {
      "label": "Laguna",
      "value": "Laguna",
      "country_id": "Philippines"
    },
    {
      "label": "Luzon",
      "value": "Luzon",
      "country_id": "Philippines"
    },
    {
      "label": "Mactan",
      "value": "Mactan",
      "country_id": "Philippines"
    },
    {
      "label": "Metropolitan Manila Area",
      "value": "Metropolitan Manila Area",
      "country_id": "Philippines"
    },
    {
      "label": "Muslim Mindanao",
      "value": "Muslim Mindanao",
      "country_id": "Philippines"
    },
    {
      "label": "Northern Mindanao",
      "value": "Northern Mindanao",
      "country_id": "Philippines"
    },
    {
      "label": "Southern Mindanao",
      "value": "Southern Mindanao",
      "country_id": "Philippines"
    },
    {
      "label": "Southern Tagalog",
      "value": "Southern Tagalog",
      "country_id": "Philippines"
    },
    {
      "label": "Western Mindanao",
      "value": "Western Mindanao",
      "country_id": "Philippines"
    },
    {
      "label": "Western Visayas",
      "value": "Western Visayas",
      "country_id": "Philippines"
    },
    {
      "label": "Pitcairn Island",
      "value": "Pitcairn Island",
      "country_id": "Pitcairn Island"
    },
    {
      "label": "Biale Blota",
      "value": "Biale Blota",
      "country_id": "Poland"
    },
    {
      "label": "Dobroszyce",
      "value": "Dobroszyce",
      "country_id": "Poland"
    },
    {
      "label": "Dolnoslaskie",
      "value": "Dolnoslaskie",
      "country_id": "Poland"
    },
    {
      "label": "Dziekanow Lesny",
      "value": "Dziekanow Lesny",
      "country_id": "Poland"
    },
    {
      "label": "Hopowo",
      "value": "Hopowo",
      "country_id": "Poland"
    },
    {
      "label": "Kartuzy",
      "value": "Kartuzy",
      "country_id": "Poland"
    },
    {
      "label": "Koscian",
      "value": "Koscian",
      "country_id": "Poland"
    },
    {
      "label": "Krakow",
      "value": "Krakow",
      "country_id": "Poland"
    },
    {
      "label": "Kujawsko-Pomorskie",
      "value": "Kujawsko-Pomorskie",
      "country_id": "Poland"
    },
    {
      "label": "Lodzkie",
      "value": "Lodzkie",
      "country_id": "Poland"
    },
    {
      "label": "Lubelskie",
      "value": "Lubelskie",
      "country_id": "Poland"
    },
    {
      "label": "Lubuskie",
      "value": "Lubuskie",
      "country_id": "Poland"
    },
    {
      "label": "Malomice",
      "value": "Malomice",
      "country_id": "Poland"
    },
    {
      "label": "Malopolskie",
      "value": "Malopolskie",
      "country_id": "Poland"
    },
    {
      "label": "Mazowieckie",
      "value": "Mazowieckie",
      "country_id": "Poland"
    },
    {
      "label": "Mirkow",
      "value": "Mirkow",
      "country_id": "Poland"
    },
    {
      "label": "Opolskie",
      "value": "Opolskie",
      "country_id": "Poland"
    },
    {
      "label": "Ostrowiec",
      "value": "Ostrowiec",
      "country_id": "Poland"
    },
    {
      "label": "Podkarpackie",
      "value": "Podkarpackie",
      "country_id": "Poland"
    },
    {
      "label": "Podlaskie",
      "value": "Podlaskie",
      "country_id": "Poland"
    },
    {
      "label": "Polska",
      "value": "Polska",
      "country_id": "Poland"
    },
    {
      "label": "Pomorskie",
      "value": "Pomorskie",
      "country_id": "Poland"
    },
    {
      "label": "Poznan",
      "value": "Poznan",
      "country_id": "Poland"
    },
    {
      "label": "Pruszkow",
      "value": "Pruszkow",
      "country_id": "Poland"
    },
    {
      "label": "Rymanowska",
      "value": "Rymanowska",
      "country_id": "Poland"
    },
    {
      "label": "Rzeszow",
      "value": "Rzeszow",
      "country_id": "Poland"
    },
    {
      "label": "Slaskie",
      "value": "Slaskie",
      "country_id": "Poland"
    },
    {
      "label": "Stare Pole",
      "value": "Stare Pole",
      "country_id": "Poland"
    },
    {
      "label": "Swietokrzyskie",
      "value": "Swietokrzyskie",
      "country_id": "Poland"
    },
    {
      "label": "Warminsko-Mazurskie",
      "value": "Warminsko-Mazurskie",
      "country_id": "Poland"
    },
    {
      "label": "Warsaw",
      "value": "Warsaw",
      "country_id": "Poland"
    },
    {
      "label": "Wejherowo",
      "value": "Wejherowo",
      "country_id": "Poland"
    },
    {
      "label": "Wielkopolskie",
      "value": "Wielkopolskie",
      "country_id": "Poland"
    },
    {
      "label": "Wroclaw",
      "value": "Wroclaw",
      "country_id": "Poland"
    },
    {
      "label": "Zachodnio-Pomorskie",
      "value": "Zachodnio-Pomorskie",
      "country_id": "Poland"
    },
    {
      "label": "Zukowo",
      "value": "Zukowo",
      "country_id": "Poland"
    },
    {
      "label": "Abrantes",
      "value": "Abrantes",
      "country_id": "Portugal"
    },
    {
      "label": "Acores",
      "value": "Acores",
      "country_id": "Portugal"
    },
    {
      "label": "Alentejo",
      "value": "Alentejo",
      "country_id": "Portugal"
    },
    {
      "label": "Algarve",
      "value": "Algarve",
      "country_id": "Portugal"
    },
    {
      "label": "Braga",
      "value": "Braga",
      "country_id": "Portugal"
    },
    {
      "label": "Centro",
      "value": "Centro",
      "country_id": "Portugal"
    },
    {
      "label": "Distrito de Leiria",
      "value": "Distrito de Leiria",
      "country_id": "Portugal"
    },
    {
      "label": "Distrito de Viana do Castelo",
      "value": "Distrito de Viana do Castelo",
      "country_id": "Portugal"
    },
    {
      "label": "Distrito de Vila Real",
      "value": "Distrito de Vila Real",
      "country_id": "Portugal"
    },
    {
      "label": "Distrito do Porto",
      "value": "Distrito do Porto",
      "country_id": "Portugal"
    },
    {
      "label": "Lisboa e Vale do Tejo",
      "value": "Lisboa e Vale do Tejo",
      "country_id": "Portugal"
    },
    {
      "label": "Madeira",
      "value": "Madeira",
      "country_id": "Portugal"
    },
    {
      "label": "Norte",
      "value": "Norte",
      "country_id": "Portugal"
    },
    {
      "label": "Paivas",
      "value": "Paivas",
      "country_id": "Portugal"
    },
    {
      "label": "Arecibo",
      "value": "Arecibo",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Bayamon",
      "value": "Bayamon",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Carolina",
      "value": "Carolina",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Florida",
      "value": "Florida",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Guayama",
      "value": "Guayama",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Humacao",
      "value": "Humacao",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Mayaguez-Aguadilla",
      "value": "Mayaguez-Aguadilla",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Ponce",
      "value": "Ponce",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Salinas",
      "value": "Salinas",
      "country_id": "Puerto Rico"
    },
    {
      "label": "San Juan",
      "value": "San Juan",
      "country_id": "Puerto Rico"
    },
    {
      "label": "Doha",
      "value": "Doha",
      "country_id": "Qatar"
    },
    {
      "label": "Jarian-al-Batnah",
      "value": "Jarian-al-Batnah",
      "country_id": "Qatar"
    },
    {
      "label": "Umm Salal",
      "value": "Umm Salal",
      "country_id": "Qatar"
    },
    {
      "label": "ad-Dawhah",
      "value": "ad-Dawhah",
      "country_id": "Qatar"
    },
    {
      "label": "al-Ghuwayriyah",
      "value": "al-Ghuwayriyah",
      "country_id": "Qatar"
    },
    {
      "label": "al-Jumayliyah",
      "value": "al-Jumayliyah",
      "country_id": "Qatar"
    },
    {
      "label": "al-Khawr",
      "value": "al-Khawr",
      "country_id": "Qatar"
    },
    {
      "label": "al-Wakrah",
      "value": "al-Wakrah",
      "country_id": "Qatar"
    },
    {
      "label": "ar-Rayyan",
      "value": "ar-Rayyan",
      "country_id": "Qatar"
    },
    {
      "label": "ash-Shamal",
      "value": "ash-Shamal",
      "country_id": "Qatar"
    },
    {
      "label": "Saint-Benoit",
      "value": "Saint-Benoit",
      "country_id": "Reunion"
    },
    {
      "label": "Saint-Denis",
      "value": "Saint-Denis",
      "country_id": "Reunion"
    },
    {
      "label": "Saint-Paul",
      "value": "Saint-Paul",
      "country_id": "Reunion"
    },
    {
      "label": "Saint-Pierre",
      "value": "Saint-Pierre",
      "country_id": "Reunion"
    },
    {
      "label": "Alba",
      "value": "Alba",
      "country_id": "Romania"
    },
    {
      "label": "Arad",
      "value": "Arad",
      "country_id": "Romania"
    },
    {
      "label": "Arges",
      "value": "Arges",
      "country_id": "Romania"
    },
    {
      "label": "Bacau",
      "value": "Bacau",
      "country_id": "Romania"
    },
    {
      "label": "Bihor",
      "value": "Bihor",
      "country_id": "Romania"
    },
    {
      "label": "Bistrita-Nasaud",
      "value": "Bistrita-Nasaud",
      "country_id": "Romania"
    },
    {
      "label": "Botosani",
      "value": "Botosani",
      "country_id": "Romania"
    },
    {
      "label": "Braila",
      "value": "Braila",
      "country_id": "Romania"
    },
    {
      "label": "Brasov",
      "value": "Brasov",
      "country_id": "Romania"
    },
    {
      "label": "Bucuresti",
      "value": "Bucuresti",
      "country_id": "Romania"
    },
    {
      "label": "Buzau",
      "value": "Buzau",
      "country_id": "Romania"
    },
    {
      "label": "Calarasi",
      "value": "Calarasi",
      "country_id": "Romania"
    },
    {
      "label": "Caras-Severin",
      "value": "Caras-Severin",
      "country_id": "Romania"
    },
    {
      "label": "Cluj",
      "value": "Cluj",
      "country_id": "Romania"
    },
    {
      "label": "Constanta",
      "value": "Constanta",
      "country_id": "Romania"
    },
    {
      "label": "Covasna",
      "value": "Covasna",
      "country_id": "Romania"
    },
    {
      "label": "Dambovita",
      "value": "Dambovita",
      "country_id": "Romania"
    },
    {
      "label": "Dolj",
      "value": "Dolj",
      "country_id": "Romania"
    },
    {
      "label": "Galati",
      "value": "Galati",
      "country_id": "Romania"
    },
    {
      "label": "Giurgiu",
      "value": "Giurgiu",
      "country_id": "Romania"
    },
    {
      "label": "Gorj",
      "value": "Gorj",
      "country_id": "Romania"
    },
    {
      "label": "Harghita",
      "value": "Harghita",
      "country_id": "Romania"
    },
    {
      "label": "Hunedoara",
      "value": "Hunedoara",
      "country_id": "Romania"
    },
    {
      "label": "Ialomita",
      "value": "Ialomita",
      "country_id": "Romania"
    },
    {
      "label": "Iasi",
      "value": "Iasi",
      "country_id": "Romania"
    },
    {
      "label": "Ilfov",
      "value": "Ilfov",
      "country_id": "Romania"
    },
    {
      "label": "Maramures",
      "value": "Maramures",
      "country_id": "Romania"
    },
    {
      "label": "Mehedinti",
      "value": "Mehedinti",
      "country_id": "Romania"
    },
    {
      "label": "Mures",
      "value": "Mures",
      "country_id": "Romania"
    },
    {
      "label": "Neamt",
      "value": "Neamt",
      "country_id": "Romania"
    },
    {
      "label": "Olt",
      "value": "Olt",
      "country_id": "Romania"
    },
    {
      "label": "Prahova",
      "value": "Prahova",
      "country_id": "Romania"
    },
    {
      "label": "Salaj",
      "value": "Salaj",
      "country_id": "Romania"
    },
    {
      "label": "Satu Mare",
      "value": "Satu Mare",
      "country_id": "Romania"
    },
    {
      "label": "Sibiu",
      "value": "Sibiu",
      "country_id": "Romania"
    },
    {
      "label": "Sondelor",
      "value": "Sondelor",
      "country_id": "Romania"
    },
    {
      "label": "Suceava",
      "value": "Suceava",
      "country_id": "Romania"
    },
    {
      "label": "Teleorman",
      "value": "Teleorman",
      "country_id": "Romania"
    },
    {
      "label": "Timis",
      "value": "Timis",
      "country_id": "Romania"
    },
    {
      "label": "Tulcea",
      "value": "Tulcea",
      "country_id": "Romania"
    },
    {
      "label": "Valcea",
      "value": "Valcea",
      "country_id": "Romania"
    },
    {
      "label": "Vaslui",
      "value": "Vaslui",
      "country_id": "Romania"
    },
    {
      "label": "Vrancea",
      "value": "Vrancea",
      "country_id": "Romania"
    },
    {
      "label": "Adygeja",
      "value": "Adygeja",
      "country_id": "Russia"
    },
    {
      "label": "Aga",
      "value": "Aga",
      "country_id": "Russia"
    },
    {
      "label": "Alanija",
      "value": "Alanija",
      "country_id": "Russia"
    },
    {
      "label": "Altaj",
      "value": "Altaj",
      "country_id": "Russia"
    },
    {
      "label": "Amur",
      "value": "Amur",
      "country_id": "Russia"
    },
    {
      "label": "Arhangelsk",
      "value": "Arhangelsk",
      "country_id": "Russia"
    },
    {
      "label": "Astrahan",
      "value": "Astrahan",
      "country_id": "Russia"
    },
    {
      "label": "Bashkortostan",
      "value": "Bashkortostan",
      "country_id": "Russia"
    },
    {
      "label": "Belgorod",
      "value": "Belgorod",
      "country_id": "Russia"
    },
    {
      "label": "Brjansk",
      "value": "Brjansk",
      "country_id": "Russia"
    },
    {
      "label": "Burjatija",
      "value": "Burjatija",
      "country_id": "Russia"
    },
    {
      "label": "Chechenija",
      "value": "Chechenija",
      "country_id": "Russia"
    },
    {
      "label": "Cheljabinsk",
      "value": "Cheljabinsk",
      "country_id": "Russia"
    },
    {
      "label": "Chita",
      "value": "Chita",
      "country_id": "Russia"
    },
    {
      "label": "Chukotka",
      "value": "Chukotka",
      "country_id": "Russia"
    },
    {
      "label": "Chuvashija",
      "value": "Chuvashija",
      "country_id": "Russia"
    },
    {
      "label": "Dagestan",
      "value": "Dagestan",
      "country_id": "Russia"
    },
    {
      "label": "Evenkija",
      "value": "Evenkija",
      "country_id": "Russia"
    },
    {
      "label": "Gorno-Altaj",
      "value": "Gorno-Altaj",
      "country_id": "Russia"
    },
    {
      "label": "Habarovsk",
      "value": "Habarovsk",
      "country_id": "Russia"
    },
    {
      "label": "Hakasija",
      "value": "Hakasija",
      "country_id": "Russia"
    },
    {
      "label": "Hanty-Mansija",
      "value": "Hanty-Mansija",
      "country_id": "Russia"
    },
    {
      "label": "Ingusetija",
      "value": "Ingusetija",
      "country_id": "Russia"
    },
    {
      "label": "Irkutsk",
      "value": "Irkutsk",
      "country_id": "Russia"
    },
    {
      "label": "Ivanovo",
      "value": "Ivanovo",
      "country_id": "Russia"
    },
    {
      "label": "Jamalo-Nenets",
      "value": "Jamalo-Nenets",
      "country_id": "Russia"
    },
    {
      "label": "Jaroslavl",
      "value": "Jaroslavl",
      "country_id": "Russia"
    },
    {
      "label": "Jevrej",
      "value": "Jevrej",
      "country_id": "Russia"
    },
    {
      "label": "Kabardino-Balkarija",
      "value": "Kabardino-Balkarija",
      "country_id": "Russia"
    },
    {
      "label": "Kaliningrad",
      "value": "Kaliningrad",
      "country_id": "Russia"
    },
    {
      "label": "Kalmykija",
      "value": "Kalmykija",
      "country_id": "Russia"
    },
    {
      "label": "Kaluga",
      "value": "Kaluga",
      "country_id": "Russia"
    },
    {
      "label": "Kamchatka",
      "value": "Kamchatka",
      "country_id": "Russia"
    },
    {
      "label": "Karachaj-Cherkessija",
      "value": "Karachaj-Cherkessija",
      "country_id": "Russia"
    },
    {
      "label": "Karelija",
      "value": "Karelija",
      "country_id": "Russia"
    },
    {
      "label": "Kemerovo",
      "value": "Kemerovo",
      "country_id": "Russia"
    },
    {
      "label": "Khabarovskiy Kray",
      "value": "Khabarovskiy Kray",
      "country_id": "Russia"
    },
    {
      "label": "Kirov",
      "value": "Kirov",
      "country_id": "Russia"
    },
    {
      "label": "Komi",
      "value": "Komi",
      "country_id": "Russia"
    },
    {
      "label": "Komi-Permjakija",
      "value": "Komi-Permjakija",
      "country_id": "Russia"
    },
    {
      "label": "Korjakija",
      "value": "Korjakija",
      "country_id": "Russia"
    },
    {
      "label": "Kostroma",
      "value": "Kostroma",
      "country_id": "Russia"
    },
    {
      "label": "Krasnodar",
      "value": "Krasnodar",
      "country_id": "Russia"
    },
    {
      "label": "Krasnojarsk",
      "value": "Krasnojarsk",
      "country_id": "Russia"
    },
    {
      "label": "Krasnoyarskiy Kray",
      "value": "Krasnoyarskiy Kray",
      "country_id": "Russia"
    },
    {
      "label": "Kurgan",
      "value": "Kurgan",
      "country_id": "Russia"
    },
    {
      "label": "Kursk",
      "value": "Kursk",
      "country_id": "Russia"
    },
    {
      "label": "Leningrad",
      "value": "Leningrad",
      "country_id": "Russia"
    },
    {
      "label": "Lipeck",
      "value": "Lipeck",
      "country_id": "Russia"
    },
    {
      "label": "Magadan",
      "value": "Magadan",
      "country_id": "Russia"
    },
    {
      "label": "Marij El",
      "value": "Marij El",
      "country_id": "Russia"
    },
    {
      "label": "Mordovija",
      "value": "Mordovija",
      "country_id": "Russia"
    },
    {
      "label": "Moscow",
      "value": "Moscow",
      "country_id": "Russia"
    },
    {
      "label": "Moskovskaja Oblast",
      "value": "Moskovskaja Oblast",
      "country_id": "Russia"
    },
    {
      "label": "Moskovskaya Oblast",
      "value": "Moskovskaya Oblast",
      "country_id": "Russia"
    },
    {
      "label": "Moskva",
      "value": "Moskva",
      "country_id": "Russia"
    },
    {
      "label": "Murmansk",
      "value": "Murmansk",
      "country_id": "Russia"
    },
    {
      "label": "Nenets",
      "value": "Nenets",
      "country_id": "Russia"
    },
    {
      "label": "Nizhnij Novgorod",
      "value": "Nizhnij Novgorod",
      "country_id": "Russia"
    },
    {
      "label": "Novgorod",
      "value": "Novgorod",
      "country_id": "Russia"
    },
    {
      "label": "Novokusnezk",
      "value": "Novokusnezk",
      "country_id": "Russia"
    },
    {
      "label": "Novosibirsk",
      "value": "Novosibirsk",
      "country_id": "Russia"
    },
    {
      "label": "Omsk",
      "value": "Omsk",
      "country_id": "Russia"
    },
    {
      "label": "Orenburg",
      "value": "Orenburg",
      "country_id": "Russia"
    },
    {
      "label": "Orjol",
      "value": "Orjol",
      "country_id": "Russia"
    },
    {
      "label": "Penza",
      "value": "Penza",
      "country_id": "Russia"
    },
    {
      "label": "Perm",
      "value": "Perm",
      "country_id": "Russia"
    },
    {
      "label": "Primorje",
      "value": "Primorje",
      "country_id": "Russia"
    },
    {
      "label": "Pskov",
      "value": "Pskov",
      "country_id": "Russia"
    },
    {
      "label": "Pskovskaya Oblast",
      "value": "Pskovskaya Oblast",
      "country_id": "Russia"
    },
    {
      "label": "Rjazan",
      "value": "Rjazan",
      "country_id": "Russia"
    },
    {
      "label": "Rostov",
      "value": "Rostov",
      "country_id": "Russia"
    },
    {
      "label": "Saha",
      "value": "Saha",
      "country_id": "Russia"
    },
    {
      "label": "Sahalin",
      "value": "Sahalin",
      "country_id": "Russia"
    },
    {
      "label": "Samara",
      "value": "Samara",
      "country_id": "Russia"
    },
    {
      "label": "Samarskaya",
      "value": "Samarskaya",
      "country_id": "Russia"
    },
    {
      "label": "Sankt-Peterburg",
      "value": "Sankt-Peterburg",
      "country_id": "Russia"
    },
    {
      "label": "Saratov",
      "value": "Saratov",
      "country_id": "Russia"
    },
    {
      "label": "Smolensk",
      "value": "Smolensk",
      "country_id": "Russia"
    },
    {
      "label": "Stavropol",
      "value": "Stavropol",
      "country_id": "Russia"
    },
    {
      "label": "Sverdlovsk",
      "value": "Sverdlovsk",
      "country_id": "Russia"
    },
    {
      "label": "Tajmyrija",
      "value": "Tajmyrija",
      "country_id": "Russia"
    },
    {
      "label": "Tambov",
      "value": "Tambov",
      "country_id": "Russia"
    },
    {
      "label": "Tatarstan",
      "value": "Tatarstan",
      "country_id": "Russia"
    },
    {
      "label": "Tjumen",
      "value": "Tjumen",
      "country_id": "Russia"
    },
    {
      "label": "Tomsk",
      "value": "Tomsk",
      "country_id": "Russia"
    },
    {
      "label": "Tula",
      "value": "Tula",
      "country_id": "Russia"
    },
    {
      "label": "Tver",
      "value": "Tver",
      "country_id": "Russia"
    },
    {
      "label": "Tyva",
      "value": "Tyva",
      "country_id": "Russia"
    },
    {
      "label": "Udmurtija",
      "value": "Udmurtija",
      "country_id": "Russia"
    },
    {
      "label": "Uljanovsk",
      "value": "Uljanovsk",
      "country_id": "Russia"
    },
    {
      "label": "Ulyanovskaya Oblast",
      "value": "Ulyanovskaya Oblast",
      "country_id": "Russia"
    },
    {
      "label": "Ust-Orda",
      "value": "Ust-Orda",
      "country_id": "Russia"
    },
    {
      "label": "Vladimir",
      "value": "Vladimir",
      "country_id": "Russia"
    },
    {
      "label": "Volgograd",
      "value": "Volgograd",
      "country_id": "Russia"
    },
    {
      "label": "Vologda",
      "value": "Vologda",
      "country_id": "Russia"
    },
    {
      "label": "Voronezh",
      "value": "Voronezh",
      "country_id": "Russia"
    },
    {
      "label": "Butare",
      "value": "Butare",
      "country_id": "Rwanda"
    },
    {
      "label": "Byumba",
      "value": "Byumba",
      "country_id": "Rwanda"
    },
    {
      "label": "Cyangugu",
      "value": "Cyangugu",
      "country_id": "Rwanda"
    },
    {
      "label": "Gikongoro",
      "value": "Gikongoro",
      "country_id": "Rwanda"
    },
    {
      "label": "Gisenyi",
      "value": "Gisenyi",
      "country_id": "Rwanda"
    },
    {
      "label": "Gitarama",
      "value": "Gitarama",
      "country_id": "Rwanda"
    },
    {
      "label": "Kibungo",
      "value": "Kibungo",
      "country_id": "Rwanda"
    },
    {
      "label": "Kibuye",
      "value": "Kibuye",
      "country_id": "Rwanda"
    },
    {
      "label": "Kigali-ngali",
      "value": "Kigali-ngali",
      "country_id": "Rwanda"
    },
    {
      "label": "Ruhengeri",
      "value": "Ruhengeri",
      "country_id": "Rwanda"
    },
    {
      "label": "Ascension",
      "value": "Ascension",
      "country_id": "Saint Helena"
    },
    {
      "label": "Gough Island",
      "value": "Gough Island",
      "country_id": "Saint Helena"
    },
    {
      "label": "Saint Helena",
      "value": "Saint Helena",
      "country_id": "Saint Helena"
    },
    {
      "label": "Tristan da Cunha",
      "value": "Tristan da Cunha",
      "country_id": "Saint Helena"
    },
    {
      "label": "Christ Church Nichola Town",
      "value": "Christ Church Nichola Town",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Anne Sandy Point",
      "value": "Saint Anne Sandy Point",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint George Basseterre",
      "value": "Saint George Basseterre",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint George Gingerland",
      "value": "Saint George Gingerland",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint James Windward",
      "value": "Saint James Windward",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint John Capesterre",
      "value": "Saint John Capesterre",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint John Figtree",
      "value": "Saint John Figtree",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Mary Cayon",
      "value": "Saint Mary Cayon",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Paul Capesterre",
      "value": "Saint Paul Capesterre",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Paul Charlestown",
      "value": "Saint Paul Charlestown",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Peter Basseterre",
      "value": "Saint Peter Basseterre",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Thomas Lowland",
      "value": "Saint Thomas Lowland",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Saint Thomas Middle Island",
      "value": "Saint Thomas Middle Island",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Trinity Palmetto Point",
      "value": "Trinity Palmetto Point",
      "country_id": "Saint Kitts And Nevis"
    },
    {
      "label": "Anse-la-Raye",
      "value": "Anse-la-Raye",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Canaries",
      "value": "Canaries",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Castries",
      "value": "Castries",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Choiseul",
      "value": "Choiseul",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Dennery",
      "value": "Dennery",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Gros Inlet",
      "value": "Gros Inlet",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Laborie",
      "value": "Laborie",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Micoud",
      "value": "Micoud",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Soufriere",
      "value": "Soufriere",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Vieux Fort",
      "value": "Vieux Fort",
      "country_id": "Saint Lucia"
    },
    {
      "label": "Miquelon-Langlade",
      "value": "Miquelon-Langlade",
      "country_id": "Saint Pierre and Miquelon"
    },
    {
      "label": "Saint-Pierre",
      "value": "Saint-Pierre",
      "country_id": "Saint Pierre and Miquelon"
    },
    {
      "label": "Charlotte",
      "value": "Charlotte",
      "country_id": "Saint Vincent And The Grenadines"
    },
    {
      "label": "Grenadines",
      "value": "Grenadines",
      "country_id": "Saint Vincent And The Grenadines"
    },
    {
      "label": "Saint Andrew",
      "value": "Saint Andrew",
      "country_id": "Saint Vincent And The Grenadines"
    },
    {
      "label": "Saint David",
      "value": "Saint David",
      "country_id": "Saint Vincent And The Grenadines"
    },
    {
      "label": "Saint George",
      "value": "Saint George",
      "country_id": "Saint Vincent And The Grenadines"
    },
    {
      "label": "Saint Patrick",
      "value": "Saint Patrick",
      "country_id": "Saint Vincent And The Grenadines"
    },
    {
      "label": "A''ana",
      "value": "A''ana",
      "country_id": "Samoa"
    },
    {
      "label": "Aiga-i-le-Tai",
      "value": "Aiga-i-le-Tai",
      "country_id": "Samoa"
    },
    {
      "label": "Atua",
      "value": "Atua",
      "country_id": "Samoa"
    },
    {
      "label": "Fa''asaleleaga",
      "value": "Fa''asaleleaga",
      "country_id": "Samoa"
    },
    {
      "label": "Gaga''emauga",
      "value": "Gaga''emauga",
      "country_id": "Samoa"
    },
    {
      "label": "Gagaifomauga",
      "value": "Gagaifomauga",
      "country_id": "Samoa"
    },
    {
      "label": "Palauli",
      "value": "Palauli",
      "country_id": "Samoa"
    },
    {
      "label": "Satupa''itea",
      "value": "Satupa''itea",
      "country_id": "Samoa"
    },
    {
      "label": "Tuamasaga",
      "value": "Tuamasaga",
      "country_id": "Samoa"
    },
    {
      "label": "Va''a-o-Fonoti",
      "value": "Va''a-o-Fonoti",
      "country_id": "Samoa"
    },
    {
      "label": "Vaisigano",
      "value": "Vaisigano",
      "country_id": "Samoa"
    },
    {
      "label": "Acquaviva",
      "value": "Acquaviva",
      "country_id": "San Marino"
    },
    {
      "label": "Borgo Maggiore",
      "value": "Borgo Maggiore",
      "country_id": "San Marino"
    },
    {
      "label": "Chiesanuova",
      "value": "Chiesanuova",
      "country_id": "San Marino"
    },
    {
      "label": "Domagnano",
      "value": "Domagnano",
      "country_id": "San Marino"
    },
    {
      "label": "Faetano",
      "value": "Faetano",
      "country_id": "San Marino"
    },
    {
      "label": "Fiorentino",
      "value": "Fiorentino",
      "country_id": "San Marino"
    },
    {
      "label": "Montegiardino",
      "value": "Montegiardino",
      "country_id": "San Marino"
    },
    {
      "label": "San Marino",
      "value": "San Marino",
      "country_id": "San Marino"
    },
    {
      "label": "Serravalle",
      "value": "Serravalle",
      "country_id": "San Marino"
    },
    {
      "label": "Agua Grande",
      "value": "Agua Grande",
      "country_id": "Sao Tome and Principe"
    },
    {
      "label": "Cantagalo",
      "value": "Cantagalo",
      "country_id": "Sao Tome and Principe"
    },
    {
      "label": "Lemba",
      "value": "Lemba",
      "country_id": "Sao Tome and Principe"
    },
    {
      "label": "Lobata",
      "value": "Lobata",
      "country_id": "Sao Tome and Principe"
    },
    {
      "label": "Me-Zochi",
      "value": "Me-Zochi",
      "country_id": "Sao Tome and Principe"
    },
    {
      "label": "Pague",
      "value": "Pague",
      "country_id": "Sao Tome and Principe"
    },
    {
      "label": "Al Khobar",
      "value": "Al Khobar",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Aseer",
      "value": "Aseer",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Ash Sharqiyah",
      "value": "Ash Sharqiyah",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Asir",
      "value": "Asir",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Central Province",
      "value": "Central Province",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Eastern Province",
      "value": "Eastern Province",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Ha''il",
      "value": "Ha''il",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Jawf",
      "value": "Jawf",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Jizan",
      "value": "Jizan",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Makkah",
      "value": "Makkah",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Najran",
      "value": "Najran",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Qasim",
      "value": "Qasim",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Tabuk",
      "value": "Tabuk",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Western Province",
      "value": "Western Province",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "al-Bahah",
      "value": "al-Bahah",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "al-Hudud-ash-Shamaliyah",
      "value": "al-Hudud-ash-Shamaliyah",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "al-Madinah",
      "value": "al-Madinah",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "ar-Riyad",
      "value": "ar-Riyad",
      "country_id": "Saudi Arabia"
    },
    {
      "label": "Dakar",
      "value": "Dakar",
      "country_id": "Senegal"
    },
    {
      "label": "Diourbel",
      "value": "Diourbel",
      "country_id": "Senegal"
    },
    {
      "label": "Fatick",
      "value": "Fatick",
      "country_id": "Senegal"
    },
    {
      "label": "Kaolack",
      "value": "Kaolack",
      "country_id": "Senegal"
    },
    {
      "label": "Kolda",
      "value": "Kolda",
      "country_id": "Senegal"
    },
    {
      "label": "Louga",
      "value": "Louga",
      "country_id": "Senegal"
    },
    {
      "label": "Saint-Louis",
      "value": "Saint-Louis",
      "country_id": "Senegal"
    },
    {
      "label": "Tambacounda",
      "value": "Tambacounda",
      "country_id": "Senegal"
    },
    {
      "label": "Thies",
      "value": "Thies",
      "country_id": "Senegal"
    },
    {
      "label": "Ziguinchor",
      "value": "Ziguinchor",
      "country_id": "Senegal"
    },
    {
      "label": "Central Serbia",
      "value": "Central Serbia",
      "country_id": "Serbia"
    },
    {
      "label": "Kosovo and Metohija",
      "value": "Kosovo and Metohija",
      "country_id": "Serbia"
    },
    {
      "label": "Vojvodina",
      "value": "Vojvodina",
      "country_id": "Serbia"
    },
    {
      "label": "Anse Boileau",
      "value": "Anse Boileau",
      "country_id": "Seychelles"
    },
    {
      "label": "Anse Royale",
      "value": "Anse Royale",
      "country_id": "Seychelles"
    },
    {
      "label": "Cascade",
      "value": "Cascade",
      "country_id": "Seychelles"
    },
    {
      "label": "Takamaka",
      "value": "Takamaka",
      "country_id": "Seychelles"
    },
    {
      "label": "Victoria",
      "value": "Victoria",
      "country_id": "Seychelles"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "Sierra Leone"
    },
    {
      "label": "Northern",
      "value": "Northern",
      "country_id": "Sierra Leone"
    },
    {
      "label": "Southern",
      "value": "Southern",
      "country_id": "Sierra Leone"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Sierra Leone"
    },
    {
      "label": "Singapore",
      "value": "Singapore",
      "country_id": "Singapore"
    },
    {
      "label": "Banskobystricky",
      "value": "Banskobystricky",
      "country_id": "Slovakia"
    },
    {
      "label": "Bratislavsky",
      "value": "Bratislavsky",
      "country_id": "Slovakia"
    },
    {
      "label": "Kosicky",
      "value": "Kosicky",
      "country_id": "Slovakia"
    },
    {
      "label": "Nitriansky",
      "value": "Nitriansky",
      "country_id": "Slovakia"
    },
    {
      "label": "Presovsky",
      "value": "Presovsky",
      "country_id": "Slovakia"
    },
    {
      "label": "Trenciansky",
      "value": "Trenciansky",
      "country_id": "Slovakia"
    },
    {
      "label": "Trnavsky",
      "value": "Trnavsky",
      "country_id": "Slovakia"
    },
    {
      "label": "Zilinsky",
      "value": "Zilinsky",
      "country_id": "Slovakia"
    },
    {
      "label": "Benedikt",
      "value": "Benedikt",
      "country_id": "Slovenia"
    },
    {
      "label": "Gorenjska",
      "value": "Gorenjska",
      "country_id": "Slovenia"
    },
    {
      "label": "Gorishka",
      "value": "Gorishka",
      "country_id": "Slovenia"
    },
    {
      "label": "Jugovzhodna Slovenija",
      "value": "Jugovzhodna Slovenija",
      "country_id": "Slovenia"
    },
    {
      "label": "Koroshka",
      "value": "Koroshka",
      "country_id": "Slovenia"
    },
    {
      "label": "Notranjsko-krashka",
      "value": "Notranjsko-krashka",
      "country_id": "Slovenia"
    },
    {
      "label": "Obalno-krashka",
      "value": "Obalno-krashka",
      "country_id": "Slovenia"
    },
    {
      "label": "Obcina Domzale",
      "value": "Obcina Domzale",
      "country_id": "Slovenia"
    },
    {
      "label": "Obcina Vitanje",
      "value": "Obcina Vitanje",
      "country_id": "Slovenia"
    },
    {
      "label": "Osrednjeslovenska",
      "value": "Osrednjeslovenska",
      "country_id": "Slovenia"
    },
    {
      "label": "Podravska",
      "value": "Podravska",
      "country_id": "Slovenia"
    },
    {
      "label": "Pomurska",
      "value": "Pomurska",
      "country_id": "Slovenia"
    },
    {
      "label": "Savinjska",
      "value": "Savinjska",
      "country_id": "Slovenia"
    },
    {
      "label": "Slovenian Littoral",
      "value": "Slovenian Littoral",
      "country_id": "Slovenia"
    },
    {
      "label": "Spodnjeposavska",
      "value": "Spodnjeposavska",
      "country_id": "Slovenia"
    },
    {
      "label": "Zasavska",
      "value": "Zasavska",
      "country_id": "Slovenia"
    },
    {
      "label": "Pitcairn",
      "value": "Pitcairn",
      "country_id": "Smaller Territories of the UK"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Choiseul",
      "value": "Choiseul",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Guadalcanal",
      "value": "Guadalcanal",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Isabel",
      "value": "Isabel",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Makira and Ulawa",
      "value": "Makira and Ulawa",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Malaita",
      "value": "Malaita",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Rennell and Bellona",
      "value": "Rennell and Bellona",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Temotu",
      "value": "Temotu",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Solomon Islands"
    },
    {
      "label": "Awdal",
      "value": "Awdal",
      "country_id": "Somalia"
    },
    {
      "label": "Bakol",
      "value": "Bakol",
      "country_id": "Somalia"
    },
    {
      "label": "Banadir",
      "value": "Banadir",
      "country_id": "Somalia"
    },
    {
      "label": "Bari",
      "value": "Bari",
      "country_id": "Somalia"
    },
    {
      "label": "Bay",
      "value": "Bay",
      "country_id": "Somalia"
    },
    {
      "label": "Galgudug",
      "value": "Galgudug",
      "country_id": "Somalia"
    },
    {
      "label": "Gedo",
      "value": "Gedo",
      "country_id": "Somalia"
    },
    {
      "label": "Hiran",
      "value": "Hiran",
      "country_id": "Somalia"
    },
    {
      "label": "Jubbada Hose",
      "value": "Jubbada Hose",
      "country_id": "Somalia"
    },
    {
      "label": "Jubbadha Dexe",
      "value": "Jubbadha Dexe",
      "country_id": "Somalia"
    },
    {
      "label": "Mudug",
      "value": "Mudug",
      "country_id": "Somalia"
    },
    {
      "label": "Nugal",
      "value": "Nugal",
      "country_id": "Somalia"
    },
    {
      "label": "Sanag",
      "value": "Sanag",
      "country_id": "Somalia"
    },
    {
      "label": "Shabellaha Dhexe",
      "value": "Shabellaha Dhexe",
      "country_id": "Somalia"
    },
    {
      "label": "Shabellaha Hose",
      "value": "Shabellaha Hose",
      "country_id": "Somalia"
    },
    {
      "label": "Togdher",
      "value": "Togdher",
      "country_id": "Somalia"
    },
    {
      "label": "Woqoyi Galbed",
      "value": "Woqoyi Galbed",
      "country_id": "Somalia"
    },
    {
      "label": "Eastern Cape",
      "value": "Eastern Cape",
      "country_id": "South Africa"
    },
    {
      "label": "Free State",
      "value": "Free State",
      "country_id": "South Africa"
    },
    {
      "label": "Gauteng",
      "value": "Gauteng",
      "country_id": "South Africa"
    },
    {
      "label": "Kempton Park",
      "value": "Kempton Park",
      "country_id": "South Africa"
    },
    {
      "label": "Kramerville",
      "value": "Kramerville",
      "country_id": "South Africa"
    },
    {
      "label": "KwaZulu Natal",
      "value": "KwaZulu Natal",
      "country_id": "South Africa"
    },
    {
      "label": "Limpopo",
      "value": "Limpopo",
      "country_id": "South Africa"
    },
    {
      "label": "Mpumalanga",
      "value": "Mpumalanga",
      "country_id": "South Africa"
    },
    {
      "label": "North West",
      "value": "North West",
      "country_id": "South Africa"
    },
    {
      "label": "Northern Cape",
      "value": "Northern Cape",
      "country_id": "South Africa"
    },
    {
      "label": "Parow",
      "value": "Parow",
      "country_id": "South Africa"
    },
    {
      "label": "Table View",
      "value": "Table View",
      "country_id": "South Africa"
    },
    {
      "label": "Umtentweni",
      "value": "Umtentweni",
      "country_id": "South Africa"
    },
    {
      "label": "Western Cape",
      "value": "Western Cape",
      "country_id": "South Africa"
    },
    {
      "label": "South Georgia",
      "value": "South Georgia",
      "country_id": "South Georgia"
    },
    {
      "label": "Central Equatoria",
      "value": "Central Equatoria",
      "country_id": "South Sudan"
    },
    {
      "label": "A Coruna",
      "value": "A Coruna",
      "country_id": "Spain"
    },
    {
      "label": "Alacant",
      "value": "Alacant",
      "country_id": "Spain"
    },
    {
      "label": "Alava",
      "value": "Alava",
      "country_id": "Spain"
    },
    {
      "label": "Albacete",
      "value": "Albacete",
      "country_id": "Spain"
    },
    {
      "label": "Almeria",
      "value": "Almeria",
      "country_id": "Spain"
    },
    {
      "label": "Andalucia",
      "value": "Andalucia",
      "country_id": "Spain"
    },
    {
      "label": "Asturias",
      "value": "Asturias",
      "country_id": "Spain"
    },
    {
      "label": "Avila",
      "value": "Avila",
      "country_id": "Spain"
    },
    {
      "label": "Badajoz",
      "value": "Badajoz",
      "country_id": "Spain"
    },
    {
      "label": "Balears",
      "value": "Balears",
      "country_id": "Spain"
    },
    {
      "label": "Barcelona",
      "value": "Barcelona",
      "country_id": "Spain"
    },
    {
      "label": "Bertamirans",
      "value": "Bertamirans",
      "country_id": "Spain"
    },
    {
      "label": "Biscay",
      "value": "Biscay",
      "country_id": "Spain"
    },
    {
      "label": "Burgos",
      "value": "Burgos",
      "country_id": "Spain"
    },
    {
      "label": "Caceres",
      "value": "Caceres",
      "country_id": "Spain"
    },
    {
      "label": "Cadiz",
      "value": "Cadiz",
      "country_id": "Spain"
    },
    {
      "label": "Cantabria",
      "value": "Cantabria",
      "country_id": "Spain"
    },
    {
      "label": "Castello",
      "value": "Castello",
      "country_id": "Spain"
    },
    {
      "label": "Catalunya",
      "value": "Catalunya",
      "country_id": "Spain"
    },
    {
      "label": "Ceuta",
      "value": "Ceuta",
      "country_id": "Spain"
    },
    {
      "label": "Ciudad Real",
      "value": "Ciudad Real",
      "country_id": "Spain"
    },
    {
      "label": "Comunidad Autonoma de Canarias",
      "value": "Comunidad Autonoma de Canarias",
      "country_id": "Spain"
    },
    {
      "label": "Comunidad Autonoma de Cataluna",
      "value": "Comunidad Autonoma de Cataluna",
      "country_id": "Spain"
    },
    {
      "label": "Comunidad Autonoma de Galicia",
      "value": "Comunidad Autonoma de Galicia",
      "country_id": "Spain"
    },
    {
      "label": "Comunidad Autonoma de las Isla",
      "value": "Comunidad Autonoma de las Isla",
      "country_id": "Spain"
    },
    {
      "label": "Comunidad Autonoma del Princip",
      "value": "Comunidad Autonoma del Princip",
      "country_id": "Spain"
    },
    {
      "label": "Comunidad Valenciana",
      "value": "Comunidad Valenciana",
      "country_id": "Spain"
    },
    {
      "label": "Cordoba",
      "value": "Cordoba",
      "country_id": "Spain"
    },
    {
      "label": "Cuenca",
      "value": "Cuenca",
      "country_id": "Spain"
    },
    {
      "label": "Gipuzkoa",
      "value": "Gipuzkoa",
      "country_id": "Spain"
    },
    {
      "label": "Girona",
      "value": "Girona",
      "country_id": "Spain"
    },
    {
      "label": "Granada",
      "value": "Granada",
      "country_id": "Spain"
    },
    {
      "label": "Guadalajara",
      "value": "Guadalajara",
      "country_id": "Spain"
    },
    {
      "label": "Guipuzcoa",
      "value": "Guipuzcoa",
      "country_id": "Spain"
    },
    {
      "label": "Huelva",
      "value": "Huelva",
      "country_id": "Spain"
    },
    {
      "label": "Huesca",
      "value": "Huesca",
      "country_id": "Spain"
    },
    {
      "label": "Jaen",
      "value": "Jaen",
      "country_id": "Spain"
    },
    {
      "label": "La Rioja",
      "value": "La Rioja",
      "country_id": "Spain"
    },
    {
      "label": "Las Palmas",
      "value": "Las Palmas",
      "country_id": "Spain"
    },
    {
      "label": "Leon",
      "value": "Leon",
      "country_id": "Spain"
    },
    {
      "label": "Lerida",
      "value": "Lerida",
      "country_id": "Spain"
    },
    {
      "label": "Lleida",
      "value": "Lleida",
      "country_id": "Spain"
    },
    {
      "label": "Lugo",
      "value": "Lugo",
      "country_id": "Spain"
    },
    {
      "label": "Madrid",
      "value": "Madrid",
      "country_id": "Spain"
    },
    {
      "label": "Malaga",
      "value": "Malaga",
      "country_id": "Spain"
    },
    {
      "label": "Melilla",
      "value": "Melilla",
      "country_id": "Spain"
    },
    {
      "label": "Murcia",
      "value": "Murcia",
      "country_id": "Spain"
    },
    {
      "label": "Navarra",
      "value": "Navarra",
      "country_id": "Spain"
    },
    {
      "label": "Ourense",
      "value": "Ourense",
      "country_id": "Spain"
    },
    {
      "label": "Pais Vasco",
      "value": "Pais Vasco",
      "country_id": "Spain"
    },
    {
      "label": "Palencia",
      "value": "Palencia",
      "country_id": "Spain"
    },
    {
      "label": "Pontevedra",
      "value": "Pontevedra",
      "country_id": "Spain"
    },
    {
      "label": "Salamanca",
      "value": "Salamanca",
      "country_id": "Spain"
    },
    {
      "label": "Santa Cruz de Tenerife",
      "value": "Santa Cruz de Tenerife",
      "country_id": "Spain"
    },
    {
      "label": "Segovia",
      "value": "Segovia",
      "country_id": "Spain"
    },
    {
      "label": "Sevilla",
      "value": "Sevilla",
      "country_id": "Spain"
    },
    {
      "label": "Soria",
      "value": "Soria",
      "country_id": "Spain"
    },
    {
      "label": "Tarragona",
      "value": "Tarragona",
      "country_id": "Spain"
    },
    {
      "label": "Tenerife",
      "value": "Tenerife",
      "country_id": "Spain"
    },
    {
      "label": "Teruel",
      "value": "Teruel",
      "country_id": "Spain"
    },
    {
      "label": "Toledo",
      "value": "Toledo",
      "country_id": "Spain"
    },
    {
      "label": "Valencia",
      "value": "Valencia",
      "country_id": "Spain"
    },
    {
      "label": "Valladolid",
      "value": "Valladolid",
      "country_id": "Spain"
    },
    {
      "label": "Vizcaya",
      "value": "Vizcaya",
      "country_id": "Spain"
    },
    {
      "label": "Zamora",
      "value": "Zamora",
      "country_id": "Spain"
    },
    {
      "label": "Zaragoza",
      "value": "Zaragoza",
      "country_id": "Spain"
    },
    {
      "label": "Amparai",
      "value": "Amparai",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Anuradhapuraya",
      "value": "Anuradhapuraya",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Badulla",
      "value": "Badulla",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Boralesgamuwa",
      "value": "Boralesgamuwa",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Colombo",
      "value": "Colombo",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Galla",
      "value": "Galla",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Gampaha",
      "value": "Gampaha",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Hambantota",
      "value": "Hambantota",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Kalatura",
      "value": "Kalatura",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Kegalla",
      "value": "Kegalla",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Kilinochchi",
      "value": "Kilinochchi",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Kurunegala",
      "value": "Kurunegala",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Madakalpuwa",
      "value": "Madakalpuwa",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Maha Nuwara",
      "value": "Maha Nuwara",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Malwana",
      "value": "Malwana",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Mannarama",
      "value": "Mannarama",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Matale",
      "value": "Matale",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Matara",
      "value": "Matara",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Monaragala",
      "value": "Monaragala",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Mullaitivu",
      "value": "Mullaitivu",
      "country_id": "Sri Lanka"
    },
    {
      "label": "North Eastern Province",
      "value": "North Eastern Province",
      "country_id": "Sri Lanka"
    },
    {
      "label": "North Western Province",
      "value": "North Western Province",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Nuwara Eliya",
      "value": "Nuwara Eliya",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Polonnaruwa",
      "value": "Polonnaruwa",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Puttalama",
      "value": "Puttalama",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Ratnapuraya",
      "value": "Ratnapuraya",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Southern Province",
      "value": "Southern Province",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Tirikunamalaya",
      "value": "Tirikunamalaya",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Tuscany",
      "value": "Tuscany",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Vavuniyawa",
      "value": "Vavuniyawa",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Western Province",
      "value": "Western Province",
      "country_id": "Sri Lanka"
    },
    {
      "label": "Yapanaya",
      "value": "Yapanaya",
      "country_id": "Sri Lanka"
    },
    {
      "label": "kadawatha",
      "value": "kadawatha",
      "country_id": "Sri Lanka"
    },
    {
      "label": "A''ali-an-Nil",
      "value": "A''ali-an-Nil",
      "country_id": "Sudan"
    },
    {
      "label": "Bahr-al-Jabal",
      "value": "Bahr-al-Jabal",
      "country_id": "Sudan"
    },
    {
      "label": "Central Equatoria",
      "value": "Central Equatoria",
      "country_id": "Sudan"
    },
    {
      "label": "Gharb Bahr-al-Ghazal",
      "value": "Gharb Bahr-al-Ghazal",
      "country_id": "Sudan"
    },
    {
      "label": "Gharb Darfur",
      "value": "Gharb Darfur",
      "country_id": "Sudan"
    },
    {
      "label": "Gharb Kurdufan",
      "value": "Gharb Kurdufan",
      "country_id": "Sudan"
    },
    {
      "label": "Gharb-al-Istiwa''iyah",
      "value": "Gharb-al-Istiwa''iyah",
      "country_id": "Sudan"
    },
    {
      "label": "Janub Darfur",
      "value": "Janub Darfur",
      "country_id": "Sudan"
    },
    {
      "label": "Janub Kurdufan",
      "value": "Janub Kurdufan",
      "country_id": "Sudan"
    },
    {
      "label": "Junqali",
      "value": "Junqali",
      "country_id": "Sudan"
    },
    {
      "label": "Kassala",
      "value": "Kassala",
      "country_id": "Sudan"
    },
    {
      "label": "Nahr-an-Nil",
      "value": "Nahr-an-Nil",
      "country_id": "Sudan"
    },
    {
      "label": "Shamal Bahr-al-Ghazal",
      "value": "Shamal Bahr-al-Ghazal",
      "country_id": "Sudan"
    },
    {
      "label": "Shamal Darfur",
      "value": "Shamal Darfur",
      "country_id": "Sudan"
    },
    {
      "label": "Shamal Kurdufan",
      "value": "Shamal Kurdufan",
      "country_id": "Sudan"
    },
    {
      "label": "Sharq-al-Istiwa''iyah",
      "value": "Sharq-al-Istiwa''iyah",
      "country_id": "Sudan"
    },
    {
      "label": "Sinnar",
      "value": "Sinnar",
      "country_id": "Sudan"
    },
    {
      "label": "Warab",
      "value": "Warab",
      "country_id": "Sudan"
    },
    {
      "label": "Wilayat al Khartum",
      "value": "Wilayat al Khartum",
      "country_id": "Sudan"
    },
    {
      "label": "al-Bahr-al-Ahmar",
      "value": "al-Bahr-al-Ahmar",
      "country_id": "Sudan"
    },
    {
      "label": "al-Buhayrat",
      "value": "al-Buhayrat",
      "country_id": "Sudan"
    },
    {
      "label": "al-Jazirah",
      "value": "al-Jazirah",
      "country_id": "Sudan"
    },
    {
      "label": "al-Khartum",
      "value": "al-Khartum",
      "country_id": "Sudan"
    },
    {
      "label": "al-Qadarif",
      "value": "al-Qadarif",
      "country_id": "Sudan"
    },
    {
      "label": "al-Wahdah",
      "value": "al-Wahdah",
      "country_id": "Sudan"
    },
    {
      "label": "an-Nil-al-Abyad",
      "value": "an-Nil-al-Abyad",
      "country_id": "Sudan"
    },
    {
      "label": "an-Nil-al-Azraq",
      "value": "an-Nil-al-Azraq",
      "country_id": "Sudan"
    },
    {
      "label": "ash-Shamaliyah",
      "value": "ash-Shamaliyah",
      "country_id": "Sudan"
    },
    {
      "label": "Brokopondo",
      "value": "Brokopondo",
      "country_id": "Suriname"
    },
    {
      "label": "Commewijne",
      "value": "Commewijne",
      "country_id": "Suriname"
    },
    {
      "label": "Coronie",
      "value": "Coronie",
      "country_id": "Suriname"
    },
    {
      "label": "Marowijne",
      "value": "Marowijne",
      "country_id": "Suriname"
    },
    {
      "label": "Nickerie",
      "value": "Nickerie",
      "country_id": "Suriname"
    },
    {
      "label": "Para",
      "value": "Para",
      "country_id": "Suriname"
    },
    {
      "label": "Paramaribo",
      "value": "Paramaribo",
      "country_id": "Suriname"
    },
    {
      "label": "Saramacca",
      "value": "Saramacca",
      "country_id": "Suriname"
    },
    {
      "label": "Wanica",
      "value": "Wanica",
      "country_id": "Suriname"
    },
    {
      "label": "Svalbard",
      "value": "Svalbard",
      "country_id": "Svalbard And Jan Mayen Islands"
    },
    {
      "label": "Hhohho",
      "value": "Hhohho",
      "country_id": "Swaziland"
    },
    {
      "label": "Lubombo",
      "value": "Lubombo",
      "country_id": "Swaziland"
    },
    {
      "label": "Manzini",
      "value": "Manzini",
      "country_id": "Swaziland"
    },
    {
      "label": "Shiselweni",
      "value": "Shiselweni",
      "country_id": "Swaziland"
    },
    {
      "label": "Alvsborgs Lan",
      "value": "Alvsborgs Lan",
      "country_id": "Sweden"
    },
    {
      "label": "Angermanland",
      "value": "Angermanland",
      "country_id": "Sweden"
    },
    {
      "label": "Blekinge",
      "value": "Blekinge",
      "country_id": "Sweden"
    },
    {
      "label": "Bohuslan",
      "value": "Bohuslan",
      "country_id": "Sweden"
    },
    {
      "label": "Dalarna",
      "value": "Dalarna",
      "country_id": "Sweden"
    },
    {
      "label": "Gavleborg",
      "value": "Gavleborg",
      "country_id": "Sweden"
    },
    {
      "label": "Gaza",
      "value": "Gaza",
      "country_id": "Sweden"
    },
    {
      "label": "Gotland",
      "value": "Gotland",
      "country_id": "Sweden"
    },
    {
      "label": "Halland",
      "value": "Halland",
      "country_id": "Sweden"
    },
    {
      "label": "Jamtland",
      "value": "Jamtland",
      "country_id": "Sweden"
    },
    {
      "label": "Jonkoping",
      "value": "Jonkoping",
      "country_id": "Sweden"
    },
    {
      "label": "Kalmar",
      "value": "Kalmar",
      "country_id": "Sweden"
    },
    {
      "label": "Kristianstads",
      "value": "Kristianstads",
      "country_id": "Sweden"
    },
    {
      "label": "Kronoberg",
      "value": "Kronoberg",
      "country_id": "Sweden"
    },
    {
      "label": "Norrbotten",
      "value": "Norrbotten",
      "country_id": "Sweden"
    },
    {
      "label": "Orebro",
      "value": "Orebro",
      "country_id": "Sweden"
    },
    {
      "label": "Ostergotland",
      "value": "Ostergotland",
      "country_id": "Sweden"
    },
    {
      "label": "Saltsjo-Boo",
      "value": "Saltsjo-Boo",
      "country_id": "Sweden"
    },
    {
      "label": "Skane",
      "value": "Skane",
      "country_id": "Sweden"
    },
    {
      "label": "Smaland",
      "value": "Smaland",
      "country_id": "Sweden"
    },
    {
      "label": "Sodermanland",
      "value": "Sodermanland",
      "country_id": "Sweden"
    },
    {
      "label": "Stockholm",
      "value": "Stockholm",
      "country_id": "Sweden"
    },
    {
      "label": "Uppsala",
      "value": "Uppsala",
      "country_id": "Sweden"
    },
    {
      "label": "Varmland",
      "value": "Varmland",
      "country_id": "Sweden"
    },
    {
      "label": "Vasterbotten",
      "value": "Vasterbotten",
      "country_id": "Sweden"
    },
    {
      "label": "Vastergotland",
      "value": "Vastergotland",
      "country_id": "Sweden"
    },
    {
      "label": "Vasternorrland",
      "value": "Vasternorrland",
      "country_id": "Sweden"
    },
    {
      "label": "Vastmanland",
      "value": "Vastmanland",
      "country_id": "Sweden"
    },
    {
      "label": "Vastra Gotaland",
      "value": "Vastra Gotaland",
      "country_id": "Sweden"
    },
    {
      "label": "Aargau",
      "value": "Aargau",
      "country_id": "Switzerland"
    },
    {
      "label": "Appenzell Inner-Rhoden",
      "value": "Appenzell Inner-Rhoden",
      "country_id": "Switzerland"
    },
    {
      "label": "Appenzell-Ausser Rhoden",
      "value": "Appenzell-Ausser Rhoden",
      "country_id": "Switzerland"
    },
    {
      "label": "Basel-Landschaft",
      "value": "Basel-Landschaft",
      "country_id": "Switzerland"
    },
    {
      "label": "Basel-Stadt",
      "value": "Basel-Stadt",
      "country_id": "Switzerland"
    },
    {
      "label": "Bern",
      "value": "Bern",
      "country_id": "Switzerland"
    },
    {
      "label": "Canton Ticino",
      "value": "Canton Ticino",
      "country_id": "Switzerland"
    },
    {
      "label": "Fribourg",
      "value": "Fribourg",
      "country_id": "Switzerland"
    },
    {
      "label": "Geneve",
      "value": "Geneve",
      "country_id": "Switzerland"
    },
    {
      "label": "Glarus",
      "value": "Glarus",
      "country_id": "Switzerland"
    },
    {
      "label": "Graubunden",
      "value": "Graubunden",
      "country_id": "Switzerland"
    },
    {
      "label": "Heerbrugg",
      "value": "Heerbrugg",
      "country_id": "Switzerland"
    },
    {
      "label": "Jura",
      "value": "Jura",
      "country_id": "Switzerland"
    },
    {
      "label": "Kanton Aargau",
      "value": "Kanton Aargau",
      "country_id": "Switzerland"
    },
    {
      "label": "Luzern",
      "value": "Luzern",
      "country_id": "Switzerland"
    },
    {
      "label": "Morbio Inferiore",
      "value": "Morbio Inferiore",
      "country_id": "Switzerland"
    },
    {
      "label": "Muhen",
      "value": "Muhen",
      "country_id": "Switzerland"
    },
    {
      "label": "Neuchatel",
      "value": "Neuchatel",
      "country_id": "Switzerland"
    },
    {
      "label": "Nidwalden",
      "value": "Nidwalden",
      "country_id": "Switzerland"
    },
    {
      "label": "Obwalden",
      "value": "Obwalden",
      "country_id": "Switzerland"
    },
    {
      "label": "Sankt Gallen",
      "value": "Sankt Gallen",
      "country_id": "Switzerland"
    },
    {
      "label": "Schaffhausen",
      "value": "Schaffhausen",
      "country_id": "Switzerland"
    },
    {
      "label": "Schwyz",
      "value": "Schwyz",
      "country_id": "Switzerland"
    },
    {
      "label": "Solothurn",
      "value": "Solothurn",
      "country_id": "Switzerland"
    },
    {
      "label": "Thurgau",
      "value": "Thurgau",
      "country_id": "Switzerland"
    },
    {
      "label": "Ticino",
      "value": "Ticino",
      "country_id": "Switzerland"
    },
    {
      "label": "Uri",
      "value": "Uri",
      "country_id": "Switzerland"
    },
    {
      "label": "Valais",
      "value": "Valais",
      "country_id": "Switzerland"
    },
    {
      "label": "Vaud",
      "value": "Vaud",
      "country_id": "Switzerland"
    },
    {
      "label": "Vauffelin",
      "value": "Vauffelin",
      "country_id": "Switzerland"
    },
    {
      "label": "Zug",
      "value": "Zug",
      "country_id": "Switzerland"
    },
    {
      "label": "Zurich",
      "value": "Zurich",
      "country_id": "Switzerland"
    },
    {
      "label": "Aleppo",
      "value": "Aleppo",
      "country_id": "Syria"
    },
    {
      "label": "Dar''a",
      "value": "Dar''a",
      "country_id": "Syria"
    },
    {
      "label": "Dayr-az-Zawr",
      "value": "Dayr-az-Zawr",
      "country_id": "Syria"
    },
    {
      "label": "Dimashq",
      "value": "Dimashq",
      "country_id": "Syria"
    },
    {
      "label": "Halab",
      "value": "Halab",
      "country_id": "Syria"
    },
    {
      "label": "Hamah",
      "value": "Hamah",
      "country_id": "Syria"
    },
    {
      "label": "Hims",
      "value": "Hims",
      "country_id": "Syria"
    },
    {
      "label": "Idlib",
      "value": "Idlib",
      "country_id": "Syria"
    },
    {
      "label": "Madinat Dimashq",
      "value": "Madinat Dimashq",
      "country_id": "Syria"
    },
    {
      "label": "Tartus",
      "value": "Tartus",
      "country_id": "Syria"
    },
    {
      "label": "al-Hasakah",
      "value": "al-Hasakah",
      "country_id": "Syria"
    },
    {
      "label": "al-Ladhiqiyah",
      "value": "al-Ladhiqiyah",
      "country_id": "Syria"
    },
    {
      "label": "al-Qunaytirah",
      "value": "al-Qunaytirah",
      "country_id": "Syria"
    },
    {
      "label": "ar-Raqqah",
      "value": "ar-Raqqah",
      "country_id": "Syria"
    },
    {
      "label": "as-Suwayda",
      "value": "as-Suwayda",
      "country_id": "Syria"
    },
    {
      "label": "Changhua County",
      "value": "Changhua County",
      "country_id": "Taiwan"
    },
    {
      "label": "Chiayi County",
      "value": "Chiayi County",
      "country_id": "Taiwan"
    },
    {
      "label": "Chiayi City",
      "value": "Chiayi City",
      "country_id": "Taiwan"
    },
    {
      "label": "Taipei City",
      "value": "Taipei City",
      "country_id": "Taiwan"
    },
    {
      "label": "Hsinchu County",
      "value": "Hsinchu County",
      "country_id": "Taiwan"
    },
    {
      "label": "Hsinchu City",
      "value": "Hsinchu City",
      "country_id": "Taiwan"
    },
    {
      "label": "Hualien County",
      "value": "Hualien County",
      "country_id": "Taiwan"
    },
    {
      "label": "Kaohsiung City",
      "value": "Kaohsiung City",
      "country_id": "Taiwan"
    },
    {
      "label": "Keelung City",
      "value": "Keelung City",
      "country_id": "Taiwan"
    },
    {
      "label": "Kinmen County",
      "value": "Kinmen County",
      "country_id": "Taiwan"
    },
    {
      "label": "Miaoli County",
      "value": "Miaoli County",
      "country_id": "Taiwan"
    },
    {
      "label": "Nantou County",
      "value": "Nantou County",
      "country_id": "Taiwan"
    },
    {
      "label": "Penghu County",
      "value": "Penghu County",
      "country_id": "Taiwan"
    },
    {
      "label": "Pingtung County",
      "value": "Pingtung County",
      "country_id": "Taiwan"
    },
    {
      "label": "Taichung City",
      "value": "Taichung City",
      "country_id": "Taiwan"
    },
    {
      "label": "Tainan City",
      "value": "Tainan City",
      "country_id": "Taiwan"
    },
    {
      "label": "New Taipei City",
      "value": "New Taipei City",
      "country_id": "Taiwan"
    },
    {
      "label": "Taitung County",
      "value": "Taitung County",
      "country_id": "Taiwan"
    },
    {
      "label": "Taoyuan City",
      "value": "Taoyuan City",
      "country_id": "Taiwan"
    },
    {
      "label": "Yilan County",
      "value": "Yilan County",
      "country_id": "Taiwan"
    },
    {
      "label": "YunLin County",
      "value": "YunLin County",
      "country_id": "Taiwan"
    },
    {
      "label": "Lienchiang County",
      "value": "Lienchiang County",
      "country_id": "Taiwan"
    },
    {
      "label": "Dushanbe",
      "value": "Dushanbe",
      "country_id": "Tajikistan"
    },
    {
      "label": "Gorno-Badakhshan",
      "value": "Gorno-Badakhshan",
      "country_id": "Tajikistan"
    },
    {
      "label": "Karotegin",
      "value": "Karotegin",
      "country_id": "Tajikistan"
    },
    {
      "label": "Khatlon",
      "value": "Khatlon",
      "country_id": "Tajikistan"
    },
    {
      "label": "Sughd",
      "value": "Sughd",
      "country_id": "Tajikistan"
    },
    {
      "label": "Arusha",
      "value": "Arusha",
      "country_id": "Tanzania"
    },
    {
      "label": "Dar es Salaam",
      "value": "Dar es Salaam",
      "country_id": "Tanzania"
    },
    {
      "label": "Dodoma",
      "value": "Dodoma",
      "country_id": "Tanzania"
    },
    {
      "label": "Iringa",
      "value": "Iringa",
      "country_id": "Tanzania"
    },
    {
      "label": "Kagera",
      "value": "Kagera",
      "country_id": "Tanzania"
    },
    {
      "label": "Kigoma",
      "value": "Kigoma",
      "country_id": "Tanzania"
    },
    {
      "label": "Kilimanjaro",
      "value": "Kilimanjaro",
      "country_id": "Tanzania"
    },
    {
      "label": "Lindi",
      "value": "Lindi",
      "country_id": "Tanzania"
    },
    {
      "label": "Mara",
      "value": "Mara",
      "country_id": "Tanzania"
    },
    {
      "label": "Mbeya",
      "value": "Mbeya",
      "country_id": "Tanzania"
    },
    {
      "label": "Morogoro",
      "value": "Morogoro",
      "country_id": "Tanzania"
    },
    {
      "label": "Mtwara",
      "value": "Mtwara",
      "country_id": "Tanzania"
    },
    {
      "label": "Mwanza",
      "value": "Mwanza",
      "country_id": "Tanzania"
    },
    {
      "label": "Pwani",
      "value": "Pwani",
      "country_id": "Tanzania"
    },
    {
      "label": "Rukwa",
      "value": "Rukwa",
      "country_id": "Tanzania"
    },
    {
      "label": "Ruvuma",
      "value": "Ruvuma",
      "country_id": "Tanzania"
    },
    {
      "label": "Shinyanga",
      "value": "Shinyanga",
      "country_id": "Tanzania"
    },
    {
      "label": "Singida",
      "value": "Singida",
      "country_id": "Tanzania"
    },
    {
      "label": "Tabora",
      "value": "Tabora",
      "country_id": "Tanzania"
    },
    {
      "label": "Tanga",
      "value": "Tanga",
      "country_id": "Tanzania"
    },
    {
      "label": "Zanzibar and Pemba",
      "value": "Zanzibar and Pemba",
      "country_id": "Tanzania"
    },
    {
      "label": "Amnat Charoen",
      "value": "Amnat Charoen",
      "country_id": "Thailand"
    },
    {
      "label": "Ang Thong",
      "value": "Ang Thong",
      "country_id": "Thailand"
    },
    {
      "label": "Bangkok",
      "value": "Bangkok",
      "country_id": "Thailand"
    },
    {
      "label": "Buri Ram",
      "value": "Buri Ram",
      "country_id": "Thailand"
    },
    {
      "label": "Chachoengsao",
      "value": "Chachoengsao",
      "country_id": "Thailand"
    },
    {
      "label": "Chai Nat",
      "value": "Chai Nat",
      "country_id": "Thailand"
    },
    {
      "label": "Chaiyaphum",
      "value": "Chaiyaphum",
      "country_id": "Thailand"
    },
    {
      "label": "Changwat Chaiyaphum",
      "value": "Changwat Chaiyaphum",
      "country_id": "Thailand"
    },
    {
      "label": "Chanthaburi",
      "value": "Chanthaburi",
      "country_id": "Thailand"
    },
    {
      "label": "Chiang Mai",
      "value": "Chiang Mai",
      "country_id": "Thailand"
    },
    {
      "label": "Chiang Rai",
      "value": "Chiang Rai",
      "country_id": "Thailand"
    },
    {
      "label": "Chon Buri",
      "value": "Chon Buri",
      "country_id": "Thailand"
    },
    {
      "label": "Chumphon",
      "value": "Chumphon",
      "country_id": "Thailand"
    },
    {
      "label": "Kalasin",
      "value": "Kalasin",
      "country_id": "Thailand"
    },
    {
      "label": "Kamphaeng Phet",
      "value": "Kamphaeng Phet",
      "country_id": "Thailand"
    },
    {
      "label": "Kanchanaburi",
      "value": "Kanchanaburi",
      "country_id": "Thailand"
    },
    {
      "label": "Khon Kaen",
      "value": "Khon Kaen",
      "country_id": "Thailand"
    },
    {
      "label": "Krabi",
      "value": "Krabi",
      "country_id": "Thailand"
    },
    {
      "label": "Krung Thep",
      "value": "Krung Thep",
      "country_id": "Thailand"
    },
    {
      "label": "Lampang",
      "value": "Lampang",
      "country_id": "Thailand"
    },
    {
      "label": "Lamphun",
      "value": "Lamphun",
      "country_id": "Thailand"
    },
    {
      "label": "Loei",
      "value": "Loei",
      "country_id": "Thailand"
    },
    {
      "label": "Lop Buri",
      "value": "Lop Buri",
      "country_id": "Thailand"
    },
    {
      "label": "Mae Hong Son",
      "value": "Mae Hong Son",
      "country_id": "Thailand"
    },
    {
      "label": "Maha Sarakham",
      "value": "Maha Sarakham",
      "country_id": "Thailand"
    },
    {
      "label": "Mukdahan",
      "value": "Mukdahan",
      "country_id": "Thailand"
    },
    {
      "label": "Nakhon Nayok",
      "value": "Nakhon Nayok",
      "country_id": "Thailand"
    },
    {
      "label": "Nakhon Pathom",
      "value": "Nakhon Pathom",
      "country_id": "Thailand"
    },
    {
      "label": "Nakhon Phanom",
      "value": "Nakhon Phanom",
      "country_id": "Thailand"
    },
    {
      "label": "Nakhon Ratchasima",
      "value": "Nakhon Ratchasima",
      "country_id": "Thailand"
    },
    {
      "label": "Nakhon Sawan",
      "value": "Nakhon Sawan",
      "country_id": "Thailand"
    },
    {
      "label": "Nakhon Si Thammarat",
      "value": "Nakhon Si Thammarat",
      "country_id": "Thailand"
    },
    {
      "label": "Nan",
      "value": "Nan",
      "country_id": "Thailand"
    },
    {
      "label": "Narathiwat",
      "value": "Narathiwat",
      "country_id": "Thailand"
    },
    {
      "label": "Nong Bua Lam Phu",
      "value": "Nong Bua Lam Phu",
      "country_id": "Thailand"
    },
    {
      "label": "Nong Khai",
      "value": "Nong Khai",
      "country_id": "Thailand"
    },
    {
      "label": "Nonthaburi",
      "value": "Nonthaburi",
      "country_id": "Thailand"
    },
    {
      "label": "Pathum Thani",
      "value": "Pathum Thani",
      "country_id": "Thailand"
    },
    {
      "label": "Pattani",
      "value": "Pattani",
      "country_id": "Thailand"
    },
    {
      "label": "Phangnga",
      "value": "Phangnga",
      "country_id": "Thailand"
    },
    {
      "label": "Phatthalung",
      "value": "Phatthalung",
      "country_id": "Thailand"
    },
    {
      "label": "Phayao",
      "value": "Phayao",
      "country_id": "Thailand"
    },
    {
      "label": "Phetchabun",
      "value": "Phetchabun",
      "country_id": "Thailand"
    },
    {
      "label": "Phetchaburi",
      "value": "Phetchaburi",
      "country_id": "Thailand"
    },
    {
      "label": "Phichit",
      "value": "Phichit",
      "country_id": "Thailand"
    },
    {
      "label": "Phitsanulok",
      "value": "Phitsanulok",
      "country_id": "Thailand"
    },
    {
      "label": "Phra Nakhon Si Ayutthaya",
      "value": "Phra Nakhon Si Ayutthaya",
      "country_id": "Thailand"
    },
    {
      "label": "Phrae",
      "value": "Phrae",
      "country_id": "Thailand"
    },
    {
      "label": "Phuket",
      "value": "Phuket",
      "country_id": "Thailand"
    },
    {
      "label": "Prachin Buri",
      "value": "Prachin Buri",
      "country_id": "Thailand"
    },
    {
      "label": "Prachuap Khiri Khan",
      "value": "Prachuap Khiri Khan",
      "country_id": "Thailand"
    },
    {
      "label": "Ranong",
      "value": "Ranong",
      "country_id": "Thailand"
    },
    {
      "label": "Ratchaburi",
      "value": "Ratchaburi",
      "country_id": "Thailand"
    },
    {
      "label": "Rayong",
      "value": "Rayong",
      "country_id": "Thailand"
    },
    {
      "label": "Roi Et",
      "value": "Roi Et",
      "country_id": "Thailand"
    },
    {
      "label": "Sa Kaeo",
      "value": "Sa Kaeo",
      "country_id": "Thailand"
    },
    {
      "label": "Sakon Nakhon",
      "value": "Sakon Nakhon",
      "country_id": "Thailand"
    },
    {
      "label": "Samut Prakan",
      "value": "Samut Prakan",
      "country_id": "Thailand"
    },
    {
      "label": "Samut Sakhon",
      "value": "Samut Sakhon",
      "country_id": "Thailand"
    },
    {
      "label": "Samut Songkhran",
      "value": "Samut Songkhran",
      "country_id": "Thailand"
    },
    {
      "label": "Saraburi",
      "value": "Saraburi",
      "country_id": "Thailand"
    },
    {
      "label": "Satun",
      "value": "Satun",
      "country_id": "Thailand"
    },
    {
      "label": "Si Sa Ket",
      "value": "Si Sa Ket",
      "country_id": "Thailand"
    },
    {
      "label": "Sing Buri",
      "value": "Sing Buri",
      "country_id": "Thailand"
    },
    {
      "label": "Songkhla",
      "value": "Songkhla",
      "country_id": "Thailand"
    },
    {
      "label": "Sukhothai",
      "value": "Sukhothai",
      "country_id": "Thailand"
    },
    {
      "label": "Suphan Buri",
      "value": "Suphan Buri",
      "country_id": "Thailand"
    },
    {
      "label": "Surat Thani",
      "value": "Surat Thani",
      "country_id": "Thailand"
    },
    {
      "label": "Surin",
      "value": "Surin",
      "country_id": "Thailand"
    },
    {
      "label": "Tak",
      "value": "Tak",
      "country_id": "Thailand"
    },
    {
      "label": "Trang",
      "value": "Trang",
      "country_id": "Thailand"
    },
    {
      "label": "Trat",
      "value": "Trat",
      "country_id": "Thailand"
    },
    {
      "label": "Ubon Ratchathani",
      "value": "Ubon Ratchathani",
      "country_id": "Thailand"
    },
    {
      "label": "Udon Thani",
      "value": "Udon Thani",
      "country_id": "Thailand"
    },
    {
      "label": "Uthai Thani",
      "value": "Uthai Thani",
      "country_id": "Thailand"
    },
    {
      "label": "Uttaradit",
      "value": "Uttaradit",
      "country_id": "Thailand"
    },
    {
      "label": "Yala",
      "value": "Yala",
      "country_id": "Thailand"
    },
    {
      "label": "Yasothon",
      "value": "Yasothon",
      "country_id": "Thailand"
    },
    {
      "label": "Centre",
      "value": "Centre",
      "country_id": "Togo"
    },
    {
      "label": "Kara",
      "value": "Kara",
      "country_id": "Togo"
    },
    {
      "label": "Maritime",
      "value": "Maritime",
      "country_id": "Togo"
    },
    {
      "label": "Plateaux",
      "value": "Plateaux",
      "country_id": "Togo"
    },
    {
      "label": "Savanes",
      "value": "Savanes",
      "country_id": "Togo"
    },
    {
      "label": "Atafu",
      "value": "Atafu",
      "country_id": "Tokelau"
    },
    {
      "label": "Fakaofo",
      "value": "Fakaofo",
      "country_id": "Tokelau"
    },
    {
      "label": "Nukunonu",
      "value": "Nukunonu",
      "country_id": "Tokelau"
    },
    {
      "label": "Eua",
      "value": "Eua",
      "country_id": "Tonga"
    },
    {
      "label": "Ha''apai",
      "value": "Ha''apai",
      "country_id": "Tonga"
    },
    {
      "label": "Niuas",
      "value": "Niuas",
      "country_id": "Tonga"
    },
    {
      "label": "Tongatapu",
      "value": "Tongatapu",
      "country_id": "Tonga"
    },
    {
      "label": "Vava''u",
      "value": "Vava''u",
      "country_id": "Tonga"
    },
    {
      "label": "Arima-Tunapuna-Piarco",
      "value": "Arima-Tunapuna-Piarco",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Caroni",
      "value": "Caroni",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Chaguanas",
      "value": "Chaguanas",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Couva-Tabaquite-Talparo",
      "value": "Couva-Tabaquite-Talparo",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Diego Martin",
      "value": "Diego Martin",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Glencoe",
      "value": "Glencoe",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Penal Debe",
      "value": "Penal Debe",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Point Fortin",
      "value": "Point Fortin",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Port of Spain",
      "value": "Port of Spain",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Princes Town",
      "value": "Princes Town",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Saint George",
      "value": "Saint George",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "San Fernando",
      "value": "San Fernando",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "San Juan",
      "value": "San Juan",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Sangre Grande",
      "value": "Sangre Grande",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Siparia",
      "value": "Siparia",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Tobago",
      "value": "Tobago",
      "country_id": "Trinidad And Tobago"
    },
    {
      "label": "Aryanah",
      "value": "Aryanah",
      "country_id": "Tunisia"
    },
    {
      "label": "Bajah",
      "value": "Bajah",
      "country_id": "Tunisia"
    },
    {
      "label": "Bin ''Arus",
      "value": "Bin ''Arus",
      "country_id": "Tunisia"
    },
    {
      "label": "Binzart",
      "value": "Binzart",
      "country_id": "Tunisia"
    },
    {
      "label": "Gouvernorat de Ariana",
      "value": "Gouvernorat de Ariana",
      "country_id": "Tunisia"
    },
    {
      "label": "Gouvernorat de Nabeul",
      "value": "Gouvernorat de Nabeul",
      "country_id": "Tunisia"
    },
    {
      "label": "Gouvernorat de Sousse",
      "value": "Gouvernorat de Sousse",
      "country_id": "Tunisia"
    },
    {
      "label": "Hammamet Yasmine",
      "value": "Hammamet Yasmine",
      "country_id": "Tunisia"
    },
    {
      "label": "Jundubah",
      "value": "Jundubah",
      "country_id": "Tunisia"
    },
    {
      "label": "Madaniyin",
      "value": "Madaniyin",
      "country_id": "Tunisia"
    },
    {
      "label": "Manubah",
      "value": "Manubah",
      "country_id": "Tunisia"
    },
    {
      "label": "Monastir",
      "value": "Monastir",
      "country_id": "Tunisia"
    },
    {
      "label": "Nabul",
      "value": "Nabul",
      "country_id": "Tunisia"
    },
    {
      "label": "Qabis",
      "value": "Qabis",
      "country_id": "Tunisia"
    },
    {
      "label": "Qafsah",
      "value": "Qafsah",
      "country_id": "Tunisia"
    },
    {
      "label": "Qibili",
      "value": "Qibili",
      "country_id": "Tunisia"
    },
    {
      "label": "Safaqis",
      "value": "Safaqis",
      "country_id": "Tunisia"
    },
    {
      "label": "Sfax",
      "value": "Sfax",
      "country_id": "Tunisia"
    },
    {
      "label": "Sidi Bu Zayd",
      "value": "Sidi Bu Zayd",
      "country_id": "Tunisia"
    },
    {
      "label": "Silyanah",
      "value": "Silyanah",
      "country_id": "Tunisia"
    },
    {
      "label": "Susah",
      "value": "Susah",
      "country_id": "Tunisia"
    },
    {
      "label": "Tatawin",
      "value": "Tatawin",
      "country_id": "Tunisia"
    },
    {
      "label": "Tawzar",
      "value": "Tawzar",
      "country_id": "Tunisia"
    },
    {
      "label": "Tunis",
      "value": "Tunis",
      "country_id": "Tunisia"
    },
    {
      "label": "Zaghwan",
      "value": "Zaghwan",
      "country_id": "Tunisia"
    },
    {
      "label": "al-Kaf",
      "value": "al-Kaf",
      "country_id": "Tunisia"
    },
    {
      "label": "al-Mahdiyah",
      "value": "al-Mahdiyah",
      "country_id": "Tunisia"
    },
    {
      "label": "al-Munastir",
      "value": "al-Munastir",
      "country_id": "Tunisia"
    },
    {
      "label": "al-Qasrayn",
      "value": "al-Qasrayn",
      "country_id": "Tunisia"
    },
    {
      "label": "al-Qayrawan",
      "value": "al-Qayrawan",
      "country_id": "Tunisia"
    },
    {
      "label": "Adana",
      "value": "Adana",
      "country_id": "Turkey"
    },
    {
      "label": "Adiyaman",
      "value": "Adiyaman",
      "country_id": "Turkey"
    },
    {
      "label": "Afyon",
      "value": "Afyon",
      "country_id": "Turkey"
    },
    {
      "label": "Agri",
      "value": "Agri",
      "country_id": "Turkey"
    },
    {
      "label": "Aksaray",
      "value": "Aksaray",
      "country_id": "Turkey"
    },
    {
      "label": "Amasya",
      "value": "Amasya",
      "country_id": "Turkey"
    },
    {
      "label": "Ankara",
      "value": "Ankara",
      "country_id": "Turkey"
    },
    {
      "label": "Antalya",
      "value": "Antalya",
      "country_id": "Turkey"
    },
    {
      "label": "Ardahan",
      "value": "Ardahan",
      "country_id": "Turkey"
    },
    {
      "label": "Artvin",
      "value": "Artvin",
      "country_id": "Turkey"
    },
    {
      "label": "Aydin",
      "value": "Aydin",
      "country_id": "Turkey"
    },
    {
      "label": "Balikesir",
      "value": "Balikesir",
      "country_id": "Turkey"
    },
    {
      "label": "Bartin",
      "value": "Bartin",
      "country_id": "Turkey"
    },
    {
      "label": "Batman",
      "value": "Batman",
      "country_id": "Turkey"
    },
    {
      "label": "Bayburt",
      "value": "Bayburt",
      "country_id": "Turkey"
    },
    {
      "label": "Bilecik",
      "value": "Bilecik",
      "country_id": "Turkey"
    },
    {
      "label": "Bingol",
      "value": "Bingol",
      "country_id": "Turkey"
    },
    {
      "label": "Bitlis",
      "value": "Bitlis",
      "country_id": "Turkey"
    },
    {
      "label": "Bolu",
      "value": "Bolu",
      "country_id": "Turkey"
    },
    {
      "label": "Burdur",
      "value": "Burdur",
      "country_id": "Turkey"
    },
    {
      "label": "Bursa",
      "value": "Bursa",
      "country_id": "Turkey"
    },
    {
      "label": "Canakkale",
      "value": "Canakkale",
      "country_id": "Turkey"
    },
    {
      "label": "Cankiri",
      "value": "Cankiri",
      "country_id": "Turkey"
    },
    {
      "label": "Corum",
      "value": "Corum",
      "country_id": "Turkey"
    },
    {
      "label": "Denizli",
      "value": "Denizli",
      "country_id": "Turkey"
    },
    {
      "label": "Diyarbakir",
      "value": "Diyarbakir",
      "country_id": "Turkey"
    },
    {
      "label": "Duzce",
      "value": "Duzce",
      "country_id": "Turkey"
    },
    {
      "label": "Edirne",
      "value": "Edirne",
      "country_id": "Turkey"
    },
    {
      "label": "Elazig",
      "value": "Elazig",
      "country_id": "Turkey"
    },
    {
      "label": "Erzincan",
      "value": "Erzincan",
      "country_id": "Turkey"
    },
    {
      "label": "Erzurum",
      "value": "Erzurum",
      "country_id": "Turkey"
    },
    {
      "label": "Eskisehir",
      "value": "Eskisehir",
      "country_id": "Turkey"
    },
    {
      "label": "Gaziantep",
      "value": "Gaziantep",
      "country_id": "Turkey"
    },
    {
      "label": "Giresun",
      "value": "Giresun",
      "country_id": "Turkey"
    },
    {
      "label": "Gumushane",
      "value": "Gumushane",
      "country_id": "Turkey"
    },
    {
      "label": "Hakkari",
      "value": "Hakkari",
      "country_id": "Turkey"
    },
    {
      "label": "Hatay",
      "value": "Hatay",
      "country_id": "Turkey"
    },
    {
      "label": "Icel",
      "value": "Icel",
      "country_id": "Turkey"
    },
    {
      "label": "Igdir",
      "value": "Igdir",
      "country_id": "Turkey"
    },
    {
      "label": "Isparta",
      "value": "Isparta",
      "country_id": "Turkey"
    },
    {
      "label": "Istanbul",
      "value": "Istanbul",
      "country_id": "Turkey"
    },
    {
      "label": "Izmir",
      "value": "Izmir",
      "country_id": "Turkey"
    },
    {
      "label": "Kahramanmaras",
      "value": "Kahramanmaras",
      "country_id": "Turkey"
    },
    {
      "label": "Karabuk",
      "value": "Karabuk",
      "country_id": "Turkey"
    },
    {
      "label": "Karaman",
      "value": "Karaman",
      "country_id": "Turkey"
    },
    {
      "label": "Kars",
      "value": "Kars",
      "country_id": "Turkey"
    },
    {
      "label": "Karsiyaka",
      "value": "Karsiyaka",
      "country_id": "Turkey"
    },
    {
      "label": "Kastamonu",
      "value": "Kastamonu",
      "country_id": "Turkey"
    },
    {
      "label": "Kayseri",
      "value": "Kayseri",
      "country_id": "Turkey"
    },
    {
      "label": "Kilis",
      "value": "Kilis",
      "country_id": "Turkey"
    },
    {
      "label": "Kirikkale",
      "value": "Kirikkale",
      "country_id": "Turkey"
    },
    {
      "label": "Kirklareli",
      "value": "Kirklareli",
      "country_id": "Turkey"
    },
    {
      "label": "Kirsehir",
      "value": "Kirsehir",
      "country_id": "Turkey"
    },
    {
      "label": "Kocaeli",
      "value": "Kocaeli",
      "country_id": "Turkey"
    },
    {
      "label": "Konya",
      "value": "Konya",
      "country_id": "Turkey"
    },
    {
      "label": "Kutahya",
      "value": "Kutahya",
      "country_id": "Turkey"
    },
    {
      "label": "Lefkosa",
      "value": "Lefkosa",
      "country_id": "Turkey"
    },
    {
      "label": "Malatya",
      "value": "Malatya",
      "country_id": "Turkey"
    },
    {
      "label": "Manisa",
      "value": "Manisa",
      "country_id": "Turkey"
    },
    {
      "label": "Mardin",
      "value": "Mardin",
      "country_id": "Turkey"
    },
    {
      "label": "Mugla",
      "value": "Mugla",
      "country_id": "Turkey"
    },
    {
      "label": "Mus",
      "value": "Mus",
      "country_id": "Turkey"
    },
    {
      "label": "Nevsehir",
      "value": "Nevsehir",
      "country_id": "Turkey"
    },
    {
      "label": "Nigde",
      "value": "Nigde",
      "country_id": "Turkey"
    },
    {
      "label": "Ordu",
      "value": "Ordu",
      "country_id": "Turkey"
    },
    {
      "label": "Osmaniye",
      "value": "Osmaniye",
      "country_id": "Turkey"
    },
    {
      "label": "Rize",
      "value": "Rize",
      "country_id": "Turkey"
    },
    {
      "label": "Sakarya",
      "value": "Sakarya",
      "country_id": "Turkey"
    },
    {
      "label": "Samsun",
      "value": "Samsun",
      "country_id": "Turkey"
    },
    {
      "label": "Sanliurfa",
      "value": "Sanliurfa",
      "country_id": "Turkey"
    },
    {
      "label": "Siirt",
      "value": "Siirt",
      "country_id": "Turkey"
    },
    {
      "label": "Sinop",
      "value": "Sinop",
      "country_id": "Turkey"
    },
    {
      "label": "Sirnak",
      "value": "Sirnak",
      "country_id": "Turkey"
    },
    {
      "label": "Sivas",
      "value": "Sivas",
      "country_id": "Turkey"
    },
    {
      "label": "Tekirdag",
      "value": "Tekirdag",
      "country_id": "Turkey"
    },
    {
      "label": "Tokat",
      "value": "Tokat",
      "country_id": "Turkey"
    },
    {
      "label": "Trabzon",
      "value": "Trabzon",
      "country_id": "Turkey"
    },
    {
      "label": "Tunceli",
      "value": "Tunceli",
      "country_id": "Turkey"
    },
    {
      "label": "Usak",
      "value": "Usak",
      "country_id": "Turkey"
    },
    {
      "label": "Van",
      "value": "Van",
      "country_id": "Turkey"
    },
    {
      "label": "Yalova",
      "value": "Yalova",
      "country_id": "Turkey"
    },
    {
      "label": "Yozgat",
      "value": "Yozgat",
      "country_id": "Turkey"
    },
    {
      "label": "Zonguldak",
      "value": "Zonguldak",
      "country_id": "Turkey"
    },
    {
      "label": "Ahal",
      "value": "Ahal",
      "country_id": "Turkmenistan"
    },
    {
      "label": "Asgabat",
      "value": "Asgabat",
      "country_id": "Turkmenistan"
    },
    {
      "label": "Balkan",
      "value": "Balkan",
      "country_id": "Turkmenistan"
    },
    {
      "label": "Dasoguz",
      "value": "Dasoguz",
      "country_id": "Turkmenistan"
    },
    {
      "label": "Lebap",
      "value": "Lebap",
      "country_id": "Turkmenistan"
    },
    {
      "label": "Mari",
      "value": "Mari",
      "country_id": "Turkmenistan"
    },
    {
      "label": "Grand Turk",
      "value": "Grand Turk",
      "country_id": "Turks And Caicos Islands"
    },
    {
      "label": "South Caicos and East Caicos",
      "value": "South Caicos and East Caicos",
      "country_id": "Turks And Caicos Islands"
    },
    {
      "label": "Funafuti",
      "value": "Funafuti",
      "country_id": "Tuvalu"
    },
    {
      "label": "Nanumanga",
      "value": "Nanumanga",
      "country_id": "Tuvalu"
    },
    {
      "label": "Nanumea",
      "value": "Nanumea",
      "country_id": "Tuvalu"
    },
    {
      "label": "Niutao",
      "value": "Niutao",
      "country_id": "Tuvalu"
    },
    {
      "label": "Nui",
      "value": "Nui",
      "country_id": "Tuvalu"
    },
    {
      "label": "Nukufetau",
      "value": "Nukufetau",
      "country_id": "Tuvalu"
    },
    {
      "label": "Nukulaelae",
      "value": "Nukulaelae",
      "country_id": "Tuvalu"
    },
    {
      "label": "Vaitupu",
      "value": "Vaitupu",
      "country_id": "Tuvalu"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Uganda"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "Uganda"
    },
    {
      "label": "Northern",
      "value": "Northern",
      "country_id": "Uganda"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Uganda"
    },
    {
      "label": "Cherkas''ka",
      "value": "Cherkas''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Chernihivs''ka",
      "value": "Chernihivs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Chernivets''ka",
      "value": "Chernivets''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Crimea",
      "value": "Crimea",
      "country_id": "Ukraine"
    },
    {
      "label": "Dnipropetrovska",
      "value": "Dnipropetrovska",
      "country_id": "Ukraine"
    },
    {
      "label": "Donets''ka",
      "value": "Donets''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Ivano-Frankivs''ka",
      "value": "Ivano-Frankivs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Kharkiv",
      "value": "Kharkiv",
      "country_id": "Ukraine"
    },
    {
      "label": "Kharkov",
      "value": "Kharkov",
      "country_id": "Ukraine"
    },
    {
      "label": "Khersonska",
      "value": "Khersonska",
      "country_id": "Ukraine"
    },
    {
      "label": "Khmel''nyts''ka",
      "value": "Khmel''nyts''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Kirovohrad",
      "value": "Kirovohrad",
      "country_id": "Ukraine"
    },
    {
      "label": "Krym",
      "value": "Krym",
      "country_id": "Ukraine"
    },
    {
      "label": "Kyyiv",
      "value": "Kyyiv",
      "country_id": "Ukraine"
    },
    {
      "label": "Kyyivs''ka",
      "value": "Kyyivs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "L''vivs''ka",
      "value": "L''vivs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Luhans''ka",
      "value": "Luhans''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Mykolayivs''ka",
      "value": "Mykolayivs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Odes''ka",
      "value": "Odes''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Odessa",
      "value": "Odessa",
      "country_id": "Ukraine"
    },
    {
      "label": "Poltavs''ka",
      "value": "Poltavs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Rivnens''ka",
      "value": "Rivnens''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Sevastopol",
      "value": "Sevastopol",
      "country_id": "Ukraine"
    },
    {
      "label": "Sums''ka",
      "value": "Sums''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Ternopil''s''ka",
      "value": "Ternopil''s''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Volyns''ka",
      "value": "Volyns''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Vynnyts''ka",
      "value": "Vynnyts''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Zakarpats''ka",
      "value": "Zakarpats''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Zaporizhia",
      "value": "Zaporizhia",
      "country_id": "Ukraine"
    },
    {
      "label": "Zhytomyrs''ka",
      "value": "Zhytomyrs''ka",
      "country_id": "Ukraine"
    },
    {
      "label": "Abu Zabi",
      "value": "Abu Zabi",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Ajman",
      "value": "Ajman",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Dubai",
      "value": "Dubai",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Ras al-Khaymah",
      "value": "Ras al-Khaymah",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Sharjah",
      "value": "Sharjah",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Sharjha",
      "value": "Sharjha",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Umm al Qaywayn",
      "value": "Umm al Qaywayn",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "al-Fujayrah",
      "value": "al-Fujayrah",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "ash-Shariqah",
      "value": "ash-Shariqah",
      "country_id": "United Arab Emirates"
    },
    {
      "label": "Aberdeen",
      "value": "Aberdeen",
      "country_id": "United Kingdom"
    },
    {
      "label": "Aberdeenshire",
      "value": "Aberdeenshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Argyll",
      "value": "Argyll",
      "country_id": "United Kingdom"
    },
    {
      "label": "Armagh",
      "value": "Armagh",
      "country_id": "United Kingdom"
    },
    {
      "label": "Bedfordshire",
      "value": "Bedfordshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Belfast",
      "value": "Belfast",
      "country_id": "United Kingdom"
    },
    {
      "label": "Berkshire",
      "value": "Berkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Birmingham",
      "value": "Birmingham",
      "country_id": "United Kingdom"
    },
    {
      "label": "Brechin",
      "value": "Brechin",
      "country_id": "United Kingdom"
    },
    {
      "label": "Bridgnorth",
      "value": "Bridgnorth",
      "country_id": "United Kingdom"
    },
    {
      "label": "Bristol",
      "value": "Bristol",
      "country_id": "United Kingdom"
    },
    {
      "label": "Buckinghamshire",
      "value": "Buckinghamshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Cambridge",
      "value": "Cambridge",
      "country_id": "United Kingdom"
    },
    {
      "label": "Cambridgeshire",
      "value": "Cambridgeshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Channel Islands",
      "value": "Channel Islands",
      "country_id": "United Kingdom"
    },
    {
      "label": "Cheshire",
      "value": "Cheshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Cleveland",
      "value": "Cleveland",
      "country_id": "United Kingdom"
    },
    {
      "label": "Co Fermanagh",
      "value": "Co Fermanagh",
      "country_id": "United Kingdom"
    },
    {
      "label": "Conwy",
      "value": "Conwy",
      "country_id": "United Kingdom"
    },
    {
      "label": "Cornwall",
      "value": "Cornwall",
      "country_id": "United Kingdom"
    },
    {
      "label": "Coventry",
      "value": "Coventry",
      "country_id": "United Kingdom"
    },
    {
      "label": "Craven Arms",
      "value": "Craven Arms",
      "country_id": "United Kingdom"
    },
    {
      "label": "Cumbria",
      "value": "Cumbria",
      "country_id": "United Kingdom"
    },
    {
      "label": "Denbighshire",
      "value": "Denbighshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Derby",
      "value": "Derby",
      "country_id": "United Kingdom"
    },
    {
      "label": "Derbyshire",
      "value": "Derbyshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Devon",
      "value": "Devon",
      "country_id": "United Kingdom"
    },
    {
      "label": "Dial Code Dungannon",
      "value": "Dial Code Dungannon",
      "country_id": "United Kingdom"
    },
    {
      "label": "Didcot",
      "value": "Didcot",
      "country_id": "United Kingdom"
    },
    {
      "label": "Dorset",
      "value": "Dorset",
      "country_id": "United Kingdom"
    },
    {
      "label": "Dunbartonshire",
      "value": "Dunbartonshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Durham",
      "value": "Durham",
      "country_id": "United Kingdom"
    },
    {
      "label": "East Dunbartonshire",
      "value": "East Dunbartonshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "East Lothian",
      "value": "East Lothian",
      "country_id": "United Kingdom"
    },
    {
      "label": "East Midlands",
      "value": "East Midlands",
      "country_id": "United Kingdom"
    },
    {
      "label": "East Sussex",
      "value": "East Sussex",
      "country_id": "United Kingdom"
    },
    {
      "label": "East Yorkshire",
      "value": "East Yorkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "England",
      "value": "England",
      "country_id": "United Kingdom"
    },
    {
      "label": "Essex",
      "value": "Essex",
      "country_id": "United Kingdom"
    },
    {
      "label": "Fermanagh",
      "value": "Fermanagh",
      "country_id": "United Kingdom"
    },
    {
      "label": "Fife",
      "value": "Fife",
      "country_id": "United Kingdom"
    },
    {
      "label": "Flintshire",
      "value": "Flintshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Fulham",
      "value": "Fulham",
      "country_id": "United Kingdom"
    },
    {
      "label": "Gainsborough",
      "value": "Gainsborough",
      "country_id": "United Kingdom"
    },
    {
      "label": "Glocestershire",
      "value": "Glocestershire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Gwent",
      "value": "Gwent",
      "country_id": "United Kingdom"
    },
    {
      "label": "Hampshire",
      "value": "Hampshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Hants",
      "value": "Hants",
      "country_id": "United Kingdom"
    },
    {
      "label": "Herefordshire",
      "value": "Herefordshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Hertfordshire",
      "value": "Hertfordshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Ireland",
      "value": "Ireland",
      "country_id": "United Kingdom"
    },
    {
      "label": "Isle Of Man",
      "value": "Isle Of Man",
      "country_id": "United Kingdom"
    },
    {
      "label": "Isle of Wight",
      "value": "Isle of Wight",
      "country_id": "United Kingdom"
    },
    {
      "label": "Kenford",
      "value": "Kenford",
      "country_id": "United Kingdom"
    },
    {
      "label": "Kent",
      "value": "Kent",
      "country_id": "United Kingdom"
    },
    {
      "label": "Kilmarnock",
      "value": "Kilmarnock",
      "country_id": "United Kingdom"
    },
    {
      "label": "Lanarkshire",
      "value": "Lanarkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Lancashire",
      "value": "Lancashire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Leicestershire",
      "value": "Leicestershire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Lincolnshire",
      "value": "Lincolnshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Llanymynech",
      "value": "Llanymynech",
      "country_id": "United Kingdom"
    },
    {
      "label": "London",
      "value": "London",
      "country_id": "United Kingdom"
    },
    {
      "label": "Ludlow",
      "value": "Ludlow",
      "country_id": "United Kingdom"
    },
    {
      "label": "Manchester",
      "value": "Manchester",
      "country_id": "United Kingdom"
    },
    {
      "label": "Mayfair",
      "value": "Mayfair",
      "country_id": "United Kingdom"
    },
    {
      "label": "Merseyside",
      "value": "Merseyside",
      "country_id": "United Kingdom"
    },
    {
      "label": "Mid Glamorgan",
      "value": "Mid Glamorgan",
      "country_id": "United Kingdom"
    },
    {
      "label": "Middlesex",
      "value": "Middlesex",
      "country_id": "United Kingdom"
    },
    {
      "label": "Mildenhall",
      "value": "Mildenhall",
      "country_id": "United Kingdom"
    },
    {
      "label": "Monmouthshire",
      "value": "Monmouthshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Newton Stewart",
      "value": "Newton Stewart",
      "country_id": "United Kingdom"
    },
    {
      "label": "Norfolk",
      "value": "Norfolk",
      "country_id": "United Kingdom"
    },
    {
      "label": "North Humberside",
      "value": "North Humberside",
      "country_id": "United Kingdom"
    },
    {
      "label": "North Yorkshire",
      "value": "North Yorkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Northamptonshire",
      "value": "Northamptonshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Northants",
      "value": "Northants",
      "country_id": "United Kingdom"
    },
    {
      "label": "Northern Ireland",
      "value": "Northern Ireland",
      "country_id": "United Kingdom"
    },
    {
      "label": "Northumberland",
      "value": "Northumberland",
      "country_id": "United Kingdom"
    },
    {
      "label": "Nottinghamshire",
      "value": "Nottinghamshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Oxford",
      "value": "Oxford",
      "country_id": "United Kingdom"
    },
    {
      "label": "Powys",
      "value": "Powys",
      "country_id": "United Kingdom"
    },
    {
      "label": "Roos-shire",
      "value": "Roos-shire",
      "country_id": "United Kingdom"
    },
    {
      "label": "SUSSEX",
      "value": "SUSSEX",
      "country_id": "United Kingdom"
    },
    {
      "label": "Sark",
      "value": "Sark",
      "country_id": "United Kingdom"
    },
    {
      "label": "Scotland",
      "value": "Scotland",
      "country_id": "United Kingdom"
    },
    {
      "label": "Scottish Borders",
      "value": "Scottish Borders",
      "country_id": "United Kingdom"
    },
    {
      "label": "Shropshire",
      "value": "Shropshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Somerset",
      "value": "Somerset",
      "country_id": "United Kingdom"
    },
    {
      "label": "South Glamorgan",
      "value": "South Glamorgan",
      "country_id": "United Kingdom"
    },
    {
      "label": "South Wales",
      "value": "South Wales",
      "country_id": "United Kingdom"
    },
    {
      "label": "South Yorkshire",
      "value": "South Yorkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Southwell",
      "value": "Southwell",
      "country_id": "United Kingdom"
    },
    {
      "label": "Staffordshire",
      "value": "Staffordshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Strabane",
      "value": "Strabane",
      "country_id": "United Kingdom"
    },
    {
      "label": "Suffolk",
      "value": "Suffolk",
      "country_id": "United Kingdom"
    },
    {
      "label": "Surrey",
      "value": "Surrey",
      "country_id": "United Kingdom"
    },
    {
      "label": "Sussex",
      "value": "Sussex",
      "country_id": "United Kingdom"
    },
    {
      "label": "Twickenham",
      "value": "Twickenham",
      "country_id": "United Kingdom"
    },
    {
      "label": "Tyne and Wear",
      "value": "Tyne and Wear",
      "country_id": "United Kingdom"
    },
    {
      "label": "Tyrone",
      "value": "Tyrone",
      "country_id": "United Kingdom"
    },
    {
      "label": "Utah",
      "value": "Utah",
      "country_id": "United Kingdom"
    },
    {
      "label": "Wales",
      "value": "Wales",
      "country_id": "United Kingdom"
    },
    {
      "label": "Warwickshire",
      "value": "Warwickshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "West Lothian",
      "value": "West Lothian",
      "country_id": "United Kingdom"
    },
    {
      "label": "West Midlands",
      "value": "West Midlands",
      "country_id": "United Kingdom"
    },
    {
      "label": "West Sussex",
      "value": "West Sussex",
      "country_id": "United Kingdom"
    },
    {
      "label": "West Yorkshire",
      "value": "West Yorkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Whissendine",
      "value": "Whissendine",
      "country_id": "United Kingdom"
    },
    {
      "label": "Wiltshire",
      "value": "Wiltshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Wokingham",
      "value": "Wokingham",
      "country_id": "United Kingdom"
    },
    {
      "label": "Worcestershire",
      "value": "Worcestershire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Wrexham",
      "value": "Wrexham",
      "country_id": "United Kingdom"
    },
    {
      "label": "Wurttemberg",
      "value": "Wurttemberg",
      "country_id": "United Kingdom"
    },
    {
      "label": "Yorkshire",
      "value": "Yorkshire",
      "country_id": "United Kingdom"
    },
    {
      "label": "Alabama",
      "value": "Alabama",
      "country_id": "United States"
    },
    {
      "label": "Alaska",
      "value": "Alaska",
      "country_id": "United States"
    },
    {
      "label": "Arizona",
      "value": "Arizona",
      "country_id": "United States"
    },
    {
      "label": "Arkansas",
      "value": "Arkansas",
      "country_id": "United States"
    },
    {
      "label": "Byram",
      "value": "Byram",
      "country_id": "United States"
    },
    {
      "label": "California",
      "value": "California",
      "country_id": "United States"
    },
    {
      "label": "Cokato",
      "value": "Cokato",
      "country_id": "United States"
    },
    {
      "label": "Colorado",
      "value": "Colorado",
      "country_id": "United States"
    },
    {
      "label": "Connecticut",
      "value": "Connecticut",
      "country_id": "United States"
    },
    {
      "label": "Delaware",
      "value": "Delaware",
      "country_id": "United States"
    },
    {
      "label": "District of Columbia",
      "value": "District of Columbia",
      "country_id": "United States"
    },
    {
      "label": "Florida",
      "value": "Florida",
      "country_id": "United States"
    },
    {
      "label": "Georgia",
      "value": "Georgia",
      "country_id": "United States"
    },
    {
      "label": "Hawaii",
      "value": "Hawaii",
      "country_id": "United States"
    },
    {
      "label": "Idaho",
      "value": "Idaho",
      "country_id": "United States"
    },
    {
      "label": "Illinois",
      "value": "Illinois",
      "country_id": "United States"
    },
    {
      "label": "Indiana",
      "value": "Indiana",
      "country_id": "United States"
    },
    {
      "label": "Iowa",
      "value": "Iowa",
      "country_id": "United States"
    },
    {
      "label": "Kansas",
      "value": "Kansas",
      "country_id": "United States"
    },
    {
      "label": "Kentucky",
      "value": "Kentucky",
      "country_id": "United States"
    },
    {
      "label": "Louisiana",
      "value": "Louisiana",
      "country_id": "United States"
    },
    {
      "label": "Lowa",
      "value": "Lowa",
      "country_id": "United States"
    },
    {
      "label": "Maine",
      "value": "Maine",
      "country_id": "United States"
    },
    {
      "label": "Maryland",
      "value": "Maryland",
      "country_id": "United States"
    },
    {
      "label": "Massachusetts",
      "value": "Massachusetts",
      "country_id": "United States"
    },
    {
      "label": "Medfield",
      "value": "Medfield",
      "country_id": "United States"
    },
    {
      "label": "Michigan",
      "value": "Michigan",
      "country_id": "United States"
    },
    {
      "label": "Minnesota",
      "value": "Minnesota",
      "country_id": "United States"
    },
    {
      "label": "Mississippi",
      "value": "Mississippi",
      "country_id": "United States"
    },
    {
      "label": "Missouri",
      "value": "Missouri",
      "country_id": "United States"
    },
    {
      "label": "Montana",
      "value": "Montana",
      "country_id": "United States"
    },
    {
      "label": "Nebraska",
      "value": "Nebraska",
      "country_id": "United States"
    },
    {
      "label": "Nevada",
      "value": "Nevada",
      "country_id": "United States"
    },
    {
      "label": "New Hampshire",
      "value": "New Hampshire",
      "country_id": "United States"
    },
    {
      "label": "New Jersey",
      "value": "New Jersey",
      "country_id": "United States"
    },
    {
      "label": "New Jersy",
      "value": "New Jersy",
      "country_id": "United States"
    },
    {
      "label": "New Mexico",
      "value": "New Mexico",
      "country_id": "United States"
    },
    {
      "label": "New York",
      "value": "New York",
      "country_id": "United States"
    },
    {
      "label": "North Carolina",
      "value": "North Carolina",
      "country_id": "United States"
    },
    {
      "label": "North Dakota",
      "value": "North Dakota",
      "country_id": "United States"
    },
    {
      "label": "Ohio",
      "value": "Ohio",
      "country_id": "United States"
    },
    {
      "label": "Oklahoma",
      "value": "Oklahoma",
      "country_id": "United States"
    },
    {
      "label": "Ontario",
      "value": "Ontario",
      "country_id": "United States"
    },
    {
      "label": "Oregon",
      "value": "Oregon",
      "country_id": "United States"
    },
    {
      "label": "Pennsylvania",
      "value": "Pennsylvania",
      "country_id": "United States"
    },
    {
      "label": "Ramey",
      "value": "Ramey",
      "country_id": "United States"
    },
    {
      "label": "Rhode Island",
      "value": "Rhode Island",
      "country_id": "United States"
    },
    {
      "label": "South Carolina",
      "value": "South Carolina",
      "country_id": "United States"
    },
    {
      "label": "South Dakota",
      "value": "South Dakota",
      "country_id": "United States"
    },
    {
      "label": "Sublimity",
      "value": "Sublimity",
      "country_id": "United States"
    },
    {
      "label": "Tennessee",
      "value": "Tennessee",
      "country_id": "United States"
    },
    {
      "label": "Texas",
      "value": "Texas",
      "country_id": "United States"
    },
    {
      "label": "Trimble",
      "value": "Trimble",
      "country_id": "United States"
    },
    {
      "label": "Utah",
      "value": "Utah",
      "country_id": "United States"
    },
    {
      "label": "Vermont",
      "value": "Vermont",
      "country_id": "United States"
    },
    {
      "label": "Virginia",
      "value": "Virginia",
      "country_id": "United States"
    },
    {
      "label": "Washington",
      "value": "Washington",
      "country_id": "United States"
    },
    {
      "label": "West Virginia",
      "value": "West Virginia",
      "country_id": "United States"
    },
    {
      "label": "Wisconsin",
      "value": "Wisconsin",
      "country_id": "United States"
    },
    {
      "label": "Wyoming",
      "value": "Wyoming",
      "country_id": "United States"
    },
    {
      "label": "United States Minor Outlying I",
      "value": "United States Minor Outlying I",
      "country_id": "United States Minor Outlying Islands"
    },
    {
      "label": "Artigas",
      "value": "Artigas",
      "country_id": "Uruguay"
    },
    {
      "label": "Canelones",
      "value": "Canelones",
      "country_id": "Uruguay"
    },
    {
      "label": "Cerro Largo",
      "value": "Cerro Largo",
      "country_id": "Uruguay"
    },
    {
      "label": "Colonia",
      "value": "Colonia",
      "country_id": "Uruguay"
    },
    {
      "label": "Durazno",
      "value": "Durazno",
      "country_id": "Uruguay"
    },
    {
      "label": "FLorida",
      "value": "FLorida",
      "country_id": "Uruguay"
    },
    {
      "label": "Flores",
      "value": "Flores",
      "country_id": "Uruguay"
    },
    {
      "label": "Lavalleja",
      "value": "Lavalleja",
      "country_id": "Uruguay"
    },
    {
      "label": "Maldonado",
      "value": "Maldonado",
      "country_id": "Uruguay"
    },
    {
      "label": "Montevideo",
      "value": "Montevideo",
      "country_id": "Uruguay"
    },
    {
      "label": "Paysandu",
      "value": "Paysandu",
      "country_id": "Uruguay"
    },
    {
      "label": "Rio Negro",
      "value": "Rio Negro",
      "country_id": "Uruguay"
    },
    {
      "label": "Rivera",
      "value": "Rivera",
      "country_id": "Uruguay"
    },
    {
      "label": "Rocha",
      "value": "Rocha",
      "country_id": "Uruguay"
    },
    {
      "label": "Salto",
      "value": "Salto",
      "country_id": "Uruguay"
    },
    {
      "label": "San Jose",
      "value": "San Jose",
      "country_id": "Uruguay"
    },
    {
      "label": "Soriano",
      "value": "Soriano",
      "country_id": "Uruguay"
    },
    {
      "label": "Tacuarembo",
      "value": "Tacuarembo",
      "country_id": "Uruguay"
    },
    {
      "label": "Treinta y Tres",
      "value": "Treinta y Tres",
      "country_id": "Uruguay"
    },
    {
      "label": "Andijon",
      "value": "Andijon",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Buhoro",
      "value": "Buhoro",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Buxoro Viloyati",
      "value": "Buxoro Viloyati",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Cizah",
      "value": "Cizah",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Fargona",
      "value": "Fargona",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Horazm",
      "value": "Horazm",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Kaskadar",
      "value": "Kaskadar",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Korakalpogiston",
      "value": "Korakalpogiston",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Namangan",
      "value": "Namangan",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Navoi",
      "value": "Navoi",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Samarkand",
      "value": "Samarkand",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Sirdare",
      "value": "Sirdare",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Surhondar",
      "value": "Surhondar",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Toskent",
      "value": "Toskent",
      "country_id": "Uzbekistan"
    },
    {
      "label": "Malampa",
      "value": "Malampa",
      "country_id": "Vanuatu"
    },
    {
      "label": "Penama",
      "value": "Penama",
      "country_id": "Vanuatu"
    },
    {
      "label": "Sanma",
      "value": "Sanma",
      "country_id": "Vanuatu"
    },
    {
      "label": "Shefa",
      "value": "Shefa",
      "country_id": "Vanuatu"
    },
    {
      "label": "Tafea",
      "value": "Tafea",
      "country_id": "Vanuatu"
    },
    {
      "label": "Torba",
      "value": "Torba",
      "country_id": "Vanuatu"
    },
    {
      "label": "Vatican City State (Holy See)",
      "value": "Vatican City State (Holy See)",
      "country_id": "Vatican City State (Holy See)"
    },
    {
      "label": "Amazonas",
      "value": "Amazonas",
      "country_id": "Venezuela"
    },
    {
      "label": "Anzoategui",
      "value": "Anzoategui",
      "country_id": "Venezuela"
    },
    {
      "label": "Apure",
      "value": "Apure",
      "country_id": "Venezuela"
    },
    {
      "label": "Aragua",
      "value": "Aragua",
      "country_id": "Venezuela"
    },
    {
      "label": "Barinas",
      "value": "Barinas",
      "country_id": "Venezuela"
    },
    {
      "label": "Bolivar",
      "value": "Bolivar",
      "country_id": "Venezuela"
    },
    {
      "label": "Carabobo",
      "value": "Carabobo",
      "country_id": "Venezuela"
    },
    {
      "label": "Cojedes",
      "value": "Cojedes",
      "country_id": "Venezuela"
    },
    {
      "label": "Delta Amacuro",
      "value": "Delta Amacuro",
      "country_id": "Venezuela"
    },
    {
      "label": "Distrito Federal",
      "value": "Distrito Federal",
      "country_id": "Venezuela"
    },
    {
      "label": "Falcon",
      "value": "Falcon",
      "country_id": "Venezuela"
    },
    {
      "label": "Guarico",
      "value": "Guarico",
      "country_id": "Venezuela"
    },
    {
      "label": "Lara",
      "value": "Lara",
      "country_id": "Venezuela"
    },
    {
      "label": "Merida",
      "value": "Merida",
      "country_id": "Venezuela"
    },
    {
      "label": "Miranda",
      "value": "Miranda",
      "country_id": "Venezuela"
    },
    {
      "label": "Monagas",
      "value": "Monagas",
      "country_id": "Venezuela"
    },
    {
      "label": "Nueva Esparta",
      "value": "Nueva Esparta",
      "country_id": "Venezuela"
    },
    {
      "label": "Portuguesa",
      "value": "Portuguesa",
      "country_id": "Venezuela"
    },
    {
      "label": "Sucre",
      "value": "Sucre",
      "country_id": "Venezuela"
    },
    {
      "label": "Tachira",
      "value": "Tachira",
      "country_id": "Venezuela"
    },
    {
      "label": "Trujillo",
      "value": "Trujillo",
      "country_id": "Venezuela"
    },
    {
      "label": "Vargas",
      "value": "Vargas",
      "country_id": "Venezuela"
    },
    {
      "label": "Yaracuy",
      "value": "Yaracuy",
      "country_id": "Venezuela"
    },
    {
      "label": "Zulia",
      "value": "Zulia",
      "country_id": "Venezuela"
    },
    {
      "label": "Bac Giang",
      "value": "Bac Giang",
      "country_id": "Vietnam"
    },
    {
      "label": "Binh Dinh",
      "value": "Binh Dinh",
      "country_id": "Vietnam"
    },
    {
      "label": "Binh Duong",
      "value": "Binh Duong",
      "country_id": "Vietnam"
    },
    {
      "label": "Da Nang",
      "value": "Da Nang",
      "country_id": "Vietnam"
    },
    {
      "label": "Dong Bang Song Cuu Long",
      "value": "Dong Bang Song Cuu Long",
      "country_id": "Vietnam"
    },
    {
      "label": "Dong Bang Song Hong",
      "value": "Dong Bang Song Hong",
      "country_id": "Vietnam"
    },
    {
      "label": "Dong Nai",
      "value": "Dong Nai",
      "country_id": "Vietnam"
    },
    {
      "label": "Dong Nam Bo",
      "value": "Dong Nam Bo",
      "country_id": "Vietnam"
    },
    {
      "label": "Duyen Hai Mien Trung",
      "value": "Duyen Hai Mien Trung",
      "country_id": "Vietnam"
    },
    {
      "label": "Hanoi",
      "value": "Hanoi",
      "country_id": "Vietnam"
    },
    {
      "label": "Hung Yen",
      "value": "Hung Yen",
      "country_id": "Vietnam"
    },
    {
      "label": "Khu Bon Cu",
      "value": "Khu Bon Cu",
      "country_id": "Vietnam"
    },
    {
      "label": "Long An",
      "value": "Long An",
      "country_id": "Vietnam"
    },
    {
      "label": "Mien Nui Va Trung Du",
      "value": "Mien Nui Va Trung Du",
      "country_id": "Vietnam"
    },
    {
      "label": "Thai Nguyen",
      "value": "Thai Nguyen",
      "country_id": "Vietnam"
    },
    {
      "label": "Thanh Pho Ho Chi Minh",
      "value": "Thanh Pho Ho Chi Minh",
      "country_id": "Vietnam"
    },
    {
      "label": "Thu Do Ha Noi",
      "value": "Thu Do Ha Noi",
      "country_id": "Vietnam"
    },
    {
      "label": "Tinh Can Tho",
      "value": "Tinh Can Tho",
      "country_id": "Vietnam"
    },
    {
      "label": "Tinh Da Nang",
      "value": "Tinh Da Nang",
      "country_id": "Vietnam"
    },
    {
      "label": "Tinh Gia Lai",
      "value": "Tinh Gia Lai",
      "country_id": "Vietnam"
    },
    {
      "label": "Anegada",
      "value": "Anegada",
      "country_id": "Virgin Islands (British)"
    },
    {
      "label": "Jost van Dyke",
      "value": "Jost van Dyke",
      "country_id": "Virgin Islands (British)"
    },
    {
      "label": "Tortola",
      "value": "Tortola",
      "country_id": "Virgin Islands (British)"
    },
    {
      "label": "Saint Croix",
      "value": "Saint Croix",
      "country_id": "Virgin Islands (US)"
    },
    {
      "label": "Saint John",
      "value": "Saint John",
      "country_id": "Virgin Islands (US)"
    },
    {
      "label": "Saint Thomas",
      "value": "Saint Thomas",
      "country_id": "Virgin Islands (US)"
    },
    {
      "label": "Alo",
      "value": "Alo",
      "country_id": "Wallis And Futuna Islands"
    },
    {
      "label": "Singave",
      "value": "Singave",
      "country_id": "Wallis And Futuna Islands"
    },
    {
      "label": "Wallis",
      "value": "Wallis",
      "country_id": "Wallis And Futuna Islands"
    },
    {
      "label": "Bu Jaydur",
      "value": "Bu Jaydur",
      "country_id": "Western Sahara"
    },
    {
      "label": "Wad-adh-Dhahab",
      "value": "Wad-adh-Dhahab",
      "country_id": "Western Sahara"
    },
    {
      "label": "al-''Ayun",
      "value": "al-''Ayun",
      "country_id": "Western Sahara"
    },
    {
      "label": "as-Samarah",
      "value": "as-Samarah",
      "country_id": "Western Sahara"
    },
    {
      "label": "Adan",
      "value": "Adan",
      "country_id": "Yemen"
    },
    {
      "label": "Abyan",
      "value": "Abyan",
      "country_id": "Yemen"
    },
    {
      "label": "Dhamar",
      "value": "Dhamar",
      "country_id": "Yemen"
    },
    {
      "label": "Hadramaut",
      "value": "Hadramaut",
      "country_id": "Yemen"
    },
    {
      "label": "Hajjah",
      "value": "Hajjah",
      "country_id": "Yemen"
    },
    {
      "label": "Hudaydah",
      "value": "Hudaydah",
      "country_id": "Yemen"
    },
    {
      "label": "Ibb",
      "value": "Ibb",
      "country_id": "Yemen"
    },
    {
      "label": "Lahij",
      "value": "Lahij",
      "country_id": "Yemen"
    },
    {
      "label": "Ma''rib",
      "value": "Ma''rib",
      "country_id": "Yemen"
    },
    {
      "label": "Madinat San''a",
      "value": "Madinat San''a",
      "country_id": "Yemen"
    },
    {
      "label": "Sa''dah",
      "value": "Sa''dah",
      "country_id": "Yemen"
    },
    {
      "label": "Sana",
      "value": "Sana",
      "country_id": "Yemen"
    },
    {
      "label": "Shabwah",
      "value": "Shabwah",
      "country_id": "Yemen"
    },
    {
      "label": "Ta''izz",
      "value": "Ta''izz",
      "country_id": "Yemen"
    },
    {
      "label": "al-Bayda",
      "value": "al-Bayda",
      "country_id": "Yemen"
    },
    {
      "label": "al-Hudaydah",
      "value": "al-Hudaydah",
      "country_id": "Yemen"
    },
    {
      "label": "al-Jawf",
      "value": "al-Jawf",
      "country_id": "Yemen"
    },
    {
      "label": "al-Mahrah",
      "value": "al-Mahrah",
      "country_id": "Yemen"
    },
    {
      "label": "al-Mahwit",
      "value": "al-Mahwit",
      "country_id": "Yemen"
    },
    {
      "label": "Central Serbia",
      "value": "Central Serbia",
      "country_id": "Yugoslavia"
    },
    {
      "label": "Kosovo and Metohija",
      "value": "Kosovo and Metohija",
      "country_id": "Yugoslavia"
    },
    {
      "label": "Montenegro",
      "value": "Montenegro",
      "country_id": "Yugoslavia"
    },
    {
      "label": "Republic of Serbia",
      "value": "Republic of Serbia",
      "country_id": "Yugoslavia"
    },
    {
      "label": "Serbia",
      "value": "Serbia",
      "country_id": "Yugoslavia"
    },
    {
      "label": "Vojvodina",
      "value": "Vojvodina",
      "country_id": "Yugoslavia"
    },
    {
      "label": "Central",
      "value": "Central",
      "country_id": "Zambia"
    },
    {
      "label": "Copperbelt",
      "value": "Copperbelt",
      "country_id": "Zambia"
    },
    {
      "label": "Eastern",
      "value": "Eastern",
      "country_id": "Zambia"
    },
    {
      "label": "Luapala",
      "value": "Luapala",
      "country_id": "Zambia"
    },
    {
      "label": "Lusaka",
      "value": "Lusaka",
      "country_id": "Zambia"
    },
    {
      "label": "North-Western",
      "value": "North-Western",
      "country_id": "Zambia"
    },
    {
      "label": "Northern",
      "value": "Northern",
      "country_id": "Zambia"
    },
    {
      "label": "Southern",
      "value": "Southern",
      "country_id": "Zambia"
    },
    {
      "label": "Western",
      "value": "Western",
      "country_id": "Zambia"
    },
    {
      "label": "Bulawayo",
      "value": "Bulawayo",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Harare",
      "value": "Harare",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Manicaland",
      "value": "Manicaland",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Mashonaland Central",
      "value": "Mashonaland Central",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Mashonaland East",
      "value": "Mashonaland East",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Mashonaland West",
      "value": "Mashonaland West",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Masvingo",
      "value": "Masvingo",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Matabeleland North",
      "value": "Matabeleland North",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Matabeleland South",
      "value": "Matabeleland South",
      "country_id": "Zimbabwe"
    },
    {
      "label": "Midlands",
      "value": "Midlands",
      "country_id": "Zimbabwe"
    }
  ]