import {countries} from './countries';
import { states } from './states';

export const getAllCountries = () =>{
    return countries;
}
export const getAllStates = () =>{
    return states;
}
export const getStateByCountry = (countryId) => {
    const stateData = states.filter(data => data.country_id == countryId)
    return stateData
} 
export const formatStates = async () =>{
    let data = [];
    await states.map(async dt=>{
      const cont = await countries.filter(co=>co.value==dt.country_id)
      if(cont.length > 0){
      data.push({
          label:dt.label,
          value:dt.label,
          country_id:cont[0].label
          
      })
    }
    })
    return data;
}