export default function usersApi(http) {
    return {
      create: (data) => {
        return http.post('/api/addUser', data)
      },
      checkToken:()=>{
        return http.post('/api/checktoken', {})
      },
      delete:(id)=>{
        return http.post('/api/delete-user',id)
      },
      getUserByID:(id) =>{
        return http.post('/api/getUserById',id)
      },
      update: (user) => {
        return http.post('/api/update-user', user)
      },
      download:(key) => {
        return http.post('/api/download-user',key)
      },
      addCustomer: (user) => {
        return http.post('/api/add-customer', user)
      },
      updateCustomer: (user) => {
        return http.post('/api/update-customer', user)
      },
      deleteCustomer:(id)=>{
        return http.post('/api/delete-customer',id)
      },

    }
  }
  