import React from 'react';
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss'

import AuthorisedRoutes from '../containers/pages/authorised';
import Forgot from '../containers/pages/unauthorised/forgot/Forgot'
import Login from '../containers/pages/unauthorised/login/Login'
import Register from '../containers/pages/unauthorised/register/Register'
import ProtectedRoutes from './protected/Protected'
import Reset from '../containers/pages/unauthorised/reset/Reset';

const App = () => (
    <div className="app-routes">
        <Switch>
             <Route path="/login" component={ Login } />
             <Route path="/register" component={ Register } />
             <Route path="/forgot" component={ Forgot } />
             <Route path="/state-password/:id" component={ Reset } />
             <ProtectedRoutes path="/" component={ AuthorisedRoutes } />
        </Switch>
      <ToastContainer role="alert" autoClose={2000}
        className="toast-container"
        toastClassName="dark-toast"/>
    </div>
)

export default App;
