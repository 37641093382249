import fetch from 'isomorphic-unfetch';
import CryptoJS from 'crypto-js'
import {toaster} from '../components/toaster';
export function httpClient(baseURL) {
  
  return {
    get: (path, options) => {
      
      const url = baseURL + path

   //   let cryptUrl = CryptoJS.AES.encrypt(JSON.stringify(url), 'my-secret-key@123').toString();
      // let bytes = CryptoJS.AES.decrypt(cryptUrl, 'my-secret-key@123');
      // let decryptedUrl = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      return fetch(url, options).then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      });
    },
    // post: (path, body, options = {}) => {
    //   return fetch(baseURL + path, {
    //     ...options,
    //     method: 'POST',
    //     body: JSON.stringify(body),
    //     headers: {
    //       'Content-Type': 'application/json',
    //       ...options.headers
    //     }
    //   }).then((res) => {
    //     if(res.status === 401)
    //       window.location.href='/login'
    //     if(res.status === 403)
    //       toaster("Invalid Access",'error')
    //     return res.json();
    //   }).catch(err=>{
    //     console.log(err.status)
    //     if(err.status === 401)
    //     window.location.href='/login'
    //     else
    //     toaster("Something went wrong",'error')
    //   });
    // }

    post: (path, body, rpt, options = {}) => {
    //   let exp = ''
    //  const page = path.split("/")
    //  if(page[2] === "export-report"){
    //    exp = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    //  }else{
    //    exp = 'application/json'

    //  }
      return fetch(baseURL + path, {
        ...options,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        }
      }).then((res) => {
        if(res.status === 401)
          window.location.href='/login'
        if(res.status === 403)
          toaster("Invalid Access",'error')
        return res.json();
      }).catch(err=>{
        console.log(err.status)
        if(err.status === 401)
        window.location.href='/login'
        else
        toaster("Something went wrong",'error')
      });
    }
  };
}
