export default function brandApi(http) {
    return {
      create: (brand) => {
        return http.post('/api/add-brand', brand)
      },
      getBrandById: (id) => {
        return http.post('/api/getBrandById',id)
      },
      update: (brand) => {
        return http.post('/api/update-brand', brand)
      },
      delete: (id) => {
        return http.post('/api/delete-brand', id)
      },
      getBramd:()=>{
        return http.get('/api/brands')
      },
      getBrandList:(data)=>{
        return http.post('/api/brandList',data)
      }
    }
  }
  