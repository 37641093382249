import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Loadable from "react-loadable";
import { createBrowserHistory } from "history"
import App from "./components/App";
import { ServerDataProvider } from "./state/serverDataContext";

const serverData = window.__SERVER_DATA__;

const hist = createBrowserHistory()


export const main = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(
      <ServerDataProvider value={serverData}>
        <Router history={hist}>
          <App />
        </Router>
      </ServerDataProvider>,
      document.getElementById("root")
    );
  });
};
