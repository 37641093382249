import React, { useContext, useEffect } from 'react';
import Head from '../../../components/Head';
import { routes } from '../../../appRoutes/AppRoutes';
import Sidebar from '../../../components/partials/sidebar/Sidebar';
import Navbar from '../../../components/partials/navbar/Navbar';
import Footer from '../../../components/partials/footer/Footer';
import { Route } from 'react-router-dom';
import AppContext from '../../../state/context';
const AuthorisedRoutes = () => {
  const { state: { role } } = useContext(AppContext)
  return (
    <div id="wrapper">
      <Head />
      <Sidebar />
      <div id="content-wrapper">
        <Navbar />
        {routes.map((route, index) => {
          const access = route.role.indexOf(role)
          if(access >= 0){
            return  (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props) => (<route.main roles={route.role} {...props}/>)}
              />
            )
          }else return null
        })}
        <Footer />
      </div>
    </div>
  )
};

export default AuthorisedRoutes;
