import React,{useState,useEffect} from 'react';
import Prototype from 'prop-types';

export default function Modal(props) {
    const { showModal, closeModal, id, modalHeader, children,size } = props
    const [modalSize,setModalSize] = useState('modal-lg');
    useEffect(()=>{
        if(size === 'small'){
            setModalSize('modal-xs')
        }
    },[])
    
    return (
        <div className={`${showModal ? 'modal fade CloseTicket show' : ''}`}
            id={id}
            style={{
                display: showModal ? 'block' : 'none',
                background: ' rgba(0, 0, 0, 0.75)',

            }}>
                <div className={`${modalSize} modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
                    <div className="modal-content model-box" style={{ width: '100%' }}>
                        <h2 className="modal-header">{modalHeader}</h2>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal">&times;</button>
                        <div className="modal-body modal-up">
                            {children}
                        </div>
                    </div>
                </div>
        </div>

    )
}
Modal.protoType = {
    showModal: Prototype.bool.isRequired,
    closeModal: Prototype.bool.isRequired,
    id: Prototype.string.isRequired,
    modalHeader: Prototype.string,
    children: Prototype.node
}
