import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import { imagePath } from "../../../../utils/assetUtils";
import Input from '../../../../components/input';
import { api } from '../../../../api';
import { ResetSchema } from '../login/loginValidationSchema';
import Loader from '../../../../components/loader/Loader';
import { toaster } from '../../../../components/toaster';

const ResetImage = imagePath("login-logo.png");

const Reset = () => {

    const token = useParams().id
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    return (
        <Formik
            validationSchema={ResetSchema}
            initialValues={{
                password: '',
                token: token
            }}
            onSubmit={(values, { resetForm }) => {
                setLoading(true)
                const formBody = { newPassword: values.password, token: values.token }
                api.authenticate.reset(formBody).then(res => {
                    if (res.success === true) {
                        setLoading(false)
                        toaster(res.message, 'success')
                        resetForm()
                        history.push('/login')
                    }
                    else {
                        setLoading(false)
                        toaster(res.message, 'error')
                        resetForm();
                    }
                }).catch(err => {
                    toaster("Oops something went wrong")
                })
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting

            }) => {
                return (

                    <div className="container">

                        <div className="login-main">

                            <div className="card">
                                <div className="welcome">
                                    <h5>Welcome Back !</h5>
                                    <p>Sign in to continue to Nodwin.</p>
                                </div>
                                <div className="card-body">
                                    <img className="login-logo" src={ResetImage} alt="login-logo" />
                                    <Form onSubmit={handleSubmit} className="form-horizontal">
                                        <div className="form-group">
                                            <Input
                                                name="password"
                                                id='password'
                                                value={values.password}
                                                onChange={handleChange}
                                                label="New Password"
                                                type="password"
                                                placeholder="Enter new password"
                                                error={touched.password && errors.password}
                                                errorText={errors.password}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group login-btn">
                                            <button disabled={isSubmitting} type="submit" className="login-btn1 btn">
                                                {loading ? <Loader /> : 'Reset'}
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <div className="copy-text1 text-center">
                                <p >Copyright © All Rights Reserved by Nodwin Gaming</p>
                            </div>

                        </div>


                    </div>
                )
            }}
        </Formik>
    )
}
export default Reset;