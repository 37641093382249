export default function authenticateApi(http) {
    return {
      loginAuthenticate: (data) => {
        return http.post('/api/userLogin', data)
      },
      logout: (data) => {
        return http.post('/api/logout', data)
      },
      forgot: (data) => {
        return http.post("/api/forgot", data)
      },
      reset: (data) => {
        return http.post("/api/resetPassword", data)
      },
      merchentRequest: (data) => {
        return http.post("/api/merchentRequest", data)
      }
    }
  }
  