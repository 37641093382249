import React, { useContext, useEffect } from "react";

import { useHistory } from 'react-router-dom'
import "./Navbar.scss";

import { Link } from "react-router-dom";

//contxt
import AppContext from '../../../state/context'
import { api } from '../../../api';
export default function Navbar() {

  const history = useHistory()
  const { state: { username }, toggleSidebar } = useContext(AppContext)
  const handleLogout = () => {
    api.authenticate.logout().then(res => {
      history.push('/login')
    })
  }

  return (
    <nav className="navbar navbar-expand  static-top">
      <button
        onClick={ toggleSidebar }
        className="btn btn-link btn-sm text-white"
        id="sidebarToggle"
        style={{ border: 'none' }}
      >
        <i className="bx bx-menu"></i>
      </button>

      <ul className="navbar-nav nav-right-ul ml-auto">
        <li className="nav-item dropdown no-arrow">
          <Link
            className="nav-link dropdown-toggle"
            to="/"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span className="user-name mr-2">{username}</span>
            <i className="bx bx-chevron-down"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="userDropdown"
            style={{ right: '10px' }}
          >
            <Link className="dropdown-item" to="/profile" style={{ fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
              <i className="bx bx-user"  style={{ fontSize: '15px'}}></i> Profile{" "}
            </Link>
            <div className="dropdown-divider"></div>
            <button className="dropdown-item" style={{ fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }} onClick={handleLogout}>
              <i className="bx bx-power-off" style={{ fontSize: '15px'}}></i> Logout
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
}
