import * as yup from 'yup';

const Schema = yup.object({
    username: yup.string().required("Name is required!"),
    password: yup.string().required("Password is required!"),
});
export default Schema;
export const ForgotSchema = yup.object({
    email: yup.string().email().required("Email is required!"),
});
export const ResetSchema = yup.object({
    password: yup.string().required("New password is required!"),
});