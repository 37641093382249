import React, { useState } from 'react'
import { Formik, Form } from 'formik';

import { api } from '../../../../api';
import { ForgotSchema } from '../login/loginValidationSchema';
import { imagePath } from "../../../../utils/assetUtils";
import Input from '../../../../components/input';
import Loader from '../../../../components/loader/Loader';
import { useHistory, Link } from 'react-router-dom';
import { toaster } from '../../../../components/toaster';

const ForgotImage = imagePath("login-logo.png");

const Forgot = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    return (
        <Formik
            validationSchema={ForgotSchema}
            initialValues={{
                email: ''
            }}
            onSubmit={(values, { resetForm }) => {
                setLoading(true)
                const data = { userEmail: values.email }
                api.authenticate.forgot(data).then(res => {
                    if (res.success === true) {
                        setLoading(false)
                        toaster(res.data.message, 'success')
                        resetForm()
                    }
                    else {
                        setLoading(false)
                        toaster(res.message, 'error')
                        resetForm();
                    }
                })
                    .catch(err => {
                        resetForm()
                        toaster("Oops! Something went wrong", 'error')
                    })
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting

            }) => {
                return (

                    <div className="container">

                        <div className="login-main">

                            <div className="card">
                                <div className="welcome">
                                    <h5>Welcome Back !</h5>
                                    <p>Sign in to continue to Nodwin.</p>
                                </div>
                                <div className="card-body">
                                    <img className="login-logo" src={ForgotImage} alt="login-logo" />
                                    <Form onSubmit={handleSubmit} className="form-horizontal">
                                        <div className="form-group">
                                            <Input
                                                name="email"
                                                id='email'
                                                value={values.email}
                                                onChange={handleChange}
                                                label="Email"
                                                type="text"
                                                placeholder="Enter email"
                                                error={touched.email && errors.email}
                                                errorText={errors.email}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-group login-btn">
                                            <button disabled={isSubmitting} type="submit" className="login-btn1 btn">
                                                {loading ? <Loader /> : 'Reset'}
                                            </button>
                                        </div>
                                        <div className="forgot text-center">
                                            <Link to="/login">
                                                <i></i> Sign In
                                            </Link>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <div className="copy-text1 text-center">
                                <p >Copyright © All Rights Reserved by Nodwin Gaming</p>
                            </div>

                        </div>


                    </div>
                )
            }}
        </Formik>

    )
}
export default Forgot;