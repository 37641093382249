export default function productApi(http) {
  return {
    create: (product) => {
      return http.post('/api/add-product', product)
    },
    createMaster: (masterData) => {
      return http.post('/api/add-master', masterData)
    },
    updateMasterProduct: (masterData) => {
      return http.post('/api/edit-master', masterData)
    },
    getMasterProducts:(data)=>{
      return http.post('/api/masterList', data)
    },
    getMasterProductById: (id) => {
      return http.post('/api/getMasterProductById', id)
    },
    //
    getMasterProductsByMerchantId: (id) => {
      return http.post('/api/getMasterProductsByMerchantId', id)
    },
    //
    getMasterProduct: (data) => {
      return http.post('/api/getMasterProduct', data)
    },
    deleteMasterProduct: (id) => {
      return http.post('/api/deleteMasterProduct', id)
    },
    uploadProductKeys: (upload) => {
      return http.post('/api/add-uploadProductKeys', upload)
    },
    setTransaction: (transaction) => {
      return http.post('/api/set-transaction', transaction)
    },
    getProducts:()=>{
      return http.get('/api/products')
    },
    getProductsList:(data)=>{
      return http.post('/api/productsList',data)
    },
    getProductById:(id)=>{
      return http.post('/api/getProductById', id)
    },
    updateProductById:(data)=>{
      return http.post('/api/updateProduct', data)
    },
    deleteProductById:(id)=>{
      return http.post('/api/deleteProduct', id)
    },
    getTransactionListById:(id)=>{
      return http.post('/api/getTransactionCodes', id)
    },
    getMasterData:(data)=>{
      return http.post("/api/getMasterProduct", data)
    },
    getEzPinProducts:(data)=>{
      return http.post('/api/fetchProducts',data)
    },
    getGenbaProducts:(data)=>{
      return http.post('/getGenbaProductsList',data)
    },
    getGenbaProductPrice:(data)=>{
      return http.post("/getGenbaProductPrice", data)
    },
    getAppIdForGenbaProduct:(data)=>{
      console.log(data, "RequiredPayload");
      return http.post("/getAppIdForGenbaProduct", data)
    },

  }
}
