import React, { useState } from "react";

const usePasswordToggle = () => {
  const [visible, setVisible] = useState(false);
  const Icon = (
    <i
      className={`fa ${visible ? "fa-eye-slash" : "fa-eye"}`}
      onClick={() => setVisible((shown) => !shown)}
    />
  );
  const InputType = visible ? "text" : "password";
  return [InputType, Icon];
};
export default usePasswordToggle;
