
// withAuth.jsx
import React, { useEffect, useState, useContext } from 'react';
import jwt from 'jsonwebtoken';
import AppContext from '../../state/context';
const sec = process.env.cookieSecretData;
export const useAuth = () => {
    const [isAuthenticate, setIsAuthenticate] = useState(false);
    const [loading, setLoading] = useState(true);
    const { setRole,setUserName, setUserId, setCdn } = useContext(AppContext)
    const getCookie = (cname) =>{
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    useEffect(() => {
        setLoading(true);
        const usertoken = getCookie('usertokenData');
        if (usertoken) {
            jwt.verify(usertoken, sec, function (err, decoded) {
                if (err) {
                    setLoading(false);
                    setIsAuthenticate(false);
                } else {
                    setRole(decoded.userType);
                    setUserName(decoded.userName);
                    setUserId(decoded.id);
                    setCdn(decoded.CDN);
                    setLoading(false);
                    setIsAuthenticate(true);
                }
            });
        } else {
            setLoading(false);
            setIsAuthenticate(false);
        }
    }, [])

    return [isAuthenticate, loading];
}