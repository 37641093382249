export const initialState = {
    role: 0,
    username: '',
    setUserId: null,
    cdn: ''
  };


const reducer = (state , action) => {
    switch (action.type) {
        case 'SET_ROLE':
            return {
                ...state,
                role: parseInt(action.payload)
            }
        case 'SET_NAME':
            return {
                ...state,
                username: action.payload
            }
        case 'SET_USER_ID':
            return {
                ...state,
                setUserId: action.payload
            }
        case 'SET_CDN':
            return {
                ...state,
                cdn: action.payload
            }
        default:
            return state
    }
}

export default reducer