import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { Formik, Field, ErrorMessage } from 'formik';
import { Link } from "react-router-dom";

import Schema from './registerValidationSchema';
import Input from '../../../../components/input';
import DropdownComp from "../../../../components/dropdown";
import { imagePath } from "../../../../utils/assetUtils";
const LoginImage = imagePath("login-logo.png");
import { useAuth } from '../../../../components/auth/AuthRoute'
import { api } from '../../../../api';
import { toaster } from '../../../../components/toaster';
import Loader from "../../../../components/loader/Loader";
import usePasswordToggle from "../../../../components/hooks/usePasswordToggle";
import FleUpload from '../../../../components/fileUpload/FleUpload';
import { getBase64 } from '../../../../utils/converter';
import { getAllCountries, getStateByCountry } from '../../../../helpers/countryState';
import { color } from "highcharts";

export default function Register(props) {
  const options = [
    { value: 'business', label: 'Business' },
    { value: 'individual', label: 'Individual' }
  ];

  const checkApiAccess = (e) => {
    setApiAccess(e.target.checked);
  };
  const handleFileChange = (name, e, setFieldValue) => {
    console.log(name);
    console.log(e.currentTarget.files[0]);
    setFieldValue(name, e.currentTarget.files[0])
    getBase64(e.currentTarget.files[0], res => {
      console.log(res);
      setFieldValue(`${name}Base64`, res)
  })
   
}
  const countrydata = getAllCountries();
  const [countries] = useState(countrydata)
  const [states, setStates] = useState([])
  const [apiAccess, setApiAccess] = useState(false);
  const [isAuthenticate] = useAuth();
  const [loading, setLoading] = useState(false)
  const [isLoad,setLoad] = useState(false)
  const [ currentMerchant, setCurrentMerchant ] = useState('') 
  const [PwdInputType, ToggleIcon] = usePasswordToggle();
  const history = useHistory()
  useEffect(() => {
    isAuthenticate && history.push('/')
  })
  useEffect(() => {
    setLoading(false)
    setLoad(true)
  })
  const defaultCountry = countries.find(country => country.value == "India")
  const defaultState = getStateByCountry("India");
  return isLoad ?  (
    <Formik
      validationSchema={Schema}
      initialValues={{
        merchentType: '',
        name: '',
        address: '',
        city: '',
        pincode: '',
        state: '',
        country: '',
        contactName: '',
        mobile: '',
        email: '',
        pan: '',
        panBase64: '',
        gst: '',
        adhaar: '',
        adhaarBase64: '',
        terms:false,
        guidline:false
      }}
      onSubmit={(values, { resetForm }) => {
        setLoading(true)
        const data = {

          merchentType: values.merchentType,
          name: values.name,
          address: values.address,
          city: values.city,
          pincode: values.pincode,
          state: values.state.value,
          country: values.country.value,
          contactName: values.contactName,
          mobile: values.mobile,
          email: values.email,
          pan: values.pan,
          panBase64: values.panBase64,
          gst: values.gst,
          adhaar: values.adhaar,
          adhaarBase64: values.adhaarBase64
      }

        console.log(data);
        api.authenticate.merchentRequest(data)
          .then(res => {
            if (res.code === 200) {
              resetForm()
              setLoading(false)
              toaster(res.message, 'success')
            }
            else {
              toaster(res.message, 'error')
              // resetForm();
            }
          })
          .catch(err => {
            // resetForm()
            toaster("Oops! Something went wrong", 'error')
          })
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting

      }) => {
        return (

          <div className="container">
            <div className="register-main">
              <div className="card">
                <div className="welcome">
                  <h5>Merchant Registration Form</h5>
                  
                </div>

                <div className="card-body">
                  <img className="login-logo" src={LoginImage} alt="login-logo" />
                  
                  <form noValidate="" className="form-horizontal" onSubmit={handleSubmit}>
                  <div className="row regreqbox">

                  <div className="form-group col-md-12">
                  <label htmlFor="merchentType">Merchant Type</label>
                  <Field as="select" name="merchentType"  className={"form-control"}>
                  <option value="" disabled>Select Merchant Type</option>
                    {options.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="merchentType" component="div" className="text-danger"/>
            
                    </div>

                    <div className="form-group col-md-6">
                      <Input
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        type="text"
                        label={values.merchentType === 'business' ? 'Company Name' : 'Name'}
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && errors.name}
                        errorText={errors.name}
                        disabled={isSubmitting} />
                    </div>

                    <div className="form-group col-md-6">
                      <Input
                        style={{ width: '100%' }}
                        id="address"
                        name="address"
                        placeholder="Registered Address"
                        type="text"
                        label="Registered Address"
                        value={values.address}
                        onChange={handleChange}
                        error={touched.address && errors.address}
                        errorText={errors.address}
                        disabled={isSubmitting}
                    />
                    </div>

                    <div className="form-group col-md-6">
                      <Input
                        style={{ width: '100%' }}
                        id="city"
                        name="city"
                        placeholder="City"
                        type="text"
                        label="City"
                        value={values.city}
                        onChange={handleChange}
                        error={touched.city && errors.city}
                        errorText={errors.city}
                        disabled={isSubmitting}
                        />
                    </div>

                    <div className="form-group col-md-6">
                      <Input
                        style={{ width: '100%' }}
                        id="pincode"
                        name="pincode"
                        placeholder="Pincode"
                        type="text"
                        label="Pincode"
                        value={values.pincode}
                        onChange={handleChange}
                        error={touched.pincode && errors.pincode}
                        errorText={errors.pincode}
                        disabled={isSubmitting}
                        />
                    </div>

                    

                    <div className="form-group col-md-6">
                         <DropdownComp
                          name="country"
                          id="country"
                          label="Country"
                          placeholder="Please select"
                          onChange={(selectedValue) => {
                              setFieldValue("country", selectedValue)
                              setFieldValue("state", "")
                              const stateList = getStateByCountry(selectedValue.value)
                              setStates(stateList)
                          }}
                          value={values.country}
                          options={countries}
                          error={touched.country && errors.country}
                          errorText={errors.country}
                          disabled={isSubmitting}>
                      </DropdownComp>
                    </div>

                      <div className="form-group col-md-6">
                      <DropdownComp
                        id="state"
                        name="state"
                        onChange={(selectedValue) => {
                            setFieldValue("state", selectedValue)
                        }}
                        value={values.state}
                        options={values.country.value == "India" ? defaultState : states}
                        label="State"
                        placeholder="Please select"
                        error={touched.state && errors.state}
                        errorText={errors.state}
                        disabled={isSubmitting || values.country === ''}>
                    </DropdownComp>
                      </div>

                    <div className="form-group col-md-6">
                      <Input
                        style={{ width: '100%' }}
                        id="contactName"
                        name="contactName"
                        placeholder="Contact Person Name"
                        type="text"
                        label="Contact Person Name"
                        value={values.contactName}
                        onChange={handleChange}
                        error={touched.contactName && errors.contactName}
                        errorText={errors.contactName}
                        disabled={isSubmitting}
                         />
                    </div>


                    <div className="form-group col-md-6">
                      <Input
                        style={{ width: '100%' }}
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile"
                        type="text"
                        label="Mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        error={touched.mobile && errors.mobile}
                        errorText={errors.mobile}
                        disabled={isSubmitting}
                       />
                    </div>

                    <div className="form-group col-md-6">
                      <Input
                        style={{ width: '100%' }}
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="text"
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                        errorText={errors.email}
                        disabled={isSubmitting}
                        />
                    </div>

                    <div className="form-group filesec col-md-6">
                    <FleUpload
                      uploadAlign={true}
                      onChange={(e) => {
                        
                          handleFileChange('pan', e, setFieldValue);
                      }}
                      name="pan"
                      value={values.pan && values.pan.name || ""}
                      labelText="PAN Card"
                      id="pan"
                      error={touched.pan && errors.pan}
                      errorText={errors.pan}
                      disabled={isSubmitting} />
                    </div>

                    {values.merchentType === 'business' && (
                    <div className="form-group col-md-6">
                  
                      <Input
                        style={{ width: '100%' }}
                        id="gst"
                        name="gst"
                        placeholder="GST Certificate"
                        type="text"
                        label="GST Certificate"
                        value={values.gst}
                        onChange={handleChange}
                        error={touched.gst && errors.gst}
                        errorText={errors.gst}
                        disabled={isSubmitting}
                         />
                       
                    </div>
                     )}

               
                    {values.merchentType === 'individual' && (
                    <div className="form-group col-md-6">
                
                <FleUpload
                      uploadAlign={true}
                      onChange={(e) => {
                        
                          handleFileChange('adhaar', e, setFieldValue);
                      }}
                      name="adhaar"
                      value={values.adhaar && values.adhaar.name || ""}
                      labelText="Aadhaar Card"
                      id="adhaar"
                      error={touched.adhaar && errors.adhaar}
                      errorText={errors.adhaar}
                      disabled={isSubmitting} />
                   
                    </div>
                       )}
{/* 
           <div className="form-group col-md-12"> 
           <div className="row"> 

             <div className="form-group col-md-6">  
             <Input
              style={{ width: '100%' }}
              id="territory"
              name="territory"
              placeholder="INDIA"
              type="text"
              label="Territory"
              value="INDIA"  // Default value set to 'INDIA'
              disabled={true}  // Input is disabled
            />
              </div>

              <div className="form-group col-md-6">  
             <Input
              style={{ width: '100%' }}
              id="exclusive"
              name="exclusive"
              placeholder="NA"
              type="text"
              label="Exclusivity (if applicable)"
              value="NA"  // Default value set to 'INDIA'
              disabled={true}  // Input is disabled
            />
              </div>

              
              <div className="form-group col-md-6">  
             <Input
              style={{ width: '100%' }}
              id="minWallet"
              name="minWallet"
              placeholder="NA"
              type="text"
              label="Minimum Wallet Balance"
              value="NA"  // Default value set to 'INDIA'
              disabled={true}  // Input is disabled
            />
              </div>

              <div className="form-group col-md-6">  
             <Input
              style={{ width: '100%' }}
              id="validityPeriod"
              name="validityPeriod"
              placeholder="NA"
              type="text"
              label="Validity Period"
              value="NA"  // Default value set to 'INDIA'
              disabled={true}  // Input is disabled
            />
              </div>

              <div className="form-group col-md-6">  
             <Input
              style={{ width: '100%' }}
              id="rollForecast"
              name="rollForecast"
              placeholder="NA"
              type="text"
              label="Rolling Forecast"
              value="NA"  // Default value set to 'INDIA'
              disabled={true}  // Input is disabled
            />
              </div>

              <div className="form-group col-md-6">  
             <Input
              style={{ width: '100%' }}
              id="additionalItem"
              name="additionalItem"
              placeholder="NA"
              type="text"
              label="Specific/Additional Terms (if any)"
              value="NA"  // Default value set to 'INDIA'
              disabled={true}  // Input is disabled
            />
              </div>




              </div>
              </div> */}

              <div className="col-md-12" style={{marginBottom:'20px',textAlign:'justify' }}>
              <p>By registering through this Merchant registration form "Online Form" You are hereby appointed and onboarded as a reseller for the Product, by NODWIN Gaming Private Limited and/or its affiliates or sister concerns. This Online Form shall be read in conjunction with the attached Reseller agreement terms and conditions "Terms and Conditions". By registering below, the Merchant acknowledges and expressly agrees to comply with the terms set forth herein and the Terms and Conditions. The Parties agree that any capitalized terms not defined in the Online Form shall have the meanings ascribed to them in the Terms and Conditions.</p>
              </div>

                    <div className="form-group col-md-12">
                    <label style={{ fontSize: '12px' }}>
                      <input
                        type="checkbox"
                        name="terms"
                        style={{ marginRight: '10px' }}
                        checked={values.terms}
                        onChange={handleChange}
                      />
                       I hereby confirm that I have read and understood the <a href="/terms/reseller_terms_and_conditions.docx"  target="_blank"  style={{ color:'blue' }}>Terms and Conditions</a>. I expressly state that I agree to the  Terms and Conditions and warrant to be fully compliant with the same
                    </label>
                    {touched.terms && errors.terms && (
                      <div className="text-danger">{errors.terms}</div>
                    )}
                  </div>

                  <div className="form-group col-md-12">
                    <label style={{ fontSize: '12px' }}>
                      <input
                        type="checkbox"
                        name="guidline"
                        style={{ marginRight: '10px' }}
                        checked={values.guidline}
                        onChange={handleChange}
                      />
                       I hereby confirm that I have read and understood the <a href="/terms/nodwin_gaming_logo_guidelines.pdf"  target="_blank"  style={{ color:'blue' }}>Branding Guidelines</a>. I expressly state that I agree to the Branding Guidelines and warrant to be fully compliant with the same
                    </label>
                    {touched.guidline && errors.guidline && (
                      <div className="text-danger">{errors.guidline}</div>
                    )}
                  </div>





                    </div>

                 

                    <div className="form-group login-btn">
                      <button disabled={isSubmitting} type="submit" className="login-btn1 btn mt-3 ">
                        {loading ? <Loader /> : 'Register'}
                      </button>
                    </div>
                    <div className="forgot text-center">
                      <Link to="/login">
                        <i className="bx bxs-lock"></i> Login
                    </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  ):"";
}
