import React, { useEffect, useState } from "react";
import Modal from '../modals/Modal';
import { useHistory } from 'react-router-dom'
import { toaster } from '../toaster';
import $ from "jquery";
require("datatables.net");
require("datatables.net-responsive");
require("datatables.net-select-dt");
import "./Table.scss";
let url = process.env.dataUrl;

const OrderDataTable = React.forwardRef((props, dataref) => {
  const history = useHistory()
  let datatable = null;
  let datalength = 0;


  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setshowActivateModal] = useState(false);
  const [dataRow, setDataRow] = useState('');
  //const {dataCsv, setDataCsv} = props;

  const csvDataHandler = (values) => {
    const csvHeaders = ["Id", "OrderDate", "Merchant Name", "Email", "Order ID", "Products", "Total Quantity", "Amount", "Available Amount", "Comment", "Status" ]
    const csvArray = []
    csvArray.push(csvHeaders)
    for(let i=0; i<values.data.length;i++){
      let currentArray = [`${values.data[i]?.orderId}` || "NA", `${values.data[i]?.orderDate}` || "NA", `${values.data[i]?.merchentName}` || "NA", `${values.data[i]?.orderEmailId}` || "NA", `${values.data[i]?.orderNo}` || "NA", `${values.data[i]?.ordersItems}` || "NA", `${values.data[i]?.ordersQuantity}` || "NA", `${values.data[i]?.orderAmount}` || "NA", `${values.data[i]?.balanceCredit}` || "NA", `${values.data[i]?.comment}` || "NA", `${values.data[i]?.status}` || "NA" ];
      csvArray.push(currentArray)
    }
    setDataCsv(csvArray)
  }



  const [propsChanged, setPropsChanged] = useState({});

  const closeModal = () => {
    setShowEditModal(false);
    setShowDeleteModal(false);
    setshowActivateModal(false);
  }
  const deleteDetail = () => {
    props.handleDelete(dataRow);
    setShowDeleteModal(false);
  }
  const activateDetail = () => {
    props.handleActive(dataRow);
    setshowActivateModal(false);
  }


  // useEffect(() => {
  //   console.log(props)
  //   dataInit(props)
  //   return () => {
  //     datatable.destroy(true);
  //   }
  // }, [])

  useEffect(() => {
    setPropsChanged(props);
  }, [])

  useEffect(() => {
    if (props !== undefined) {
      setPropsChanged(props);
    }
  }, [props.updatedReports])

  useEffect(() => {
    if (props !== undefined) {
      setPropsChanged(props);
    }
  }, [props.product_key])

  useEffect(() => {
    if (props !== undefined) {
      setPropsChanged(props);
    }

  }, [props.status])


  useEffect(() => {
    dataInit(props)
  }, [])


  useEffect(() => {
    const { dataref: { current }, updateData, setUpdateData } = props;
    if (updateData) {
      $(current).DataTable().draw(false)
      setUpdateData(false)
    }
  }, [!props.page && props.updateData])


  useEffect(() => {
    const { dataref: { current }, updateData, setUpdateData } = props;
    if (updateData) {
      $(current).DataTable().draw(false)
      setUpdateData(false)
    }
  }, [!props.page && props.updateData])



  useEffect(() => {
    const { dataref: { current }, updateData, setUpdateData, setReportLoad, reportLoad } = props;
    if (updateData && reportLoad) {
      dataInit(props)
      $(current).DataTable().draw()
      setReportLoad(false)
      setUpdateData(false)
    }
  })

  // useEffect(() => {
  //   // console.log("first")
  //   const { dataref: { current }, updateData, setUpdateData,reportLoad} = props;
  //   if (updateData) {
  //     if(reportLoad)
  //       $(current).DataTable().draw()
  //       else{
  //         $(current).DataTable().draw(false)
  //       }
  //     setUpdateData(false)
  //   }
  // })


  const dataInit = (items) => {

    const { dataref: { current }, columns, dataUrl, reStock, transactionModal,
      topUp, topUpHistory, editProduct, editAgent, viewAgent, viewOrder, editBrand, editadminAgent,
      viewBrand, handleView, setUpdateData, handleMerchant, handleDetail, handleEditMaster,
      viewProduct, page, type, startDate, endDate, handleEdit, editSupport, product_key,
      status, reportLoad, merchentId, productId, orderType, pageSize, handleMasterView,
      pageNo, testViewOrder, transactionType, handleViewMerchant, handleEditMerchant, handleTopupHistoryView,  handleCustomerInfoView, handleCustomerInfoEdit } = items;


    const ajaxOptions = {
      "url": url + dataUrl,
      "contentType": "application/json",
      "type": "GET",
    }

    if (page === 'Reports') {
      ajaxOptions.data = {};
      if (type === "sale") {
        ajaxOptions.data.status = status
      }
      if (type === "topup") {
        ajaxOptions.data.transactionType = transactionType
        ajaxOptions.data.pageSize = pageSize
        ajaxOptions.data.pageNo = pageNo
        ajaxOptions.data.status = ''
      }
      ajaxOptions.data.startDate = startDate
      ajaxOptions.data.endDate = endDate
      ajaxOptions.data.type = type
      ajaxOptions.data.pageSize = pageSize
      ajaxOptions.data.pageNo = pageNo
      if (reportLoad) {
        $(current).DataTable().state.clear()
      }
    }

    if (type === 'keyReport') {
      ajaxOptions.data = {};
      if (startDate) {
        ajaxOptions.data.startDate = startDate
      }
      if (endDate) {
        ajaxOptions.data.endDate = endDate
      }
      if (product_key) {
        ajaxOptions.data.product_key = product_key
      }
      ajaxOptions.data.type = type
    }

    if (page === 'ViewReports') {
      ajaxOptions.data = {};
      ajaxOptions.data.startDate = startDate
      ajaxOptions.data.endDate = endDate
      ajaxOptions.data.type = type
      ajaxOptions.data.transactionType = transactionType
      ajaxOptions.data.pageSize = pageSize
      ajaxOptions.data.pageNo = pageNo
      ajaxOptions.data.merchentId = merchentId

      if (reportLoad) {
        $(current).DataTable().state.clear()
      }
    }

    if (page === 'ViewOrders') {
      ajaxOptions.data = {};
      if (orderType === "product") {
        ajaxOptions.data.productId = productId
      }
      if (orderType === "merchant") {

        ajaxOptions.data.merchentId = merchentId
      }
      if (startDate) {
        ajaxOptions.data.startDate = startDate
      }
      if (endDate) {
        ajaxOptions.data.endDate = endDate

      }
      if (status) {
        ajaxOptions.data.status = status
      }
      if (orderType) {
        ajaxOptions.data.orderType = orderType
      }

      if (reportLoad) {
        $(current).DataTable().state.clear()
      }
    }
    if (page === 'Orders') {
      ajaxOptions.data = {};

      if (startDate) {
        ajaxOptions.data.startDate = startDate
      }
      if (endDate) {
        ajaxOptions.data.endDate = endDate

      }
      if (reportLoad) {
        $(current).DataTable().state.clear()
      }
    }
    const lenMenu1 = [10, 50, 100]
    const lenMenu2 = [150, 100, 50]
    datatable = $(current).DataTable({
      destroy: true,
      "serverSide": true,
      stateSave: true,
      "processing": true,
      "paging": true,
      "searching": { "regex": true },
      "pagingType": "numbers",
      "lengthMenu": props && props.numOfRow ? lenMenu1 : lenMenu2,
      scrollX: true,
      "order":[[0,"desc"]],
      "ajax": {

        ...ajaxOptions,
        // "dataSrc": function (d) {
        //   datalength = d.data.length;
        //   return d.data;
        // },
        "dataSrc": function (d) {
          //csvDataHandler(d)
          let dtInfo = $('.table').DataTable().page.info()
          dtInfo = dtInfo ? dtInfo : [];
          console.log(dtInfo, "dtInfo......")
          let len = dtInfo.length
          let pgNo = dtInfo.page
          let pageCount = ((len*pgNo)-pgNo) + pgNo+1
          datalength = d.data.length;
          console.log(datalength, "datalength..........")
          return d.data.map((d, i) => {
            return {...d, pageCount: pageCount + i }
          });
        },
        error: function (err) {
          if (err.status === 401) {
            history.push('/login')
          }
          else {
            toaster("Something went wrong", 'error')
          }
        }
      },
      fnDrawCallback: function () {
        if (datalength <= 0) {
          $(current).find(".select-checkbox").removeClass("select-checkbox");
        }
        $(current).find(".select-checkbox").removeClass("sorting_disabled");
        $(current).find(".select-checkbox").removeClass("sorting_asc")
        $(current).find(".select-checkbox").removeClass("sorting_1");
      },
      "language": {
        sInfoFiltered: "",
      },

      columns: columns,
      // columnDefs: [
      //   {
      //     orderable: false,
      //     className: props.hideCheckbox ? '' : "select-checkbox",
      //     targets: 0,
      //     sortable: false
      //   }
      // ],
      // select: {
      //   style: "multi",
      //   // style: "os",
      //   selector: "td:first-child"
      // },
      ...(props.options || {}),
    });
    $(document)
      .on("click", "th.select-checkbox", function () {
        if ($("th.select-checkbox").hasClass("selected")) {
          datatable.rows().deselect();
          $("th.select-checkbox").removeClass("selected");
        } else {
          datatable.rows().select();
          $("th.select-checkbox").addClass("selected");
        }
      })
      .on("select deselect", function () {
        ("Some selection or deselection going on");
        if (
          datatable
            .rows({
              selected: true
            })
            .count() !== datatable.rows().count()
        ) {
          $("th.select-checkbox").removeClass("selected");
        } else {
          $("th.select-checkbox").addClass("selected");
        }
      });

    $(".table").on('search.dt', function (e) {
      var value = $('.dataTables_filter input').val();
      props.setKeySearch && props.setKeySearch(value)
    });

    $(document)
      .on("click", ".view-details", function () {
        let datarow = $(this).attr('data-row');
        props.handleDetail && props.handleDetail(datarow);
      });
    $(document)
      .on("click", ".viewMerchant", function () {
        let datarow = $(this).attr('data-row');
        handleMerchant && handleMerchant(datarow);
      });
    $(document)
      .on("click", ".viewproduct", function (e) {
        let datarow = $(this).attr('data-row');
        props.viewProduct && props.viewProduct(e, datarow);
      });

    $(document)
      .on("click", ".delete-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setShowDeleteModal(true)
      });
    $(document)
      .on("click", ".delete-master-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setShowDeleteModal(true)
      });
    $(document)
      .on("click", ".productactivate-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setshowActivateModal(true)
      });
    $(document)
      .on("click", ".merchantActivate-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setshowActivateModal(true)
      });

    $(document)
      .on("click", ".brandactivate-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setshowActivateModal(true)
      });
    $(document)
      .on("click", ".adminActivate-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setshowActivateModal(true)
      });
    $(document)
      .on("click", ".reStock", function () {
        let datarow = $(this).attr('data-row');
        reStock && reStock(datarow);
      });
    $(document)
      .on("click", ".transaction", function () {
        let datarow = $(this).attr('data-row');
        let dataref = $(this).attr('data-refId');
        let dataRefProductId = $(this).attr('data-refProductId');
        transactionModal && transactionModal(datarow, dataref, dataRefProductId);
      });
    $(document)
      .on("click", ".editProduct", function (e) {
        let datarow = $(this).attr('data-row');
        editProduct && editProduct(e, datarow);
      });

    $(document)
      .on("click", ".editAgent", function () {
        let datarow = $(this).attr('data-row');
        editAgent && editAgent(datarow);
      });
    $(document)
      .on("click", ".editadminAgent", function (e) {
        let datarow = $(this).attr('data-row');
        editadminAgent && editadminAgent(e, datarow);
      });

    $(document)
      .on("click", ".viewagent", function (e) {
        let datarow = $(this).attr('data-row');
        props.viewadminAgent && props.viewadminAgent(e, datarow);
      });
    $(document)
      .on("click", ".viewOrder", function (e) {
        let datarow = $(this).attr('data-row');
        viewOrder && viewOrder(e, datarow);
      });
    $(document)
      .on("click", ".view-orders", function (e) {
        let datarow = $(this).attr('data-row');
        testViewOrder && testViewOrder(e, datarow);
      });

    $(document)
      .on("click", ".merchantView", function (e) {
        let datarow = $(this).attr('data-row');
        handleViewMerchant && handleViewMerchant(e, datarow);
      });
    $(document)
      .on("click", ".topUpHistory", function (e) {
        let datarow = $(this).attr('data-row');
        handleTopupHistoryView && handleTopupHistoryView(e, datarow);
      });
    $(document)
      .on("click", ".merchantEdit", function (e) {
        let datarow = $(this).attr('data-row');
        handleEditMerchant && handleEditMerchant(e, datarow);
      });

    $(document)
      .on("click", ".handleView", function (e) {
        let datarow = $(this).attr('data-row');
        handleView && handleView(e, datarow);
      });
    $(document)
      .on("click", ".handleEdit", function (e) {
        let datarow = $(this).attr('data-row');
        handleEdit && handleEdit(e, datarow);
      });

    $(document)
      .on("click", ".masterView", function (e) {
        let datarow = $(this).attr('data-row');
        handleMasterView && handleMasterView(e, datarow);
      }
      );

    $(document)
      .on("click", ".masterEdit", function (e) {
        let datarow = $(this).attr('data-row');
        handleEditMaster && handleEditMaster(e, datarow);
      }
      );

      //
      $(document)
      .on("click", ".customerInfoView", function (e) {
        let datarow = $(this).attr('data-row');
        handleCustomerInfoView && handleCustomerInfoView(e, datarow);
      }
      );
      $(document)
      .on("click", ".customerInfoEdit", function (e) {
        let datarow = $(this).attr('data-row');
        handleCustomerInfoEdit && handleCustomerInfoEdit(e, datarow);
      }
      );
      //

    $(document)
      .on("click", ".editBrand", function (e) {
        let datarow = $(this).attr('data-row');
        editBrand && editBrand(datarow);
      });
    $(document)
      .on("click", ".viewBrand", function (e) {
        let datarow = $(this).attr('data-row');
        viewBrand && viewBrand(datarow);
      });
    $(document)
      .on("click", ".viewsupport", function (e) {
        let datarow = $(this).attr('data-row');
        props.viewSupport && props.viewSupport(e, datarow);
      });

    //
    $(document)
      .on("click", ".customeractivate-details", function () {
        let datarow = $(this).attr('data-row');
        setDataRow(datarow);
        setshowActivateModal(true)
      });
    //

    $(document)
      .on("click", ".editSupport", function (e) {
        let datarow = $(this).attr('data-row');
        editSupport && editSupport(e, datarow);
      });

    $(document)
      .on("click", ".topUp", function () {
        let datarow = $(this).attr('data-row');
        let dataname = $(this).attr('data-name');
        topUp && topUp(datarow, dataname);
      });

    $(document)
      .on("click", ".topUpHistory", function () {
        let datarow = $(this).attr('data-row');
        let dataname = $(this).attr('data-name');
        topUpHistory && topUpHistory(datarow, dataname);
      });

    $(document)
      .on("click", ".pullProductKey", function () {
        let datarow = $(this).attr('data-row');
        let dataquantity = $(this).attr('data-quantity');
        props.showKey && props.showKey(datarow, dataquantity);
      });

    $(document).off('click',".editBrand")
      .on("click", ".editBrand", function () {
        let datarow = $(this).attr('data-row');
        editBrand && editBrand(datarow);
      });
    $(document).off('click',".viewBrand")
      .on("click", ".viewBrand", function () {
        let datarow = $(this).attr('data-row');
        viewBrand && viewBrand(datarow);
      });

  }


  return (
    <>
      <table className="table table-responsive" width="100%" ref={props.dataref} />
      <Modal
        showModal={showDeleteModal}
        closeModal={closeModal}
        id="restock"
        modalHeader="Are you sure?"
        size="small">
        <div className="col-md-12 deleteText">
          <p>Do you really want to delete this record?</p>
        </div>
        <div className="modal-footer">
          <button type="button" style={{ marginRight: '10px' }} className="btn btn-info" onClick={closeModal}>Cancel</button>
          <button type="button" className="btn btn-danger" onClick={deleteDetail}>Delete</button>
        </div>
      </Modal>
      <Modal
        showModal={showActivateModal}
        closeModal={closeModal}
        id="activate"
        modalHeader="Are you sure?"
        size="small">
        <div className="col-md-12 deleteText">
          <p>Do you really want to activate this record?</p>
        </div>
        <div className="modal-footer">
          <button type="button" style={{ marginRight: '10px' }} className="btn btn-info" onClick={closeModal}>Cancel</button>
          <button type="button" className="btn btn-success" onClick={activateDetail}>Activate</button>
        </div>
      </Modal>
    </>
  )
})

export default OrderDataTable