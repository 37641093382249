export default function topupApi(http) {
  
    return {
      create: (topup) => {
        return http.post('/api/topup', topup)
      },
      getTopups:()=>{
        return http.get('/api/topups')
      }
    }
  }
  