import { httpClient } from './httpClient'
import { todosApi } from './todosApi'
import productApi from './product';
import merchantApi from './merchant'
import brandApi from './brand';
import orderApi from './order';
import authenticateApi from './authenticate'
import uploadApi from './upload';
import usersApi from './users';
import topupApi from './topup';
import dashboardApi from './dashboard';
import logsApi from './logs';
import reportsApi from './reports';

import customerInfoApi from './customerinfo'

export function apiFactory(http) {
  return {
    todos: todosApi(http),
    authenticate: authenticateApi(http),
    products: productApi(http),
    merchants: merchantApi(http),
    products: productApi(http),
    brands: brandApi(http),
    orders: orderApi(http),
    upload: uploadApi(http),
    users: usersApi(http),
    topup: topupApi(http),
    deleteMerchant: merchantApi(http),
    dashboard: dashboardApi(http),
    logs: logsApi(http),
    reports: reportsApi(http),
    dashboardGraph: dashboardApi(http),
    customerInfo: customerInfoApi(http)
  }
}
const http = httpClient(process.env.dataUrl)
export const api = apiFactory(http)
