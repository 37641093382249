export const keepOpen = id => {
    // console.log($('.hide', ' hideeeee' ))
   // console.log($('.keep-opennnnnnnnnnnnnn'))
   // console.log($('.shown'))
   // console.log($('.dropdown'))
   $(`.dropdown${id}`).on({
     "shown.bs.dropdown": function() { this.closable = true },
     // "hide.bs.dropdown":  function() { return this.closable; },
     "hide.bs.dropdown": function(e){
     if(!this.closable){
         this.closable = true;
         return false;
     }
       return this.closable
     },
     "click": function(e){
       this.closable = false;
     }
 });    
 }