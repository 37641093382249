/**
 * Roles : 
 * 1- speradmin
 * 2-admin
 * 3-merchant
 * 4-CSA
 */
export default {
    dashboard: [1, 2, 3, 4],
    brands: [1, 2],
    addBrands: [1, 2],
    editBrands: [1, 2],
    viewBrands: [1, 2],
    merchants: [1, 2, 4],
    products: [1, 2],
    masterProduct: [1, 2],
    productDenomination: [1, 2],
    editProduct: [1, 2, 3],
    infoProduct: [1, 2, 3],
    bulkUpload: [1],
    orders: [1, 2, 3, 4],
    adminAgent: [1],
    addAgent: [1],
    viewAgent: [1],
    editAgent: [1],
    reports: [1, 2, 3, 4],
    logs: [1, 2],
    addMerchants: [1, 2],
    viewMerchants: [1, 2, 3, 4],
    editMerchants: [1, 2],
    orderDetails: [1, 2, 3, 4],
    settings: [1, 2, 3, 4],
    customerSupport: [1, 2],
    addSupport: [1, 2],
    viewSupport: [1, 2],
    editSupport: [1, 2],
    addProduct: [1, 2, 3],
    addOrder: [3],
    // addOrder: [2,3,4],
    viewOrder: [1, 2, 3, 4],
    orderInfo: [1, 2, 3, 4],
    invoice: [3, 4],
    profile: [1, 2, 3, 4],

    orderProduct: [1, 2],
    createOrder: [1, 2, 3],
    purchaseProduct: [1, 2, 3],
    viewReport:[1,2,3,4],

    pullList: [ 0 ],

    customerInfo: [1, 2],
    customerInfoAdd: [1, 2],
    customerInfoEdit: [1, 2],
    customerInfoView: [1, 2],

    editMerchantRequest: [1, 2],
    merchantRequests: [1, 2, 4],
    merchantAgreement: [1, 2,3, 4],
    viewMerchantAgreement: [1, 2,3, 4]
}