import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik';
import { Link } from "react-router-dom";

import Schema from './loginValidationSchema';
import Input from '../../../../components/input';
import { imagePath } from "../../../../utils/assetUtils";
const LoginImage = imagePath("login-logo.png");
import { useAuth } from '../../../../components/auth/AuthRoute'
import { api } from '../../../../api';
import { toaster } from '../../../../components/toaster';
import Loader from "../../../../components/loader/Loader";
import usePasswordToggle from "../../../../components/hooks/usePasswordToggle";

export default function Login(props) {
  const [isAuthenticate] = useAuth();
  const [loading, setLoading] = useState(false)
  const [PwdInputType, ToggleIcon] = usePasswordToggle();
  const history = useHistory()
  useEffect(() => {
    isAuthenticate && history.push('/')
  })
  useEffect(() => {
    setLoading(false)
  })
  return (
    <Formik
      validationSchema={Schema}
      initialValues={{
        username: '',
        password: ''
      }}
      onSubmit={({ username, password } = values, { resetForm }) => {
        setLoading(true)
        const data = { username, password }
        api.authenticate.loginAuthenticate(data)
          .then(res => {
            if (res.code === 200) {
              resetForm()
              setLoading(false)
              history.push('/')
            }
            else {
              toaster(res.message, 'error')
              resetForm();
            }
          })
          .catch(err => {
            resetForm()
            toaster("Oops! Something went wrong", 'error')
          })
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting

      }) => {
        return (

          <div className="container">
            <div className="login-main">
              <div className="card">
                <div className="welcome">
                  <h5>Welcome Back !</h5>
                  <p>Sign in to continue to Nodwin.</p>
                </div>

                <div className="card-body">
                  <img className="login-logo" src={LoginImage} alt="login-logo" />

                  <form noValidate="" className="form-horizontal" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <Input
                        id="userName"
                        name="username"
                        placeholder="Enter User name"
                        type="text"
                        label="User Name"
                        value={values.username}
                        onChange={handleChange}
                        error={touched.username && errors.username}
                        errorText={errors.username}
                        disabled={isSubmitting} />
                    </div>

                    <div className="form-group">
                      <Input
                        style={{ width: '100%' }}
                        id="password"
                        name="password"
                        placeholder="password"
                        type={PwdInputType}
                        label="Password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && errors.password}
                        errorText={errors.password}
                        disabled={isSubmitting}
                        isToggle={ values.password ? true: false }
                        toggleIcon={ ToggleIcon } />
                    </div>

                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="customControlInline"
                          className="custom-control-input"
                        />
                        <label
                          htmlFor="customControlInline"
                          className="custom-control-label"
                        >
                          Remember me
                      </label>
                      </div>
                    </div>

                    <div className="form-group login-btn">
                      <button disabled={isSubmitting} type="submit" className="login-btn1 btn mt-3 ">
                        {loading ? <Loader /> : 'Log In'}
                      </button>
                    </div>
                    <div className="text-center">
                      <Link to="/register">
                        <i className="bx bxs-user"></i> Register
                    </Link>
                    </div>
                    <div className="forgot text-center">
                      <Link to="/forgot">
                        <i className="bx bxs-lock"></i> Forgot your password?
                    </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Formik>
  );
}
