export default function reportsApi(http) {
  return {
    getReports: () => {
      return http.get("/api/reports")
    },
    getReportsData: (data) => {
      return http.post("/api/getReports", data)
    },
    getProductKeyLis: (data) => {
      return http.post("/api/productKeysList", data)
    },
    export: (key) => {
      return http.post('/api/export-report', key)
    },
    getMerchantSummary: (data) => {
      return http.post('/api/getMerchentSummary', data)
    },
    exportSoldQuantityReport: (data) => {
      return http.post('/productQuantitySoldbyMerchentReport', data)
    },
    exportMerchantMarginReport: (data) => {
      return http.post('/merchantMarginReport', data)
    },
  }
}
//reports
