import React from "react";
import Prototype from "prop-types";
export default function FleUpload(props) {
  const {
    labelText,
    id,
    onChange,
    name,
    error,
    errorText,
    disabled,
    value,
    changeFileAlign,
    uploadAlign,
    viewFile,
    viewProof,
    onClick
  } = props;

  return (
    <>
      <label htmlFor={id}>{labelText}</label>{" "}
      <br />
      <div>
        <div
          className="fileUpload btn btn-primary"
          style={{
            margin: changeFileAlign ? "10px 0" : uploadAlign ? "7px 10px" : "",
            padding: changeFileAlign && "6px 25px",
          }}
        >
          <span>Browse</span>
          <input
            name={name}
            onClick={onClick}
            onChange={onChange}
            id={id}
            type="file"
            className="upload"
            disabled={disabled}
          />
        </div>
        {viewFile && (
          <button
            className="btn"
            variant="primary"
            style={{ width: "30%" }}
            onClick={() => {
              viewProof();
            }}
          >
            {" "}
            View{" "}
          </button>
        )}
      </div>
      <input
        value={viewFile ? "" : value}
        id="uploadFile"
        placeholder="Choose File"
        disabled="disabled"
        style={{ width: "100%" }}
      />
      <div>{error && <small className="text-danger">{errorText}</small>}</div>
    </>
  );
}
FleUpload.protoType = {
  name: Prototype.string.isRequired,
  labelText: Prototype.string.isRequired,
  id: Prototype.string.isRequired,
  value: Prototype.string.isRequired,
  onChange: Prototype.func.isRequired,
  errorText: Prototype.string,
  error: Prototype.bool,
  disabled: Prototype.bool,
};
