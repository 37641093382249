import React, { useEffect } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useAuth } from '../auth/AuthRoute';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticate, loading] = useAuth()
  return (
    !loading && (
      <Route
        {...rest}
        render={props =>
          isAuthenticate ? (
            <Component {...props} {...rest} />
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location
                  }
                }}
              />
            )
        }
      />
    )
  );
};
export default withRouter(ProtectedRoute);